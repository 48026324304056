import React, { Component, Suspense } from "react";
// import "./Main.css";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes } from "../../../routes/index";
import { withRouter } from "react-router-dom";
import Loader from "../../Shared/Loader/Loader";
import Toaster from "../../Shared/Toaster/Toaster";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import "./DefaultLayout.scss";
import io from "socket.io-client";
import Configuration from "../../../config/configuration";
import { connect } from "react-redux";
import { actions } from "../../../store/actions/index";
import LocalStoreService from "../../../services/localStore-service";
import CommonService from "../../../services/common-service";
import * as Scroll from "react-scroll";
import { Helmet } from "react-helmet";

import ReactGA from "react-ga";
const TRACKING_ID = "UA-84379034-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const MobileFooter = React.lazy(() => import("../../Shared/MobileFooter/MobileFooter"));
const MobileHeader = React.lazy(() => import("../../Shared/MobileHeader/Mobile.header"));
const Notification = window.Notification || window.mozNotification || window.webkitNotification;

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.config = new Configuration();
    this.localStorageService = new LocalStoreService();
    this.commonService = new CommonService();

    this.state = {
      componentClass: "home-page",
      prevPath: "",
      socket: "",
      receiverId: this.localStorageService.getUserId(),
      metaData: {},
      size: window.innerWidth,
    };
  }

  componentDidMount() {
    if (Notification) {
      Notification.requestPermission(function (permission) {});
    }
    this.changePageClass(this.props.location);
    this.props.history.listen((location, action) => {
      this.changePageClass(location);
      this.scrollToTop();
    });
    this.setSocketToRedux();
    this.setState({ size: window.innerWidth });
  }

  setSocketToRedux = () => {
    const socket = io(this.config.apiBaseUrl);
    socket.on("connect", () => {
      console.log("Connected");
    });
    socket.on("browserReload", function () {
      document.location.reload(true);
    });
    this.props.setSocket(socket);
    this.setState({ socket }, () => {
      this.setSocketId();
      this.initNotifications();
    });
  };

  initNotifications = () => {
    const { socket, receiverId } = this.state;
    if (receiverId) {
      socket.on(`initiate-chat-notification-${receiverId}`, (notificationObj, chatId) => {
        let message = { title: notificationObj.title, chatId };
        this.showDesktopNotification(message, "", "", "", "");
      });
    }
  };

  requestNotificationPermissions() {
    if (Notification && Notification.permission !== "denied") {
      Notification.requestPermission(function (permission) {});
    }
  }

  showDesktopNotification(message, body, icon, sound, timeout) {
    if (!timeout) {
      timeout = 400000;
    }
    this.requestNotificationPermissions();
    var instance = new Notification(message.title, {
      body: body,
      icon: icon,
      sound: sound,
    });
    instance.onclick = () => {
      this.props.history.push("/dashboard/messages", {
        chatId: message.chatId,
      });
    };
    instance.onerror = function () {
      // Something to do
    };
    instance.onshow = function () {
      // Something to do
    };
    instance.onclose = function () {
      // Something to do
    };
    setTimeout(instance.close.bind(instance), timeout);
    return false;
  }

  setSocketId = () => {
    const { socket } = this.state;
    let profile = this.localStorageService.getUserProfile();
    if (profile) {
      let id = profile.userId;
      let username = profile.username;
      let name = profile.firstName;
      socket.emit("VERIFY_USER", id, name, username, this.setUser);
    }
  };

  setUser = ({ user, isUser }) => {
    const { socket } = this.state;
    socket.emit("USER_CONNECTED", user);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.scrollToTop();
      this.setState({ prevPath: prevProps.location.pathname });
    }
  }

  getProtectedRoute = (path, component, exact) => {
    if (!this.isAuth()) {
      if (path.includes("checkout")) {
        // this.localStorageService.setLoginRedirection(
        //   "/accessories-spare-parts/search/-/sId_autodeals_37"
        // );
        this.localStorageService.setLoginRedirection("/dashboard/ads");
      }
      return <Redirect key={path} exact path={path} to="/auth/signin" />;
    }

    return <Route key={path} exact={exact} path={path} component={component} />;
  };

  getOnlyPublicRoute = (path, component, exact) => {
    // if (this.isAuth()) {
    //   const { prevPath } = this.state;
    //   return <Redirect key={path} exact to={'/'} />;
    //   // window.history.back();
    // }

    return <Route key={path} exact={exact} path={path} component={component} />;
  };

  changePageClass(location) {
    console.log(this.props);
    //location.pathname.split('/')[2] ? location.pathname.split('/')[2] :

    let routeObj = routes.appRoutes.filter((x) => x.path.includes(location.pathname.split("/")[2] ? location.pathname.split("/")[2] : location.pathname.split("/")[1]))[0];
    // debugger
    if (location.pathname.split("/")[2] === "pricelist") {
      routeObj = routes.appRoutes.filter((x) => x.path.includes(location.pathname.split("/")[1]))[0];
    }
    console.log("route obj", routeObj);
    if (routeObj) {
      this.setState(
        {
          componentClass: routeObj.className,
        },
        () => {
          this.getPageMetaData();
        }
      );
    } else if (location.pathname.split("/")[2]) {
      routeObj = routes.appRoutes.filter((x) => x.path.includes(location.pathname.split("/")[1]) && x.path.split("/")[2] && x.path.split("/")[2].includes(":"))[0];
      if (routeObj) {
        this.setState(
          {
            componentClass: routeObj.className,
          },
          () => {
            this.getPageMetaData();
          }
        );
      }
    } else {
      routeObj = routes.appRoutes.filter((x) => x.path.includes(location.pathname.split("/")[1]));
      routeObj = routeObj[1] ? routeObj[1] : routeObj[0];
      if (routeObj) {
        this.setState(
          {
            componentClass: routeObj.className,
          },
          () => {
            this.getPageMetaData();
          }
        );
      }
    }
  }

  isAuth = () => {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  };

  loading = () => {
    return (
      <div className="animated fadeIn pt-1 text-center">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    );
  };

  scrollToTop = () => {
    if ("scrollRestoration" in window.history) {
      // Back off, browser, I got this...
      window.history.scrollRestoration = "manual";
    }
    Scroll.animateScroll.scrollToTop();
  };

  getPageMetaData = () => {
    const { componentClass } = this.state;
    // debugger
    this.commonService
      .getPageMetaData(componentClass)
      .then((res) => {
        const { data } = res;
        this.setState(
          {
            metaData: data,
          },
          () => {
            this.props.setMetaData(data);
          }
        );
      })
      .catch((err) => {
        this.commonService.handleError(err);
      });
  };

  renderMetaTag = () => {
    const metaData = this.props.metaData ? this.props.metaData : this.state.metaData;
    return (
      metaData &&
      metaData.title && (
        <Helmet>
          <title>{metaData.title ? metaData.title + " | AutoDeals" : ""} </title>
          <meta name="title" content={metaData.title ? metaData.title + " | AutoDeals" : ""} />
          <meta name="description" content={metaData.description ? metaData.description + " | AutoDeals" : ""} />
          <meta property="og:title" content={metaData.title ? metaData.title + " | AutoDeals" : ""} />
          <meta property="og:description" content={metaData.description ? metaData.description + " | AutoDeals" : ""} />
          <meta property="og:image" itemprop="image" content="https://autodeals-fe-server.herokuapp.com/logo.png" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="www.autodeals.pk" />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
          <meta property="og:site_name" content="Autodeals.pk"></meta>
        </Helmet>
      )
    );
  };

  render() {
    const { socket, size } = this.state;
    const path = this.props.location.pathname;

    return (
      <div className={this.state.componentClass}>
        <BreadcrumbsItem to="/">Home</BreadcrumbsItem>

        {this.renderMetaTag()}
        <Suspense fallback={this.loading()}>
          {!path.includes("/post-ad/sell-car/post-your-ad") && !path.includes("/post-ad/sell-bike") && !path.includes("/post-ad/sell-part") ? (
            <>
              <div className="web-view">
                <Header socket={socket} />
              </div>
            </>
          ) : (
            ""
          )}
          {size < 481 && (
            <div className="mobile-view">
              <MobileHeader socket={socket} />
            </div>
          )}
        </Suspense>
        <Loader />
        <Toaster />

        <Suspense fallback={this.loading()}>
          <Switch>
            {routes.appRoutes.map((route, i) => {
              if (route.access === "public") {
                return <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />;
              } else if (route.access === "onlyPublic") {
                return this.getOnlyPublicRoute(route.path, route.component, route.exact);
              } else {
                return this.getProtectedRoute(route.path, route.component, route.exact);
              }
            })}
            {/* Redirection */}
            <Route
              path="/testimonials"
              component={() => {
                window.location = "/";
              }}
            />
            <Route
              path="/import"
              component={() => {
                window.location = "/car-import";
              }}
            />
            <Route
              path="/new/cars"
              component={() => {
                window.location = "/new-cars";
              }}
            />
            <Route
              path="/loginregister"
              component={() => {
                window.location = "/auth/signin";
              }}
            />
            <Route
              path="/custom-duty-calculator"
              component={() => {
                window.location = "/car-import/custom-duty-calculator";
              }}
            />
            <Route
              path="/custom-clearance"
              component={() => {
                window.location = "/car-import/custom-duty-calculator";
              }}
            />
            <Route
              path="/verify-auction-sheet"
              component={() => {
                window.location = "/auction-sheet-verification";
              }}
            />
            <Route
              path="/best-cost-effective-660cc-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/best-cost-effective-660cc-cars-in-pakistan";
              }}
            />
            <Route
              path="/car-registration"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-registration-process-in-lahore";
              }}
            />
            <Route
              path="/best-affordable-japanese-cars-available-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/best-cost-effective-660cc-cars-in-pakistan";
              }}
            />
            <Route
              path="/honda-city-7th-generation-soon-to-launch-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-city-7th-generation-introduces-in-global-market-soon";
              }}
            />
            <Route
              path="/how-to-transfer-ownership-of-a-vehicle-in-karachi-complete-guide"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-transfer-ownership-of-a-vehicle-in-karachi-complete-guide/";
              }}
            />
            <Route
              path="/honda-civic-city-gets-new-model-in-2021"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-civic-city-gets-new-model-in-2021/";
              }}
            />
            <Route
              path="/honda-civic-city-gets-new-model-in-2021"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-civic-city-gets-new-model-in-2021/";
              }}
            />
            <Route
              path="/10-tips-to-help-you-drive-safely-in-the-nigh"
              component={() => {
                window.location = "https://autodeals.pk/blog/10-tips-to-help-you-drive-safely-in-the-nigh/";
              }}
            />
            <Route
              path="/kia-sportage-2019-pakistan-detail-review"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-sportage-2019-pakistan-detail-review/";
              }}
            />
            <Route
              path="/pre-booking-of-prince-pearl-start-how-to-book-prince-pearl"
              component={() => {
                window.location = "https://autodeals.pk/blog/pre-booking-of-prince-pearl-start-how-to-book-prince-pearl/";
              }}
            />
            <Route
              path="/author/anwar"
              component={() => {
                window.location = "https://autodeals.pk/blog/author/andleebsidhu/";
              }}
            />
            <Route
              path="/the-arriving-of-7th-gen-hyundai-elantra-makes-6th-gen-out-of-date-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-arriving-of-7th-gen-hyundai-elantra-makes-6th-gen-out-of-date-in-pakistan/";
              }}
            />
            <Route
              path="/how-to-easily-import-your-dream-car-from-japan-us-canada"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-easily-import-your-dream-car-from-japan-us-canada/";
              }}
            />
            <Route
              path="/import-cars-in-pakistan-1000cc-600cc-from-japan-from-uk-from-us-from-canada-autodeals-pk"
              component={() => {
                window.location = "https://autodeals.pk/blog/import-cars-in-pakistan-1000cc-600cc-from-japan-from-uk-from-us-from-canada-autodeals-pk/";
              }}
            />
            <Route
              path="/ignore-haptic-feedback-jaguar-is-looking-for-a-bright-future-for-knobs"
              component={() => {
                window.location = "https://autodeals.pk/blog/ignore-haptic-feedback-jaguar-is-looking-for-a-bright-future-for-knobs/";
              }}
            />
            <Route
              path="/seven-things-we-learned-from-toyota-and-mazdas-team-up-announcement"
              component={() => {
                window.location = "https://autodeals.pk/blog/seven-things-we-learned-from-toyota-and-mazdas-team-up-announcement/";
              }}
            />
            <Route
              path="/the-exceptionally-rare-rides-presenting-your-majesty-the-1966-prince-r380"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-exceptionally-rare-rides-presenting-your-majesty-the-1966-prince-r380/";
              }}
            />
            <Route
              path="the-car-of-your-dreams-toyota-corolla-altis-grande-2017-unveiled-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-car-of-your-dreams-toyota-corolla-altis-grande-2017-unveiled-in-pakistan/";
              }}
            />
            <Route
              path="/how-to-tell-when-its-time-for-a-wheel-balancing"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-tell-when-its-time-for-a-wheel-balancing/";
              }}
            />
            <Route
              path="/essential-things-to-know-before-buying-a-used-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/essential-things-to-know-before-buying-a-used-car/";
              }}
            />
            <Route
              path="top-5-most-safest-japanese-manufactured-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/top-5-most-safest-japanese-manufactured-cars-in-pakistan/";
              }}
            />
            <Route
              path="/meet-the-new-toyota-aqua-2018-first-look"
              component={() => {
                window.location = "https://autodeals.pk/blog/meet-the-new-toyota-aqua-2018-first-look/";
              }}
            />
            <Route
              path="/penalty-system-finally-revealed-in-lahore-traffic-police"
              component={() => {
                window.location = "https://autodeals.pk/blog/penalty-system-finally-revealed-in-lahore-traffic-police/";
              }}
            />
            <Route
              path="/smart-card-to-over-take-vehicle-registration-book-in-punjab-by-november-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/smart-card-to-over-take-vehicle-registration-book-in-punjab-by-november-2017/";
              }}
            />
            <Route
              path="/8-must-have-accessories-to-meet-the-needs-of-your-car-tyre"
              component={() => {
                window.location = "https://autodeals.pk/blog/8-must-have-accessories-to-meet-the-needs-of-your-car-tyre/";
              }}
            />
            <Route
              path="/shaheen-force-to-be-the-upcoming-traffic-control-in-punjab"
              component={() => {
                window.location = "https://autodeals.pk/blog/shaheen-force-to-be-the-upcoming-traffic-control-in-punjab/";
              }}
            />
            <Route
              path="/is-bmw-i8-the-affordable-hybrid-supercar"
              component={() => {
                window.location = "https://autodeals.pk/blog/is-bmw-i8-the-affordable-hybrid-supercar/";
              }}
            />
            <Route
              path="/autonomous-technology-to-be-unveiled-in-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/autonomous-technology-to-be-unveiled-in-2018/";
              }}
            />
            <Route
              path="/want-a-better-driving-experience-here-are-top-5-android-applications"
              component={() => {
                window.location = "https://autodeals.pk/blog/want-a-better-driving-experience-here-are-top-5-android-applications/";
              }}
            />
            <Route
              path="/a-glance-of-all-new-and-ground-breaking-2017-ford-fusion"
              component={() => {
                window.location = "https://autodeals.pk/blog/a-glance-of-all-new-and-ground-breaking-2017-ford-fusion/";
              }}
            />
            <Route
              path="/greet-the-new-audi-a8-2018-a-semi-autonomous-sedan-with-a-pristine-design"
              component={() => {
                window.location = "https://autodeals.pk/blog/greet-the-new-audi-a8-2018-a-semi-autonomous-sedan-with-a-pristine-design/";
              }}
            />
            <Route
              path="/tax-filers-to-pay-lesser-vehicle-transfer-fee-proclaims-fbr"
              component={() => {
                window.location = "https://autodeals.pk/blog/tax-filers-to-pay-lesser-vehicle-transfer-fee-proclaims-fbr/";
              }}
            />
            <Route
              path="/purple-perfection-the-rolls-royce-phantom-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/purple-perfection-the-rolls-royce-phantom-2018/";
              }}
            />
            <Route
              path="/chinese-car-faw-v2-to-initiate-its-assembling-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/chinese-car-faw-v2-to-initiate-its-assembling-in-pakistan/";
              }}
            />
            <Route
              path="/trouble-maintaining-suspension-of-your-vehicle-heres-how"
              component={() => {
                window.location = "https://autodeals.pk/blog/trouble-maintaining-suspension-of-your-vehicle-heres-how/";
              }}
            />
            <Route
              path="/entry-level-a3-launched-by-audi-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/entry-level-a3-launched-by-audi-pakistan/";
              }}
            />
            <Route
              path="/being-in-the-trance-of-infiniti-prototype-9"
              component={() => {
                window.location = "https://autodeals.pk/blog/being-in-the-trance-of-infiniti-prototype-9/";
              }}
            />
            <Route
              path="/dysin-unveiled-supreme-luxury-buses-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/dysin-unveiled-supreme-luxury-buses-in-pakistan/";
              }}
            />
            <Route
              path="/want-to-maintain-your-cars-interior-here-are-few-tips"
              component={() => {
                window.location = "https://autodeals.pk/blog/want-to-maintain-your-cars-interior-here-are-few-tips/";
              }}
            />
            <Route
              path="/regenerative-braking-in-hybrids-how-does-it-work"
              component={() => {
                window.location = "https://autodeals.pk/blog/regenerative-braking-in-hybrids-how-does-it-work/";
              }}
            />
            <Route
              path="/chinese-auto-manufacturers-great-wall-and-zyote-approaching-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/chinese-auto-manufacturers-great-wall-and-zyote-approaching-pakistan/";
              }}
            />
            <Route
              path="/2018-subaru-impreza-a-trendy-and-compact-sedan"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-subaru-impreza-a-trendy-and-compact-sedan/";
              }}
            />
            <Route
              path="/the-ford-gt-67-heritage-edition-contemporary-reminder-of-dan-gurney-and-a-j-foyt"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-ford-gt-67-heritage-edition-contemporary-reminder-of-dan-gurney-and-a-j-foyt/";
              }}
            />
            <Route
              path="/aqua-planing-a-drivers-awful-enemy-in-monsoon"
              component={() => {
                window.location = "https://autodeals.pk/blog/aqua-planing-a-drivers-awful-enemy-in-monsoon/";
              }}
            />
            <Route
              path="/the-new-2017-honda-city-tends-to-have-puzzled-customers-response"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-new-2017-honda-city-tends-to-have-puzzled-customers-response/";
              }}
            />
            <Route
              path="/geet-pakistans-first-latest-model-toyota-c-hr-1-8-hybrid-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/geet-pakistans-first-latest-model-toyota-c-hr-1-8-hybrid-2017/";
              }}
            />
            <Route
              path="/deciding-the-exact-spark-plugs-for-the-finest-performance-heres-the-deal"
              component={() => {
                window.location = "https://autodeals.pk/blog/deciding-the-exact-spark-plugs-for-the-finest-performance-heres-the-deal/";
              }}
            />
            <Route
              path="/2018-porsche-panamera-4-e-hybrid-the-car-of-future"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-porsche-panamera-4-e-hybrid-the-car-of-future/";
              }}
            />
            <Route
              path="/taxi-drivers-objection-in-opposition-to-online-cab-companies-in-islamabad"
              component={() => {
                window.location = "https://autodeals.pk/blog/taxi-drivers-objection-in-opposition-to-online-cab-companies-in-islamabad/";
              }}
            />
            <Route
              path="/meet-new-mazda-6-the-unsung-hero"
              component={() => {
                window.location = "https://autodeals.pk/blog/meet-new-mazda-6-the-unsung-hero/";
              }}
            />
            <Route
              path="/interior-alterations-in-new-toyota-corolla-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/interior-alterations-in-new-toyota-corolla-2017/";
              }}
            />
            <Route
              path="/considering-unsprung-weight-and-its-impact-on-your-daily-driving"
              component={() => {
                window.location = "https://autodeals.pk/blog/considering-unsprung-weight-and-its-impact-on-your-daily-driving/";
              }}
            />
            <Route
              path="/daytime-running-lights-what-are-they-and-how-do-they-work"
              component={() => {
                window.location = "https://autodeals.pk/blog/daytime-running-lights-what-are-they-and-how-do-they-work/";
              }}
            />
            <Route
              path="/analysis-of-automotive-policy-of-pakistan-2016-2021"
              component={() => {
                window.location = "https://autodeals.pk/blog/analysis-of-automotive-policy-of-pakistan-2016-2021/";
              }}
            />
            <Route
              path="/the-mercedes-maybach-vision-6-cabriolet-is-just-spectacular"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-mercedes-maybach-vision-6-cabriolet-is-just-spectacular/";
              }}
            />
            <Route
              path="/take-a-glance-at-the-bmw-z4-concept-car-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/take-a-glance-at-the-bmw-z4-concept-car-2018/";
              }}
            />
            <Route
              path="/facelift-honda-city-vs-toyota-corolla"
              component={() => {
                window.location = "https://autodeals.pk/blog/facelift-honda-city-vs-toyota-corolla/";
              }}
            />
            <Route
              path="/ferrari-portofinos-first-gaze"
              component={() => {
                window.location = "https://autodeals.pk/blog/ferrari-portofinos-first-gaze/";
              }}
            />
            <Route
              path="/mclaren-put-together-a-tremendously-purple-720s"
              component={() => {
                window.location = "https://autodeals.pk/blog/mclaren-put-together-a-tremendously-purple-720s/";
              }}
            />
            <Route
              path="/take-a-look-at-the-new-2018-acura-rlx"
              component={() => {
                window.location = "https://autodeals.pk/blog/take-a-look-at-the-new-2018-acura-rlx/";
              }}
            />
            <Route
              path="/things-you-need-to-check-under-the-hood"
              component={() => {
                window.location = "https://autodeals.pk/blog/things-you-need-to-check-under-the-hood/";
              }}
            />
            <Route
              path="/tips-to-avoid-car-rust"
              component={() => {
                window.location = "https://autodeals.pk/blog/tips-to-avoid-car-rust/";
              }}
            />
            <Route
              path="/indus-motor-companys-annual-profit-rises-to-rs-13-billion"
              component={() => {
                window.location = "https://autodeals.pk/blog/indus-motor-companys-annual-profit-rises-to-rs-13-billion/";
              }}
            />
            <Route
              path="/new-auto-firms-1b-investment-challenged-in-court"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-auto-firms-1b-investment-challenged-in-court/";
              }}
            />
            <Route
              path="/have-a-look-at-the-gorgeous-lamborghini-huracan-performante-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/have-a-look-at-the-gorgeous-lamborghini-huracan-performante-2017/";
              }}
            />
            <Route
              path="/petroleum-products-observed-overall-3-39-raise-throughout-last-financial-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/petroleum-products-observed-overall-3-39-raise-throughout-last-financial-year/";
              }}
            />
            <Route
              path="/govt-may-legalize-tariff-of-online-ride-hailing-services"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-may-legalize-tariff-of-online-ride-hailing-services/";
              }}
            />
            <Route
              path="/the-eye-catching-lexus-lc-500h-sport-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-eye-catching-lexus-lc-500h-sport-2017/";
              }}
            />
            <Route
              path="/petrol-prices-expected-to-stay-same-in-september-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-expected-to-stay-same-in-september-2017/";
              }}
            />
            <Route
              path="/the-potential-of-chinese-cars-in-pakistan-auto-industry"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-potential-of-chinese-cars-in-pakistan-auto-industry/";
              }}
            />
            <Route
              path="/an-overall-impression-of-faw-sirius-s80-grand"
              component={() => {
                window.location = "https://autodeals.pk/blog/an-overall-impression-of-faw-sirius-s80-grand/";
              }}
            />
            <Route
              path="/js-bank-joint-venture-with-bmw-x1"
              component={() => {
                window.location = "https://autodeals.pk/blog/js-bank-joint-venture-with-bmw-x1/";
              }}
            />
            <Route
              path="/a-quick-look-at-the-new-facelift-of-toyota-corolla-gli"
              component={() => {
                window.location = "https://autodeals.pk/blog/a-quick-look-at-the-new-facelift-of-toyota-corolla-gli/";
              }}
            />
            <Route
              path="/innovative-conceptualizations-preview-mini-electric-car-launching-in-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/innovative-conceptualizations-preview-mini-electric-car-launching-in-2019/";
              }}
            />
            <Route
              path="/7-driving-habits-destructive-for-your-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/7-driving-habits-destructive-for-your-car/";
              }}
            />
            <Route
              path="/follow-these-5-driving-tips-sail-through-monsoon-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/follow-these-5-driving-tips-sail-through-monsoon-in-pakistan/";
              }}
            />
            <Route
              path="/here-is-the-latest-porsche-cayenne-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-latest-porsche-cayenne-2018/";
              }}
            />
            <Route
              path="/suzukis-marketing-strategy-bring-used-car-and-get-brand-new-suzuki-ciaz"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzukis-marketing-strategy-bring-used-car-and-get-brand-new-suzuki-ciaz/";
              }}
            />
            <Route
              path="/here-is-the-new-bentley-continental-gt-a-grand-touring-beauty-626-horsepower"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-new-bentley-continental-gt-a-grand-touring-beauty-626-horsepower/";
              }}
            />
            <Route
              path="/lets-have-a-glance-at-2018-bmw-m5-with-600-hp-and-all-wheel-drive"
              component={() => {
                window.location = "https://autodeals.pk/blog/lets-have-a-glance-at-2018-bmw-m5-with-600-hp-and-all-wheel-drive/";
              }}
            />
            <Route
              path="/the-maserati-ghibli-gets-a-very-fine-facelift-for-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-maserati-ghibli-gets-a-very-fine-facelift-for-2018/";
              }}
            />
            <Route
              path="/gear-skipping-in-manual-transmission-is-risky"
              component={() => {
                window.location = "https://autodeals.pk/blog/gear-skipping-in-manual-transmission-is-risky/";
              }}
            />
            <Route
              path="/tips-to-clean-your-cars-engine-bay-like-a-pro"
              component={() => {
                window.location = "https://autodeals.pk/blog/tips-to-clean-your-cars-engine-bay-like-a-pro/";
              }}
            />
            <Route
              path="/honda-br-v-the-most-booming-7-seater-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-br-v-the-most-booming-7-seater-in-pakistan/";
              }}
            />
            <Route
              path="/how-faw-v2-contends-next-to-three-suzuki-hatchbacks"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-faw-v2-contends-next-to-three-suzuki-hatchbacks/";
              }}
            />
            <Route
              path="/honda-civic-record-its-sales-for-a-single-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-civic-record-its-sales-for-a-single-year/";
              }}
            />
            <Route
              path="/all-the-new-car-launches-to-anticipate-at-the-2017-frankfurt-motor-show"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-the-new-car-launches-to-anticipate-at-the-2017-frankfurt-motor-show/";
              }}
            />
            <Route
              path="/10-factors-affecting-the-fuel-economy"
              component={() => {
                window.location = "https://autodeals.pk/blog/10-factors-affecting-the-fuel-economy/";
              }}
            />
            <Route
              path="/mazda-working-on-next-generation-rotary-engine"
              component={() => {
                window.location = "https://autodeals.pk/blog/mazda-working-on-next-generation-rotary-engine/";
              }}
            />
            <Route
              path="/take-a-look-at-the-final-ferrari-laferrari-aperta"
              component={() => {
                window.location = "https://autodeals.pk/blog/take-a-look-at-the-final-ferrari-laferrari-aperta/";
              }}
            />
            <Route
              path="/nissan-leaf-unveiled-for-2018-more-power-range-features-an-economical-catch"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissan-leaf-unveiled-for-2018-more-power-range-features-an-economical-catch/";
              }}
            />
            <Route
              path="/5-things-to-stay-away-from-while-driving-automatic-transmission-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-things-to-stay-away-from-while-driving-automatic-transmission-vehicles/";
              }}
            />
            <Route
              path="/check-out-the-new-2018-mercedes-benz-e400-coupe"
              component={() => {
                window.location = "https://autodeals.pk/blog/check-out-the-new-2018-mercedes-benz-e400-coupe/";
              }}
            />
            <Route
              path="/how-to-stay-away-from-backache-while-driving"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-stay-away-from-backache-while-driving/";
              }}
            />
            <Route
              path="/when-the-monopoly-of-suzuki-pakistan-has-to-end"
              component={() => {
                window.location = "https://autodeals.pk/blog/when-the-monopoly-of-suzuki-pakistan-has-to-end/";
              }}
            />
            <Route
              path="/toyota-prado-is-finally-being-updated-here-are-2018-facelift-spy-shots"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-prado-is-finally-being-updated-here-are-2018-facelift-spy-shots/";
              }}
            />
            <Route
              path="/bmws-upcoming-concept-x7-iperformance"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmws-upcoming-concept-x7-iperformance/";
              }}
            />
            <Route
              path="/have-a-look-at-lamborghini-aventador-s-roadster"
              component={() => {
                window.location = "https://autodeals.pk/blog/have-a-look-at-lamborghini-aventador-s-roadster/";
              }}
            />
            <Route
              path="/take-a-look-at-the-gorgeous-2018-hyundai-tucson-highlander"
              component={() => {
                window.location = "https://autodeals.pk/blog/take-a-look-at-the-gorgeous-2018-hyundai-tucson-highlander/";
              }}
            />
            <Route
              path="/2017-tvr-griffith-launched-v8-two-seater-marks-official-come-back-of-uk-icon"
              component={() => {
                window.location = "https://autodeals.pk/blog/2017-tvr-griffith-launched-v8-two-seater-marks-official-come-back-of-uk-icon/";
              }}
            />
            <Route
              path="/here-are-5-guidelines-for-preventing-a-tire-blowout"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-are-5-guidelines-for-preventing-a-tire-blowout/";
              }}
            />
            <Route
              path="/1000-horsepower-mercedes-amg-project-one-hypercar-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/1000-horsepower-mercedes-amg-project-one-hypercar-revealed/";
              }}
            />
            <Route
              path="/should-pak-suzuki-be-swaping-the-aging-apv-with-ertiga-mpv"
              component={() => {
                window.location = "https://autodeals.pk/blog/should-pak-suzuki-be-swaping-the-aging-apv-with-ertiga-mpv/";
              }}
            />
            <Route
              path="/2018-bmw-530e-plug-in-hybrid-the-edrive-enigma"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-bmw-530e-plug-in-hybrid-the-edrive-enigma/";
              }}
            />
            <Route
              path="/this-is-the-450-horsepower-2018-audi-rs4-avant"
              component={() => {
                window.location = "https://autodeals.pk/blog/this-is-the-450-horsepower-2018-audi-rs4-avant/";
              }}
            />
            <Route
              path="/honda-amaze-privilege-edition-unveiled-in-india-at-inr-6-49-lac"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-amaze-privilege-edition-unveiled-in-india-at-inr-6-49-lac/";
              }}
            />
            <Route
              path="/things-to-ensure-on-your-car-before-a-long-trip"
              component={() => {
                window.location = "https://autodeals.pk/blog/things-to-ensure-on-your-car-before-a-long-trip/";
              }}
            />
            <Route
              path="/meet-the-2018-suzuki-swift-a-true-hot-hatch"
              component={() => {
                window.location = "https://autodeals.pk/blog/meet-the-2018-suzuki-swift-a-true-hot-hatch/";
              }}
            />
            <Route
              path="/the-most-anticipated-toyota-land-cruiser-prado-2018-is-finally-here"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-most-anticipated-toyota-land-cruiser-prado-2018-is-finally-here/";
              }}
            />
            <Route
              path="/the-auto-sector-of-pakistan-observes-a-healthy-growth-toyota-still-on-top"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-auto-sector-of-pakistan-observes-a-healthy-growth-toyota-still-on-top/";
              }}
            />
            <Route
              path="/2017-mazda-2-maxx-review"
              component={() => {
                window.location = "https://autodeals.pk/blog/2017-mazda-2-maxx-review/";
              }}
            />
            <Route
              path="/important-tips-that-will-help-you-save-fuel-and-money"
              component={() => {
                window.location = "https://autodeals.pk/blog/important-tips-that-will-help-you-save-fuel-and-money/";
              }}
            />
            <Route
              path="/do-you-retain-a-safe-distance-while-driving"
              component={() => {
                window.location = "https://autodeals.pk/blog/do-you-retain-a-safe-distance-while-driving/";
              }}
            />
            <Route
              path="/locally-assembled-2017-faw-v2-review"
              component={() => {
                window.location = "https://autodeals.pk/blog/locally-assembled-2017-faw-v2-review/";
              }}
            />
            <Route
              path="/legendary-and-wonderful-hennessy-venom-gt"
              component={() => {
                window.location = "https://autodeals.pk/blog/legendary-and-wonderful-hennessy-venom-gt/";
              }}
            />
            <Route
              path="/the-all-new-mitsubishi-xpander-7-seater-mpv"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-all-new-mitsubishi-xpander-7-seater-mpv/";
              }}
            />
            <Route
              path="/toyota-c-hr-hy-power-concept"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-c-hr-hy-power-concept/";
              }}
            />
            <Route
              path="/hybridized-honda-suv-the-new-cr-v-hybrid"
              component={() => {
                window.location = "https://autodeals.pk/blog/hybridized-honda-suv-the-new-cr-v-hybrid/";
              }}
            />
            <Route
              path="/toyota-dealerships-forcing-new-corolla-buyers-to-buy-extended-warranty"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-dealerships-forcing-new-corolla-buyers-to-buy-extended-warranty/";
              }}
            />
            <Route
              path="/toyota-reveals-new-gr-brand-in-japan-with-sportier-models"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-reveals-new-gr-brand-in-japan-with-sportier-models/";
              }}
            />
            <Route
              path="/here-is-the-mclaren-570gt-mso-xp-in-retro-green"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-mclaren-570gt-mso-xp-in-retro-green/";
              }}
            />
            <Route
              path="/have-a-2018-toyota-camry-hybrid-first-drive-who-needs-a-prius"
              component={() => {
                window.location = "https://autodeals.pk/blog/have-a-2018-toyota-camry-hybrid-first-drive-who-needs-a-prius/";
              }}
            />
            <Route
              path="/chinese-electric-cars-in-pakistan-shifeng-d101-is-currently-about-to-get-the-grabs"
              component={() => {
                window.location = "https://autodeals.pk/blog/chinese-electric-cars-in-pakistan-shifeng-d101-is-currently-about-to-get-the-grabs/";
              }}
            />
            <Route
              path="/2019-mercedes-benz-s560e-plug-in-hybrid-announced"
              component={() => {
                window.location = "https://autodeals.pk/blog/2019-mercedes-benz-s560e-plug-in-hybrid-announced/";
              }}
            />
            <Route
              path="/2018-honda-accord-enters-the-series-of-production"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-honda-accord-enters-the-series-of-production/";
              }}
            />
            <Route
              path="/petrol-shortage-in-lahore-creating-panic-among-motorists"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-shortage-in-lahore-creating-panic-among-motorists/";
              }}
            />
            <Route
              path="/3-signs-that-point-out-a-failing-axle-cv-shaft-assembly"
              component={() => {
                window.location = "https://autodeals.pk/blog/3-signs-that-point-out-a-failing-axle-cv-shaft-assembly/";
              }}
            />
            <Route
              path="/electric-vehicles-the-future-of-automakers-and-which-ones-to-get"
              component={() => {
                window.location = "https://autodeals.pk/blog/electric-vehicles-the-future-of-automakers-and-which-ones-to-get/";
              }}
            />
            <Route
              path="/kia-motors-starts-hiring-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-motors-starts-hiring-in-pakistan/";
              }}
            />
            <Route
              path="/gkn-etwinsterx-is-this-how-your-future-car-will-drive-its-wheels"
              component={() => {
                window.location = "https://autodeals.pk/blog/gkn-etwinsterx-is-this-how-your-future-car-will-drive-its-wheels/";
              }}
            />
            <Route
              path="/here-is-the-new-2017-suzuki-swift-rs-turbo"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-new-2017-suzuki-swift-rs-turbo/";
              }}
            />
            <Route
              path="/2018-volvo-xc40-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-volvo-xc40-revealed/";
              }}
            />
            <Route
              path="/mclaren-ultimate-vision-gt-virtual-race-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/mclaren-ultimate-vision-gt-virtual-race-car/";
              }}
            />
            <Route
              path="/the-all-new-mitsubishi-eclipse-cross"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-all-new-mitsubishi-eclipse-cross/";
              }}
            />
            <Route
              path="/how-to-remove-the-swirl-marks-from-your-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-remove-the-swirl-marks-from-your-car/";
              }}
            />
            <Route
              path="/beauty-within-the-beast-the-2018-nissan-x-trail"
              component={() => {
                window.location = "https://autodeals.pk/blog/beauty-within-the-beast-the-2018-nissan-x-trail/";
              }}
            />
            <Route
              path="/the-top-5-driving-distractions-to-avoid-while-driving"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-top-5-driving-distractions-to-avoid-while-driving/";
              }}
            />
            <Route
              path="/2018-porche-panamera-turbo-s-e-hybrid-sport-turismo-makes-680-hp"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-porche-panamera-turbo-s-e-hybrid-sport-turismo-makes-680-hp/";
              }}
            />
            <Route
              path="/2017-mazda-cx-9-touring-awd-arrival"
              component={() => {
                window.location = "https://autodeals.pk/blog/2017-mazda-cx-9-touring-awd-arrival/";
              }}
            />
            <Route
              path="/2018-audi-tt-rs-roadster-review"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-audi-tt-rs-roadster-review/";
              }}
            />
            <Route
              path="/honda-pakistan-brings-in-new-color-for-honda-civic-2018-what-are-your-views"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-pakistan-brings-in-new-color-for-honda-civic-2018-what-are-your-views/";
              }}
            />
            <Route
              path="/jaguar-land-rover-cars-develops-the-self-learning-inteligence"
              component={() => {
                window.location = "https://autodeals.pk/blog/jaguar-land-rover-cars-develops-the-self-learning-inteligence/";
              }}
            />
            <Route
              path="/2018-kia-stinger-the-korean-beauty"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-kia-stinger-the-korean-beauty/";
              }}
            />
            <Route
              path="/pak-suzuki-to-setup-auto-glass-plant-worth-rs-744-million-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-to-setup-auto-glass-plant-worth-rs-744-million-in-pakistan/";
              }}
            />
            <Route
              path="/karachi-confirmed-as-worst-city-in-the-world-for-driving"
              component={() => {
                window.location = "https://autodeals.pk/blog/karachi-confirmed-as-worst-city-in-the-world-for-driving/";
              }}
            />
            <Route
              path="/imported-luxury-cars-to-become-a-lot-cheaper-in-punjab"
              component={() => {
                window.location = "https://autodeals.pk/blog/imported-luxury-cars-to-become-a-lot-cheaper-in-punjab/";
              }}
            />
            <Route
              path="/bmw-pakistan-is-bringing-two-new-bmw-2-series-models"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-pakistan-is-bringing-two-new-bmw-2-series-models/";
              }}
            />
            <Route
              path="/combat-of-the-hybrid-wagons-toyota-corolla-fielder-vs-honda-fit-shuttle"
              component={() => {
                window.location = "https://autodeals.pk/blog/combat-of-the-hybrid-wagons-toyota-corolla-fielder-vs-honda-fit-shuttle/";
              }}
            />
            <Route
              path="/datsun-redi-go-gold-unveiled-in-india-at-inr-3-69-lac"
              component={() => {
                window.location = "https://autodeals.pk/blog/datsun-redi-go-gold-unveiled-in-india-at-inr-3-69-lac/";
              }}
            />
            <Route
              path="/zotye-z100-things-that-would-have-been-incorporated-to-make-a-better-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/zotye-z100-things-that-would-have-been-incorporated-to-make-a-better-car/";
              }}
            />
            <Route
              path="/automakers-worried-about-uncertainty-of-rupee-value"
              component={() => {
                window.location = "https://autodeals.pk/blog/automakers-worried-about-uncertainty-of-rupee-value/";
              }}
            />
            <Route
              path="/toyota-and-mazda-alliance-to-develop-electric-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-and-mazda-alliance-to-develop-electric-cars/";
              }}
            />
            <Route
              path="/battle-of-the-wagon-r-inr-5-4-lac-maruti-vs-pkr-10-94-lac-pak-suzuki"
              component={() => {
                window.location = "https://autodeals.pk/blog/battle-of-the-wagon-r-inr-5-4-lac-maruti-vs-pkr-10-94-lac-pak-suzuki/";
              }}
            />
            <Route
              path="/car-tyre-pressure-guide-and-the-ideal-exerted-pressure-on-tyres"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-tyre-pressure-guide-and-the-ideal-exerted-pressure-on-tyres/";
              }}
            />
            <Route
              path="/the-all-new-2018-honda-accord"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-all-new-2018-honda-accord/";
              }}
            />
            <Route
              path="/nissans-sweat-sensing-car-seat-senses-your-dehydration"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissans-sweat-sensing-car-seat-senses-your-dehydration/";
              }}
            />
            <Route
              path="/audi-crossover-reaches-a-boom-in-sales"
              component={() => {
                window.location = "https://autodeals.pk/blog/audi-crossover-reaches-a-boom-in-sales/";
              }}
            />
            <Route
              path="/feel-the-glory-of-2018-land-rover-range-rover-sport"
              component={() => {
                window.location = "https://autodeals.pk/blog/feel-the-glory-of-2018-land-rover-range-rover-sport/";
              }}
            />
            <Route
              path="/datsun-is-planning-a-comeback-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/datsun-is-planning-a-comeback-in-pakistan/";
              }}
            />
            <Route
              path="/infotainment-systems-causes-distracted-driving-says-american-automobile-association"
              component={() => {
                window.location = "https://autodeals.pk/blog/infotainment-systems-causes-distracted-driving-says-american-automobile-association/";
              }}
            />
            <Route
              path="/nab-to-investigate-toyota-indus-dealerships-of-forcing-customers-in-buying-extended-warranty"
              component={() => {
                window.location = "https://autodeals.pk/blog/nab-to-investigate-toyota-indus-dealerships-of-forcing-customers-in-buying-extended-warranty/";
              }}
            />
            <Route
              path="/suzuki-celerio-facelift-unveiled-in-india-at-inr-4-15-lac"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-celerio-facelift-unveiled-in-india-at-inr-4-15-lac/";
              }}
            />
            <Route
              path="/altis-grande-ad-is-being-bashed-on-social-media"
              component={() => {
                window.location = "https://autodeals.pk/blog/altis-grande-ad-is-being-bashed-on-social-media/";
              }}
            />
            <Route
              path="/toyota-asserts-to-be-worlds-fastest-suv-title"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-asserts-to-be-worlds-fastest-suv-title/";
              }}
            />
            <Route
              path="/datsun-in-pakistan-what-to-look-forward-to"
              component={() => {
                window.location = "https://autodeals.pk/blog/datsun-in-pakistan-what-to-look-forward-to/";
              }}
            />
            <Route
              path="/toyota-brings-in-two-wonderful-hybrid-concept-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-brings-in-two-wonderful-hybrid-concept-vehicles/";
              }}
            />
            <Route
              path="/here-is-the-2017-bmw-530i"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-2017-bmw-530i/";
              }}
            />
            <Route
              path="/corolla-xli-and-gli-to-be-discontinued-by-toyota-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/corolla-xli-and-gli-to-be-discontinued-by-toyota-pakistan/";
              }}
            />
            <Route
              path="/2017-facelifted-toyota-corolla-altis-grande-vs-honda-civic-which-one-to-go-for"
              component={() => {
                window.location = "https://autodeals.pk/blog/2017-facelifted-toyota-corolla-altis-grande-vs-honda-civic-which-one-to-go-for/";
              }}
            />
            <Route
              path="/fossil-fuels-to-banned-in-near-future"
              component={() => {
                window.location = "https://autodeals.pk/blog/fossil-fuels-to-banned-in-near-future/";
              }}
            />
            <Route
              path="/dn-trec-and-dn-multisix-concepts-by-daihatsu-will-be-revealed-at-2017-tokyo-motor-show"
              component={() => {
                window.location = "https://autodeals.pk/blog/dn-trec-and-dn-multisix-concepts-by-daihatsu-will-be-revealed-at-2017-tokyo-motor-show/";
              }}
            />
            <Route
              path="/2018-ford-mustang-with-better-aero-and-better-fuel-economy"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-ford-mustang-with-better-aero-and-better-fuel-economy/";
              }}
            />
            <Route
              path="/suzuki-pakistan-leading-car-sales-september-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-pakistan-leading-car-sales-september-2017/";
              }}
            />
            <Route
              path="/isuzu-d-max-to-be-launched-in-pakistan-by-ghandhara-industries"
              component={() => {
                window.location = "https://autodeals.pk/blog/isuzu-d-max-to-be-launched-in-pakistan-by-ghandhara-industries/";
              }}
            />
            <Route
              path="/faw-b50-20th-anniversary-edition-unveiled-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/faw-b50-20th-anniversary-edition-unveiled-in-china/";
              }}
            />
            <Route
              path="/what-causes-the-hard-steering-wheel"
              component={() => {
                window.location = "https://autodeals.pk/blog/what-causes-the-hard-steering-wheel/";
              }}
            />
            <Route
              path="/upgraded-toyota-vitz-replaces-aqua-with-1-5-litre-hybrid-engine"
              component={() => {
                window.location = "https://autodeals.pk/blog/upgraded-toyota-vitz-replaces-aqua-with-1-5-litre-hybrid-engine/";
              }}
            />
            <Route
              path="/2018-toyota-corolla-axio-and-fielder-refresh-officially-unveiled"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-toyota-corolla-axio-and-fielder-refresh-officially-unveiled/";
              }}
            />
            <Route
              path="/hyundai-tucson-4wd-unveiled-in-india-at-inr-25-19-lacs"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-tucson-4wd-unveiled-in-india-at-inr-25-19-lacs/";
              }}
            />
            <Route
              path="/new-yaris-ativ-to-take-place-of-corolla-1-3-variants"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-yaris-ativ-to-take-place-of-corolla-1-3-variants/";
              }}
            />
            <Route
              path="/2018-honda-accord-vs-toyota-camry-the-design-jury"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-honda-accord-vs-toyota-camry-the-design-jury/";
              }}
            />
            <Route
              path="/2018-bmw-x6-m-sport-and-x5-special-edition"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-bmw-x6-m-sport-and-x5-special-edition/";
              }}
            />
            <Route
              path="/own-money-open-blackmailing-by-car-dealers-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/own-money-open-blackmailing-by-car-dealers-in-pakistan/";
              }}
            />
            <Route
              path="/2017-zotye-z100-a-hit-or-a-miss"
              component={() => {
                window.location = "https://autodeals.pk/blog/2017-zotye-z100-a-hit-or-a-miss/";
              }}
            />
            <Route
              path="/mitsubishi-recalls-over-48000-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/mitsubishi-recalls-over-48000-vehicles/";
              }}
            />
            <Route
              path="/daihatsu-dn-compagno-concept-a-retro-styled-4-door-coupe"
              component={() => {
                window.location = "https://autodeals.pk/blog/daihatsu-dn-compagno-concept-a-retro-styled-4-door-coupe/";
              }}
            />
            <Route
              path="/government-increases-custom-duty-on-car-imports-by-upto-30"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-increases-custom-duty-on-car-imports-by-upto-30/";
              }}
            />
            <Route
              path="/renault-to-setup-duster-suv-manufacturing-plant-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/renault-to-setup-duster-suv-manufacturing-plant-in-pakistan/";
              }}
            />
            <Route
              path="/electric-cars-production-in-china-to-reach-1-million-the-next-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/electric-cars-production-in-china-to-reach-1-million-the-next-year/";
              }}
            />
            <Route
              path="/toyota-fine-comfort-ride-concept-is-plum-with-plum-perfection"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-fine-comfort-ride-concept-is-plum-with-plum-perfection/";
              }}
            />
            <Route
              path="/maruti-dzire-records-1-lac-units-sold-in-5-months"
              component={() => {
                window.location = "https://autodeals.pk/blog/maruti-dzire-records-1-lac-units-sold-in-5-months/";
              }}
            />
            <Route
              path="/suzuki-alivio-pro-ciaz-facelift-unveiled-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-alivio-pro-ciaz-facelift-unveiled-in-china/";
              }}
            />
            <Route
              path="/mercedes-amg-g65-final-edition-reveals-a-v-12-g-wagen-send-off"
              component={() => {
                window.location = "https://autodeals.pk/blog/mercedes-amg-g65-final-edition-reveals-a-v-12-g-wagen-send-off/";
              }}
            />
            <Route
              path="/the-2018-audi-a7-sportback-a-design-with-perfection"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-2018-audi-a7-sportback-a-design-with-perfection/";
              }}
            />
            <Route
              path="/nocturne-black-bugatti-chiron-is-up-for-the-grabs"
              component={() => {
                window.location = "https://autodeals.pk/blog/nocturne-black-bugatti-chiron-is-up-for-the-grabs/";
              }}
            />
            <Route
              path="/here-are-the-2018-nissan-maxima-msrp-and-specs"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-are-the-2018-nissan-maxima-msrp-and-specs/";
              }}
            />
            <Route
              path="/mercedes-benz-is-launching-10-electric-models-by-2022"
              component={() => {
                window.location = "https://autodeals.pk/blog/mercedes-benz-is-launching-10-electric-models-by-2022/";
              }}
            />
            <Route
              path="/technologies-the-cars-of-the-future-will-be-featuring"
              component={() => {
                window.location = "https://autodeals.pk/blog/technologies-the-cars-of-the-future-will-be-featuring/";
              }}
            />
            <Route
              path="/10-things-to-be-kept-in-mind-while-driving-defensive-driving-procedures"
              component={() => {
                window.location = "https://autodeals.pk/blog/10-things-to-be-kept-in-mind-while-driving-defensive-driving-procedures/";
              }}
            />
            <Route
              path="/comparison-of-old-toyota-passo-vs-new-toyota-passo"
              component={() => {
                window.location = "https://autodeals.pk/blog/comparison-of-old-toyota-passo-vs-new-toyota-passo/";
              }}
            />
            <Route
              path="/all-pakistan-oil-tankers-owners-association-on-strike-oil-supply-crises-to-emerge"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-pakistan-oil-tankers-owners-association-on-strike-oil-supply-crises-to-emerge/";
              }}
            />
            <Route
              path="/honda-pakistan-running-airbag-replacement-awareness-campaign"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-pakistan-running-airbag-replacement-awareness-campaign/";
              }}
            />
            <Route
              path="/pak-suzuki-profits-grow-by-152-yoy-in-third-quarter-of-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-profits-grow-by-152-yoy-in-third-quarter-of-2017/";
              }}
            />
            <Route
              path="/have-a-look-at-mitsubishi-e-evolution-concept-at-the-2017-tokyo-motor-show"
              component={() => {
                window.location = "https://autodeals.pk/blog/have-a-look-at-mitsubishi-e-evolution-concept-at-the-2017-tokyo-motor-show/";
              }}
            />
            <Route
              path="/tesla-to-setup-its-first-global-factory-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/tesla-to-setup-its-first-global-factory-in-china/";
              }}
            />
            <Route
              path="/js-bank-offering-caraamad-car-financing-service"
              component={() => {
                window.location = "https://autodeals.pk/blog/js-bank-offering-caraamad-car-financing-service/";
              }}
            />
            <Route
              path="/facelifted-zotye-z700h-unveils-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/facelifted-zotye-z700h-unveils-in-china/";
              }}
            />
            <Route
              path="/2018-porsche-911-carrera-t-is-a-spectacular-beauty"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-porsche-911-carrera-t-is-a-spectacular-beauty/";
              }}
            />
            <Route
              path="/kia-vehicles-spotted-testing-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-vehicles-spotted-testing-in-pakistan/";
              }}
            />
            <Route
              path="/indus-motor-posts-profits-regardless-of-hike-in-vehicle-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/indus-motor-posts-profits-regardless-of-hike-in-vehicle-prices/";
              }}
            />
            <Route
              path="/tesla-in-pakistan-model-s-75d-goes-viral"
              component={() => {
                window.location = "https://autodeals.pk/blog/tesla-in-pakistan-model-s-75d-goes-viral/";
              }}
            />
            <Route
              path="/one-more-kia-rio-spotted-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/one-more-kia-rio-spotted-in-pakistan/";
              }}
            />
            <Route
              path="/price-of-petroleum-products-to-rise-in-november"
              component={() => {
                window.location = "https://autodeals.pk/blog/price-of-petroleum-products-to-rise-in-november/";
              }}
            />
            <Route
              path="/nissan-and-subaru-confess-improper-checks-on-japanese-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissan-and-subaru-confess-improper-checks-on-japanese-cars/";
              }}
            />
            <Route
              path="/2018-facelifted-isuzu-d-max-officially-unveiled-in-thailand"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-facelifted-isuzu-d-max-officially-unveiled-in-thailand/";
              }}
            />
            <Route
              path="/petrol-price-sore-by-rs-2-49-high-speed-diesel-by-rs-5-19"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-price-sore-by-rs-2-49-high-speed-diesel-by-rs-5-19/";
              }}
            />
            <Route
              path="/toyota-unveils-the-macho-tj-cruiser-concept-at-2017-tokyo-motor-show"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-unveils-the-macho-tj-cruiser-concept-at-2017-tokyo-motor-show/";
              }}
            />
            <Route
              path="/toyota-c-hr-r-tuned-asserts-to-be-worlds-fastest-crossover"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-c-hr-r-tuned-asserts-to-be-worlds-fastest-crossover/";
              }}
            />
            <Route
              path="/honda-proclaims-fuel-from-shell-total-and-pso-harms-its-engines"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-proclaims-fuel-from-shell-total-and-pso-harms-its-engines/";
              }}
            />
            <Route
              path="/toyota-pakistan-to-launch-a-new-hybrid-car-in-coming-days"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-pakistan-to-launch-a-new-hybrid-car-in-coming-days/";
              }}
            />
            <Route
              path="/audi-bmw-and-porsche-pakistan-in-courts-against-sro103512017"
              component={() => {
                window.location = "https://autodeals.pk/blog/audi-bmw-and-porsche-pakistan-in-courts-against-sro103512017/";
              }}
            />
            <Route
              path="/audi-pakistan-raised-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/audi-pakistan-raised-prices/";
              }}
            />
            <Route
              path="/traffic-violators-watch-out-gop-brings-in-e-challan-in-lahore"
              component={() => {
                window.location = "https://autodeals.pk/blog/traffic-violators-watch-out-gop-brings-in-e-challan-in-lahore/";
              }}
            />
            <Route
              path="/winter-driving-safety-5-best-tips"
              component={() => {
                window.location = "https://autodeals.pk/blog/winter-driving-safety-5-best-tips/";
              }}
            />
            <Route
              path="/here-is-the-upcoming-faw-r7-suv-imminent-to-be-launched-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-upcoming-faw-r7-suv-imminent-to-be-launched-in-pakistan/";
              }}
            />
            <Route
              path="/bmw-recalls-approximately-one-million-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-recalls-approximately-one-million-vehicles/";
              }}
            />
            <Route
              path="/three-door-land-rover-range-rover-evoque-to-be-discontinued"
              component={() => {
                window.location = "https://autodeals.pk/blog/three-door-land-rover-range-rover-evoque-to-be-discontinued/";
              }}
            />
            <Route
              path="/toyota-looks-forward-to-further-profits-after-posting-sturdy-growth-in-h1-fy2017-18"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-looks-forward-to-further-profits-after-posting-sturdy-growth-in-h1-fy2017-18/";
              }}
            />
            <Route
              path="/bmw-profits-decreases-in-q3-2017-since-auto-maker-attempts-to-update-its-portfolio"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-profits-decreases-in-q3-2017-since-auto-maker-attempts-to-update-its-portfolio/";
              }}
            />
            <Route
              path="/nissan-is-stunning-rivals-with-some-good-news"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissan-is-stunning-rivals-with-some-good-news/";
              }}
            />
            <Route
              path="/kia-grand-carnival-the-upcoming-mpv-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-grand-carnival-the-upcoming-mpv-in-pakistan/";
              }}
            />
            <Route
              path="/hascol-pakistan-took-action-against-hondas-complaint-against-oil-companies"
              component={() => {
                window.location = "https://autodeals.pk/blog/hascol-pakistan-took-action-against-hondas-complaint-against-oil-companies/";
              }}
            />
            <Route
              path="/mercedes-benz-pakistan-changes-prices-of-its-cars-following-sro-103512017/"
              component={() => {
                window.location = "https://autodeals.pk/blog/mercedes-benz-pakistan-changes-prices-of-its-cars-following-sro-103512017/";
              }}
            />
            <Route
              path="/volkswagen-is-planning-to-invest-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/volkswagen-is-planning-to-invest-in-pakistan/";
              }}
            />
            <Route
              path="/zotye-launching-t500-suv-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/zotye-launching-t500-suv-in-china/";
              }}
            />
            <Route
              path="/2018-toyota-harrier-to-be-officially-sold-in-asian-markets"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-toyota-harrier-to-be-officially-sold-in-asian-markets/";
              }}
            />
            <Route
              path="/heres-how-to-drive-safely-in-smog-and-avoid-accidents-like-the-one-in-india"
              component={() => {
                window.location = "https://autodeals.pk/blog/heres-how-to-drive-safely-in-smog-and-avoid-accidents-like-the-one-in-india/";
              }}
            />
            <Route
              path="/once-again-kia-cars-spotted-testing-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/once-again-kia-cars-spotted-testing-in-pakistan/";
              }}
            />
            <Route
              path="/should-volkswagen-be-launching-polo-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/should-volkswagen-be-launching-polo-in-pakistan/";
              }}
            />
            <Route
              path="/imc-pakistan-to-launch-a-new-for-diesel-engines-lovers"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-pakistan-to-launch-a-new-for-diesel-engines-lovers/";
              }}
            />
            <Route
              path="/hyundai-nishat-website-give-clues-of-the-upcoming-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-nishat-website-give-clues-of-the-upcoming-vehicles/";
              }}
            />
            <Route
              path="/tie-up-talks-among-ghandhara-nissan-renault-break-off"
              component={() => {
                window.location = "https://autodeals.pk/blog/tie-up-talks-among-ghandhara-nissan-renault-break-off/";
              }}
            />
            <Route
              path="/pak-suzuki-will-bring-in-mega-carry-to-compete-faw-carrier"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-will-bring-in-mega-carry-to-compete-faw-carrier/";
              }}
            />
            <Route
              path="/suzuki-leads-the-monthly-vehicle-sales-reach-record"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-leads-the-monthly-vehicle-sales-reach-record/";
              }}
            />
            <Route
              path="/oil-companies-proclaim-honda-at-fault-in-fuel-complaint"
              component={() => {
                window.location = "https://autodeals.pk/blog/oil-companies-proclaim-honda-at-fault-in-fuel-complaint/";
              }}
            />
            <Route
              path="/a-pakistani-company-modifies-the-audi-r8-its-fantastic"
              component={() => {
                window.location = "https://autodeals.pk/blog/a-pakistani-company-modifies-the-audi-r8-its-fantastic/";
              }}
            />
            <Route
              path="/2018-facelifted-toyota-hilux-revo-unveiled-in-thailand"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-facelifted-toyota-hilux-revo-unveiled-in-thailand/";
              }}
            />
            <Route
              path="/ogra-to-suspend-licenses-of-companies-selling-substandard-fuel"
              component={() => {
                window.location = "https://autodeals.pk/blog/ogra-to-suspend-licenses-of-companies-selling-substandard-fuel/";
              }}
            />
            <Route
              path="/sro10671-2017-some-updates-you-need-to-know"
              component={() => {
                window.location = "https://autodeals.pk/blog/sro10671-2017-some-updates-you-need-to-know/";
              }}
            />
            <Route
              path="/should-faw-bring-in-the-v2-cross-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/should-faw-bring-in-the-v2-cross-in-pakistan/";
              }}
            />
            <Route
              path="/kia-picanto-spotted-testing-in-karachi"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-picanto-spotted-testing-in-karachi/";
              }}
            />
            <Route
              path="/tesla-reveals-new-2nd-gen-roadster"
              component={() => {
                window.location = "https://autodeals.pk/blog/tesla-reveals-new-2nd-gen-roadster/";
              }}
            />
            <Route
              path="/binggo-faw-has-a-new-ev-brand-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/binggo-faw-has-a-new-ev-brand-in-china/";
              }}
            />
            <Route
              path="/dongfeng-s50-sedan-launched-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/dongfeng-s50-sedan-launched-in-china/";
              }}
            />
            <Route
              path="/kia-picanto-the-things-you-need-to-know"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-picanto-the-things-you-need-to-know/";
              }}
            />
            <Route
              path="/renault-officially-announces-its-coming-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/renault-officially-announces-its-coming-in-pakistan/";
              }}
            />
            <Route
              path="/imc-pakistan-has-cancelled-more-than-1000-booked-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-pakistan-has-cancelled-more-than-1000-booked-cars/";
              }}
            />
            <Route
              path="/2018-new-car-models-likely-to-be-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-new-car-models-likely-to-be-in-pakistan/";
              }}
            />
            <Route
              path="/honda-company-has-recalled-odyssey-minivan"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-company-has-recalled-odyssey-minivan/";
              }}
            />
            <Route
              path="/united-motors-to-launch-two-new-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/united-motors-to-launch-two-new-cars-in-pakistan/";
              }}
            />
            <Route
              path="/honda-atlas-profits-increased-by-10-to-rs-1-62-billion"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-atlas-profits-increased-by-10-to-rs-1-62-billion/";
              }}
            />
            <Route
              path="/have-a-look-at-the-all-new-2018-daihatsu-terios"
              component={() => {
                window.location = "https://autodeals.pk/blog/have-a-look-at-the-all-new-2018-daihatsu-terios/";
              }}
            />
            <Route
              path="/new-swift-to-launch-in-thailand-by-early-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-swift-to-launch-in-thailand-by-early-2018/";
              }}
            />
            <Route
              path="/indus-motor-investing-40-million-for-boosting-output-by-10000-units"
              component={() => {
                window.location = "https://autodeals.pk/blog/indus-motor-investing-40-million-for-boosting-output-by-10000-units/";
              }}
            />
            <Route
              path="/bmw-is-also-recalling-its-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-is-also-recalling-its-vehicles/";
              }}
            />
            <Route
              path="/should-honda-atlas-introduce-brio-amaze-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/should-honda-atlas-introduce-brio-amaze-in-pakistan/";
              }}
            />
            <Route
              path="/updated-suzuki-ciaz-rs-launching-in-thailand"
              component={() => {
                window.location = "https://autodeals.pk/blog/updated-suzuki-ciaz-rs-launching-in-thailand/";
              }}
            />
            <Route
              path="/fiat-cronos-fiat-linea-successor-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/fiat-cronos-fiat-linea-successor-revealed/";
              }}
            />
            <Route
              path="/all-electric-faw-b30ev-unveiled-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-electric-faw-b30ev-unveiled-in-china/";
              }}
            />
            <Route
              path="/5-winter-tips-for-your-cars-better-performance"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-winter-tips-for-your-cars-better-performance/";
              }}
            />
            <Route
              path="/petrol-prices-likely-to-rise-up-again-in-december"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-likely-to-rise-up-again-in-december/";
              }}
            />
            <Route
              path="/2018-bmw-i8-roadster-to-be-on-sale-early-next-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-bmw-i8-roadster-to-be-on-sale-early-next-year/";
              }}
            />
            <Route
              path="/suzuki-celeriox-revealed-in-india-at-inr-4-57-lac"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-celeriox-revealed-in-india-at-inr-4-57-lac/";
              }}
            />
            <Route
              path="/renault-manufacturing-the-kwid-electric"
              component={() => {
                window.location = "https://autodeals.pk/blog/renault-manufacturing-the-kwid-electric/";
              }}
            />
            <Route
              path="/pak-suzuki-all-set-to-launch-exciting-new-vehicles-in-december"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-all-set-to-launch-exciting-new-vehicles-in-december/";
              }}
            />
            <Route
              path="/how-to-adjust-your-side-and-rear-view-mirrors-accurately"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-adjust-your-side-and-rear-view-mirrors-accurately/";
              }}
            />
            <Route
              path="/nissan-sues-india-seeking-nearly-rs-8000-crore"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissan-sues-india-seeking-nearly-rs-8000-crore/";
              }}
            />
            <Route
              path="/toyota-to-roll-out-new-safety-packages-from-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-to-roll-out-new-safety-packages-from-2018/";
              }}
            />
            <Route
              path="/pak-suzuki-unveils-cultus-automatic-and-mega-carry"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-unveils-cultus-automatic-and-mega-carry/";
              }}
            />
            <Route
              path="/al-futtaim-group-will-be-confirming-new-renault-dealers-in-pakistan-soon"
              component={() => {
                window.location = "https://autodeals.pk/blog/al-futtaim-group-will-be-confirming-new-renault-dealers-in-pakistan-soon/";
              }}
            />
            <Route
              path="/lamborghini-urus-reveals-as-the-worlds-fastest-suv"
              component={() => {
                window.location = "https://autodeals.pk/blog/lamborghini-urus-reveals-as-the-worlds-fastest-suv/";
              }}
            />
            <Route
              path="/2018-suzuki-cultus-ags-a-brief-review"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-suzuki-cultus-ags-a-brief-review/";
              }}
            />
            <Route
              path="/a-comparison-of-suzuki-ciaz-and-honda-city"
              component={() => {
                window.location = "https://autodeals.pk/blog/a-comparison-of-suzuki-ciaz-and-honda-city/";
              }}
            />
            <Route
              path="/pak-suzuki-cultus-vxl-auto-worth-the-money"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-cultus-vxl-auto-worth-the-money/";
              }}
            />
            <Route
              path="/imc-will-introduce-vios-to-replace-xli-and-gli-by-july-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-will-introduce-vios-to-replace-xli-and-gli-by-july-2018/";
              }}
            />
            <Route
              path="/comparison-maruti-celerio-vs-pak-suzuki-cultus"
              component={() => {
                window.location = "https://autodeals.pk/blog/comparison-maruti-celerio-vs-pak-suzuki-cultus/";
              }}
            />
            <Route
              path="/here-is-the-new-toyota-corolla-gli-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-new-toyota-corolla-gli-2018/";
              }}
            />
            <Route
              path="/2018-geely-emgrand-gl-unveiled-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-geely-emgrand-gl-unveiled-in-china/";
              }}
            />
            <Route
              path="/ghandhara-is-offering-3s-and-2s-dealerships-for-isuzu-range-of-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/ghandhara-is-offering-3s-and-2s-dealerships-for-isuzu-range-of-vehicles/";
              }}
            />
            <Route
              path="/the-competitors-coming-hyundai-verna-and-kia-rio"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-competitors-coming-hyundai-verna-and-kia-rio/";
              }}
            />
            <Route
              path="/2018-renault-duster-unveiled-might-reach-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-renault-duster-unveiled-might-reach-pakistan/";
              }}
            />
            <Route
              path="/car-and-oil-prices-in-pakistan-might-rise-by-10-percent"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-and-oil-prices-in-pakistan-might-rise-by-10-percent/";
              }}
            />
            <Route
              path="/here-is-how-local-car-makers-are-finally-changing-tactics-to-contend-with-newcomers"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-how-local-car-makers-are-finally-changing-tactics-to-contend-with-newcomers/";
              }}
            />
            <Route
              path="/alto-660cc-toyota-vios-and-honda-brio-to-be-unveiled-in-2018-19"
              component={() => {
                window.location = "https://autodeals.pk/blog/alto-660cc-toyota-vios-and-honda-brio-to-be-unveiled-in-2018-19/";
              }}
            />
            <Route
              path="/honda-atlas-is-upgrading-its-navigation-system"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-atlas-is-upgrading-its-navigation-system/";
              }}
            />
            <Route
              path="/isuzu-d-max-might-create-trouble-for-toyota-hilux"
              component={() => {
                window.location = "https://autodeals.pk/blog/isuzu-d-max-might-create-trouble-for-toyota-hilux/";
              }}
            />
            <Route
              path="/hyundai-nishat-got-approved-for-greenfield-auto-assembly-project"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-nishat-got-approved-for-greenfield-auto-assembly-project/";
              }}
            />
            <Route
              path="/the-2016-suzuki-baleno-xt-a-brief-review"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-2016-suzuki-baleno-xt-a-brief-review/";
              }}
            />
            <Route
              path="/geelys-new-1-4t-engine-won-china-heart-award"
              component={() => {
                window.location = "https://autodeals.pk/blog/geelys-new-1-4t-engine-won-china-heart-award/";
              }}
            />
            <Route
              path="/porsche-mission-e-electric-vehicle-to-be-launched-in-multiple-variants"
              component={() => {
                window.location = "https://autodeals.pk/blog/porsche-mission-e-electric-vehicle-to-be-launched-in-multiple-variants/";
              }}
            />
            <Route
              path="/here-is-the-new-mercedes-e-class-4x4-squared"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-new-mercedes-e-class-4x4-squared/";
              }}
            />
            <Route
              path="/alibaba-to-sell-cars-via-vending-machines"
              component={() => {
                window.location = "https://autodeals.pk/blog/alibaba-to-sell-cars-via-vending-machines/";
              }}
            />
            <Route
              path="/5-upcoming-pickups-and-suvs-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-upcoming-pickups-and-suvs-in-pakistan/";
              }}
            />
            <Route
              path="/toyota-to-launch-10-evs-by-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-to-launch-10-evs-by-2020/";
              }}
            />
            <Route
              path="/kia-lucky-motors-signs-aggrement-with-moip"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-lucky-motors-signs-aggrement-with-moip/";
              }}
            />
            <Route
              path="/nissan-recalls-320000-vehicles-in-japan"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissan-recalls-320000-vehicles-in-japan/";
              }}
            />
            <Route
              path="/mercedes-a-class-to-come-in-a-sedan"
              component={() => {
                window.location = "https://autodeals.pk/blog/mercedes-a-class-to-come-in-a-sedan/";
              }}
            />
            <Route
              path="/new-honda-insight-prototype-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-honda-insight-prototype-revealed/";
              }}
            />
            <Route
              path="/all-new-2018-toyota-rush-coming-to-thailand"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-new-2018-toyota-rush-coming-to-thailand/";
              }}
            />
            <Route
              path="/chinas-biggest-oil-producer-coming-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/chinas-biggest-oil-producer-coming-pakistan/";
              }}
            />
            <Route
              path="/nio-launches-the-es8-7-seat-electric-suv"
              component={() => {
                window.location = "https://autodeals.pk/blog/nio-launches-the-es8-7-seat-electric-suv/";
              }}
            />
            <Route
              path="/faw-considering-to-bring-in-v2-amt-next-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/faw-considering-to-bring-in-v2-amt-next-year/";
              }}
            />
            <Route
              path="/indus-motors-increase-corolla-1-3-1-6-price"
              component={() => {
                window.location = "https://autodeals.pk/blog/indus-motors-increase-corolla-1-3-1-6-price/";
              }}
            />
            <Route
              path="/suzuki-xbee-unveiled-in-japan"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-xbee-unveiled-in-japan/";
              }}
            />
            <Route
              path="/china-to-set-up-automobile-plants-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/china-to-set-up-automobile-plants-in-pakistan/";
              }}
            />
            <Route
              path="/2018-faw-a50-unveiled"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-faw-a50-unveiled/";
              }}
            />
            <Route
              path="/toyota-vios-facelift-at-2017-thai-motor-expo"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-vios-facelift-at-2017-thai-motor-expo/";
              }}
            />
            <Route
              path="/ogra-to-increase-fuel-prices-upto-rs-10"
              component={() => {
                window.location = "https://autodeals.pk/blog/ogra-to-increase-fuel-prices-upto-rs-10/";
              }}
            />
            <Route
              path="/isuzu-mu-x-facelift-unveiled-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/isuzu-mu-x-facelift-unveiled-in-china/";
              }}
            />
            <Route
              path="/kia-rio-all-you-need-to-know"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-rio-all-you-need-to-know/";
              }}
            />
            <Route
              path="/imported-cars-are-not-being-banned-in-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/imported-cars-are-not-being-banned-in-2018/";
              }}
            />
            <Route
              path="/2018-toyota-camry-hybrid-price-leaked-photo"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-toyota-camry-hybrid-price-leaked-photo/";
              }}
            />
            <Route
              path="/porsche-mission-e-specs-leaked-before-launch"
              component={() => {
                window.location = "https://autodeals.pk/blog/porsche-mission-e-specs-leaked-before-launch/";
              }}
            />
            <Route
              path="/dewan-motors-limited-given-brownfield-status"
              component={() => {
                window.location = "https://autodeals.pk/blog/dewan-motors-limited-given-brownfield-status/";
              }}
            />
            <Route
              path="/pak-suzuki-increases-its-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-increases-its-car-prices/";
              }}
            />
            <Route
              path="/imc-refunding-rs-600m-of-canceled-bookings"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-refunding-rs-600m-of-canceled-bookings/";
              }}
            />
            <Route
              path="/petrol-prices-increased-from-1st-january-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-increased-from-1st-january-2018/";
              }}
            />
            <Route
              path="/al-haj-faw-vehicle-prices-has-revised"
              component={() => {
                window.location = "https://autodeals.pk/blog/al-haj-faw-vehicle-prices-has-revised/";
              }}
            />
            <Route
              path="/fiat-cronos-interior-unveiled"
              component={() => {
                window.location = "https://autodeals.pk/blog/fiat-cronos-interior-unveiled/";
              }}
            />
            <Route
              path="/hyundai-kia-to-add-ai-car-assistants-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-kia-to-add-ai-car-assistants-2019/";
              }}
            />
            <Route
              path="/used-car-prices-might-rise"
              component={() => {
                window.location = "https://autodeals.pk/blog/used-car-prices-might-rise/";
              }}
            />
            <Route
              path="/here-is-the-2018-toyota-fortuner-diesel"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-2018-toyota-fortuner-diesel/";
              }}
            />
            <Route
              path="/toyota-avanza-price-revised-pkr-34-5-lac"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-avanza-price-revised-pkr-34-5-lac/";
              }}
            />
            <Route
              path="/honda-br-v-sold-6270-units-in-april-nov-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-br-v-sold-6270-units-in-april-nov-2017/";
              }}
            />
            <Route
              path="/here-is-the-worlds-fastest-estate-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-worlds-fastest-estate-car/";
              }}
            />
            <Route
              path="/this-nissan-technology-will-prevent-accidents"
              component={() => {
                window.location = "https://autodeals.pk/blog/this-nissan-technology-will-prevent-accidents/";
              }}
            />
            <Route
              path="/honda-car-prices-have-been-revised"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-car-prices-have-been-revised/";
              }}
            />
            <Route
              path="/automakers-to-invest-800-million-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/automakers-to-invest-800-million-in-pakistan/";
              }}
            />
            <Route
              path="/5-features-of-the-new-2018-toyota-fortuner"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-features-of-the-new-2018-toyota-fortuner/";
              }}
            />
            <Route
              path="/toyota-unveils-self-driving-store-on-wheels"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-unveils-self-driving-store-on-wheels/";
              }}
            />
            <Route
              path="/toyota-pakistan-teases-camry-2018-hybrid"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-pakistan-teases-camry-2018-hybrid/";
              }}
            />
            <Route
              path="/foreign-companies-to-bring-light-efi-cng-kits"
              component={() => {
                window.location = "https://autodeals.pk/blog/foreign-companies-to-bring-light-efi-cng-kits/";
              }}
            />
            <Route
              path="/here-is-the-hyundais-hydrogen-powered-nexo"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-hyundais-hydrogen-powered-nexo/";
              }}
            />
            <Route
              path="/car-sales-rose-by-20-in-december-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-sales-rose-by-20-in-december-2017/";
              }}
            />
            <Route
              path="/japan-to-diversify-auto-sector-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/japan-to-diversify-auto-sector-in-pakistan/";
              }}
            />
            <Route
              path="/subaru-viziv-performance-sti-concept-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/subaru-viziv-performance-sti-concept-revealed/";
              }}
            />
            <Route
              path="/toyota-c-hr-the-best-selling-suv-in-japan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-c-hr-the-best-selling-suv-in-japan/";
              }}
            />
            <Route
              path="/the-recall-returns-takata-airbag-disaster"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-recall-returns-takata-airbag-disaster/";
              }}
            />
            <Route
              path="/suzuki-swift-is-at-domestic-sales-chart-foot"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-swift-is-at-domestic-sales-chart-foot/";
              }}
            />
            <Route
              path="/mercedes-new-ai-infotainment-has-3d-ability"
              component={() => {
                window.location = "https://autodeals.pk/blog/mercedes-new-ai-infotainment-has-3d-ability/";
              }}
            />
            <Route
              path="/indus-motors-has-launch-toyota-camry-hybrid"
              component={() => {
                window.location = "https://autodeals.pk/blog/indus-motors-has-launch-toyota-camry-hybrid/";
              }}
            />
            <Route
              path="/the-2019-kia-forte-unveiled"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-2019-kia-forte-unveiled/";
              }}
            />
            <Route
              path="/importers-reluctant-to-get-cars-from-the-port"
              component={() => {
                window.location = "https://autodeals.pk/blog/importers-reluctant-to-get-cars-from-the-port/";
              }}
            />
            <Route
              path="/ferrari-to-make-all-electric-supercar"
              component={() => {
                window.location = "https://autodeals.pk/blog/ferrari-to-make-all-electric-supercar/";
              }}
            />
            <Route
              path="/2018-honda-insight-hybrid-prototype-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-honda-insight-hybrid-prototype-revealed/";
              }}
            />
            <Route
              path="/here-is-the-new-2019-mini-cooper"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-new-2019-mini-cooper/";
              }}
            />
            <Route
              path="/datsun-unveils-the-go-cross-in-indonesia"
              component={() => {
                window.location = "https://autodeals.pk/blog/datsun-unveils-the-go-cross-in-indonesia/";
              }}
            />
            <Route
              path="/imc-suspends-toyota-dealerships-registration"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-suspends-toyota-dealerships-registration/";
              }}
            />
            <Route
              path="/punjab-excise-dept-launching-smart-cards"
              component={() => {
                window.location = "https://autodeals.pk/blog/punjab-excise-dept-launching-smart-cards/";
              }}
            />
            <Route
              path="/video2019-kia-forte-vs-lamborghini-aventador"
              component={() => {
                window.location = "https://autodeals.pk/blog/video2019-kia-forte-vs-lamborghini-aventador/";
              }}
            />
            <Route
              path="/dfml-to-start-vehicle-production-next-month"
              component={() => {
                window.location = "https://autodeals.pk/blog/dfml-to-start-vehicle-production-next-month/";
              }}
            />
            <Route
              path="/govt-to-clear-imported-used-cars-on-old-duty"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-to-clear-imported-used-cars-on-old-duty/";
              }}
            />
            <Route
              path="/4-tips-to-save-your-car-tires-from-cracking"
              component={() => {
                window.location = "https://autodeals.pk/blog/4-tips-to-save-your-car-tires-from-cracking/";
              }}
            />
            <Route
              path="/bugatti-3d-prints-biggest-titanium-car-part"
              component={() => {
                window.location = "https://autodeals.pk/blog/bugatti-3d-prints-biggest-titanium-car-part/";
              }}
            />
            <Route
              path="/honda-vezel-facelift-photo-leaked"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-vezel-facelift-photo-leaked/";
              }}
            />
            <Route
              path="/next-lamborghini-flagship-is-a-1000bhp-hybrid"
              component={() => {
                window.location = "https://autodeals.pk/blog/next-lamborghini-flagship-is-a-1000bhp-hybrid/";
              }}
            />
            <Route
              path="/2018-honda-vezel-hr-v-facelift-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-honda-vezel-hr-v-facelift-revealed/";
              }}
            />
            <Route
              path="/fourth-generation-swift-to-launch-in-india"
              component={() => {
                window.location = "https://autodeals.pk/blog/fourth-generation-swift-to-launch-in-india/";
              }}
            />
            <Route
              path="/kia-k5-optima-facelift-unveiled"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-k5-optima-facelift-unveiled/";
              }}
            />
            <Route
              path="/pakistanis-imported-65723-used-cars-in-2017"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistanis-imported-65723-used-cars-in-2017/";
              }}
            />
            <Route
              path="/pakistan-getting-latest-tech-from-renault"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistan-getting-latest-tech-from-renault/";
              }}
            />
            <Route
              path="/here-is-the-2018-faw-t086"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-2018-faw-t086/";
              }}
            />
            <Route
              path="/the-2018-changan-eado-and-eado-xt"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-2018-changan-eado-and-eado-xt/";
              }}
            />
            <Route
              path="/2019-porsche-718-spyder-spy-shots"
              component={() => {
                window.location = "https://autodeals.pk/blog/2019-porsche-718-spyder-spy-shots/";
              }}
            />
            <Route
              path="/2018-audi-q5-unveiled-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-audi-q5-unveiled-in-pakistan/";
              }}
            />
            <Route
              path="/bmw-daimler-vw-caught-testing-toxic-fumes"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-daimler-vw-caught-testing-toxic-fumes/";
              }}
            />
            <Route
              path="/how-to-read-japanese-car-auction-sheet"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-read-japanese-car-auction-sheet/";
              }}
            />
            <Route
              path="/here-is-the-new-toyota-vios-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-new-toyota-vios-2018/";
              }}
            />
            <Route
              path="/pak-suzuki-suspends-wagonr-bookings"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-suspends-wagonr-bookings/";
              }}
            />
            <Route
              path="/petroleum-products-prices-increase-by-pkr-6"
              component={() => {
                window.location = "https://autodeals.pk/blog/petroleum-products-prices-increase-by-pkr-6/";
              }}
            />
            <Route
              path="/upcoming-toyota-rav4-2019-test-vehicle-spied"
              component={() => {
                window.location = "https://autodeals.pk/blog/upcoming-toyota-rav4-2019-test-vehicle-spied/";
              }}
            />
            <Route
              path="/corolla-owners-demand-recall-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/corolla-owners-demand-recall-in-pakistan/";
              }}
            />
            <Route
              path="/porsche-911-getting-485hp-hybrid-phev-upgrade"
              component={() => {
                window.location = "https://autodeals.pk/blog/porsche-911-getting-485hp-hybrid-phev-upgrade/";
              }}
            />
            <Route
              path="/2018-kia-picanto-launched-in-malaysia"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-kia-picanto-launched-in-malaysia/";
              }}
            />
            <Route
              path="/toyota-corolla-pakistans-top-selling-sedan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-corolla-pakistans-top-selling-sedan/";
              }}
            />
            <Route
              path="/2018-hyundai-santa-fe-unveiled"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-hyundai-santa-fe-unveiled/";
              }}
            />
            <Route
              path="/ford-plans-to-make-autonomous-police-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/ford-plans-to-make-autonomous-police-cars/";
              }}
            />
            <Route
              path="/choosing-the-right-color-for-your-car-in-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/choosing-the-right-color-for-your-car-in-2018/";
              }}
            />
            <Route
              path="/3-best-toyota-luxury-cars-ranked-by-the-experts"
              component={() => {
                window.location = "https://autodeals.pk/blog/3-best-toyota-luxury-cars-ranked-by-the-experts/";
              }}
            />
            <Route
              path="/top-rated-wheels-for-cars-2017-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/top-rated-wheels-for-cars-2017-2018/";
              }}
            />
            <Route
              path="/byton-a-tech-freaks-dream-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/byton-a-tech-freaks-dream-car/";
              }}
            />
            <Route
              path="/honda-br-v-2018-brings-something-new-to-the-table-or-not"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-br-v-2018-brings-something-new-to-the-table-or-not/";
              }}
            />
            <Route
              path="/face-off-city-2018-vs-civic-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/face-off-city-2018-vs-civic-2018/";
              }}
            />
            <Route
              path="/ecc-to-restate-rules-on-used-imported-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/ecc-to-restate-rules-on-used-imported-cars/";
              }}
            />
            <Route
              path="/2018-honda-amaze-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-honda-amaze-revealed/";
              }}
            />
            <Route
              path="/new-import-rules-and-regulations-set-by-ecc"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-import-rules-and-regulations-set-by-ecc/";
              }}
            />
            <Route
              path="/new-generation-acura-nsx-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-generation-acura-nsx-2018/";
              }}
            />
            <Route
              path="/maserati-ghibli-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/maserati-ghibli-2018/";
              }}
            />
            <Route
              path="/bmw-7-series-close-up-views"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-7-series-close-up-views/";
              }}
            />
            <Route
              path="/bmw-7-series-laserlight"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-7-series-laserlight/";
              }}
            />
            <Route
              path="/the-2018-hyundai-elantra-gt"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-2018-hyundai-elantra-gt/";
              }}
            />
            <Route
              path="/bugatti-veyron-vs-euro-fighter-typhoon-drag-race"
              component={() => {
                window.location = "https://autodeals.pk/blog/bugatti-veyron-vs-euro-fighter-typhoon-drag-race/";
              }}
            />
            <Route
              path="/the-design-of-the-new-porsche-cayenne-turbo"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-design-of-the-new-porsche-cayenne-turbo/";
              }}
            />
            <Route
              path="/2018-range-rover-sport-interior-exterior"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-range-rover-sport-interior-exterior/";
              }}
            />
            <Route
              path="/2018-hyundai-azera-interior-exterior-and-drive"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-hyundai-azera-interior-exterior-and-drive/";
              }}
            />
            <Route
              path="/2018-honda-fit-sport-secret-life-of-fits"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-honda-fit-sport-secret-life-of-fits/";
              }}
            />
            <Route
              path="/the-new-porsche-cayennes-comfort-features"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-new-porsche-cayennes-comfort-features/";
              }}
            />
            <Route
              path="/the-all-new-2018-audi-a8-design"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-all-new-2018-audi-a8-design/";
              }}
            />
            <Route
              path="/the-new-2018-audi-a7-sportback-looks-good"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-new-2018-audi-a7-sportback-looks-good/";
              }}
            />
            <Route
              path="/all-new-2018-honda-accord-first-look"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-new-2018-honda-accord-first-look/";
              }}
            />
            <Route
              path="/beauty-within-the-beast-2018-nissan-x-trail-exterior-interior"
              component={() => {
                window.location = "https://autodeals.pk/blog/beauty-within-the-beast-2018-nissan-x-trail-exterior-interior/";
              }}
            />
            <Route
              path="/the-all-new-toyota-rush-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-all-new-toyota-rush-2018/";
              }}
            />
            <Route
              path="/all-new-lamborghini-urus-2018-the-fastest-suv-in-the-world"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-new-lamborghini-urus-2018-the-fastest-suv-in-the-world/";
              }}
            />
            <Route
              path="/when-the-lamborghini-aventador-s-comes-to-palm-beach"
              component={() => {
                window.location = "https://autodeals.pk/blog/when-the-lamborghini-aventador-s-comes-to-palm-beach/";
              }}
            />
            <Route
              path="/top-rated-car-interiors-loved-by-everyone"
              component={() => {
                window.location = "https://autodeals.pk/blog/top-rated-car-interiors-loved-by-everyone/";
              }}
            />
            <Route
              path="/audi-a6-the-first-luxury-class-e-tron-concept-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/audi-a6-the-first-luxury-class-e-tron-concept-car/";
              }}
            />
            <Route
              path="/world-famous-italian-brands-of-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/world-famous-italian-brands-of-cars/";
              }}
            />
            <Route
              path="/sindh-smart-card-for-vehicle-registration-launched"
              component={() => {
                window.location = "https://autodeals.pk/blog/sindh-smart-card-for-vehicle-registration-launched/";
              }}
            />
            <Route
              path="/daewoo-brings-100-maintenance-free-batteries-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/daewoo-brings-100-maintenance-free-batteries-in-pakistan/";
              }}
            />
            <Route
              path="/local-assemblers-suspend-booking-of-cars-for-non-filers"
              component={() => {
                window.location = "https://autodeals.pk/blog/local-assemblers-suspend-booking-of-cars-for-non-filers/";
              }}
            />
            <Route
              path="/sales-will-drop-if-government-doesnt-allow-non-filers-to-buy-cars-ceo-indus-motors"
              component={() => {
                window.location = "https://autodeals.pk/blog/sales-will-drop-if-government-doesnt-allow-non-filers-to-buy-cars-ceo-indus-motors/";
              }}
            />
            <Route
              path="/how-to-transfer-ownership-of-a-vehicle-in-karachi-complete-guide"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-transfer-ownership-of-a-vehicle-in-karachi-complete-guide/";
              }}
            />
            <Route
              path="/7-odd-budget-cars-you-might-want-to-check-out"
              component={() => {
                window.location = "https://autodeals.pk/blog/7-odd-budget-cars-you-might-want-to-check-out/";
              }}
            />
            <Route
              path="/are-pakistani-cars-as-fast-as-their-japanese-siblings"
              component={() => {
                window.location = "https://autodeals.pk/blog/are-pakistani-cars-as-fast-as-their-japanese-siblings/";
              }}
            />
            <Route
              path="/suzuki-baleno-1-0-booster-jet-a-big-car-with-a-fun-size-engine"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-baleno-1-0-booster-jet-a-big-car-with-a-fun-size-engine/";
              }}
            />
            <Route
              path="/the-new-mid-engined-corvette-spied-with-c5-style-nose"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-new-mid-engined-corvette-spied-with-c5-style-nose/";
              }}
            />
            <Route
              path="/islamabad-excise-and-taxation-department-introduced-bio-metric-verification-to-transfer-vehicle"
              component={() => {
                window.location = "https://autodeals.pk/blog/islamabad-excise-and-taxation-department-introduced-bio-metric-verification-to-transfer-vehicle/";
              }}
            />
            <Route
              path="/2019-toyota-corolla-hatchback-starts-at-20910"
              component={() => {
                window.location = "https://autodeals.pk/blog/2019-toyota-corolla-hatchback-starts-at-20910/";
              }}
            />
            <Route
              path="/auto-prices-are-up-partly-because-people-choose-to-buy-bigger-and-better"
              component={() => {
                window.location = "https://autodeals.pk/blog/auto-prices-are-up-partly-because-people-choose-to-buy-bigger-and-better/";
              }}
            />
            <Route
              path="/first-drive-2018-ford-f-150-diesel"
              component={() => {
                window.location = "https://autodeals.pk/blog/first-drive-2018-ford-f-150-diesel/";
              }}
            />
            <Route
              path="/gms-self-driving-fleet-gets-2-25-billion-capital-infusion-from-softbank-ahead-of-2019-launch"
              component={() => {
                window.location = "https://autodeals.pk/blog/gms-self-driving-fleet-gets-2-25-billion-capital-infusion-from-softbank-ahead-of-2019-launch/";
              }}
            />
            <Route
              path="/hyundai-nexo-leads-eco-charge"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-nexo-leads-eco-charge/";
              }}
            />
            <Route
              path="/everything-you-need-to-know-about-lead-acid-car-batteries"
              component={() => {
                window.location = "https://autodeals.pk/blog/everything-you-need-to-know-about-lead-acid-car-batteries/";
              }}
            />
            <Route
              path="/kia-lucky-motors-launches-grand-carnival"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-lucky-motors-launches-grand-carnival/";
              }}
            />
            <Route
              path="/tesla-model-s-on-autopilot-crashes-into-parked-police-vehicle-in-laguna-beach"
              component={() => {
                window.location = "https://autodeals.pk/blog/tesla-model-s-on-autopilot-crashes-into-parked-police-vehicle-in-laguna-beach/";
              }}
            />
            <Route
              path="/toyota-still-no-1-mercedes-passes-bmw-for-no-2-in-ranking-of-most-valuable-car-brands"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-still-no-1-mercedes-passes-bmw-for-no-2-in-ranking-of-most-valuable-car-brands/";
              }}
            />
            <Route
              path="/u-s-investigates-failing-air-bags-in-hyundai-kia-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/u-s-investigates-failing-air-bags-in-hyundai-kia-cars/";
              }}
            />
            <Route
              path="/5-must-have-cosmetic-upgrades-in-your-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-must-have-cosmetic-upgrades-in-your-car/";
              }}
            />
            <Route
              path="/interesting-trends-of-buying-bikes-in-pakistan-uncovered"
              component={() => {
                window.location = "https://autodeals.pk/blog/interesting-trends-of-buying-bikes-in-pakistan-uncovered/";
              }}
            />
            <Route
              path="/pak-suzuki-increases-the-prices-of-their-vehicles-for-third-time-in-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-increases-the-prices-of-their-vehicles-for-third-time-in-year/";
              }}
            />
            <Route
              path="/saudia-arabia-starts-giving-driving-license-to-women"
              component={() => {
                window.location = "https://autodeals.pk/blog/saudia-arabia-starts-giving-driving-license-to-women/";
              }}
            />
            <Route
              path="/suzuki-wagon-r-stingray-hybrid-t"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-wagon-r-stingray-hybrid-t/";
              }}
            />
            <Route
              path="/what-to-expect-from-master-motors-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/what-to-expect-from-master-motors-in-pakistan/";
              }}
            />
            <Route
              path="/10-things-that-can-cause-an-engine-to-lose-power"
              component={() => {
                window.location = "https://autodeals.pk/blog/10-things-that-can-cause-an-engine-to-lose-power/";
              }}
            />
            <Route
              path="/fake-chinese-lamborghini-urus-up-for-sale"
              component={() => {
                window.location = "https://autodeals.pk/blog/fake-chinese-lamborghini-urus-up-for-sale/";
              }}
            />
            <Route
              path="/honda-pakistan-offers-the-best-quality-automobiles"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-pakistan-offers-the-best-quality-automobiles/";
              }}
            />
            <Route
              path="/trends-of-buying-used-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/trends-of-buying-used-cars-in-pakistan/";
              }}
            />
            <Route
              path="/how-many-people-spent-on-buying-a-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-many-people-spent-on-buying-a-car/";
              }}
            />
            <Route
              path="/uber-opens-paris-research-centre-to-develop-flying-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/uber-opens-paris-research-centre-to-develop-flying-cars/";
              }}
            />
            <Route
              path="/kitty-hawk-reveals-first-personal-flying-vehicle-that-you-dont-need-a-license-to-operate"
              component={() => {
                window.location = "https://autodeals.pk/blog/kitty-hawk-reveals-first-personal-flying-vehicle-that-you-dont-need-a-license-to-operate/";
              }}
            />
            <Route
              path="/government-decides-not-to-increase-petrol-prices-in-june"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-decides-not-to-increase-petrol-prices-in-june/";
              }}
            />
            <Route
              path="/electronic-license-plates-introduced-in-california"
              component={() => {
                window.location = "https://autodeals.pk/blog/electronic-license-plates-introduced-in-california/";
              }}
            />
            <Route
              path="/volvo-electric-garbage-truck-to-clean-up-in-germany-next-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/volvo-electric-garbage-truck-to-clean-up-in-germany-next-year/";
              }}
            />
            <Route
              path="/audi-tops-in-suv-range-with-new-qb"
              component={() => {
                window.location = "https://autodeals.pk/blog/audi-tops-in-suv-range-with-new-qb/";
              }}
            />
            <Route
              path="/bmw-goes-bigger-and-bolder-with-fourth-gen-x5-luxury-sav"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-goes-bigger-and-bolder-with-fourth-gen-x5-luxury-sav/";
              }}
            />
            <Route
              path="/jeep-wrangler-becomes-the-ultimate-pop-up-adventure-camper-with-new-jxl-conversion"
              component={() => {
                window.location = "https://autodeals.pk/blog/jeep-wrangler-becomes-the-ultimate-pop-up-adventure-camper-with-new-jxl-conversion/";
              }}
            />
            <Route
              path="/land-rover-looks-to-take-autonomous-driving-off-road"
              component={() => {
                window.location = "https://autodeals.pk/blog/land-rover-looks-to-take-autonomous-driving-off-road/";
              }}
            />
            <Route
              path="/ups-announces-upcoming-arrival-of-electric-delivery-truck"
              component={() => {
                window.location = "https://autodeals.pk/blog/ups-announces-upcoming-arrival-of-electric-delivery-truck/";
              }}
            />
            <Route
              path="/tesla-tackles-guinness-world-record-by-towing-a-commercial-airliner"
              component={() => {
                window.location = "https://autodeals.pk/blog/tesla-tackles-guinness-world-record-by-towing-a-commercial-airliner/";
              }}
            />
            <Route
              path="/a-week-with-nissans-new-propilot-assist-technology"
              component={() => {
                window.location = "https://autodeals.pk/blog/a-week-with-nissans-new-propilot-assist-technology/";
              }}
            />
            <Route
              path="/bmw-i-launches-wireless-charging-for-plug-in-hybrids"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-i-launches-wireless-charging-for-plug-in-hybrids/";
              }}
            />
            <Route
              path="/ferrari-unveils-achingly-gorgeous-one-off-sp38"
              component={() => {
                window.location = "https://autodeals.pk/blog/ferrari-unveils-achingly-gorgeous-one-off-sp38/";
              }}
            />
            <Route
              path="/daimler-unveils-heavy-duty-ecascadia-and-medium-duty-em2-electric-trucks"
              component={() => {
                window.location = "https://autodeals.pk/blog/daimler-unveils-heavy-duty-ecascadia-and-medium-duty-em2-electric-trucks/";
              }}
            />
            <Route
              path="/ford-prototypes-smart-window-that-lets-blind-passengers-feel-the-view"
              component={() => {
                window.location = "https://autodeals.pk/blog/ford-prototypes-smart-window-that-lets-blind-passengers-feel-the-view/";
              }}
            />
            <Route
              path="/is-the-hybridization-of-the-gasoline-engine-a-threat-to-the-petrolhead-community"
              component={() => {
                window.location = "https://autodeals.pk/blog/is-the-hybridization-of-the-gasoline-engine-a-threat-to-the-petrolhead-community/";
              }}
            />
            <Route
              path="/nissan-takes-camper-vans-fully-electric-with-new-e-nv200-pop-top"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissan-takes-camper-vans-fully-electric-with-new-e-nv200-pop-top/";
              }}
            />
            <Route
              path="/over-88000-2018-gmc-terrain-suvs-recalled-due-to-possible-airbag-deployment-issue"
              component={() => {
                window.location = "https://autodeals.pk/blog/over-88000-2018-gmc-terrain-suvs-recalled-due-to-possible-airbag-deployment-issue/";
              }}
            />
            <Route
              path="/space-x-package-will-add-small-rocket-thursters-to-2020-tesla-roadster"
              component={() => {
                window.location = "https://autodeals.pk/blog/space-x-package-will-add-small-rocket-thursters-to-2020-tesla-roadster/";
              }}
            />
            <Route
              path="/tesla-model-s-restyle-broadens-station-wagon-appeal"
              component={() => {
                window.location = "https://autodeals.pk/blog/tesla-model-s-restyle-broadens-station-wagon-appeal/";
              }}
            />
            <Route
              path="/the-army-wants-new-track-vehicles-that-will-run-in-deep-snow-at-50-below"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-army-wants-new-track-vehicles-that-will-run-in-deep-snow-at-50-below/";
              }}
            />
            <Route
              path="/ultra-capacitor-hybrid-radically-boost-power-and-efficiency-of-lithium-batteries"
              component={() => {
                window.location = "https://autodeals.pk/blog/ultra-capacitor-hybrid-radically-boost-power-and-efficiency-of-lithium-batteries/";
              }}
            />
            <Route
              path="/few-simple-tips-to-improve-fuel-economy-of-your-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/few-simple-tips-to-improve-fuel-economy-of-your-car/";
              }}
            />
            <Route
              path="/new-suzuki-jimny-is-on-the-way-and-we-just-wanna-pinch-its-cheeks"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-suzuki-jimny-is-on-the-way-and-we-just-wanna-pinch-its-cheeks/";
              }}
            />
            <Route
              path="/suzuki-wagon-r-crowned-the-most-fuel-efficient-car-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-wagon-r-crowned-the-most-fuel-efficient-car-in-pakistan/";
              }}
            />
            <Route
              path="/teslas-next-software-update-will-bring-full-self-driving-functionality"
              component={() => {
                window.location = "https://autodeals.pk/blog/teslas-next-software-update-will-bring-full-self-driving-functionality/";
              }}
            />
            <Route
              path="/what-makes-suzuki-wagon-r-top-the-list-of-hatchbacks-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/what-makes-suzuki-wagon-r-top-the-list-of-hatchbacks-in-pakistan/";
              }}
            />
            <Route
              path="/all-new-2019-bmw-8-series-coupe-introduced"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-new-2019-bmw-8-series-coupe-introduced/";
              }}
            />
            <Route
              path="/new-audi-a1-sportback-gets-bigger-more-tech-advanced"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-audi-a1-sportback-gets-bigger-more-tech-advanced/";
              }}
            />
            <Route
              path="/review-2018-jeep-grand-cherokee-trackhawk-we-pity-the-fool-that-dont-drive-one"
              component={() => {
                window.location = "https://autodeals.pk/blog/review-2018-jeep-grand-cherokee-trackhawk-we-pity-the-fool-that-dont-drive-one/";
              }}
            />
            <Route
              path="/shell-pakistan-to-invest-millions-to-meet-the-local-demand"
              component={() => {
                window.location = "https://autodeals.pk/blog/shell-pakistan-to-invest-millions-to-meet-the-local-demand/";
              }}
            />
            <Route
              path="/volvo-begins-new-made-in-usa-era-with-s60-sedan"
              component={() => {
                window.location = "https://autodeals.pk/blog/volvo-begins-new-made-in-usa-era-with-s60-sedan/";
              }}
            />
            <Route
              path="/fia-customs-fail-to-take-action-against-websites-selling-non-custom-paid-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/fia-customs-fail-to-take-action-against-websites-selling-non-custom-paid-vehicles/";
              }}
            />
            <Route
              path="/suzuki-mehran-wins-the-best-car-award-based-on-the-cost-of-ownership-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-mehran-wins-the-best-car-award-based-on-the-cost-of-ownership-in-pakistan/";
              }}
            />
            <Route
              path="/when-will-we-see-electric-cars-running-on-pakistan-roads"
              component={() => {
                window.location = "https://autodeals.pk/blog/when-will-we-see-electric-cars-running-on-pakistan-roads/";
              }}
            />
            <Route
              path="/careem-launches-its-services-in-multan-gujranwala-and-sialkot"
              component={() => {
                window.location = "https://autodeals.pk/blog/careem-launches-its-services-in-multan-gujranwala-and-sialkot/";
              }}
            />
            <Route
              path="/government-increases-prices-of-petrol-and-diesel"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-increases-prices-of-petrol-and-diesel/";
              }}
            />
            <Route
              path="/honda-cd-70-win-award-for-the-best-bike-in-the-resale-value-category"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-cd-70-win-award-for-the-best-bike-in-the-resale-value-category/";
              }}
            />
            <Route
              path="/peshawar-bus-rapid-transit-launch-is-delayed-till-year-end"
              component={() => {
                window.location = "https://autodeals.pk/blog/peshawar-bus-rapid-transit-launch-is-delayed-till-year-end/";
              }}
            />
            <Route
              path="/traffic-signs-a-simple-guide-to-understand-road-signs"
              component={() => {
                window.location = "https://autodeals.pk/blog/traffic-signs-a-simple-guide-to-understand-road-signs/";
              }}
            />
            <Route
              path="/chinese-electric-car-manufactures-eye-pakistani-market"
              component={() => {
                window.location = "https://autodeals.pk/blog/chinese-electric-car-manufactures-eye-pakistani-market/";
              }}
            />
            <Route
              path="/toyota-corolla-ranked-as-the-best-car-in-pakistan-for-resale-value"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-corolla-ranked-as-the-best-car-in-pakistan-for-resale-value/";
              }}
            />
            <Route
              path="/hybrid-cars-that-you-can-buy-in-honda-city-price"
              component={() => {
                window.location = "https://autodeals.pk/blog/hybrid-cars-that-you-can-buy-in-honda-city-price/";
              }}
            />
            <Route
              path="/japanese-company-plans-to-launch-electric-rickshaws-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/japanese-company-plans-to-launch-electric-rickshaws-in-pakistan/";
              }}
            />
            <Route
              path="/atlas-honda-jacks-up-prices-of-motorbikes-third-time-this-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/atlas-honda-jacks-up-prices-of-motorbikes-third-time-this-year/";
              }}
            />
            <Route
              path="/toyota-raises-prices-for-cars-by-upto-rs-1-9-million"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-raises-prices-for-cars-by-upto-rs-1-9-million/";
              }}
            />
            <Route
              path="/government-is-gonna-reduce-car-registration-charges-in-new-budget"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-is-gonna-reduce-car-registration-charges-in-new-budget/";
              }}
            />
            <Route
              path="/pakistans-1st-ev-charging-station-going-to-launch"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistans-1st-ev-charging-station-going-to-launch/";
              }}
            />
            <Route
              path="/imc-toyota-offer-special-warranty-packages"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-toyota-offer-special-warranty-packages/";
              }}
            />
            <Route
              path="/ubl-collaborate-with-honda-to-offer-discount-for-its-clients"
              component={() => {
                window.location = "https://autodeals.pk/blog/ubl-collaborate-with-honda-to-offer-discount-for-its-clients/";
              }}
            />
            <Route
              path="/pak-suzuki-save-your-money-for-1-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-save-your-money-for-1-year/";
              }}
            />
            <Route
              path="/kia-brings-sportage-alpha-for-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-brings-sportage-alpha-for-pakistan/";
              }}
            />
            <Route
              path="/exciting-offer-by-hyundai-for-manual-transmission-users"
              component={() => {
                window.location = "https://autodeals.pk/blog/exciting-offer-by-hyundai-for-manual-transmission-users/";
              }}
            />
            <Route
              path="/hyundai-tuscon-latest-pictures-leaked"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-tuscon-latest-pictures-leaked/";
              }}
            />
            <Route
              path="/finally-toyota-yaris-booking-in-pakistan-start"
              component={() => {
                window.location = "https://autodeals.pk/blog/finally-toyota-yaris-booking-in-pakistan-start/";
              }}
            />
            <Route
              path="/bmw-2-series-grand-coupe-launched-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-2-series-grand-coupe-launched-in-pakistan/";
              }}
            />
            <Route
              path="/imc-toyota-cars-get-massive-price-hike"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-toyota-cars-get-massive-price-hike/";
              }}
            />
            <Route
              path="/suzuki-alto-vx-gets-a-major-price-hike-check-out"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-alto-vx-gets-a-major-price-hike-check-out/";
              }}
            />
            <Route
              path="/is-this-the-end-of-euro-2-and-start-of-euro-5-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/is-this-the-end-of-euro-2-and-start-of-euro-5-in-pakistan/";
              }}
            />
            <Route
              path="/govt-reduce-custom-duty-on-electric-vehicles-parts"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-reduce-custom-duty-on-electric-vehicles-parts/";
              }}
            />
            <Route
              path="/pak-suzuki-motorbikes-prices-rise-check-out"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-motorbikes-prices-rise-check-out/";
              }}
            />
            <Route
              path="/toyota-hilux-2020-gets-price-hike-check-out"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-hilux-2020-gets-price-hike-check-out/";
              }}
            />
            <Route
              path="/once-again-yamaha-and-honda-bikes-prices-bump"
              component={() => {
                window.location = "https://autodeals.pk/blog/once-again-yamaha-and-honda-bikes-prices-bump/";
              }}
            />
            <Route
              path="/pakistani-vehicles-get-international-safety-features"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistani-vehicles-get-international-safety-features/";
              }}
            />
            <Route
              path="/petrol-prices-in-pakistan-increased-check-details"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-in-pakistan-increased-check-details/";
              }}
            />
            <Route
              path="/a-major-increase-of-prince-pearl-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/a-major-increase-of-prince-pearl-prices/";
              }}
            />
            <Route
              path="/are-petrol-prices-likely-to-increase-from-july-1st"
              component={() => {
                window.location = "https://autodeals.pk/blog/are-petrol-prices-likely-to-increase-from-july-1st/";
              }}
            />
            <Route
              path="/crunches-of-new-computerized-number-plates-resolved"
              component={() => {
                window.location = "https://autodeals.pk/blog/crunches-of-new-computerized-number-plates-resolved/";
              }}
            />
            <Route
              path="/toyota-brings-exciting-offer-for-its-consumers"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-brings-exciting-offer-for-its-consumers/";
              }}
            />
            <Route
              path="/local-auto-makers-ask-government-for-tax-relief"
              component={() => {
                window.location = "https://autodeals.pk/blog/local-auto-makers-ask-government-for-tax-relief/";
              }}
            />
            <Route
              path="/govt-offer-incentives-on-motor-tax-fy-budget-2020-21"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-offer-incentives-on-motor-tax-fy-budget-2020-21/";
              }}
            />
            <Route
              path="/changan-alsvin-sedan-first-time-spotted-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/changan-alsvin-sedan-first-time-spotted-in-pakistan/";
              }}
            />
            <Route
              path="/order-worlds-cheapest-electric-car-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/order-worlds-cheapest-electric-car-in-pakistan/";
              }}
            />
            <Route
              path="/price-hike-expected-for-hilux-revo-d-max"
              component={() => {
                window.location = "https://autodeals.pk/blog/price-hike-expected-for-hilux-revo-d-max/";
              }}
            />
            <Route
              path="/pakistans-first-ev-policy-is-partially-approved"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistans-first-ev-policy-is-partially-approved/";
              }}
            />
            <Route
              path="/toyota-ventures-with-china-to-produces-hydrogen-cell-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-ventures-with-china-to-produces-hydrogen-cell-cars/";
              }}
            />
            <Route
              path="/petrol-prices-in-pakistan-may-deregulate-city-wise"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-in-pakistan-may-deregulate-city-wise/";
              }}
            />
            <Route
              path="/what-automakers-are-doing-with-their-employees-in-a-pandemic"
              component={() => {
                window.location = "https://autodeals.pk/blog/what-automakers-are-doing-with-their-employees-in-a-pandemic/";
              }}
            />
            <Route
              path="/imc-toyota-brings-an-exciting-offer-for-limited-time"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-toyota-brings-an-exciting-offer-for-limited-time/";
              }}
            />
            <Route
              path="/fuel-shortage-in-the-country"
              component={() => {
                window.location = "https://autodeals.pk/blog/fuel-shortage-in-the-country/";
              }}
            />
            <Route
              path="/finally-toyota-introduce-fortuner-facelift-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/finally-toyota-introduce-fortuner-facelift-2020/";
              }}
            />
            <Route
              path="/toyota-to-disclose-fortuner-and-revo-facelifts"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-to-disclose-fortuner-and-revo-facelifts/";
              }}
            />
            <Route
              path="/isuzu-d-max-facelift-launched-check-new-features"
              component={() => {
                window.location = "https://autodeals.pk/blog/isuzu-d-max-facelift-launched-check-new-features/";
              }}
            />
            <Route
              path="/reduction-in-petrol-prices-approved-by-government"
              component={() => {
                window.location = "https://autodeals.pk/blog/reduction-in-petrol-prices-approved-by-government/";
              }}
            />
            <Route
              path="/honda-city-hatchback-spotted-in-camouflage"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-city-hatchback-spotted-in-camouflage/";
              }}
            />
            <Route
              path="/proton-x70-on-way-of-testing-without-camouflage"
              component={() => {
                window.location = "https://autodeals.pk/blog/proton-x70-on-way-of-testing-without-camouflage/";
              }}
            />
            <Route
              path="/ogra-sent-proposal-to-government-reduce-petrol-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/ogra-sent-proposal-to-government-reduce-petrol-prices/";
              }}
            />
            <Route
              path="/ghandhara-has-disclosed-isuzu-d-max-2020-price"
              component={() => {
                window.location = "https://autodeals.pk/blog/ghandhara-has-disclosed-isuzu-d-max-2020-price/";
              }}
            />
            <Route
              path="/honda-launching-soon-new-honda-city"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-launching-soon-new-honda-city/";
              }}
            />
            <Route
              path="/toyota-yaris-3rd-generation-facelift-pictures-leaked"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-yaris-3rd-generation-facelift-pictures-leaked/";
              }}
            />
            <Route
              path="/finally-toyota-and-honda-resume-production-plants"
              component={() => {
                window.location = "https://autodeals.pk/blog/finally-toyota-and-honda-resume-production-plants/";
              }}
            />
            <Route
              path="/now-book-your-pak-suzuki-bikes-and-cars-online"
              component={() => {
                window.location = "https://autodeals.pk/blog/now-book-your-pak-suzuki-bikes-and-cars-online/";
              }}
            />
            <Route
              path="/auto-industry-face-hard-downturn-in-april-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/auto-industry-face-hard-downturn-in-april-2020/";
              }}
            />
            <Route
              path="/suzuki-launched-swift-facelift-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-launched-swift-facelift-2020/";
              }}
            />
            <Route
              path="/toyota-corolla-12th-generation-spotted-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-corolla-12th-generation-spotted-in-pakistan/";
              }}
            />
            <Route
              path="/first-ev-charging-facility-in-pakistan-introduced"
              component={() => {
                window.location = "https://autodeals.pk/blog/first-ev-charging-facility-in-pakistan-introduced/";
              }}
            />
            <Route
              path="/the-arriving-of-7th-gen-hyundai-elantra-makes-6th-gen-out-of-date-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-arriving-of-7th-gen-hyundai-elantra-makes-6th-gen-out-of-date-in-pakistan/";
              }}
            />
            <Route
              path="/finally-suzuki-swift-facelift-2020-disclosed"
              component={() => {
                window.location = "https://autodeals.pk/blog/finally-suzuki-swift-facelift-2020-disclosed/";
              }}
            />
            <Route
              path="/finally-kia-picanto-facelift-morning-launched-pictures-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/finally-kia-picanto-facelift-morning-launched-pictures-revealed/";
              }}
            />
            <Route
              path="/10-must-have-car-kit-tools-for-roadside-emergency"
              component={() => {
                window.location = "https://autodeals.pk/blog/10-must-have-car-kit-tools-for-roadside-emergency/";
              }}
            />
            <Route
              path="/detail-review-on-journey-of-honda-city-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/detail-review-on-journey-of-honda-city-pakistan/";
              }}
            />
            <Route
              path="/discover-5-trends-of-the-future-in-urban-mobility"
              component={() => {
                window.location = "https://autodeals.pk/blog/discover-5-trends-of-the-future-in-urban-mobility/";
              }}
            />
            <Route
              path="/have-a-look-at-pakistan-vs-india-sedan"
              component={() => {
                window.location = "https://autodeals.pk/blog/have-a-look-at-pakistan-vs-india-sedan/";
              }}
            />
            <Route
              path="/honda-atlas-increases-prices-of-its-cars-once-again"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-atlas-increases-prices-of-its-cars-once-again/";
              }}
            />
            <Route
              path="/find-out-more-about-honda-civic-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/find-out-more-about-honda-civic-in-pakistan/";
              }}
            />
            <Route
              path="/latest-pictures-revealed-of-kia-picanto-facelift-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/latest-pictures-revealed-of-kia-picanto-facelift-2020/";
              }}
            />
            <Route
              path="/deewan-motors-offering-bmw-330e-in-pakistan-limited-number-of-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/deewan-motors-offering-bmw-330e-in-pakistan-limited-number-of-cars/";
              }}
            />
            <Route
              path="/top-5-upcoming-sedans-in-2020-come-to-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/top-5-upcoming-sedans-in-2020-come-to-pakistan/";
              }}
            />
            <Route
              path="/more-than-15-million-toyota-hybrid-cars-have-been-sold-globally"
              component={() => {
                window.location = "https://autodeals.pk/blog/more-than-15-million-toyota-hybrid-cars-have-been-sold-globally/";
              }}
            />
            <Route
              path="/government-reduce-petrol-prices-from-1st-may"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-reduce-petrol-prices-from-1st-may/";
              }}
            />
            <Route
              path="/kia-revealed-its-state-of-the-art-3rd-generation-kia-picanto"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-revealed-its-state-of-the-art-3rd-generation-kia-picanto/";
              }}
            />
            <Route
              path="/pakistan-government-give-positive-sign-to-cut-down-fuel-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistan-government-give-positive-sign-to-cut-down-fuel-prices/";
              }}
            />
            <Route
              path="/is-this-end-of-suzuki-ciaz-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/is-this-end-of-suzuki-ciaz-in-pakistan/";
              }}
            />
            <Route
              path="/regal-automobiles-closed-its-manufacturing-plant"
              component={() => {
                window.location = "https://autodeals.pk/blog/regal-automobiles-closed-its-manufacturing-plant/";
              }}
            />
            <Route
              path="/toyota-corolla-crossover-near-to-the-launching"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-corolla-crossover-near-to-the-launching/";
              }}
            />
            <Route
              path="/hi-octane-whether-use-or-not"
              component={() => {
                window.location = "https://autodeals.pk/blog/hi-octane-whether-use-or-not/";
              }}
            />
            <Route
              path="/yamaha-r1-price-specifications"
              component={() => {
                window.location = "https://autodeals.pk/blog/yamaha-r1-price-specifications/";
              }}
            />
            <Route
              path="/honda-cg-125-special-edition-price-and-specifications"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-cg-125-special-edition-price-and-specifications/";
              }}
            />
            <Route
              path="/all-you-know-about-tesla-cybertruck"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-you-know-about-tesla-cybertruck/";
              }}
            />
            <Route
              path="/you-need-to-do-this-get-cars-radiator-cleaned-as-you-see-this"
              component={() => {
                window.location = "https://autodeals.pk/blog/you-need-to-do-this-get-cars-radiator-cleaned-as-you-see-this/";
              }}
            />
            <Route
              path="/toyota-revealed-4th-gen-harrier-in-japan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-revealed-4th-gen-harrier-in-japan/";
              }}
            />
            <Route
              path="/first-sedan-by-changan-coming-soon-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/first-sedan-by-changan-coming-soon-in-pakistan/";
              }}
            />
            <Route
              path="/whats-new-coming-for-imc-competitors"
              component={() => {
                window.location = "https://autodeals.pk/blog/whats-new-coming-for-imc-competitors/";
              }}
            />
            <Route
              path="/what-is-the-difference-between-suvs-and-crossovers"
              component={() => {
                window.location = "https://autodeals.pk/blog/what-is-the-difference-between-suvs-and-crossovers/";
              }}
            />
            <Route
              path="/whats-new-toyota-introduce-in-uae-land-cruiser-model"
              component={() => {
                window.location = "https://autodeals.pk/blog/whats-new-toyota-introduce-in-uae-land-cruiser-model/";
              }}
            />
            <Route
              path="/automakers-disclosed-car-sale-decline-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/automakers-disclosed-car-sale-decline-in-pakistan/";
              }}
            />
            <Route
              path="/toyota-pakistan-closed-its-production-plant"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-pakistan-closed-its-production-plant/";
              }}
            />
            <Route
              path="/2wd-or-4wd-which-is-better-for-driving"
              component={() => {
                window.location = "https://autodeals.pk/blog/2wd-or-4wd-which-is-better-for-driving/";
              }}
            />
            <Route
              path="/best-cost-effective-660cc-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/best-cost-effective-660cc-cars-in-pakistan/";
              }}
            />
            <Route
              path="/imc-toyota-hike-corolla-yaris-and-imv-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-toyota-hike-corolla-yaris-and-imv-prices/";
              }}
            />
            <Route
              path="/up-coming-suvs-in-2020-for-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/up-coming-suvs-in-2020-for-pakistan/";
              }}
            />
            <Route
              path="/how-to-maintain-your-car-during-lockdown"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-maintain-your-car-during-lockdown/";
              }}
            />
            <Route
              path="/pakistan-auto-sector-review-of-2019-have-a-look"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistan-auto-sector-review-of-2019-have-a-look/";
              }}
            />
            <Route
              path="/closing-of-automotive-plants-may-cause-a-huge-decrease-in-car-price"
              component={() => {
                window.location = "https://autodeals.pk/blog/closing-of-automotive-plants-may-cause-a-huge-decrease-in-car-price/";
              }}
            />
            <Route
              path="/auto-industrialist-asked-to-hold-up-ev-policy"
              component={() => {
                window.location = "https://autodeals.pk/blog/auto-industrialist-asked-to-hold-up-ev-policy/";
              }}
            />
            <Route
              path="/toyota-yaris-1-5l-ativ-x-cvt-unveiled"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-yaris-1-5l-ativ-x-cvt-unveiled/";
              }}
            />
            <Route
              path="/toyota-yaris-variants-for-replace-corolla-here-is-detail"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-yaris-variants-for-replace-corolla-here-is-detail/";
              }}
            />
            <Route
              path="/the-top-bad-driving-habits"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-top-bad-driving-habits/";
              }}
            />
            <Route
              path="/factors-that-are-effect-on-pakistan-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/factors-that-are-effect-on-pakistan-car-prices/";
              }}
            />
            <Route
              path="/toyota-and-byd-work-together-on-electric-vehicle-production"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-and-byd-work-together-on-electric-vehicle-production/";
              }}
            />
            <Route
              path="/chances-to-increase-car-prices-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/chances-to-increase-car-prices-in-pakistan/";
              }}
            />
            <Route
              path="/pakistan-car-prices-cut-down-after-tax-relief"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistan-car-prices-cut-down-after-tax-relief/";
              }}
            />
            <Route
              path="/toyota-yaris-1-3l-gli-cvt-vs-honda-city-i-vtec-1-3l-prosmatec"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-yaris-1-3l-gli-cvt-vs-honda-city-i-vtec-1-3l-prosmatec/";
              }}
            />
            <Route
              path="/datsun-by-nissan-stopped-production-in-indonesia"
              component={() => {
                window.location = "https://autodeals.pk/blog/datsun-by-nissan-stopped-production-in-indonesia/";
              }}
            />
            <Route
              path="/toyota-officially-discontinued-vitz-nameplate"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-officially-discontinued-vitz-nameplate/";
              }}
            />
            <Route
              path="/disinfect-car-to-reduce-the-risk-of-covid-19"
              component={() => {
                window.location = "https://autodeals.pk/blog/disinfect-car-to-reduce-the-risk-of-covid-19/";
              }}
            />
            <Route
              path="/how-to-drive-safely-during-this-coronavirus-outbreak"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-drive-safely-during-this-coronavirus-outbreak/";
              }}
            />
            <Route
              path="/petrol-prices-not-to-change-for-april-petroleum-division"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-not-to-change-for-april-petroleum-division/";
              }}
            />
            <Route
              path="/nhmp-closed-motorway-across-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/nhmp-closed-motorway-across-pakistan/";
              }}
            />
            <Route
              path="/toyota-pakistan-postpone-the-launching-of-yaris"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-pakistan-postpone-the-launching-of-yaris/";
              }}
            />
            <Route
              path="/govt-reduce-the-price-of-petrol-by-pkr-15-per-liter"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-reduce-the-price-of-petrol-by-pkr-15-per-liter/";
              }}
            />
            <Route
              path="/a-comparison-of-toyota-yaris-gli-1-3l-mt-vs-suzuki-ciaz-1-4l-mt"
              component={() => {
                window.location = "https://autodeals.pk/blog/a-comparison-of-toyota-yaris-gli-1-3l-mt-vs-suzuki-ciaz-1-4l-mt/";
              }}
            />
            <Route
              path="/how-coronavirus-effect-on-global-automotive-supply-chain"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-coronavirus-effect-on-global-automotive-supply-chain/";
              }}
            />
            <Route
              path="/first-baic-d20-spotted-at-lahore-roads"
              component={() => {
                window.location = "https://autodeals.pk/blog/first-baic-d20-spotted-at-lahore-roads/";
              }}
            />
            <Route
              path="/toyota-planning-for-12th-generation-corolla-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-planning-for-12th-generation-corolla-in-pakistan/";
              }}
            />
            <Route
              path="/here-is-the-booking-process-for-toyota-yaris"
              component={() => {
                window.location = "https://autodeals.pk/blog/here-is-the-booking-process-for-toyota-yaris/";
              }}
            />
            <Route
              path="/toyota-revealed-yaris-price-pictures-and-specifications"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-revealed-yaris-price-pictures-and-specifications/";
              }}
            />
            <Route
              path="/the-top-selling-brand-new-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-top-selling-brand-new-cars-in-pakistan/";
              }}
            />
            <Route
              path="/first-test-drive-yaris-spotted-in-karachi"
              component={() => {
                window.location = "https://autodeals.pk/blog/first-test-drive-yaris-spotted-in-karachi/";
              }}
            />
            <Route
              path="/imc-rolled-out-first-yaris-for-selling-purpose"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-rolled-out-first-yaris-for-selling-purpose/";
              }}
            />
            <Route
              path="/kia-break-the-reason-why-sportage-caught-fire"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-break-the-reason-why-sportage-caught-fire/";
              }}
            />
            <Route
              path="/suzuki-bring-exciting-offer-on-its-alto-660cc"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-bring-exciting-offer-on-its-alto-660cc/";
              }}
            />
            <Route
              path="/the-exclusive-pictures-of-toyota-yaris-unveiled"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-exclusive-pictures-of-toyota-yaris-unveiled/";
              }}
            />
            <Route
              path="/audi-launched-its-first-electric-car-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/audi-launched-its-first-electric-car-in-pakistan/";
              }}
            />
            <Route
              path="/the-launching-date-of-toyota-yaris-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-launching-date-of-toyota-yaris-revealed/";
              }}
            />
            <Route
              path="/punjab-govt-plan-to-bring-electric-buses-for-public-transport"
              component={() => {
                window.location = "https://autodeals.pk/blog/punjab-govt-plan-to-bring-electric-buses-for-public-transport/";
              }}
            />
            <Route
              path="/honda-atlas-brings-free-offers-for-its-customers"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-atlas-brings-free-offers-for-its-customers/";
              }}
            />
            <Route
              path="/imc-toyota-unveiled-yaris-2020-price-and-variant"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-toyota-unveiled-yaris-2020-price-and-variant/";
              }}
            />
            <Route
              path="/bmw-removes-the-curtains-of-its-i4-electric-sedan"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmw-removes-the-curtains-of-its-i4-electric-sedan/";
              }}
            />
            <Route
              path="/koenigsegg-introduced-gemera-hybrid-hyper-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/koenigsegg-introduced-gemera-hybrid-hyper-car/";
              }}
            />
            <Route
              path="/toyota-to-introduce-its-first-hybrid-suv-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-to-introduce-its-first-hybrid-suv-in-pakistan/";
              }}
            />
            <Route
              path="/best-affordable-japanese-cars-available-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/best-affordable-japanese-cars-available-in-pakistan/";
              }}
            />
            <Route
              path="/ogra-suggested-to-reduce-the-price-of-petrol"
              component={() => {
                window.location = "https://autodeals.pk/blog/ogra-suggested-to-reduce-the-price-of-petrol/";
              }}
            />
            <Route
              path="/honda-increase-its-motorbike-prices-up-to-rs-5000"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-increase-its-motorbike-prices-up-to-rs-5000/";
              }}
            />
            <Route
              path="/toyota-yaris-2020-is-near-to-the-launching"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-yaris-2020-is-near-to-the-launching/";
              }}
            />
            <Route
              path="/the-highlights-of-4-wheeler-at-pas-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-highlights-of-4-wheeler-at-pas-2020/";
              }}
            />
            <Route
              path="/iner-z-show-case-its-first-electric-bike-at-pakistan-auto-show-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/iner-z-show-case-its-first-electric-bike-at-pakistan-auto-show-2020/";
              }}
            />
            <Route
              path="/pakistan-international-auto-show-2020-at-expo-center-lahore"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistan-international-auto-show-2020-at-expo-center-lahore/";
              }}
            />
            <Route
              path="/vehicle-ownership-only-change-with-biometric-verification-in-punjab"
              component={() => {
                window.location = "https://autodeals.pk/blog/vehicle-ownership-only-change-with-biometric-verification-in-punjab/";
              }}
            />
            <Route
              path="/pakistans-best-small-cars-for-the-year-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistans-best-small-cars-for-the-year-2020/";
              }}
            />
            <Route
              path="/toyota-to-unveil-yaris-2020-at-geneva-motor-show"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-to-unveil-yaris-2020-at-geneva-motor-show/";
              }}
            />
            <Route
              path="/suzuki-brings-a-free-registration-offer-on-wagonr"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-brings-a-free-registration-offer-on-wagonr/";
              }}
            />
            <Route
              path="/yamaha-once-again-hike-its-bike-prices-2"
              component={() => {
                window.location = "https://autodeals.pk/blog/yamaha-once-again-hike-its-bike-prices-2/";
              }}
            />
            <Route
              path="/all-about-suzuki-jimny-4th-generation"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-about-suzuki-jimny-4th-generation/";
              }}
            />
            <Route
              path="/proton-x70-spotted-at-karachi-roads"
              component={() => {
                window.location = "https://autodeals.pk/blog/proton-x70-spotted-at-karachi-roads/";
              }}
            />
            <Route
              path="/a-chinese-company-invests-600-million-in-pakistan-auto-sector"
              component={() => {
                window.location = "https://autodeals.pk/blog/a-chinese-company-invests-600-million-in-pakistan-auto-sector/";
              }}
            />
            <Route
              path="/pak-suzuki-launched-wagon-r-vxl-ags"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-launched-wagon-r-vxl-ags/";
              }}
            />
            <Route
              path="/imc-toyota-add-more-colors-in-corolla-xli"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-toyota-add-more-colors-in-corolla-xli/";
              }}
            />
            <Route
              path="/booking-procedure-of-prince-pearl"
              component={() => {
                window.location = "https://autodeals.pk/blog/booking-procedure-of-prince-pearl/";
              }}
            />
            <Route
              path="/pak-suzuki-updated-cultus-features-and-price"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-updated-cultus-features-and-price/";
              }}
            />
            <Route
              path="/dfsk-to-launch-prince-pearl-on-31st-january-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/dfsk-to-launch-prince-pearl-on-31st-january-2020/";
              }}
            />
            <Route
              path="/hyundai-plant-to-produce-15000-cars-in-faislabad"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-plant-to-produce-15000-cars-in-faislabad/";
              }}
            />
            <Route
              path="/the-beginning-of-electric-vehicle-start-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-beginning-of-electric-vehicle-start-in-pakistan/";
              }}
            />
            <Route
              path="/sazgar-to-bring-a-baic-vehicles-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/sazgar-to-bring-a-baic-vehicles-in-pakistan/";
              }}
            />
            <Route
              path="/sazgar-going-to-display-pakistans-first-electric-rikshaw"
              component={() => {
                window.location = "https://autodeals.pk/blog/sazgar-going-to-display-pakistans-first-electric-rikshaw/";
              }}
            />
            <Route
              path="/honda-atlas-introduce-the-latest-model-of-civic-turbo-oriel"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-atlas-introduce-the-latest-model-of-civic-turbo-oriel/";
              }}
            />
            <Route
              path="/audi-is-all-set-to-launch-e-tron-50-quattro-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/audi-is-all-set-to-launch-e-tron-50-quattro-in-pakistan/";
              }}
            />
            <Route
              path="/the-next-generation-of-land-cruiser-will-be-a-hybrid"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-next-generation-of-land-cruiser-will-be-a-hybrid/";
              }}
            />
            <Route
              path="/pakistans-ev-policy-edited-with-the-meeting-of-stakeholders"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistans-ev-policy-edited-with-the-meeting-of-stakeholders/";
              }}
            />
            <Route
              path="/have-a-look-at-the-new-toyota-gr-yaris-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/have-a-look-at-the-new-toyota-gr-yaris-2020/";
              }}
            />
            <Route
              path="/metro-bus-fares-are-increased-by-rs-10-per-person"
              component={() => {
                window.location = "https://autodeals.pk/blog/metro-bus-fares-are-increased-by-rs-10-per-person/";
              }}
            />
            <Route
              path="/govt-order-to-strict-action-against-unregistered-vehicle"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-order-to-strict-action-against-unregistered-vehicle/";
              }}
            />
            <Route
              path="/honda-atlas-increase-prices-of-its-cars-by-up-to-rs-100000"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-atlas-increase-prices-of-its-cars-by-up-to-rs-100000/";
              }}
            />
            <Route
              path="/imc-toyota-celebrate-anniversary-with-special-discount-offer"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-toyota-celebrate-anniversary-with-special-discount-offer/";
              }}
            />
            <Route
              path="/first-electric-car-company-launched-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/first-electric-car-company-launched-in-pakistan/";
              }}
            />
            <Route
              path="/toyota-bring-an-amazing-car-care-offer-for-winters"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-bring-an-amazing-car-care-offer-for-winters/";
              }}
            />
            <Route
              path="/pak-suzuki-launched-wagon-r-ags-variant"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-launched-wagon-r-ags-variant/";
              }}
            />
            <Route
              path="/imc-toyota-launches-a-new-variant-of-fortuner"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-toyota-launches-a-new-variant-of-fortuner/";
              }}
            />
            <Route
              path="/imc-increase-prices-of-its-car-for-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-increase-prices-of-its-car-for-2020/";
              }}
            />
            <Route
              path="/ogra-suggested-to-hike-petrol-prices-for-january-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/ogra-suggested-to-hike-petrol-prices-for-january-2020/";
              }}
            />
            <Route
              path="/honda-bring-something-exciting-in-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-bring-something-exciting-in-2020/";
              }}
            />
            <Route
              path="/electric-vehicle-policy-will-start-from-next-month"
              component={() => {
                window.location = "https://autodeals.pk/blog/electric-vehicle-policy-will-start-from-next-month/";
              }}
            />
            <Route
              path="/imc-to-introduce-corolla-altis-grande-x"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-to-introduce-corolla-altis-grande-x/";
              }}
            />
            <Route
              path="/lahore-ring-road-toll-tax-increased-by-government"
              component={() => {
                window.location = "https://autodeals.pk/blog/lahore-ring-road-toll-tax-increased-by-government/";
              }}
            />
            <Route
              path="/how-to-drive-safe-in-winters-night"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-drive-safe-in-winters-night/";
              }}
            />
            <Route
              path="/proton-cars-soon-to-enter-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/proton-cars-soon-to-enter-in-pakistan/";
              }}
            />
            <Route
              path="/dfsk-launched-prince-pearl-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/dfsk-launched-prince-pearl-in-pakistan/";
              }}
            />
            <Route
              path="/toyota-plan-for-introducing-self-driving-vehicle"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-plan-for-introducing-self-driving-vehicle/";
              }}
            />
            <Route
              path="/suzuki-unveiled-the-variant-of-wagon-r-ags"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-unveiled-the-variant-of-wagon-r-ags/";
              }}
            />
            <Route
              path="/pak-suzuki-hike-the-price-of-jimny"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-hike-the-price-of-jimny/";
              }}
            />
            <Route
              path="/dfsk-has-to-introduce-two-new-variants-of-glory-580t"
              component={() => {
                window.location = "https://autodeals.pk/blog/dfsk-has-to-introduce-two-new-variants-of-glory-580t/";
              }}
            />
            <Route
              path="/toyota-yaris-2020-soon-to-launch-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-yaris-2020-soon-to-launch-in-pakistan/";
              }}
            />
            <Route
              path="/hyundai-nishat-introduce-3-passengers-cars-in-the-coming-years"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-nishat-introduce-3-passengers-cars-in-the-coming-years/";
              }}
            />
            <Route
              path="/special-discount-offer-on-corolla-xli-imc-announced"
              component={() => {
                window.location = "https://autodeals.pk/blog/special-discount-offer-on-corolla-xli-imc-announced/";
              }}
            />
            <Route
              path="/suzuki-introduce-face-lift-version-of-swift-4th-generation"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-introduce-face-lift-version-of-swift-4th-generation/";
              }}
            />
            <Route
              path="/petrol-prices-reduced-for-december-govt-announced"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-reduced-for-december-govt-announced/";
              }}
            />
            <Route
              path="/honda-introduce-5th-generation-of-honda-city"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-introduce-5th-generation-of-honda-city/";
              }}
            />
            <Route
              path="/govt-plan-to-facilitate-overseas-pakistani-with-duty-free-hybrid-and-electric-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-plan-to-facilitate-overseas-pakistani-with-duty-free-hybrid-and-electric-cars/";
              }}
            />
            <Route
              path="https://autodeals.pk/blog/how-to-buy-a-good-car-in-pakistan/	"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-buy-a-good-car-in-pakistan/";
              }}
            />
            <Route
              path="/jinbei-introduce-x30-and-x30l-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/jinbei-introduce-x30-and-x30l-in-pakistan/";
              }}
            />
            <Route
              path="/yamaha-discontinued-ybr-125g-night-fluo"
              component={() => {
                window.location = "https://autodeals.pk/blog/yamaha-discontinued-ybr-125g-night-fluo/";
              }}
            />
            <Route
              path="/cars-under-5-lakhs-available-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/cars-under-5-lakhs-available-in-pakistan/";
              }}
            />
            <Route
              path="/neon-launching-new-electric-bike-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/neon-launching-new-electric-bike-in-pakistan/";
              }}
            />
            <Route
              path="/prince-pearl-delayed-car-launching-and-scheduled-in-january-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/prince-pearl-delayed-car-launching-and-scheduled-in-january-2020/";
              }}
            />
            <Route
              path="/dfsk-prince-motors-increase-the-price-of-its-suv-glory-580t"
              component={() => {
                window.location = "https://autodeals.pk/blog/dfsk-prince-motors-increase-the-price-of-its-suv-glory-580t/";
              }}
            />
            <Route
              path="/why-use-agriauto-shock-absorbers"
              component={() => {
                window.location = "https://autodeals.pk/blog/why-use-agriauto-shock-absorbers/";
              }}
            />
            <Route
              path="/how-car-shipment-works-from-japan-to-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-car-shipment-works-from-japan-to-pakistan/";
              }}
            />
            <Route
              path="/1000-imported-vehicles-approves-for-clearance-by-ecc"
              component={() => {
                window.location = "https://autodeals.pk/blog/1000-imported-vehicles-approves-for-clearance-by-ecc/";
              }}
            />
            <Route
              path="/pakistans-first-electric-vehicle-policy-approved"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistans-first-electric-vehicle-policy-approved/";
              }}
            />
            <Route
              path="/how-do-i-know-i-need-a-new-shock-absorber"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-do-i-know-i-need-a-new-shock-absorber/";
              }}
            />
            <Route
              path="/amazing-things-about-660cc-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/amazing-things-about-660cc-cars-in-pakistan/";
              }}
            />
            <Route
              path="/best-1000cc-cars-available-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/best-1000cc-cars-available-in-pakistan/";
              }}
            />
            <Route
              path="/automatic-variant-of-united-bravo-to-launching-soon"
              component={() => {
                window.location = "https://autodeals.pk/blog/automatic-variant-of-united-bravo-to-launching-soon/";
              }}
            />
            <Route
              path="/moip-wants-to-introduce-the-first-ev-policy-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/moip-wants-to-introduce-the-first-ev-policy-in-pakistan/";
              }}
            />
            <Route
              path="/honda-once-again-increases-its-bike-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-once-again-increases-its-bike-prices/";
              }}
            />
            <Route
              path="/al-haj-started-assembling-proton-cars-under-the-greenfield-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/al-haj-started-assembling-proton-cars-under-the-greenfield-in-pakistan/";
              }}
            />
            <Route
              path="/nissan-ev-concept-closer-to-start-its-production"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissan-ev-concept-closer-to-start-its-production/";
              }}
            />
            <Route
              path="/honda-city-7th-generation-introduces-in-global-market-soon"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-city-7th-generation-introduces-in-global-market-soon/";
              }}
            />
            <Route
              path="/the-importance-of-the-auction-sheet-for-japanese-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-importance-of-the-auction-sheet-for-japanese-cars/";
              }}
            />
            <Route
              path="/low-economic-activities-affect-the-auto-sector"
              component={() => {
                window.location = "https://autodeals.pk/blog/low-economic-activities-affect-the-auto-sector/";
              }}
            />
            <Route
              path="/toyota-introduce-tiny-electric-cars-at-tokyo-motor-show"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-introduce-tiny-electric-cars-at-tokyo-motor-show/";
              }}
            />
            <Route
              path="/lahore-wasa-increases-fine-for-wash-car-with-pipes"
              component={() => {
                window.location = "https://autodeals.pk/blog/lahore-wasa-increases-fine-for-wash-car-with-pipes/";
              }}
            />
            <Route
              path="/most-popular-small-cars-of-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/most-popular-small-cars-of-2019/";
              }}
            />
            <Route
              path="/yamaha-once-again-hike-its-bike-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/yamaha-once-again-hike-its-bike-prices/";
              }}
            />
            <Route
              path="/looking-for-best-suv-car-for-family"
              component={() => {
                window.location = "https://autodeals.pk/blog/looking-for-best-suv-car-for-family/";
              }}
            />
            <Route
              path="/united-motors-added-new-features-in-bravo-and-increase-price"
              component={() => {
                window.location = "https://autodeals.pk/blog/united-motors-added-new-features-in-bravo-and-increase-price/";
              }}
            />
            <Route
              path="/automatic-car-vs-manual-cars-fuel-economy"
              component={() => {
                window.location = "https://autodeals.pk/blog/automatic-car-vs-manual-cars-fuel-economy/";
              }}
            />
            <Route
              path="/toyota-and-honda-again-halted-its-production-for-october"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-and-honda-again-halted-its-production-for-october/";
              }}
            />
            <Route
              path="/atlas-honda-unveiled-a-new-design-for-its-cg-125-bikes"
              component={() => {
                window.location = "https://autodeals.pk/blog/atlas-honda-unveiled-a-new-design-for-its-cg-125-bikes/";
              }}
            />
            <Route
              path="/honda-br-v-facelift-launched-in-pakistan-check-details"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-br-v-facelift-launched-in-pakistan-check-details/";
              }}
            />
            <Route
              path="/non-custom-vehicles-should-we-buy-or-not"
              component={() => {
                window.location = "https://autodeals.pk/blog/non-custom-vehicles-should-we-buy-or-not/";
              }}
            />
            <Route
              path="/ajrak-logo-on-smart-number-plates-to-be-introduce-in-sindh"
              component={() => {
                window.location = "https://autodeals.pk/blog/ajrak-logo-on-smart-number-plates-to-be-introduce-in-sindh/";
              }}
            />
            <Route
              path="/toyota-offers-a-free-insurance-for-corolla-altis-1-6l"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-offers-a-free-insurance-for-corolla-altis-1-6l/";
              }}
            />
            <Route
              path="/hyundai-nishat-open-the-doors-for-its-ioniq-hybrid"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-nishat-open-the-doors-for-its-ioniq-hybrid/";
              }}
            />
            <Route
              path="/honda-starts-pre-booking-for-br-v-facelift-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-starts-pre-booking-for-br-v-facelift-in-pakistan/";
              }}
            />
            <Route
              path="/islamabad-soon-to-introduce-rfid-vehicle-registration-plates"
              component={() => {
                window.location = "https://autodeals.pk/blog/islamabad-soon-to-introduce-rfid-vehicle-registration-plates/";
              }}
            />
            <Route
              path="/everything-you-need-to-know-for-a-road-trip-in-winters"
              component={() => {
                window.location = "https://autodeals.pk/blog/everything-you-need-to-know-for-a-road-trip-in-winters/";
              }}
            />
            <Route
              path="/something-exciting-is-coming-soon-for-hyundai-customers"
              component={() => {
                window.location = "https://autodeals.pk/blog/something-exciting-is-coming-soon-for-hyundai-customers/";
              }}
            />
            <Route
              path="/special-discount-available-on-the-toyota-corolla-1-3l-variants"
              component={() => {
                window.location = "https://autodeals.pk/blog/special-discount-available-on-the-toyota-corolla-1-3l-variants/";
              }}
            />
            <Route
              path="/cars-sale-continuously-decline-in-the-local-market"
              component={() => {
                window.location = "https://autodeals.pk/blog/cars-sale-continuously-decline-in-the-local-market/";
              }}
            />
            <Route
              path="/toyota-launches-manual-variant-of-altis-1-6l-in-pakistan-for-limited-time"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-launches-manual-variant-of-altis-1-6l-in-pakistan-for-limited-time/";
              }}
            />
            <Route
              path="/pak-suzuki-increase-the-price-of-alto-by-up-to-pkr-85000"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-increase-the-price-of-alto-by-up-to-pkr-85000/";
              }}
            />
            <Route
              path="/petrol-prices-still-unchanged-for-october"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-still-unchanged-for-october/";
              }}
            />
            <Route
              path="/suzuki-jimny-2019-price-pictures-features-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-jimny-2019-price-pictures-features-in-pakistan/";
              }}
            />
            <Route
              path="/fbr-decides-to-move-online-vehicle-dealers-ln-the-tax-net"
              component={() => {
                window.location = "https://autodeals.pk/blog/fbr-decides-to-move-online-vehicle-dealers-ln-the-tax-net/";
              }}
            />
            <Route
              path="/ogra-suggested-to-reduce-oil-prices-by-up-to-rs-4"
              component={() => {
                window.location = "https://autodeals.pk/blog/ogra-suggested-to-reduce-oil-prices-by-up-to-rs-4/";
              }}
            />
            <Route
              path="/atlas-honda-pakistan-halted-the-production-of-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/atlas-honda-pakistan-halted-the-production-of-cars-in-pakistan/";
              }}
            />
            <Route
              path="/yamaha-and-benelli-pakistan-increases-its-bikes-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/yamaha-and-benelli-pakistan-increases-its-bikes-prices/";
              }}
            />
            <Route
              path="/pak-suzuki-hike-the-price-of-vitara-glx"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-hike-the-price-of-vitara-glx/";
              }}
            />
            <Route
              path="/pbs-report-cars-import-down-by-85-in-this-fiscal-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/pbs-report-cars-import-down-by-85-in-this-fiscal-year/";
              }}
            />
            <Route
              path="/kia-plans-to-introduce-picanto-electric-variant-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-plans-to-introduce-picanto-electric-variant-in-pakistan/";
              }}
            />
            <Route
              path="/fbr-withdraw-sales-tax-relief-for-automaker"
              component={() => {
                window.location = "https://autodeals.pk/blog/fbr-withdraw-sales-tax-relief-for-automaker/";
              }}
            />
            <Route
              path="/toyota-closed-its-plant-till-30-september-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-closed-its-plant-till-30-september-2019/";
              }}
            />
            <Route
              path="/pak-suzuki-ceased-several-cars-booking"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-ceased-several-cars-booking/";
              }}
            />
            <Route
              path="/the-detail-review-of-kia-picanto-features-and-specifications"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-detail-review-of-kia-picanto-features-and-specifications/";
              }}
            />
            <Route
              path="/self-driving-cars-introduce-in-pakistan-in-the-next-few-years"
              component={() => {
                window.location = "https://autodeals.pk/blog/self-driving-cars-introduce-in-pakistan-in-the-next-few-years/";
              }}
            />
            <Route
              path="/kia-picanto-pre-booking-start-in-the-mid-of-this-month"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-picanto-pre-booking-start-in-the-mid-of-this-month/";
              }}
            />
            <Route
              path="/new-traffic-laws-soon-to-introduce-in-islamabad"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-traffic-laws-soon-to-introduce-in-islamabad/";
              }}
            />
            <Route
              path="/first-electric-vehicle-policy-ready-for-approval-of-cabinet"
              component={() => {
                window.location = "https://autodeals.pk/blog/first-electric-vehicle-policy-ready-for-approval-of-cabinet/";
              }}
            />
            <Route
              path="/pak-suzuki-increase-the-demand-for-pre-booking-of-alto-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-increase-the-demand-for-pre-booking-of-alto-2019/";
              }}
            />
            <Route
              path="/honda-launched-10th-generation-of-accord-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-launched-10th-generation-of-accord-in-pakistan/";
              }}
            />
            <Route
              path="/govt-to-introduce-electric-rickshaws-and-motorbikes-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-to-introduce-electric-rickshaws-and-motorbikes-in-pakistan/";
              }}
            />
            <Route
              path="/motorway-toll-tax-increase-for-lahore-to-islamabad"
              component={() => {
                window.location = "https://autodeals.pk/blog/motorway-toll-tax-increase-for-lahore-to-islamabad/";
              }}
            />
            <Route
              path="/government-wants-to-introduce-duty-free-electric-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-wants-to-introduce-duty-free-electric-cars/";
              }}
            />
            <Route
              path="/atlas-honda-jump-up-the-prices-of-bikes-for-september-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/atlas-honda-jump-up-the-prices-of-bikes-for-september-2019/";
              }}
            />
            <Route
              path="/yamaha-pakistan-hike-the-prices-of-its-bikes"
              component={() => {
                window.location = "https://autodeals.pk/blog/yamaha-pakistan-hike-the-prices-of-its-bikes/";
              }}
            />
            <Route
              path="/government-to-decrease-the-price-of-petrol-by-rs-4-59"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-to-decrease-the-price-of-petrol-by-rs-4-59/";
              }}
            />
            <Route
              path="/pre-booking-of-prince-pearl-start-how-to-book-prince-pearl"
              component={() => {
                window.location = "https://autodeals.pk/blog/pre-booking-of-prince-pearl-start-how-to-book-prince-pearl/";
              }}
            />
            <Route
              path="/suzuki-soon-to-launch-jimny-4th-generation-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-soon-to-launch-jimny-4th-generation-in-pakistan/";
              }}
            />
            <Route
              path="/suzuki-plan-to-launch-automatic-variant-of-wagon-r"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-plan-to-launch-automatic-variant-of-wagon-r/";
              }}
            />
            <Route
              path="/govt-plan-to-introduce-a-new-policy-for-used-cars-import"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-plan-to-introduce-a-new-policy-for-used-cars-import/";
              }}
            />
            <Route
              path="/itp-collect-rs-10-29-million-fines-in-this-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/itp-collect-rs-10-29-million-fines-in-this-year/";
              }}
            />
            <Route
              path="/best-small-family-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/best-small-family-cars-in-pakistan/";
              }}
            />
            <Route
              path="/prince-ready-for-launch-in-next-month"
              component={() => {
                window.location = "https://autodeals.pk/blog/prince-ready-for-launch-in-next-month/";
              }}
            />
            <Route
              path="/cars-sales-falls-in-pakistan-pama-report"
              component={() => {
                window.location = "https://autodeals.pk/blog/cars-sales-falls-in-pakistan-pama-report/";
              }}
            />
            <Route
              path="/government-orders-for-online-vehicle-registration-system"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-orders-for-online-vehicle-registration-system/";
              }}
            />
            <Route
              path="/jica-plan-to-boost-the-pakistan-auto-sector"
              component={() => {
                window.location = "https://autodeals.pk/blog/jica-plan-to-boost-the-pakistan-auto-sector/";
              }}
            />
            <Route
              path="/the-future-of-electric-vehicles-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-future-of-electric-vehicles-in-pakistan/";
              }}
            />
            <Route
              path="/toyota-launched-the-6th-generation-of-hiace-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-launched-the-6th-generation-of-hiace-in-pakistan/";
              }}
            />
            <Route
              path="/all-businesses-related-to-auto-sector-to-be-add-in-the-tax-net"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-businesses-related-to-auto-sector-to-be-add-in-the-tax-net/";
              }}
            />
            <Route
              path="/report-of-18-months-increase-car-prices-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/report-of-18-months-increase-car-prices-in-pakistan/";
              }}
            />
            <Route
              path="/kia-lucky-motors-introduce-first-ever-all-wheel-sportage-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-lucky-motors-introduce-first-ever-all-wheel-sportage-in-pakistan/";
              }}
            />
            <Route
              path="/bmwx7-2019-ready-for-launch-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/bmwx7-2019-ready-for-launch-in-pakistan/";
              }}
            />
            <Route
              path="/sukkur-multan-motorway-to-be-opened-for-traffic"
              component={() => {
                window.location = "https://autodeals.pk/blog/sukkur-multan-motorway-to-be-opened-for-traffic/";
              }}
            />
            <Route
              path="/cng-and-lpg-vehicles-ban-on-motorways-by-nha"
              component={() => {
                window.location = "https://autodeals.pk/blog/cng-and-lpg-vehicles-ban-on-motorways-by-nha/";
              }}
            />
            <Route
              path="/cars-production-down-in-first-11-months-of-fy2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/cars-production-down-in-first-11-months-of-fy2019/";
              }}
            />
            <Route
              path="/pak-suzuki-hike-the-prices-of-its-bikes"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-hike-the-prices-of-its-bikes/";
              }}
            />
            <Route
              path="/honda-atlas-profit-decline-by-30"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-atlas-profit-decline-by-30/";
              }}
            />
            <Route
              path="/ogra-bans-cng-lng-vans-for-public-transport"
              component={() => {
                window.location = "https://autodeals.pk/blog/ogra-bans-cng-lng-vans-for-public-transport/";
              }}
            />
            <Route
              path="/the-price-of-oil-will-increase-for-august-suggested-by-ogra"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-price-of-oil-will-increase-for-august-suggested-by-ogra/";
              }}
            />
            <Route
              path="/pak-suzuki-increase-the-price-of-alto-by-up-to-1-5-lac"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-increase-the-price-of-alto-by-up-to-1-5-lac/";
              }}
            />
            <Route
              path="/the-demand-of-high-end-vehicles-down-in-the-market"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-demand-of-high-end-vehicles-down-in-the-market/";
              }}
            />
            <Route
              path="/ghandhara-again-increase-the-price-of-its-vehicle-isuzu-d-max-variants"
              component={() => {
                window.location = "https://autodeals.pk/blog/ghandhara-again-increase-the-price-of-its-vehicle-isuzu-d-max-variants/";
              }}
            />
            <Route
              path="/honda-atlas-report-a-decline-in-profit-by-77-in-the-last-quarter"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-atlas-report-a-decline-in-profit-by-77-in-the-last-quarter/";
              }}
            />
            <Route
              path="/financial-results-of-pak-suzuki-of-first-half-year-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/financial-results-of-pak-suzuki-of-first-half-year-2019/";
              }}
            />
            <Route
              path="/cda-to-install-smart-traffic-signals-in-islamabad"
              component={() => {
                window.location = "https://autodeals.pk/blog/cda-to-install-smart-traffic-signals-in-islamabad/";
              }}
            />
            <Route
              path="/mini-cooper-se-2020-review-first-real-electric-mini"
              component={() => {
                window.location = "https://autodeals.pk/blog/mini-cooper-se-2020-review-first-real-electric-mini/";
              }}
            />
            <Route
              path="/pak-china-bus-service-started-by-bilal-travels"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-china-bus-service-started-by-bilal-travels/";
              }}
            />
            <Route
              path="/2019-tough-time-for-pakistan-automotive-industry"
              component={() => {
                window.location = "https://autodeals.pk/blog/2019-tough-time-for-pakistan-automotive-industry/";
              }}
            />
            <Route
              path="/toyota-is-going-to-launch-yaris-in-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-is-going-to-launch-yaris-in-2020/";
              }}
            />
            <Route
              path="/toyota-launch-its-3rd-generation-of-vios-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-launch-its-3rd-generation-of-vios-in-pakistan/";
              }}
            />
            <Route
              path="/suzuki-commercial-vehicles-prices-decreased"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-commercial-vehicles-prices-decreased/";
              }}
            />
            <Route
              path="/toyota-launched-hiace-6th-generation-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-launched-hiace-6th-generation-in-pakistan/";
              }}
            />
            <Route
              path="/petrol-station-started-a-rule-not-helmet-no-petrol"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-station-started-a-rule-not-helmet-no-petrol/";
              }}
            />
            <Route
              path="/karachi-traffic-police-find-thousand-of-bikes-without-helmet"
              component={() => {
                window.location = "https://autodeals.pk/blog/karachi-traffic-police-find-thousand-of-bikes-without-helmet/";
              }}
            />
            <Route
              path="/prince-dfsk-open-3s-dealership-in-lahore"
              component={() => {
                window.location = "https://autodeals.pk/blog/prince-dfsk-open-3s-dealership-in-lahore/";
              }}
            />
            <Route
              path="/faw-v2-price-increased-after-the-fed"
              component={() => {
                window.location = "https://autodeals.pk/blog/faw-v2-price-increased-after-the-fed/";
              }}
            />
            <Route
              path="/kia-sportage-2019-pakistan-detail-review"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-sportage-2019-pakistan-detail-review/";
              }}
            />
            <Route
              path="/the-updated-prices-of-honda-cars-after-fed"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-updated-prices-of-honda-cars-after-fed/";
              }}
            />
            <Route
              path="/yamaha-pakistan-increase-its-bikes-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/yamaha-pakistan-increase-its-bikes-prices/";
              }}
            />
            <Route
              path="/fbr-announced-a-great-news-for-vehicle-owners"
              component={() => {
                window.location = "https://autodeals.pk/blog/fbr-announced-a-great-news-for-vehicle-owners/";
              }}
            />
            <Route
              path="/government-imposed-news-taxes-on-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-imposed-news-taxes-on-vehicles/";
              }}
            />
            <Route
              path="/dollar-price-effects-pakistan-auto-industry-2"
              component={() => {
                window.location = "https://autodeals.pk/blog/dollar-price-effects-pakistan-auto-industry-2/";
              }}
            />
            <Route
              path="/pak-suzuki-increases-its-car-prices-2"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-increases-its-car-prices-2/";
              }}
            />
            <Route
              path="/sportage-booking-start-in-pakistan-by-kia-lucky-motors"
              component={() => {
                window.location = "https://autodeals.pk/blog/sportage-booking-start-in-pakistan-by-kia-lucky-motors/";
              }}
            />
            <Route
              path="/how-the-auto-industry-affected-by-finance-bill-2019-20"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-the-auto-industry-affected-by-finance-bill-2019-20/";
              }}
            />
            <Route
              path="/all-you-need-to-know-about-suzuki-alto-660cc-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-you-need-to-know-about-suzuki-alto-660cc-2019/";
              }}
            />
            <Route
              path="/al-haj-faw-increases-its-car-prices-by-up-to-rs-100000"
              component={() => {
                window.location = "https://autodeals.pk/blog/al-haj-faw-increases-its-car-prices-by-up-to-rs-100000/";
              }}
            />
            <Route
              path="/evs-a-big-challenge-for-local-car-industry-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/evs-a-big-challenge-for-local-car-industry-in-pakistan/";
              }}
            />
            <Route
              path="/isuzu-d-max-v-cross-increase-their-price-by-pkr-200000-more"
              component={() => {
                window.location = "https://autodeals.pk/blog/isuzu-d-max-v-cross-increase-their-price-by-pkr-200000-more/";
              }}
            />
            <Route
              path="/atlas-honda-did-group-together-planned-to-manufacture-motorcycle-chain-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/atlas-honda-did-group-together-planned-to-manufacture-motorcycle-chain-in-pakistan/";
              }}
            />
            <Route
              path="/electric-cars-can-help-to-save-rs-109-billion-every-year-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/electric-cars-can-help-to-save-rs-109-billion-every-year-in-pakistan/";
              }}
            />
            <Route
              path="/small-cars-are-helpful-for-fuel-efficient"
              component={() => {
                window.location = "https://autodeals.pk/blog/small-cars-are-helpful-for-fuel-efficient/";
              }}
            />
            <Route
              path="/lucky-motors-start-assembling-peugeot-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/lucky-motors-start-assembling-peugeot-cars-in-pakistan/";
              }}
            />
            <Route
              path="/authorities-seized-2-smuggled-land-cruisers-in-karachi"
              component={() => {
                window.location = "https://autodeals.pk/blog/authorities-seized-2-smuggled-land-cruisers-in-karachi/";
              }}
            />
            <Route
              path="/petrol-prices-increase-from-july-by-up-to-rs-5"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-increase-from-july-by-up-to-rs-5/";
              }}
            />
            <Route
              path="/honda-increase-its-car-prices-by-up-to-rs-400000"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-increase-its-car-prices-by-up-to-rs-400000/";
              }}
            />
            <Route
              path="/auto-deals-monthly-news-updates-on-auto-sector"
              component={() => {
                window.location = "https://autodeals.pk/blog/auto-deals-monthly-news-updates-on-auto-sector/";
              }}
            />
            <Route
              path="/ministry-rejects-the-proposal-of-import-used-cars-on-a-commercial-basis"
              component={() => {
                window.location = "https://autodeals.pk/blog/ministry-rejects-the-proposal-of-import-used-cars-on-a-commercial-basis/";
              }}
            />
            <Route
              path="/future-of-hydrogen-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/future-of-hydrogen-cars/";
              }}
            />
            <Route
              path="/pakistan-brand-new-cheapest-car-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistan-brand-new-cheapest-car-2019/";
              }}
            />
            <Route
              path="/benefits-of-car-tracker"
              component={() => {
                window.location = "https://autodeals.pk/blog/benefits-of-car-tracker/";
              }}
            />
            <Route
              path="/amnesty-scheme-for-non-custom-paid-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/amnesty-scheme-for-non-custom-paid-vehicles/";
              }}
            />
            <Route
              path="/prime-minister-going-to-introduce-electric-vehicles-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/prime-minister-going-to-introduce-electric-vehicles-in-pakistan/";
              }}
            />
            <Route
              path="/used-cars-sales-down-by-60"
              component={() => {
                window.location = "https://autodeals.pk/blog/used-cars-sales-down-by-60/";
              }}
            />
            <Route
              path="/psca-get-started-e-challan-for-islamabad-registered-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/psca-get-started-e-challan-for-islamabad-registered-vehicles/";
              }}
            />
            <Route
              path="/sales-of-cars-fell-by-20-from-last-year"
              component={() => {
                window.location = "https://autodeals.pk/blog/sales-of-cars-fell-by-20-from-last-year/";
              }}
            />
            <Route
              path="/local-cars-manufacturer-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/local-cars-manufacturer-in-pakistan/";
              }}
            />
            <Route
              path="/lahore-traffic-police-banned-tinted-glass-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/lahore-traffic-police-banned-tinted-glass-vehicles/";
              }}
            />
            <Route
              path="/automatic-brand-new-cars-under-10-lac-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/automatic-brand-new-cars-under-10-lac-in-pakistan/";
              }}
            />
            <Route
              path="/khyber-pakhtunkhwa-excise-and-taxation-department-seizes-hundred-motorbikes-for-recovery-of-token-tax"
              component={() => {
                window.location = "https://autodeals.pk/blog/khyber-pakhtunkhwa-excise-and-taxation-department-seizes-hundred-motorbikes-for-recovery-of-token-tax/";
              }}
            />
            <Route
              path="/prince-pearl-hatchback-car-reaches-lahore-and-ready-for-launch"
              component={() => {
                window.location = "https://autodeals.pk/blog/prince-pearl-hatchback-car-reaches-lahore-and-ready-for-launch/";
              }}
            />
            <Route
              path="/2018-car-purchase-comparison-report"
              component={() => {
                window.location = "https://autodeals.pk/blog/2018-car-purchase-comparison-report/";
              }}
            />
            <Route
              path="/altas-honda-increases-bike-prices-up-to-pkr-2000"
              component={() => {
                window.location = "https://autodeals.pk/blog/altas-honda-increases-bike-prices-up-to-pkr-2000/";
              }}
            />
            <Route
              path="/pak-suzuki-increases-the-prices-by-pkr-30000"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-increases-the-prices-by-pkr-30000/";
              }}
            />
            <Route
              path="/bank-increase-the-rates-of-car-financing-up-to-pkr-10000"
              component={() => {
                window.location = "https://autodeals.pk/blog/bank-increase-the-rates-of-car-financing-up-to-pkr-10000/";
              }}
            />
            <Route
              path="/fuel-price-increase-up-to-9-rupees"
              component={() => {
                window.location = "https://autodeals.pk/blog/fuel-price-increase-up-to-9-rupees/";
              }}
            />
            <Route
              path="/most-searched-cars-of-the-year-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/most-searched-cars-of-the-year-2018/";
              }}
            />
            <Route
              path="/due-to-the-smuggling-of-tyre-pakistan-face-loss-up-to-rs-30-billion-in-taxes"
              component={() => {
                window.location = "https://autodeals.pk/blog/due-to-the-smuggling-of-tyre-pakistan-face-loss-up-to-rs-30-billion-in-taxes/";
              }}
            />
            <Route
              path="/prince-pearl-rex7-interior-exterior-price-and-launching-details"
              component={() => {
                window.location = "https://autodeals.pk/blog/prince-pearl-rex7-interior-exterior-price-and-launching-details/";
              }}
            />
            <Route
              path="/safety-guidelines-to-prevent-car-theft"
              component={() => {
                window.location = "https://autodeals.pk/blog/safety-guidelines-to-prevent-car-theft/";
              }}
            />
            <Route
              path="/mercedes-benz-eq-silver-arrow-specifications-and-features"
              component={() => {
                window.location = "https://autodeals.pk/blog/mercedes-benz-eq-silver-arrow-specifications-and-features/";
              }}
            />
            <Route
              path="/fuel-average-of-cars-in-city-and-long-routes"
              component={() => {
                window.location = "https://autodeals.pk/blog/fuel-average-of-cars-in-city-and-long-routes/";
              }}
            />
            <Route
              path="/how-to-buy-a-car-without-customs-duty-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-buy-a-car-without-customs-duty-in-pakistan/";
              }}
            />
            <Route
              path="/how-car-import-affect-our-economy"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-car-import-affect-our-economy/";
              }}
            />
            <Route
              path="/separate-bike-lanes-in-lahore"
              component={() => {
                window.location = "https://autodeals.pk/blog/separate-bike-lanes-in-lahore/";
              }}
            />
            <Route
              path="/how-to-get-car-financed-by-bank-procedure-and-guide"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-get-car-financed-by-bank-procedure-and-guide/";
              }}
            />
            <Route
              path="/suzuki-alto-2019-booking-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-alto-2019-booking-in-pakistan/";
              }}
            />
            <Route
              path="/brand-new-low-budget-cars-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/brand-new-low-budget-cars-in-pakistan/";
              }}
            />
            <Route
              path="/changan-start-local-production-of-its-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/changan-start-local-production-of-its-cars/";
              }}
            />
            <Route
              path="/local-cars-vs-imported-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/local-cars-vs-imported-cars/";
              }}
            />
            <Route
              path="/how-self-driving-cars-helps-us"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-self-driving-cars-helps-us/";
              }}
            />
            <Route
              path="/pink-bus-service-has-launched-in-mardan"
              component={() => {
                window.location = "https://autodeals.pk/blog/pink-bus-service-has-launched-in-mardan/";
              }}
            />
            <Route
              path="/importers-appeal-to-govt-for-commercializing-the-import-of-used-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/importers-appeal-to-govt-for-commercializing-the-import-of-used-cars/";
              }}
            />
            <Route
              path="/secp-taking-legal-action-against-crypt-currency-dealers"
              component={() => {
                window.location = "https://autodeals.pk/blog/secp-taking-legal-action-against-crypt-currency-dealers/";
              }}
            />
            <Route
              path="/xiaomi-has-launched-a-redmi-suv"
              component={() => {
                window.location = "https://autodeals.pk/blog/xiaomi-has-launched-a-redmi-suv/";
              }}
            />
            <Route
              path="/lahore-abdul-hakeem-motorway-opens-for-general-public"
              component={() => {
                window.location = "https://autodeals.pk/blog/lahore-abdul-hakeem-motorway-opens-for-general-public/";
              }}
            />
            <Route
              path="/hyundai-ioniq-vs-toyota-prius"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-ioniq-vs-toyota-prius/";
              }}
            />
            <Route
              path="/pak-suzuki-starts-production-of-660cc-alto"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-starts-production-of-660cc-alto/";
              }}
            />
            <Route
              path="/psca-adds-more-banks-to-the-receipt-of-the-e-challan-payments"
              component={() => {
                window.location = "https://autodeals.pk/blog/psca-adds-more-banks-to-the-receipt-of-the-e-challan-payments/";
              }}
            />
            <Route
              path="/alloy-wheels-vs-steel-wheels"
              component={() => {
                window.location = "https://autodeals.pk/blog/alloy-wheels-vs-steel-wheels/";
              }}
            />
            <Route
              path="/volkswagen-set-new-targets-in-the-evs-segment"
              component={() => {
                window.location = "https://autodeals.pk/blog/volkswagen-set-new-targets-in-the-evs-segment/";
              }}
            />
            <Route
              path="/import-of-used-cars-in-pakistan-decreased-by-74-percent"
              component={() => {
                window.location = "https://autodeals.pk/blog/import-of-used-cars-in-pakistan-decreased-by-74-percent/";
              }}
            />
            <Route
              path="/motorways-closed-on-march-18-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/motorways-closed-on-march-18-2019/";
              }}
            />
            <Route
              path="/authorities-to-investigate-the-source-of-income-if-a-non-filer-buys-a-vehicle"
              component={() => {
                window.location = "https://autodeals.pk/blog/authorities-to-investigate-the-source-of-income-if-a-non-filer-buys-a-vehicle/";
              }}
            />
            <Route
              path="/yamaha-and-suzuki-bikes-sales-up-for-feb-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/yamaha-and-suzuki-bikes-sales-up-for-feb-2019/";
              }}
            />
            <Route
              path="/toyota-passo-vs-suzuki-cultus"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-passo-vs-suzuki-cultus/";
              }}
            />
            <Route
              path="/m-tag-registration-made-compulsory-for-all-motorists-on-m-1-and-m-2"
              component={() => {
                window.location = "https://autodeals.pk/blog/m-tag-registration-made-compulsory-for-all-motorists-on-m-1-and-m-2/";
              }}
            />
            <Route
              path="/steps-to-jump-start-a-car-with-a-dead-battery"
              component={() => {
                window.location = "https://autodeals.pk/blog/steps-to-jump-start-a-car-with-a-dead-battery/";
              }}
            />
            <Route
              path="/hyundai-starex-vs-toyota-hiace"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-starex-vs-toyota-hiace/";
              }}
            />
            <Route
              path="/law-and-order-exposed-the-statistics-for-the-month-of-february-by-psca"
              component={() => {
                window.location = "https://autodeals.pk/blog/law-and-order-exposed-the-statistics-for-the-month-of-february-by-psca/";
              }}
            />
            <Route
              path="/tips-to-spend-long-life-with-your-alloy-wheels"
              component={() => {
                window.location = "https://autodeals.pk/blog/tips-to-spend-long-life-with-your-alloy-wheels/";
              }}
            />
            <Route
              path="/atlas-honda-launched-cg-125-in-pakistan-with-self-start-option-and-5-gear-transmission"
              component={() => {
                window.location = "https://autodeals.pk/blog/atlas-honda-launched-cg-125-in-pakistan-with-self-start-option-and-5-gear-transmission/";
              }}
            />
            <Route
              path="/motor-registration-authority-take-strict-action-against-people-using-vehicles-on-open-letter"
              component={() => {
                window.location = "https://autodeals.pk/blog/motor-registration-authority-take-strict-action-against-people-using-vehicles-on-open-letter/";
              }}
            />
            <Route
              path="/tips-to-safe-yourself-while-using-a-ride-hailing-service"
              component={() => {
                window.location = "https://autodeals.pk/blog/tips-to-safe-yourself-while-using-a-ride-hailing-service/";
              }}
            />
            <Route
              path="/hyundai-launched-grand-starex-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-launched-grand-starex-in-pakistan/";
              }}
            />
            <Route
              path="/prince-is-ready-to-launch-a-new-800cc-car-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/prince-is-ready-to-launch-a-new-800cc-car-in-pakistan/";
              }}
            />
            <Route
              path="/road-safety-campaign-careem-join-hands-with-traffic-police"
              component={() => {
                window.location = "https://autodeals.pk/blog/road-safety-campaign-careem-join-hands-with-traffic-police/";
              }}
            />
            <Route
              path="/islamabad-local-government-to-revive-toll-plazas"
              component={() => {
                window.location = "https://autodeals.pk/blog/islamabad-local-government-to-revive-toll-plazas/";
              }}
            />
            <Route
              path="/lahore-traffic-police-launches-yet-another-safety-campaign"
              component={() => {
                window.location = "https://autodeals.pk/blog/lahore-traffic-police-launches-yet-another-safety-campaign/";
              }}
            />
            <Route
              path="/pak-suzuki-launches-limited-edition-of-mehran-vx"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-launches-limited-edition-of-mehran-vx/";
              }}
            />
            <Route
              path="/hyundai-to-launch-the-1st-digital-car-showroom-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-to-launch-the-1st-digital-car-showroom-in-pakistan/";
              }}
            />
            <Route
              path="/hyundai-car-washer"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-car-washer/";
              }}
            />
            <Route
              path="/toyota-revo-vs-isuzu-d-max"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-revo-vs-isuzu-d-max/";
              }}
            />
            <Route
              path="/careem-ride-hailing-service-changes-its-payment-method-for-the-customers"
              component={() => {
                window.location = "https://autodeals.pk/blog/careem-ride-hailing-service-changes-its-payment-method-for-the-customers/";
              }}
            />
            <Route
              path="/non-filers-can-now-purchase-up-to-1300cc-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/non-filers-can-now-purchase-up-to-1300cc-cars/";
              }}
            />
            <Route
              path="/pay-your-e-challan-today-or-face-the-law"
              component={() => {
                window.location = "https://autodeals.pk/blog/pay-your-e-challan-today-or-face-the-law/";
              }}
            />
            <Route
              path="/legal-action-to-be-taken-against-the-use-of-hid-lights-in-the-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/legal-action-to-be-taken-against-the-use-of-hid-lights-in-the-vehicles/";
              }}
            />
            <Route
              path="/benelli-increases-prices-of-its-bikes-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/benelli-increases-prices-of-its-bikes-in-pakistan/";
              }}
            />
            <Route
              path="/itp-forms-special-squad-to-control-traffic-lane-violations"
              component={() => {
                window.location = "https://autodeals.pk/blog/itp-forms-special-squad-to-control-traffic-lane-violations/";
              }}
            />
            <Route
              path="/skyways-launches-luxury-bus-with-massage-for-lahore-islamabad-route"
              component={() => {
                window.location = "https://autodeals.pk/blog/skyways-launches-luxury-bus-with-massage-for-lahore-islamabad-route/";
              }}
            />
            <Route
              path="/only-40-of-traffic-signals-are-functional-in-islamabad-capital-city"
              component={() => {
                window.location = "https://autodeals.pk/blog/only-40-of-traffic-signals-are-functional-in-islamabad-capital-city/";
              }}
            />
            <Route
              path="/ecc-allows-car-import-duty-to-be-paid-in-us-dollar-only"
              component={() => {
                window.location = "https://autodeals.pk/blog/ecc-allows-car-import-duty-to-be-paid-in-us-dollar-only/";
              }}
            />
            <Route
              path="/heavy-bikes-allowed-on-motorway"
              component={() => {
                window.location = "https://autodeals.pk/blog/heavy-bikes-allowed-on-motorway/";
              }}
            />
            <Route
              path="/tips-for-maintaining-a-new-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/tips-for-maintaining-a-new-car/";
              }}
            />
            <Route
              path="/motor-way-now-open-from-lahore-to-abdul-hakeem"
              component={() => {
                window.location = "https://autodeals.pk/blog/motor-way-now-open-from-lahore-to-abdul-hakeem/";
              }}
            />
            <Route
              path="/tips-for-improving-gas-mileage"
              component={() => {
                window.location = "https://autodeals.pk/blog/tips-for-improving-gas-mileage/";
              }}
            />
            <Route
              path="/sindh-government-has-started-taking-action-against-vehicle-tax-defaulters"
              component={() => {
                window.location = "https://autodeals.pk/blog/sindh-government-has-started-taking-action-against-vehicle-tax-defaulters/";
              }}
            />
            <Route
              path="/how-to-pay-e-challan"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-pay-e-challan/";
              }}
            />
            <Route
              path="/pak-suzuki-increase-bike-prices-by-up-to-rs-8000"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-increase-bike-prices-by-up-to-rs-8000/";
              }}
            />
            <Route
              path="/benefits-of-hybrid-vehicle"
              component={() => {
                window.location = "https://autodeals.pk/blog/benefits-of-hybrid-vehicle/";
              }}
            />
            <Route
              path="/top-car-importers-in-lahore"
              component={() => {
                window.location = "https://autodeals.pk/blog/top-car-importers-in-lahore/";
              }}
            />
            <Route
              path="/importance-of-driving-license"
              component={() => {
                window.location = "https://autodeals.pk/blog/importance-of-driving-license/";
              }}
            />
            <Route
              path="/land-rover-defender-2020-all-you-need-to-know"
              component={() => {
                window.location = "https://autodeals.pk/blog/land-rover-defender-2020-all-you-need-to-know/";
              }}
            />
            <Route
              path="/government-employees-will-pay-toll-tax"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-employees-will-pay-toll-tax/";
              }}
            />
            <Route
              path="/how-long-drive-affect-our-health"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-long-drive-affect-our-health/";
              }}
            />
            <Route
              path="/benefits-of-car-insurance"
              component={() => {
                window.location = "https://autodeals.pk/blog/benefits-of-car-insurance/";
              }}
            />
            <Route
              path="/tips-to-buy-a-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/tips-to-buy-a-car/";
              }}
            />
            <Route
              path="/auction-sheet-explained-verify-auction-sheet-before-buy-japanese-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/auction-sheet-explained-verify-auction-sheet-before-buy-japanese-cars/";
              }}
            />
            <Route
              path="/tips-for-maintaining-your-car-tyres"
              component={() => {
                window.location = "https://autodeals.pk/blog/tips-for-maintaining-your-car-tyres/";
              }}
            />
            <Route
              path="/the-thing-everyone-should-know-before-driving"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-thing-everyone-should-know-before-driving/";
              }}
            />
            <Route
              path="/how-to-avoid-the-accident"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-avoid-the-accident/";
              }}
            />
            <Route
              path="/compare-car-sale-2017-vs-2018-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/compare-car-sale-2017-vs-2018-in-pakistan/";
              }}
            />
            <Route
              path="/the-reasons-of-brakes-failure"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-reasons-of-brakes-failure/";
              }}
            />
            <Route
              path="/punjab-government-provide-scooties-bikes-to-students-on-easy-installments"
              component={() => {
                window.location = "https://autodeals.pk/blog/punjab-government-provide-scooties-bikes-to-students-on-easy-installments/";
              }}
            />
            <Route
              path="/follow-the-simple-winter-tips-for-a-safe-trip"
              component={() => {
                window.location = "https://autodeals.pk/blog/follow-the-simple-winter-tips-for-a-safe-trip/";
              }}
            />
            <Route
              path="/want-petrol-for-your-bike-wear-a-helmet-first"
              component={() => {
                window.location = "https://autodeals.pk/blog/want-petrol-for-your-bike-wear-a-helmet-first/";
              }}
            />
            <Route
              path="/islamabad-traffic-police-officers-in-action-against-underage-drivers"
              component={() => {
                window.location = "https://autodeals.pk/blog/islamabad-traffic-police-officers-in-action-against-underage-drivers/";
              }}
            />
            <Route
              path="/the-toyota-vitz-hybrid"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-toyota-vitz-hybrid/";
              }}
            />
            <Route
              path="/automakers-likely-to-hike-the-prices-as-the-dollar-goes-up-to-rs-144"
              component={() => {
                window.location = "https://autodeals.pk/blog/automakers-likely-to-hike-the-prices-as-the-dollar-goes-up-to-rs-144/";
              }}
            />
            <Route
              path="/lahore-high-court-suspends-all-the-service-stations-with-no-water-recycling-system"
              component={() => {
                window.location = "https://autodeals.pk/blog/lahore-high-court-suspends-all-the-service-stations-with-no-water-recycling-system/";
              }}
            />
            <Route
              path="/government-is-committed-to-the-uplift-automobile-sector"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-is-committed-to-the-uplift-automobile-sector/";
              }}
            />
            <Route
              path="/the-government-slashed-petrol-price-by-pkr-2"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-government-slashed-petrol-price-by-pkr-2/";
              }}
            />
            <Route
              path="/rules-for-the-pillion-passenger"
              component={() => {
                window.location = "https://autodeals.pk/blog/rules-for-the-pillion-passenger/";
              }}
            />
            <Route
              path="/the-new-bmw-z4"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-new-bmw-z4/";
              }}
            />
            <Route
              path="/car-import-process-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-import-process-in-pakistan/";
              }}
            />
            <Route
              path="/buy-and-sell-cars-online-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/buy-and-sell-cars-online-in-pakistan/";
              }}
            />
            <Route
              path="/car-registration-process-in-lahore"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-registration-process-in-lahore/";
              }}
            />
            <Route
              path="/what-is-the-auction-sheet-and-how-to-verify-auction-sheet"
              component={() => {
                window.location = "https://autodeals.pk/blog/what-is-the-auction-sheet-and-how-to-verify-auction-sheet/";
              }}
            />
            <Route
              path="/beware-of-over-speeding"
              component={() => {
                window.location = "https://autodeals.pk/blog/beware-of-over-speeding/";
              }}
            />
            <Route
              path="/suzuki-alto-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-alto-2019/";
              }}
            />
            <Route
              path="/7-ways-to-protect-your-car-paint"
              component={() => {
                window.location = "https://autodeals.pk/blog/7-ways-to-protect-your-car-paint/";
              }}
            />
            <Route
              path="/government-launches-national-road-safety-strategy"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-launches-national-road-safety-strategy/";
              }}
            />
            <Route
              path="/volkswagen-comes-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/volkswagen-comes-in-pakistan/";
              }}
            />
            <Route
              path="/say-hello-to-new-toyota-corolla-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/say-hello-to-new-toyota-corolla-2020/";
              }}
            />
            <Route
              path="/car-maintenance-guide"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-maintenance-guide/";
              }}
            />
            <Route
              path="/5-things-you-can-save-yourself-from-car-trouble"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-things-you-can-save-yourself-from-car-trouble/";
              }}
            />
            <Route
              path="/safety-measures-for-drivers"
              component={() => {
                window.location = "https://autodeals.pk/blog/safety-measures-for-drivers/";
              }}
            />
            <Route
              path="/5-best-world-class-luxury-full-size-suv-2018"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-best-world-class-luxury-full-size-suv-2018/";
              }}
            />
            <Route
              path="/5-smart-automotive-gadgets-you-need-to-see"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-smart-automotive-gadgets-you-need-to-see/";
              }}
            />
            <Route
              path="/2019-bmw-8-series-convertible"
              component={() => {
                window.location = "https://autodeals.pk/blog/2019-bmw-8-series-convertible/";
              }}
            />
            <Route
              path="/travel-from-lahore-to-china-in-just-pkr-13000"
              component={() => {
                window.location = "https://autodeals.pk/blog/travel-from-lahore-to-china-in-just-pkr-13000/";
              }}
            />
            <Route
              path="/12-motorcycle-safety-tips-for-every-rider-should-know"
              component={() => {
                window.location = "https://autodeals.pk/blog/12-motorcycle-safety-tips-for-every-rider-should-know/";
              }}
            />
            <Route
              path="/say-hello-to-2019-bmw-x7-bmw-biggest-suv-with-a-giant-grille"
              component={() => {
                window.location = "https://autodeals.pk/blog/say-hello-to-2019-bmw-x7-bmw-biggest-suv-with-a-giant-grille/";
              }}
            />
            <Route
              path="/top-10-tips-to-keep-your-car-battery-run-longer"
              component={() => {
                window.location = "https://autodeals.pk/blog/top-10-tips-to-keep-your-car-battery-run-longer/";
              }}
            />
            <Route
              path="/pak-suzuki-profit-drops-by-91"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-profit-drops-by-91/";
              }}
            />
            <Route
              path="/few-simple-tips-to-improve-fuel-economy-of-your-car-2"
              component={() => {
                window.location = "https://autodeals.pk/blog/few-simple-tips-to-improve-fuel-economy-of-your-car-2/";
              }}
            />
            <Route
              path="/psca-launched-online-e-challan-portal"
              component={() => {
                window.location = "https://autodeals.pk/blog/psca-launched-online-e-challan-portal/";
              }}
            />
            <Route
              path="/lahore-traffic-police-has-decided-to-take-actions-bikers-who-dont-install-side-mirrors"
              component={() => {
                window.location = "https://autodeals.pk/blog/lahore-traffic-police-has-decided-to-take-actions-bikers-who-dont-install-side-mirrors/";
              }}
            />
            <Route
              path="/uber-and-careem-banned-online-ride-hailing-services-to-banned-across-sindh"
              component={() => {
                window.location = "https://autodeals.pk/blog/uber-and-careem-banned-online-ride-hailing-services-to-banned-across-sindh/";
              }}
            />
            <Route
              path="/punjab-govt-decides-to-end-subsidy-on-metro-bus-service"
              component={() => {
                window.location = "https://autodeals.pk/blog/punjab-govt-decides-to-end-subsidy-on-metro-bus-service/";
              }}
            />
            <Route
              path="/government-to-make-bike-track-in-karachi"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-to-make-bike-track-in-karachi/";
              }}
            />
            <Route
              path="/blackfly-flying-car-to-hit-the-market-in-2019-for-the-price-of-an-suv"
              component={() => {
                window.location = "https://autodeals.pk/blog/blackfly-flying-car-to-hit-the-market-in-2019-for-the-price-of-an-suv/";
              }}
            />
            <Route
              path="/6-budget-sports-cars-that-you-can-buy-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/6-budget-sports-cars-that-you-can-buy-in-pakistan/";
              }}
            />
            <Route
              path="/suzuki-alto-2019-to-be-launched-in-2019-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-alto-2019-to-be-launched-in-2019-in-pakistan/";
              }}
            />
            <Route
              path="/dubai-launches-its-self-driving-taxi-service"
              component={() => {
                window.location = "https://autodeals.pk/blog/dubai-launches-its-self-driving-taxi-service/";
              }}
            />
            <Route
              path="/mercedes-plans-advanced-self-driving-tech-for-s-class"
              component={() => {
                window.location = "https://autodeals.pk/blog/mercedes-plans-advanced-self-driving-tech-for-s-class/";
              }}
            />
            <Route
              path="/car-sales-drop-by-30-in-3-months-due-to-purchase-ban-on-non-filers"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-sales-drop-by-30-in-3-months-due-to-purchase-ban-on-non-filers/";
              }}
            />
            <Route
              path="/smart-cards-are-replacing-vehicle-registration-book-from-this-month-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/smart-cards-are-replacing-vehicle-registration-book-from-this-month-in-pakistan/";
              }}
            />
            <Route
              path="/dollar-reaches-record-high-of-134-rupees-highest-rate-in-pakistan-history"
              component={() => {
                window.location = "https://autodeals.pk/blog/dollar-reaches-record-high-of-134-rupees-highest-rate-in-pakistan-history/";
              }}
            />
            <Route
              path="/the-all-new-bmw-3-seriese-2019"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-all-new-bmw-3-seriese-2019/";
              }}
            />
            <Route
              path="/car-prices-likely-to-increase-as-dollar-reaches-138"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-prices-likely-to-increase-as-dollar-reaches-138/";
              }}
            />
            <Route
              path="/united-bravo-arrives-at-dealership-bookings-have-started"
              component={() => {
                window.location = "https://autodeals.pk/blog/united-bravo-arrives-at-dealership-bookings-have-started/";
              }}
            />
            <Route
              path="/blackfly-wants-to-be-the-flying-car-you-can-finally-buy-next-year-and-for-cheap"
              component={() => {
                window.location = "https://autodeals.pk/blog/blackfly-wants-to-be-the-flying-car-you-can-finally-buy-next-year-and-for-cheap/";
              }}
            />
            <Route
              path="/non-filers-allowed-to-buy-bikes-and-rickshaws-government-amends-finance-bill"
              component={() => {
                window.location = "https://autodeals.pk/blog/non-filers-allowed-to-buy-bikes-and-rickshaws-government-amends-finance-bill/";
              }}
            />
            <Route
              path="/top-10-roadside-emergency-must-haves"
              component={() => {
                window.location = "https://autodeals.pk/blog/top-10-roadside-emergency-must-haves/";
              }}
            />
            <Route
              path="/driving-tips-do-or-not-to-do-while-driving"
              component={() => {
                window.location = "https://autodeals.pk/blog/driving-tips-do-or-not-to-do-while-driving/";
              }}
            />
            <Route
              path="/10-tips-to-help-you-drive-safely-in-the-nigh"
              component={() => {
                window.location = "https://autodeals.pk/blog/10-tips-to-help-you-drive-safely-in-the-nigh/";
              }}
            />
            <Route
              path="/5-things-you-should-never-do-with-your-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-things-you-should-never-do-with-your-car/";
              }}
            />
            <Route
              path="/5-brake-problems-you-should-never-ignore"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-brake-problems-you-should-never-ignore/";
              }}
            />
            <Route
              path="/4-reasons-why-your-car-shakes-when-ac-is-turned-on"
              component={() => {
                window.location = "https://autodeals.pk/blog/4-reasons-why-your-car-shakes-when-ac-is-turned-on/";
              }}
            />
            <Route
              path="/pedestrians-not-following-rules-will-charged-with-fine"
              component={() => {
                window.location = "https://autodeals.pk/blog/pedestrians-not-following-rules-will-charged-with-fine/";
              }}
            />
            <Route
              path="/government-to-increase-cng-rates-by-pkr-15"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-to-increase-cng-rates-by-pkr-15/";
              }}
            />
            <Route
              path="/court-order-showroom-owners-to-remove-cars-from-jail-road-lhr"
              component={() => {
                window.location = "https://autodeals.pk/blog/court-order-showroom-owners-to-remove-cars-from-jail-road-lhr/";
              }}
            />
            <Route
              path="/5-tips-to-stop-your-wiper-blades-from-squeaking"
              component={() => {
                window.location = "https://autodeals.pk/blog/5-tips-to-stop-your-wiper-blades-from-squeaking/";
              }}
            />
            <Route
              path="/used-cars-under-5-lakh-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/used-cars-under-5-lakh-in-pakistan/";
              }}
            />
            <Route
              path="/toyota-used-cars-under-10-lakh-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-used-cars-under-10-lakh-in-pakistan/";
              }}
            />
            <Route
              path="/verify-auction-sheet-before-buy-japanese-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/verify-auction-sheet-before-buy-japanese-cars/";
              }}
            />
            <Route
              path="/petrol-price-increase-to-pkr-6-30"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-price-increase-to-pkr-6-30/";
              }}
            />
            <Route
              path="/federal-excise-duty-increased-to-20-on-1800cc-and-above-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/federal-excise-duty-increased-to-20-on-1800cc-and-above-cars/";
              }}
            />
            <Route
              path="/e-challan-system-and-fines-for-traffic-violators-e-challan-system-comes-into-effect-in-lahore"
              component={() => {
                window.location = "https://autodeals.pk/blog/e-challan-system-and-fines-for-traffic-violators-e-challan-system-comes-into-effect-in-lahore/";
              }}
            />
            <Route
              path="/bike-riders-without-a-helmet-do-not-travel-in-lahore"
              component={() => {
                window.location = "https://autodeals.pk/blog/bike-riders-without-a-helmet-do-not-travel-in-lahore/";
              }}
            />
            <Route
              path="/four-reasons-you-should-never-run-low-on-fuel"
              component={() => {
                window.location = "https://autodeals.pk/blog/four-reasons-you-should-never-run-low-on-fuel/";
              }}
            />
            <Route
              path="/united-autos-re-confirms-the-united-bravo-launch-date"
              component={() => {
                window.location = "https://autodeals.pk/blog/united-autos-re-confirms-the-united-bravo-launch-date/";
              }}
            />
            <Route
              path="/suzuki-releases-details-on-the-super-cute-2019-jimny-4x4"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-releases-details-on-the-super-cute-2019-jimny-4x4/";
              }}
            />
            <Route
              path="/nissan-and-italdesign-might-sell-the-gt-r50-for-1-million-a-pop"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissan-and-italdesign-might-sell-the-gt-r50-for-1-million-a-pop/";
              }}
            />
            <Route
              path="/how-hybrid-cars-works"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-hybrid-cars-works/";
              }}
            />
            <Route
              path="/ryno-motorcycle-the-one-wheeler-that-offers-better-city-driving"
              component={() => {
                window.location = "https://autodeals.pk/blog/ryno-motorcycle-the-one-wheeler-that-offers-better-city-driving/";
              }}
            />
            <Route
              path="/imported-cars-help-raising-customs-duty-revenue-by-24-to-rs-97-094-billion"
              component={() => {
                window.location = "https://autodeals.pk/blog/imported-cars-help-raising-customs-duty-revenue-by-24-to-rs-97-094-billion/";
              }}
            />
            <Route
              path="/government-plans-to-impose-wealth-tax-on-vehicles"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-plans-to-impose-wealth-tax-on-vehicles/";
              }}
            />
            <Route
              path="/mazda-cx-9-2019-brings-more-tech-and-enhancements"
              component={() => {
                window.location = "https://autodeals.pk/blog/mazda-cx-9-2019-brings-more-tech-and-enhancements/";
              }}
            />
            <Route
              path="/pakistani-engineer-turns-farm-waste-to-fuel"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistani-engineer-turns-farm-waste-to-fuel/";
              }}
            />
            <Route
              path="/why-people-buy-imported-660cc-cars-over-locally-assembled-vehicle"
              component={() => {
                window.location = "https://autodeals.pk/blog/why-people-buy-imported-660cc-cars-over-locally-assembled-vehicle/";
              }}
            />
            <Route
              path="/islamabad-anti-car-lifting-cell-recovers-stolen-cars-and-bikes"
              component={() => {
                window.location = "https://autodeals.pk/blog/islamabad-anti-car-lifting-cell-recovers-stolen-cars-and-bikes/";
              }}
            />
            <Route
              path="/honda-civic-2019-gets-revised-styling-and-some-new-tech"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-civic-2019-gets-revised-styling-and-some-new-tech/";
              }}
            />
            <Route
              path="/five-factors-that-affect-the-fuel-economy-of-your-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/five-factors-that-affect-the-fuel-economy-of-your-car/";
              }}
            />
            <Route
              path="/how-to-protect-cars-interior"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-protect-cars-interior/";
              }}
            />
            <Route
              path="/nha-increases-toll-tax-rates-on-national-highways-and-motorways"
              component={() => {
                window.location = "https://autodeals.pk/blog/nha-increases-toll-tax-rates-on-national-highways-and-motorways/";
              }}
            />
            <Route
              path="/porsche-911-2019-images-leaked-online"
              component={() => {
                window.location = "https://autodeals.pk/blog/porsche-911-2019-images-leaked-online/";
              }}
            />
            <Route
              path="/sales-tax-on-petroleum-products-reduced"
              component={() => {
                window.location = "https://autodeals.pk/blog/sales-tax-on-petroleum-products-reduced/";
              }}
            />
            <Route
              path="/atlas-honda-increases-bike-prices-by-3"
              component={() => {
                window.location = "https://autodeals.pk/blog/atlas-honda-increases-bike-prices-by-3/";
              }}
            />
            <Route
              path="/japan-launches-mosque-on-wheels"
              component={() => {
                window.location = "https://autodeals.pk/blog/japan-launches-mosque-on-wheels/";
              }}
            />
            <Route
              path="/toyota-imc-launches-corolla-xli-with-4-speed-auto-transmission"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-imc-launches-corolla-xli-with-4-speed-auto-transmission/";
              }}
            />
            <Route
              path="/volkswagen-makes-van-life-bigger-with-production-california-xxl-camper-van"
              component={() => {
                window.location = "https://autodeals.pk/blog/volkswagen-makes-van-life-bigger-with-production-california-xxl-camper-van/";
              }}
            />
            <Route
              path="/review-2018-hyundai-elantra-gt-sport-brings-europe-to-america"
              component={() => {
                window.location = "https://autodeals.pk/blog/review-2018-hyundai-elantra-gt-sport-brings-europe-to-america/";
              }}
            />
            <Route
              path="/new-ariel-atom-4-prepares-to-slaughter-cars-10-times-its-price"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-ariel-atom-4-prepares-to-slaughter-cars-10-times-its-price/";
              }}
            />
            <Route
              path="/dodges-srt-hellcat-redeye-launches-with-a-scorching-797-horsepower"
              component={() => {
                window.location = "https://autodeals.pk/blog/dodges-srt-hellcat-redeye-launches-with-a-scorching-797-horsepower/";
              }}
            />
            <Route
              path="/beyond-belief-meet-the-5221-horsepower-battery-powered-bulgarian-batmobile"
              component={() => {
                window.location = "https://autodeals.pk/blog/beyond-belief-meet-the-5221-horsepower-battery-powered-bulgarian-batmobile/";
              }}
            />
            <Route
              path="/nissan-titan-xd-becomes-a-mobile-base-camp-for-surfers"
              component={() => {
                window.location = "https://autodeals.pk/blog/nissan-titan-xd-becomes-a-mobile-base-camp-for-surfers/";
              }}
            />
            <Route
              path="/teslas-self-parking-summon-feature-comes-to-the-model-3"
              component={() => {
                window.location = "https://autodeals.pk/blog/teslas-self-parking-summon-feature-comes-to-the-model-3/";
              }}
            />
            <Route
              path="/california-chosen-to-host-daimler-bosch-self-driving-pilot-project"
              component={() => {
                window.location = "https://autodeals.pk/blog/california-chosen-to-host-daimler-bosch-self-driving-pilot-project/";
              }}
            />
            <Route
              path="/first-production-solo-three-wheeler-ev-rolls-off-the-assembly-line"
              component={() => {
                window.location = "https://autodeals.pk/blog/first-production-solo-three-wheeler-ev-rolls-off-the-assembly-line/";
              }}
            />
            <Route
              path="/where-did-united-bravo-go"
              component={() => {
                window.location = "https://autodeals.pk/blog/where-did-united-bravo-go/";
              }}
            />
            <Route
              path="/tesla-goes-big-in-china/tesla-goes-big-in-china"
              component={() => {
                window.location = "https://autodeals.pk/blog/tesla-goes-big-in-china/";
              }}
            />
            <Route
              path="/suzuki-pakistan-discontinuing-mehran-vx"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-pakistan-discontinuing-mehran-vx/";
              }}
            />
            <Route
              path="/suzuki-mehran-vx-will-not-be-missed"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-mehran-vx-will-not-be-missed/";
              }}
            />
            <Route
              path="/shell-launches-new-performance-fuel-v-power-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/shell-launches-new-performance-fuel-v-power-in-pakistan/";
              }}
            />
            <Route
              path="/government-awards-greenfield-status-to-two-new-automakers"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-awards-greenfield-status-to-two-new-automakers/";
              }}
            />
            <Route
              path="/honda-wins-best-car-and-favorite-brand-award-including-other-survey"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-wins-best-car-and-favorite-brand-award-including-other-survey/";
              }}
            />
            <Route
              path="/government-is-gonna-reduce-car-registration-charges-in-new-budget"
              component={() => {
                window.location = "https://autodeals.pk/blog/government-is-gonna-reduce-car-registration-charges-in-new-budget/";
              }}
            />
            <Route
              path="/united-bravo-car-price-increased-check-details"
              component={() => {
                window.location = "https://autodeals.pk/blog/united-bravo-car-price-increased-check-details/";
              }}
            />
            <Route
              path="/why-hyundai-elantra-and-tuscon-will-be-popular"
              component={() => {
                window.location = "https://autodeals.pk/blog/why-hyundai-elantra-and-tuscon-will-be-popular/";
              }}
            />
            <Route
              path="/camera-readable-number-plates-launches-in-sindh"
              component={() => {
                window.location = "https://autodeals.pk/blog/camera-readable-number-plates-launches-in-sindh/";
              }}
            />
            <Route
              path="/toyota-pakistan-brings-exciting-offer-for-its-customers"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-pakistan-brings-exciting-offer-for-its-customers/";
              }}
            />
            <Route
              path="/kia-cerato-to-be-launch-in-pakistan-check-details"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-cerato-to-be-launch-in-pakistan-check-details/";
              }}
            />
            <Route
              path="/did-you-know-hi-octane-price-in-pakistan-falling-down"
              component={() => {
                window.location = "https://autodeals.pk/blog/did-you-know-hi-octane-price-in-pakistan-falling-down/";
              }}
            />
            <Route
              path="/euro-5-petrol-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/euro-5-petrol-in-pakistan/";
              }}
            />
            <Route
              path="/how-to-improve-driving-skills"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-improve-driving-skills/";
              }}
            />
            <Route
              path="/finally-official-price-of-hyundai-tucson-expose"
              component={() => {
                window.location = "https://autodeals.pk/blog/finally-official-price-of-hyundai-tucson-expose/";
              }}
            />
            <Route
              path="/hyundai-tucson-price-increased-check-details"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-tucson-price-increased-check-details/";
              }}
            />
            <Route
              path="/honda-civic-city-gets-new-model-in-2021"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-civic-city-gets-new-model-in-2021/";
              }}
            />
            <Route
              path="/universal-number-plates-launches-by-punjab-govt"
              component={() => {
                window.location = "https://autodeals.pk/blog/universal-number-plates-launches-by-punjab-govt/";
              }}
            />
            <Route
              path="/massive-hike-in-isuzu-d-max-price-have-a-look"
              component={() => {
                window.location = "https://autodeals.pk/blog/massive-hike-in-isuzu-d-max-price-have-a-look/";
              }}
            />
            <Route
              path="/pak-suzuki-brings-cheap-used-car-financing-program"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-brings-cheap-used-car-financing-program/";
              }}
            />
            <Route
              path="/imc-offer-toyota-yaris-test-driving-from-home"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-offer-toyota-yaris-test-driving-from-home/";
              }}
            />
            <Route
              path="/top-10-car-covers-million-miles-on-single-engine"
              component={() => {
                window.location = "https://autodeals.pk/blog/top-10-car-covers-million-miles-on-single-engine/";
              }}
            />
            <Route
              path="/new-vehicle-standards-to-be-introduce-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-vehicle-standards-to-be-introduce-in-pakistan/";
              }}
            />
            <Route
              path="/is-suzuki-swift-going-to-be-updated-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/is-suzuki-swift-going-to-be-updated-in-pakistan/";
              }}
            />
            <Route
              path="/7-vehicle-safety-standards-for-local-produced-cars-conclude"
              component={() => {
                window.location = "https://autodeals.pk/blog/7-vehicle-safety-standards-for-local-produced-cars-conclude/";
              }}
            />
            <Route
              path="/toyota-introduced-corolla-altis-1-6l-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-introduced-corolla-altis-1-6l-in-pakistan/";
              }}
            />
            <Route
              path="/kia-picanto-hatchback-price-increased"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-picanto-hatchback-price-increased/";
              }}
            />
            <Route
              path="/faw-v2-price-in-pakistan-increased"
              component={() => {
                window.location = "https://autodeals.pk/blog/faw-v2-price-in-pakistan-increased/";
              }}
            />
            <Route
              path="/imc-toyota-introduced-new-fortuner-trd"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-toyota-introduced-new-fortuner-trd/";
              }}
            />
            <Route
              path="/massive-hike-in-pak-suzuki-car-prices-up-to-rs-1million"
              component={() => {
                window.location = "https://autodeals.pk/blog/massive-hike-in-pak-suzuki-car-prices-up-to-rs-1million/";
              }}
            />
            <Route
              path="/baic-launches-d20-hatchback-with-cheapest-price-tag"
              component={() => {
                window.location = "https://autodeals.pk/blog/baic-launches-d20-hatchback-with-cheapest-price-tag/";
              }}
            />
            <Route
              path="/toyota-corolla-cross-soon-to-launch-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-corolla-cross-soon-to-launch-in-pakistan/";
              }}
            />
            <Route
              path="/honda-city-7th-generation-soon-to-launch-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-city-7th-generation-soon-to-launch-in-pakistan/";
              }}
            />
            <Route
              path="/suzuki-alto-9th-gen-is-all-set-to-reveal-in-december-2020"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-alto-9th-gen-is-all-set-to-reveal-in-december-2020/";
              }}
            />
            <Route
              path="/test-how-pakistan-local-cars-can-grow-up-have-a-look"
              component={() => {
                window.location = "https://autodeals.pk/blog/test-how-pakistan-local-cars-can-grow-up-have-a-look/";
              }}
            />
            <Route
              path="/united-motors-announced-decrease-in-bravo-800cc-car-price"
              component={() => {
                window.location = "https://autodeals.pk/blog/united-motors-announced-decrease-in-bravo-800cc-car-price/";
              }}
            />
            <Route
              path="/imc-revealed-launching-date-and-expected-price-for-corolla-cross"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-revealed-launching-date-and-expected-price-for-corolla-cross/";
              }}
            />
            <Route
              path="/toyota-corolla-to-get-new-changings-in-2021-with-increased-price"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-corolla-to-get-new-changings-in-2021-with-increased-price/";
              }}
            />
            <Route
              path="/imc-offer-some-new-features-in-its-toyota-yaris"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-offer-some-new-features-in-its-toyota-yaris/";
              }}
            />
            <Route
              path="/imc-officially-revealed-corolla-cross-2020-features"
              component={() => {
                window.location = "https://autodeals.pk/blog/imc-officially-revealed-corolla-cross-2020-features/";
              }}
            />
            <Route
              path="/toyota-discontinued-its-corolla-altis-grande-1-8l-manual"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-discontinued-its-corolla-altis-grande-1-8l-manual/";
              }}
            />
            <Route
              path="/glory-580-pro-is-near-to-launching-price-features-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/glory-580-pro-is-near-to-launching-price-features-revealed/";
              }}
            />
            <Route
              path="/volkswagen-to-start-producing-vehicles-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/volkswagen-to-start-producing-vehicles-in-pakistan/";
              }}
            />
            <Route
              path="/alsvin-sedan-near-to-launching-in-pakistan-price-specifications"
              component={() => {
                window.location = "https://autodeals.pk/blog/alsvin-sedan-near-to-launching-in-pakistan-price-specifications/";
              }}
            />
            <Route
              path="/how-to-book-changan-alsvin-sedan"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-book-changan-alsvin-sedan/";
              }}
            />
            <Route
              path="/hyundai-elantra-soon-to-enter-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-elantra-soon-to-enter-in-pakistan/";
              }}
            />
            <Route
              path="/dfsk-to-present-first-7-seater-suv-in-pakistan-soon"
              component={() => {
                window.location = "https://autodeals.pk/blog/dfsk-to-present-first-7-seater-suv-in-pakistan-soon/";
              }}
            />
            <Route
              path="/dfsk-launched-glory-580-pro-officially"
              component={() => {
                window.location = "https://autodeals.pk/blog/dfsk-launched-glory-580-pro-officially/";
              }}
            />
            <Route
              path="/expected-reduced-in-cars-prices-of-1000cc-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/expected-reduced-in-cars-prices-of-1000cc-in-pakistan/";
              }}
            />
            <Route
              path="/what-is-the-expected-date-of-honda-city-new-generation-launching"
              component={() => {
                window.location = "https://autodeals.pk/blog/what-is-the-expected-date-of-honda-city-new-generation-launching/";
              }}
            />
            <Route
              path="/honda-denied-price-reduction-of-its-cars"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-denied-price-reduction-of-its-cars/";
              }}
            />
            <Route
              path="/how-to-take-care-car-tyres-in-summer"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-take-care-car-tyres-in-summer/";
              }}
            />
            <Route
              path="/auto-policy-for-next-five-years-announced-with-reduction-in-tax"
              component={() => {
                window.location = "https://autodeals.pk/blog/auto-policy-for-next-five-years-announced-with-reduction-in-tax/";
              }}
            />
            <Route
              path="/toyota-reduce-car-prices-after-reduction-in-taxes"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-reduce-car-prices-after-reduction-in-taxes/";
              }}
            />
            <Route
              path="/blog/honda-reduced-its-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-reduced-its-car-prices/";
              }}
            />
            <Route
              path="/after-honda-and-toyota-suzuki-decrease-its-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/after-honda-and-toyota-suzuki-decrease-its-car-prices/";
              }}
            />
            <Route
              path="/kia-sportage-picanto-sorento-glory-580-prices-cut-down"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-sportage-picanto-sorento-glory-580-prices-cut-down/";
              }}
            />
            <Route
              path="/wait-for-new-honda-city-is-over-for-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/wait-for-new-honda-city-is-over-for-pakistan/";
              }}
            />
            <Route
              path="/honda-disclose-official-price-of-new-honda-city-features"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-disclose-official-price-of-new-honda-city-features/";
              }}
            />
            <Route
              path="/pak-suzuki-hits-the-surprising-record-of-sale-last-month"
              component={() => {
                window.location = "https://autodeals.pk/blog/pak-suzuki-hits-the-surprising-record-of-sale-last-month/";
              }}
            />
            <Route
              path="/mg-hs-exclusive-booking-is-reopened-confirmed-by-company"
              component={() => {
                window.location = "https://autodeals.pk/blog/mg-hs-exclusive-booking-is-reopened-confirmed-by-company/";
              }}
            />
            <Route
              path="/honda-suzuki-and-yamaha-increased-motorbikes-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/honda-suzuki-and-yamaha-increased-motorbikes-prices/";
              }}
            />
            <Route
              path="/changan-uni-k-suv-testing-unit-seen-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/changan-uni-k-suv-testing-unit-seen-in-pakistan/";
              }}
            />
            <Route
              path="/car-insurance-and-types-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-insurance-and-types-in-pakistan/";
              }}
            />
            <Route
              path="/atlas-honda-makes-101-changes-in-cd-70"
              component={() => {
                window.location = "https://autodeals.pk/blog/atlas-honda-makes-101-changes-in-cd-70/";
              }}
            />
            <Route
              path="/changan-car-prices-gets-massive-increase"
              component={() => {
                window.location = "https://autodeals.pk/blog/changan-car-prices-gets-massive-increase/";
              }}
            />
            <Route
              path="/mg-motors-unveiled-locally-assembled-mg-hs"
              component={() => {
                window.location = "https://autodeals.pk/blog/mg-motors-unveiled-locally-assembled-mg-hs/";
              }}
            />
            <Route
              path="/pakistans-well-valued-new-sedans"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistans-well-valued-new-sedans/";
              }}
            />
            <Route
              path="/2021-best-used-cars-under-10-lakhs"
              component={() => {
                window.location = "https://autodeals.pk/blog/2021-best-used-cars-under-10-lakhs/";
              }}
            />
            <Route
              path="/changan-master-motors-hold-price-increase-on-govt-request"
              component={() => {
                window.location = "https://autodeals.pk/blog/changan-master-motors-hold-price-increase-on-govt-request/";
              }}
            />
            <Route
              path="/baic-bj40-plus-booking-to-start-in-pakistan-very-soon"
              component={() => {
                window.location = "https://autodeals.pk/blog/baic-bj40-plus-booking-to-start-in-pakistan-very-soon/";
              }}
            />
            <Route
              path="/how-to-clean-a-car-interior"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-clean-a-car-interior/";
              }}
            />
            <Route
              path="/upcoming-crossover-suvs-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/upcoming-crossover-suvs-in-pakistan/";
              }}
            />
            <Route
              path="/peugeot-2008-spotted-in-karachi-is-it-cue-of-launching"
              component={() => {
                window.location = "https://autodeals.pk/blog/peugeot-2008-spotted-in-karachi-is-it-cue-of-launching/";
              }}
            />
            <Route
              path="/suzuki-swift-discontinued-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-swift-discontinued-in-pakistan/";
              }}
            />
            <Route
              path="/govt-take-action-against-car-prices-increase"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-take-action-against-car-prices-increase/";
              }}
            />
            <Route
              path="/toyota-to-locally-assemble-hybrid-cars-very-soon"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-to-locally-assemble-hybrid-cars-very-soon/";
              }}
            />
            <Route
              path="/this-time-haval-jolion-gets-huge-price-hike"
              component={() => {
                window.location = "https://autodeals.pk/blog/this-time-haval-jolion-gets-huge-price-hike/";
              }}
            />
            <Route
              path="/mg-hs-plug-in-hybrid-booking-has-start"
              component={() => {
                window.location = "https://autodeals.pk/blog/mg-hs-plug-in-hybrid-booking-has-start/";
              }}
            />
            <Route
              path="/locally-assembled-kia-stonic-is-on-the-way-of-launching"
              component={() => {
                window.location = "https://autodeals.pk/blog/locally-assembled-kia-stonic-is-on-the-way-of-launching/";
              }}
            />
            <Route
              path="/over-1500cc-cars-prices-be-increases-soon-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/over-1500cc-cars-prices-be-increases-soon-in-pakistan/";
              }}
            />
            <Route
              path="/changan-oshan-x7-suv-coming-to-the-market"
              component={() => {
                window.location = "https://autodeals.pk/blog/changan-oshan-x7-suv-coming-to-the-market/";
              }}
            />
            <Route
              path="/mercedes-benz-made-in-india"
              component={() => {
                window.location = "https://autodeals.pk/blog/mercedes-benz-made-in-india/";
              }}
            />
            <Route
              path="/the-new-gs3-by-gac-group-launch-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-new-gs3-by-gac-group-launch-in-pakistan/";
              }}
            />
            <Route
              path="/peugeot-3008-vs-proton-x70"
              component={() => {
                window.location = "https://autodeals.pk/blog/peugeot-3008-vs-proton-x70/";
              }}
            />
            <Route
              path="/petroleum-prices-in-pakistan-increasing-again"
              component={() => {
                window.location = "https://autodeals.pk/blog/petroleum-prices-in-pakistan-increasing-again/";
              }}
            />
            <Route
              path="/hyundai-tucson-vs-peugeot-3008"
              component={() => {
                window.location = "https://autodeals.pk/blog/hyundai-tucson-vs-peugeot-3008/";
              }}
            />
            <Route
              path="/peugeot-2008-beast-is-coming-to-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/peugeot-2008-beast-is-coming-to-pakistan/";
              }}
            />
            <Route
              path="/new-car-financing-policy-in-pakistan-better-or-worst-an-overview"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-car-financing-policy-in-pakistan-better-or-worst-an-overview/";
              }}
            />
            <Route
              path="/imported-vehicles-in-pakistan-highest-number-touched-in-2021"
              component={() => {
                window.location = "https://autodeals.pk/blog/imported-vehicles-in-pakistan-highest-number-touched-in-2021/";
              }}
            />
            <Route
              path="/new-55-changes-in-honda-cg125-2022"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-55-changes-in-honda-cg125-2022/";
              }}
            />
            <Route
              path="/introducing-new-lexus-lx600-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/introducing-new-lexus-lx600-in-pakistan/";
              }}
            />
            <Route
              path="/moving-auto-show-room-a-new-concept-by-honda-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/moving-auto-show-room-a-new-concept-by-honda-pakistan/";
              }}
            />
            <Route
              path="/fares-of-buses-are-directly-proportional-to-petroleum-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/fares-of-buses-are-directly-proportional-to-petroleum-prices/";
              }}
            />
            <Route
              path="/11th-generation-honda-civic-2022-introduction-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/11th-generation-honda-civic-2022-introduction-in-pakistan/";
              }}
            />
            <Route
              path="/choosing-the-best-engine-oil-for-your-car"
              component={() => {
                window.location = "https://autodeals.pk/blog/choosing-the-best-engine-oil-for-your-car/";
              }}
            />
            <Route
              path="/new-auto-manufacturing-policy-is-expected-to-be-implemented"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-auto-manufacturing-policy-is-expected-to-be-implemented/";
              }}
            />
            <Route
              path="/govt-is-expected-to-give-subsidy-on-petrol-price-for-motorcyclists"
              component={() => {
                window.location = "https://autodeals.pk/blog/govt-is-expected-to-give-subsidy-on-petrol-price-for-motorcyclists/";
              }}
            />
            <Route
              path="/auto-manufacturers-are-planning-to-increase-car-prices-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/auto-manufacturers-are-planning-to-increase-car-prices-in-pakistan/";
              }}
            />
            <Route
              path="/peugeot-revealed-dealerships-in-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/peugeot-revealed-dealerships-in-pakistan/";
              }}
            />
            <Route
              path="/when-new-auto-financing-policy-will-be-applicable"
              component={() => {
                window.location = "https://autodeals.pk/blog/when-new-auto-financing-policy-will-be-applicable/";
              }}
            />
            <Route
              path="/toyota-top-valuable-brand-in-the-interbrand-list-2021"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-top-valuable-brand-in-the-interbrand-list-2021/";
              }}
            />
            <Route
              path="/classic-car-show-2021-by-heritage-club-of-motoring-pakistan"
              component={() => {
                window.location = "https://autodeals.pk/blog/classic-car-show-2021-by-heritage-club-of-motoring-pakistan/";
              }}
            />
            <Route
              path="/new-cars-in-pakistan-recently-launched-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/new-cars-in-pakistan-recently-launched-prices/";
              }}
            />
            <Route
              path="/petrol-prices-in-pakistan-expected-to-rise-again"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-prices-in-pakistan-expected-to-rise-again/";
              }}
            />
            <Route
              path="/the-new-range-rover-2022"
              component={() => {
                window.location = "https://autodeals.pk/blog/the-new-range-rover-2022/";
              }}
            />
            <Route
              path="/proton-x70-import-will-start-in-december-2021"
              component={() => {
                window.location = "https://autodeals.pk/blog/proton-x70-import-will-start-in-december-2021/";
              }}
            />
            <Route
              path="/kia-stonic-vs-toyota-corolla-altis-x-a-birds-eye-view"
              component={() => {
                window.location = "https://autodeals.pk/blog/kia-stonic-vs-toyota-corolla-altis-x-a-birds-eye-view/";
              }}
            />
            <Route
              path="/petrol-price-in-pakistan-remains-unchanged"
              component={() => {
                window.location = "https://autodeals.pk/blog/petrol-price-in-pakistan-remains-unchanged/";
              }}
            />
            <Route
              path="/start-of-the-month-atlas-honda-price-in-pakistan-increased-once-again"
              component={() => {
                window.location = "https://autodeals.pk/blog/start-of-the-month-atlas-honda-price-in-pakistan-increased-once-again/";
              }}
            />
            <Route
              path="/pakistan-auto-sector-is-growing-demand-of-used-cars-in-pakistan-is-also-increasing"
              component={() => {
                window.location = "https://autodeals.pk/blog/pakistan-auto-sector-is-growing-demand-of-used-cars-in-pakistan-is-also-increasing/";
              }}
            />
            <Route
              path="/toyota-will-produce-its-first-electric-suv-toyota-bz4x"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-will-produce-its-first-electric-suv-toyota-bz4x/";
              }}
            />
            <Route
              path="/toyota-pakistan-broke-another-record-highest-number-of-sales"
              component={() => {
                window.location = "https://autodeals.pk/blog/toyota-pakistan-broke-another-record-highest-number-of-sales/";
              }}
            />
            <Route
              path="/no-more-fancy-number-plates-on-the-motorway"
              component={() => {
                window.location = "https://autodeals.pk/blog/no-more-fancy-number-plates-on-the-motorway/";
              }}
            />
            <Route
              path="/avoid-fraud-while-visiting-a-mechanic"
              component={() => {
                window.location = "https://autodeals.pk/blog/avoid-fraud-while-visiting-a-mechanic/";
              }}
            />
            <Route
              path="/2022-bmw-x3-the-ultimate-power-machine"
              component={() => {
                window.location = "https://autodeals.pk/blog/2022-bmw-x3-the-ultimate-power-machine/";
              }}
            />
            <Route
              path="/prices-of-toyota-in-pakistan-all-are-increased"
              component={() => {
                window.location = "https://autodeals.pk/blog/prices-of-toyota-in-pakistan-all-are-increased/";
              }}
            />
            <Route
              path="/all-you-need-to-know-about-kia-stonic-ex"
              component={() => {
                window.location = "https://autodeals.pk/blog/all-you-need-to-know-about-kia-stonic-ex/";
              }}
            />
            <Route
              path="/audi-e-tron-gt-has-reached-in-pakistan-limited-units-48"
              component={() => {
                window.location = "https://autodeals.pk/blog/audi-e-tron-gt-has-reached-in-pakistan-limited-units-48/";
              }}
            />
            <Route
              path="/pre-booking-for-bj40-plus-will-start-from-this-week"
              component={() => {
                window.location = "https://autodeals.pk/blog/pre-booking-for-bj40-plus-will-start-from-this-week/";
              }}
            />
            <Route
              path="/proton-saga-ckd-units-deliveries-are-starting"
              component={() => {
                window.location = "https://autodeals.pk/blog/proton-saga-ckd-units-deliveries-are-starting/";
              }}
            />
            <Route
              path="/by-following-the-others-proton-saga-prices-has-increased"
              component={() => {
                window.location = "https://autodeals.pk/blog/by-following-the-others-proton-saga-prices-has-increased/";
              }}
            />
            <Route
              path="/suzuki-alto-9th-generation-has-officially-revealed"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-alto-9th-generation-has-officially-revealed/";
              }}
            />
            <Route
              path="/yamaha-and-suzuki-increases-motorbikes-prices"
              component={() => {
                window.location = "https://autodeals.pk/blog/yamaha-and-suzuki-increases-motorbikes-prices/";
              }}
            />
            <Route
              path="/suzuki-mentioned-the-reason-of-delaying-alto-deliveries"
              component={() => {
                window.location = "https://autodeals.pk/blog/suzuki-mentioned-the-reason-of-delaying-alto-deliveries/";
              }}
            />
            <Route
              path="/mg-hs-exclusive-gets-massive-price-increased"
              component={() => {
                window.location = "https://autodeals.pk/blog/mg-hs-exclusive-gets-massive-price-increased/";
              }}
            />
            <Route
              path="/dealers-list"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/vehicle-verification-and-registration"
              component={() => {
                window.location = "https://autodeals.pk/blog/car-registration-process-in-lahore/";
              }}
            />
            <Route
              path="/e-challan-check"
              component={() => {
                window.location = "https://autodeals.pk/blog/how-to-pay-e-challan/";
              }}
            />
            <Route
              path="/car-finance"
              component={() => {
                window.location = "https://autodeals.pk/car-financing/car-loan-calculator";
              }}
            />
            <Route
              path="/add-car"
              component={() => {
                window.location = "https://autodeals.pk/post-ad/sell-car/post-your-ad";
              }}
            />
            <Route
              path="/how-to-buy"
              component={() => {
                window.location = "https://autodeals.pk/how-to-pay";
              }}
            />
            <Route
              path="/gm-chevrolet"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/mk_chevrolet_3";
              }}
            />
            <Route
              path="/auction-sheet-explained"
              component={() => {
                window.location = "https://autodeals.pk/blog/auction-sheet-explained-verify-auction-sheet-before-buy-japanese-cars/";
              }}
            />
            <Route
              path="/mazda-car-maker"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/mk_mazda_52";
              }}
            />
            <Route
              path="/mira-custom-2/x-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/mira-es/l-sa-iii-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/jw-forland-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/new-cars/pricelist";
              }}
            />
            <Route
              path="/listings"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/services"
              component={() => {
                window.location = "https://autodeals.pk/our-products";
              }}
            />
            <Route
              path="/inventory"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/86/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/move-conte"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_move";
              }}
            />
            <Route
              path="/new/cars"
              component={() => {
                window.location = "https://autodeals.pk/new-cars";
              }}
            />
            <Route
              path="/toyota-lexus"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_lexus";
              }}
            />
            <Route
              path="/page/140/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/car-loan-calculator"
              component={() => {
                window.location = "https://autodeals.pk/car-import/custom-duty-calculator";
              }}
            />
            <Route
              path="/move/custom-rs-hyper-sa-iii"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_move";
              }}
            />
            <Route
              path="/career"
              component={() => {
                window.location = "https://autodeals.pk/contact-us";
              }}
            />
            <Route
              path="/gm-faw"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/mk_faw_28";
              }}
            />
            <Route
              path="/dfsk-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/new-cars/pricelist/dfsk-25";
              }}
            />
            <Route
              path="/mira-es/x-sa-iii"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/page/37/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/import-now"
              component={() => {
                window.location = "https://autodeals.pk/car-import";
              }}
            />
            <Route
              path="/audi-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/new-cars/pricelist/audi-5";
              }}
            />
            <Route
              path="/mira-es/l-sa-iii"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/page/10/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/100/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/25/?taxonomy=stm_additional_features&term=alloy-wheels"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/194/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/car-inspection"
              component={() => {
                window.location = "https://autodeals.pk/auction-sheet-verification";
              }}
            />
            <Route
              path="/author/marvelmotors"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/mira-es"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/mira-gino-2/x"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/inventory/?make=mercedes-benz"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/mk_mercedes-benz_54";
              }}
            />
            <Route
              path="/move/l"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/md_move_107";
              }}
            />
            <Route
              path="/car-prices"
              component={() => {
                window.location = "https://autodeals.pk/new-cars/pricelist";
              }}
            />
            <Route
              path="/author/u3sbvb12"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/mira-gino/premium-x"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/max-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_max";
              }}
            />
            <Route
              path="/listings/used-suzuki-cultus-2016-15"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_cultus";
              }}
            />
            <Route
              path="/author/raqibmotors"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/mira-gino/l-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/page/97/?taxonomy=stm_additional_features&term=immobilizer-key"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/90/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/25/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/mira-es/g-sa-iii-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/82/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/56/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-toyota-hilux-revo-2017"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_revo";
              }}
            />
            <Route
              path="/bahamas/toyota-aqua-hyrid"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_aqua";
              }}
            />
            <Route
              path="/page/304/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/author/tayyabimran"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/listings/used-toyota-corolla-2009-17"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_corolla";
              }}
            />
            <Route
              path="/page/175/?taxonomy=stm_additional_features&term=lcd"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/158/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/car-prices/porsche-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/new-cars/pricelist/porsche-60";
              }}
            />
            <Route
              path="/touring"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_touring";
              }}
            />
            <Route
              path="/mira-gino"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/car-prices/daehan-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/new-cars/pricelist";
              }}
            />
            <Route
              path="/#used-cars"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/move-conte/l"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_move";
              }}
            />
            <Route
              path="/mira-es/l"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/page/7/?taxonomy=condition&term=used-cars"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/158/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/m"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-suzuki-mehran-2013-5"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mehran";
              }}
            />
            <Route
              path="/mclaren-automotive/"
              component={() => {
                window.location = "https://autodeals.pk/blog/";
              }}
            />
            <Route
              path="/page/131/?taxonomy=stm_additional_features&term=immobilizer-key"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/60/?taxonomy=stm_additional_features&term=fog-lights"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/mira-gino-2/x-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/mira-custom-2/x"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/author/anwar"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/leeza-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/fca-brands"
              component={() => {
                window.location = "https://autodeals.pk/new-cars";
              }}
            />
            <Route
              path="/geely-brands"
              component={() => {
                window.location = "https://autodeals.pk/new-cars";
              }}
            />
            <Route
              path="/page/32/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-suzuki-mehran-2005-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mehran";
              }}
            />
            <Route
              path="/inventory/?make=mazda"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mazda";
              }}
            />
            <Route
              path="/move"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_move";
              }}
            />
            <Route
              path="/author/m-k"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/author/carjunction"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/page/3/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/48/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/move-canbus/l"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_move";
              }}
            />
            <Route
              path="/page/18/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/103/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/mira/x-special-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/listings/used-suzuki-cultus-2009-7"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_cultus";
              }}
            />
            <Route
              path="/page/89/?taxonomy=stm_additional_features&term=immobilizer-key"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/183/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/inventory/page/8/?make=mercedes-benz&serie&max_price&ca_location&stm_lat&stm_lng"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/move-canbus/l-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_move";
              }}
            />
            <Route
              path="/author/muhammadfaheem"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/listings/page/310"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/13/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/author/ravimotorsokara"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/author/mahad2001"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/listings/used-toyota-land-cruiser-axg-2015"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_land-cruiser";
              }}
            />
            <Route
              path="/listings/used-toyota-vitz-2014-76"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_vitz";
              }}
            />
            <Route
              path="/?taxonomy=serie&term=a5"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/104/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/?taxonomy=serie&term=a5"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/137/?taxonomy=stm_additional_features&term=alloy-wheels"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/283/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/24/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/264/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-suzuki-cultus-2008-5"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_cultus";
              }}
            />
            <Route
              path="/author/kashmirmotors"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/author/waheedahmed"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/page/29/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/101/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/69/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-mercedes-benz-c180-2007-4"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mercedes";
              }}
            />
            <Route
              path="/post_id_4344_8ytbp"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/196/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/36/?taxonomy=stm_additional_features&term=cruise-control"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-mazda-scrum-wagon-2012"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mazda";
              }}
            />
            <Route
              path="/bahamas"
              component={() => {
                window.location = "https://autodealsjapan.com/";
              }}
            />
            <Route
              path="/page/6/?taxonomy=stm_additional_features&term=climate-control"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/author/taimoor3080"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/page/12/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-nissan-dayz-highway-star-2018"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_nissan";
              }}
            />
            <Route
              path="/listings/used-suzuki-bolan-2016"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_bolan";
              }}
            />
            <Route
              path="/wp-includes/"
              component={() => {
                window.location = "https://autodeals.pk/";
              }}
            />
            <Route
              path="/page/103/?taxonomy=stm_additional_features&term=immobilizer-key"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/299/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-toyota-land-cruiser-prado-tx-2005-4"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_prado";
              }}
            />
            <Route
              path="/page/164/?taxonomy=stm_additional_features&term=lcd"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/71/?taxonomy=stm_additional_features&term=immobilizer-key"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/77/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/69/?taxonomy=stm_additional_features&term=lcd"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/201/?taxonomy=stm_additional_features&term=audio-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-suzuki-bolan-2014-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/cars/daihatsu-cars/mira-cocoa-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mira";
              }}
            />
            <Route
              path="/page/14/?taxonomy=stm_additional_features&term=spoiler"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/240/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-toyota-vitz-2016-14"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_vitz";
              }}
            />
            <Route
              path="/auc"
              component={() => {
                window.location = "https://autodeals.pk/";
              }}
            />
            <Route
              path="/listings/used-toyota-corolla-2015-5"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_corolla";
              }}
            />
            <Route
              path="/listings/used-suzuki-wagon-r-2017-13/55492982_831024387231468_8935760569785384960_n"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_wagon-r";
              }}
            />
            <Route
              path="/page/12/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/author/latif"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/listings/used-suzuki-wagon-r-2013-6"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_wagon-r";
              }}
            />
            <Route
              path="/listings/used-honda-s660-2015-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_honda";
              }}
            />
            <Route
              path="/move/l-sa-iii-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_move";
              }}
            />
            <Route
              path="/page/4/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/240/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-toyota-vitz-2016-33"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_vitz";
              }}
            />
            <Route
              path="/listings/used-suzuki-cultus-2006-11"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_cultus";
              }}
            />
            <Route
              path="/page/282/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/19/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/128/?taxonomy=stm_additional_features&term=immobilizer-key"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/12/?taxonomy=make&term=toyota"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/37/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/17/?taxonomy=stm_additional_features&term=immobilizer-key"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/2/?taxonomy=fuel&term=lpg-autogas"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/?taxonomy=fuel&term=lpg-autogas"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-nissan-dayz-highway-star-2015-4"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_nissan";
              }}
            />
            <Route
              path="/author/faisalautodeals"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/listings/used-toyota-corolla-2014-61"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_corolla";
              }}
            />
            <Route
              path="/listings/used-honda-city-2016-16"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_honda";
              }}
            />
            <Route
              path="/page/150/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-toyota-corolla-2017-12"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_corolla";
              }}
            />
            <Route
              path="/page/187/?taxonomy=stm_additional_features&term=steering-adjustment"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/post_id_2320_bvan3"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-honda-fit-shuttle-hybrid-2015"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-honda-city-2018-33"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_honda";
              }}
            />
            <Route
              path="/page/48/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-suzuki-cultus-2015-10"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_cultus";
              }}
            />
            <Route
              path="/page/167/?taxonomy=stm_additional_features&term=abs"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/13/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/160/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/181/?taxonomy=stm_additional_features&term=lcd"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-toyota-mark-x-2005-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mark-x";
              }}
            />
            <Route
              path="/page/101/?taxonomy=stm_additional_features&term=audio-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/4/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/80/?taxonomy=stm_additional_features&term=audio-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/10/?taxonomy=stm_additional_features&term=spoiler"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/3/?taxonomy=stm_additional_features&term=spoiler"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/166/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/author/muhammadahmed"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/listings/used-honda-cross-road-2007"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_honda-cross";
              }}
            />
            <Route
              path="/listings/used-toyota-vitz-2008-4"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_toyota-vitz";
              }}
            />
            <Route
              path="/author/aneequemotors"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/listings/used-mitsubishi-lancer-2004"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_lancer";
              }}
            />
            <Route
              path="/listings/used-suzuki-cultus-2016-22"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_cultus";
              }}
            />
            <Route
              path="/listings/used-toyota-corolla-2017-48"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_corolla";
              }}
            />
            <Route
              path="/page/102/?taxonomy=stm_additional_features&term=immobilizer-key"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/4/?taxonomy=serie&term=swift"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/new-cars/review/4"
              component={() => {
                window.location = "https://autodeals.pk/recent-car-reviews";
              }}
            />
            <Route
              path="/page/6/?taxonomy=serie&term=fortuner"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/4/?taxonomy=stm_additional_features&term=active-radar"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/99/?taxonomy=stm_additional_features&term=immobilizer-key"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/inventory/page/143/?make=toyota"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_toyota";
              }}
            />
            <Route
              path="/page/11/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/303/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/286/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/288/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/213/?taxonomy=stm_additional_features&term=audio-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/236/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/344/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/6/?taxonomy=make&term=mercedes-benz"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/134/?taxonomy=stm_additional_features&term=cddvd-player"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/move-canbus/x-limited-make-up-sa-iii-2"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_move";
              }}
            />
            <Route
              path="/auc/cgi-bin"
              component={() => {
                window.location = "https://autodeals.pk/";
              }}
            />
            <Route
              path="/listings/used-daihatsu-cast-style-2016-3"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_daihatsu";
              }}
            />
            <Route
              path="/page/165/?taxonomy=stm_additional_features&term=lcd"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/46/?taxonomy=stm_additional_features&term=keyless-entry"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/107/?taxonomy=stm_additional_features&term=multimedia-steering"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/listings/used-toyota-mark-x-2005"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_mark-x";
              }}
            />
            <Route
              path="/page/162/?taxonomy=stm_additional_features&term=floor-mats"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/page/41/?taxonomy=stm_additional_features&term=alloy-wheels"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/car-industry"
              component={() => {
                window.location = "https://autodeals.pk/blog/";
              }}
            />
            <Route
              path="/page/69/?taxonomy=stm_additional_features&term=fog-lights"
              component={() => {
                window.location = "https://autodeals.pk/used-cars";
              }}
            />
            <Route
              path="/terms"
              component={() => {
                window.location = "https://autodeals.pk/";
              }}
            />
            <Route
              path="/author/metromotors"
              component={() => {
                window.location = "https://autodeals.pk/used-cars-dealers";
              }}
            />
            <Route
              path="/sport-sa-iii"
              component={() => {
                window.location = "https://autodeals.pk/used-cars/search/-/searchStr_sports";
              }}
            />
            <Route
              path="/car-prices/suzuki-car-prices"
              component={() => {
                window.location = "https://autodeals.pk/new-cars/pricelist";
              }}
            />
            <Route
              path="/gm-autobaojun"
              component={() => {
                window.location = "https://autodeals.pk/new-cars";
              }}
            />
            <Route
              path="/learn-how-to-post-ad"
              component={() => {
                window.location = "https://autodeals.pk/faq";
              }}
            />
            {/* not found */}
            <Redirect to={{ pathname: "/" }} />
          </Switch>
        </Suspense>
        {!path.includes("/post-ad/sell-car/post-your-ad") && !path.includes("/post-ad/sell-bike") && !path.includes("/post-ad/sell-part") ? (
          <>
            <Footer />
            <Suspense fallback={this.loading()}>
              <MobileFooter />
            </Suspense>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSocket: (socket) => dispatch(actions.setSocket(socket)),
    setMetaData: (metaData) => dispatch(actions.setMetaData(metaData)),
  };
};

const mapStateToProps = (state) => {
  return {
    metaData: state.metaData && state.metaData.metaData ? state.metaData.metaData : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
