import React, { Component } from "react";
import "./PostRidePopUp.scss";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { CustomInput } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import ModalPopup from "../../Shared/ModalPopup/ModalPopup";
import CarInfoModal from "../../PostAd/SellCar/CarInfoModal/CarInfoModal";
import MultiImageModal from "../../PostAd/MultiImageModal/MultiImageModal";
import StyleConfig from "../../../config/styleConfig";
import { data } from "../../../assets/data/index";
import MaskConfig from "../../../config/maskConfig";
import BikeMakeModel from "../../PostAd/SellBike/BikeMakeModel/BikeMakeModel";
import LocalStorageService from "../../../services/localStore-service";
import AdsService from "../../../services/ads-service";
import CommonService from "../../../services/common-service";
import MobileCarInfoModal from "../../PostAd/SellCar/MobileCarInfoModal/MobileCarInfoModal";

const styleConfig = new StyleConfig();

class PostRidePopUp extends Component {
  rideCategories = [
    { label: "Standard", value: "Standard" },
    { label: "Vintage/Classic", value: "Vintage/Classic" },
    { label: "Sports", value: "Sports" },
    { label: "Modified/Project", value: "Modified/Project" },
    { label: "Muscle", value: "PetrMuscleol" },
    { label: "Off-Road/4x4", value: "Off-Road/4x4" },
    { label: "Luxury/Exotic", value: "Luxury/Exotic" },
  ];

  constructor() {
    super();
    this.maskConfig = new MaskConfig();
    this.localService = new LocalStorageService();
    this.adsService = new AdsService();
    this.commonService = new CommonService();

    this.state = {
      carInfo: "",
      productInfo: "",
      title: "",
      bikeInfo: "",
      activeTab: "1",
      showCarInfoModal: false,
      showMobileCarInfoModal: false,
      showBikeInfoModal: false,
      showMobileBikeInfoModal: false,
      showImageModal: false,
      makeId: 0,
      modelId: 0,
      variantId: 0,
      modelYear: 0,
      images: [],
      cities: [],
      errors: {},
      rideCategory: [],
      description: "",
      city: "",
      car: "",
      bike: "",
      phoneNumber: "",
      images: "",
      bike: "",
      category: "car",
      name: "",
      address: "",
      email: "",
      riderId: 0,
      id: 0,
      update: false,
      isPublic: true,
      errors: {
        productInfo: "",
        city: "",
        rideCategory: "",
        description: "",
        name: "",
        email: "",
        phoneNumber: "",
        address: "",
        images: "",
      },
    };
  }

  componentDidMount() {
    this.getCities();
    this.setState({
      riderId: this.localService.getUserId(),
    });

    this.mapEditObjToState(this.props.ride);
  }

  mapEditObjToState = (state) => {
    if (state) {
      const { rideCategories, title, category, riderId, id } = state;
      let arr = [];
      rideCategories &&
        JSON.parse(rideCategories).map((item) => {
          let rideCat = {};
          rideCat.label = item;
          rideCat.value = item;
          arr.push(rideCat);
        });
      this.setState({
        update: state ? true : false,
        ...state,
        rideCategory: arr,
        bike: category === "bike" ? title : "",
        car: category === "car" ? title : "",
        riderId: riderId,
        id: id,
        images: state.images,
      });
    }
  };

  toggleCarInfoModel = () => {
    this.setState({
      showCarInfoModal: !this.state.showCarInfoModal,
    });
  };
  toggleMobileCarInfoModel = () => {
    this.setState({
      showMobileCarInfoModal: !this.state.showMobileCarInfoModal,
    });
  };

  toggleBikeInfoModel = () => {
    this.setState({
      showBikeInfoModal: !this.state.showBikeInfoModal,
    });
  };

  toggleMobileBikeInfoModel = () => {
    this.setState({
      showMobileBikeInfoModal: !this.state.showMobileBikeInfoModal,
    });
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) this.setState({ activeTab: tab });

    if (tab === "1") {
      this.setState({
        category: "car",
        description: "",
        city: "",
        car: "",
        rideCategory: "",
        images: "",
      });
    } else if (tab === "2") {
      this.setState({
        category: "bike",
        description: "",
        city: "",
        bike: "",
        rideCategory: "",
        images: "",
      });
    }
  };

  toggleModal = () => {
    this.setState({
      showImageModal: !this.state.showImageModal,
    });
  };

  handleModalClose = () => {
    this.toggleModal();
  };

  imageUploadFinished = (images = []) => {
    this.setState({
      images,
    });
    this.toggleModal();
  };

  handleCarInfoModalClose = (carInfo) => {
    this.toggleCarInfoModel();

    this.setCarInfo(carInfo, "add");
    const { make, model, variant, year } = carInfo;
    this.setState({
      makeId: make.id,
      modelId: model.id,
      variantId: variant.id,
      modelYear: year && year.value ? year.value : 0,
    });
  };

  handleMobileCarInfoModalClose = (carInfo) => {
    this.toggleMobileCarInfoModel();

    this.setCarInfo(carInfo, "add");
    const { make, model, variant, year } = carInfo;
    this.setState({
      makeId: make.id,
      modelId: model.id,
      variantId: variant.id,
      modelYear: year && year.value ? year.value : 0,
    });
  };

  handleBikeInfoModalClose = (carInfo) => {
    this.toggleBikeInfoModel();
    this.setBikeInfo(carInfo, "add");
    const { make, model } = carInfo;
    this.setState({
      makeId: make.id,
      modelId: model.id,
    });
  };

  handleMobileBikeInfoModalClose = (carInfo) => {
    this.toggleMobileBikeInfoModel();
    this.setBikeInfo(carInfo, "add");
    const { make, model } = carInfo;
    this.setState({
      makeId: make.id,
      modelId: model.id,
    });
  };

  setCarInfo = ({ year, make, model, variant }, type) => {
    const info = `${year && year.value ? year.value : ""} ${make.name ? make.name : ""} ${model.name ? model.name : ""} ${variant && variant.name ? variant.name : ""}`;
    const title = `${make && make.name ? make.name : ""} ${model && model.name ? model.name : ""} ${year && year.value ? year.value : ""} ${variant && variant.name ? variant.name : ""}`;
    this.setState({
      car: info,
      productInfo: info,
      title,
      modelYear: year ? year.value : "",
      carInfo: { year, make, model, variant },
    });

    if (variant && variant.car) {
      const { engineCapacity, engineType, transmission, assembly, features, seats, bodyType } = variant.car;
      if (type)
        if (type === "edit") {
          this.setState({
            engineCapacity,
            engineType,
            transmission,
            assembly,
            seats,
            bodyType,
          });
        } else if (type === "add") {
          this.setState({
            engineCapacity,
            engineType,
            transmission,
            assembly,
            seats,
            bodyType,
            features: features && features.length ? features.map((x) => x.id) : [],
          });
        }
    } else {
    }
  };

  setBikeInfo = ({ year, make, model }, type) => {
    const info = `${year && year.value ? year.value : ""} ${make.name ? make.name : ""} ${model.name ? model.name : ""} `;
    const title = `${make && make.name ? make.name : ""} ${model && model.name ? model.name : ""} ${year && year.value ? year.value : ""}`;
    this.setState({
      bike: info,
      title,
      modelYear: year ? year.value : "",
      bikeInfo: { year, make, model },
    });
  };

  getCities() {
    let cities = [];
    data.pakCities.forEach((city) => {
      cities.push({ label: city.name, value: city.name });
    });
    this.setState({ cities });
  }

  getCityAreas(city, operation) {
    const cityData = data.pakCities.filter((x) => x.name === city)[0];
    this.setState({
      cityAreas:
        cityData && cityData.areas && cityData.areas.length
          ? cityData.areas.map((area) => {
              return { label: area, value: area };
            })
          : [],
    });
    if (operation === "add") {
      this.setState({
        cityArea: "",
      });
    }
  }

  handleChange = (e, actionMeta) => {
    if (e && e.target) {
      const { value, name, type, checked } = e.target;
      if (name === "description") {
        if (value.length > 1000) {
          return;
        }
      }

      this.checkFormErrors(name, value);
      this.setState({
        ...this.state,
        [name]: type === "checkbox" ? checked : value,
      });
    } else if (actionMeta && actionMeta.name) {
      const { name } = actionMeta;

      if (e && e.length) {
        this.setState({
          [name]: e,
        });
      } else {
        const value = e && e.value ? e.value : "";
        const label = e && e.label ? e.label : "";
        this.checkFormErrors(label, value);
        this.setState({
          [name]: value,
        });
      }
    }
  };

  checkFormErrors = (name, value) => {
    let errors = this.state.errors;
    switch (name) {
      case "productInfo":
        const { makeId, modelId, variantId } = this.state;
        if (!makeId || !modelId || !variantId) {
          errors.productInfo = "Car Info is invalid!";
        } else {
          errors.productInfo = "";
        }
        break;
      case "city":
        errors.city = value === "" ? "City is required!" : "";
        break;
      case "rideCategory":
        errors.rideCategory = value === "" ? "Ride Category is required!" : "";
        break;
      case "description":
        errors.description = value === "" ? "Description is required!" : "";
        break;
      case "images":
        errors.images = value.length < 1 ? "Images are required!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors });
  };

  setFormData = () => {
    const { title, makeId, modelId, variantId, city, rideCategory, description, images, category, modelYear, isPublic, riderId } = this.state;
    let rides = rideCategory.map((item) => item.value);
    let formData = { title, makeId, modelId, variantId, city, rideCategories: JSON.stringify(rides), description, images, category, modelYear, isPublic, riderId };
    if (category === "bike") {
      delete formData.variantId;
    }
    return formData;
  };

  isValidForm = (formData, errors) => {
    for (let key in formData) {
      this.checkFormErrors(key, formData[key]);
    }
    return this.commonService.checkFormValidity(this.state.errors);
  };

  submit = () => {
    let formData = this.setFormData();
    const { errors } = this.state;

    if (this.isValidForm(formData, errors)) {
      this.adsService
        .postRide(formData)
        .then((res) => {
          const { message, data } = res.data;
          if (message === "Ride added successfully") {
            this.commonService.showToast("Your ride submitted successfully!", "success");
            this.props.onPostCloseModel();
          } else {
            this.commonService.showToast("Ride not submitted!", "error");
          }
        })
        .catch((err) => {
          this.commonService.showToast("Something went wrong!", "error");
        });
    } else {
      this.commonService.showToast("Invalid Form !", "error");
    }
  };

  update = () => {
    let formData = this.setFormData();

    const { id, riderId } = this.state;

    const { errors } = this.state;

    if (this.isValidForm(formData, errors)) {
      formData.id = id;
      formData.riderId = riderId;

      this.adsService
        .updateRide(formData)
        .then((res) => {
          const { message, data } = res.data;
          if (message === "Ride updated successfully") {
            this.commonService.showToast("Your ride updated successfully!", "success");
            this.props.rideModel();
          } else {
            this.commonService.showToast("Ride not submitted!", "error");
          }
        })
        .catch((err) => {
          this.commonService.showToast("Something went wrong!", "error");
          //   this.userService.handleError(err);
        });
    } else {
      this.commonService.showToast("Invalid Form !", "error");
    }
  };

  render() {
    const {
      carInfo,
      activeTab,
      images,
      errors,
      showImageModal,
      cities,
      city,
      description,
      car,
      bike,
      rideCategory,
      phoneNumber,
      showCarInfoModal,
      showBikeInfoModal,
      update,
      isPublic,
      showMobileCarInfoModal,
      showMobileBikeInfoModal,
    } = this.state;
    return (
      <div className="post-ride-popup-main">
        <div className="post-ride-text">
          {update ? <h2>Update Your Ride</h2> : <h2>Add Your Ride</h2>}
          <p>It's free and takes less than a minute</p>
        </div>
        <div className="post-for-car-bike">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <div className="tabs-for-ride">
                  <div className="ride-img">
                    <img loading="lazy" src={require(`../../../assets/Images/postRide/car.png`)} alt="Car Ride Post" />
                  </div>
                  <div className="tab-ride-name">
                    <p>Car</p>
                  </div>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                <div className="tabs-for-ride">
                  <div className="ride-img">
                    <img loading="lazy" src={require(`../../../assets/Images/postRide/bike.png`)} alt="Bike Ride Post" />
                  </div>
                  <div className="tab-ride-name">
                    <p>Bike</p>
                  </div>
                </div>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={"1"}>
            <TabPane tabId="1">
              <div className="rides-tabes-content">
                <div className="tabs-content-text">
                  <h2>Basic Information</h2>
                  <p>All fields marked with * are mandatory</p>
                </div>
                <div className="form-for-ride-post">
                  <div className="ride-post-form-main">
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <div className="postrider-desktop-view">
                          {activeTab === "1" ? (
                            <div className="form-group">
                              <label htmlFor="carInfo">Ride's Name*</label>
                              <div className="post-catagories">
                                <input
                                  type="text"
                                  className="form-control"
                                  ref={this.handleRef}
                                  name="productInfo"
                                  placeholder="Year* / Make* / Model* / Variant"
                                  defaultValue={car}
                                  onClick={this.toggleCarInfoModel}
                                  readOnly
                                  disabled={update ? true : false}
                                />
                                {errors.productInfo && <div className="invalid-feedback d-block">{errors.productInfo}</div>}
                              </div>
                            </div>
                          ) : (
                            <div className="form-group">
                              <label htmlFor="carInfo">Ride's Name*</label>
                              <div className="post-catagories">
                                <input
                                  type="text"
                                  className="form-control"
                                  ref={this.handleRef}
                                  name="productInfo"
                                  placeholder="Year* / Make* / Model*"
                                  defaultValue={bike}
                                  onClick={this.toggleBikeInfoModel}
                                  readOnly
                                  disabled={update ? true : false}
                                />
                                {errors.productInfo && <div className="invalid-feedback d-block">{errors.productInfo}</div>}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="postrider-mobile-view">
                          {activeTab === "1" ? (
                            <div className="form-group">
                              <label htmlFor="carInfo">Ride's Name*</label>
                              <div className="post-catagories">
                                <input
                                  type="text"
                                  className="form-control"
                                  ref={this.handleRef}
                                  name="productInfo"
                                  placeholder="Year* / Make* / Model* / Variant"
                                  defaultValue={car}
                                  onClick={this.toggleMobileCarInfoModel}
                                  readOnly
                                  disabled={update ? true : false}
                                />
                                {errors.productInfo && <div className="invalid-feedback d-block">{errors.productInfo}</div>}
                              </div>
                            </div>
                          ) : (
                            <div className="form-group">
                              <label htmlFor="carInfo">Ride's Name*</label>
                              <div className="post-catagories">
                                <input
                                  type="text"
                                  className="form-control"
                                  ref={this.handleRef}
                                  name="productInfo"
                                  placeholder="Year* / Make* / Model*"
                                  defaultValue={bike}
                                  onClick={this.toggleMobileBikeInfoModel}
                                  readOnly
                                  disabled={update ? true : false}
                                />
                                {errors.productInfo && <div className="invalid-feedback d-block">{errors.productInfo}</div>}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="regCity">City*</label>
                          <Select
                            options={cities}
                            ref={this.handleRef}
                            styles={styleConfig.reactSelectStyleCommon}
                            name="city"
                            placeholder="Select City"
                            value={city ? { label: city, value: city } : ""}
                            onChange={(e, actionMeta) => {
                              this.handleChange(e, actionMeta);
                              this.getCityAreas(e.value, "add");
                            }}
                          />
                          {errors.city && <div className="invalid-feedback d-block">{errors.city}</div>}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="profile">Ride’s Categories*</label>
                          <Select options={this.rideCategories} name="rideCategory" onChange={this.handleChange} value={rideCategory} placeholder="Select All" isMulti />
                          {errors.rideCategory && <div className="invalid-feedback d-block">{errors.rideCategory}</div>}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="comment">About Ride*</label>
                          <textarea
                            className="form-control"
                            rows="5"
                            id="comment"
                            placeholder="Share your Ride Experience  with us…"
                            name="description"
                            rows="4"
                            placeholder="Description..."
                            value={description}
                            onChange={this.handleChange}
                          ></textarea>
                          {errors.description && <div className="invalid-feedback d-block">{errors.description}</div>}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                                                <h4>Contact Information</h4>
                                                <div className="form-group">
                                                    <label htmlFor="name">Name*</label>
                                                    <input type="text" className="form-control" name="name" placeholder="Name" onChange={this.handleChange} />
                                                    {errors.name && <div className="invalid-feedback d-block">{errors.name}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="email">Email*</label>
                                                    <input type="Email" className="form-control" name="email" placeholder="Email" onChange={this.handleChange} />
                                                    {errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="mobileNumber">Mobile Number*</label>
                                                    <InputMask mask={this.maskConfig.phoneNumberMask} maskChar={null} className={`form-control ${errors.phoneNumber ? 'error' : ''}`} name="phoneNumber" placeholder="03XXXXXXXXX" value={phoneNumber} onChange={this.handleChange} />
                                                    {errors.phoneNumber && <div className="invalid-feedback d-block">{errors.phoneNumber}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="adress">Address*</label>
                                                    <input type="text" className="form-control" name="address" placeholder="Address" onChange={this.handleChange} />
                                                    {errors.address && <div className="invalid-feedback d-block">{errors.address}</div>}
                                                </div>
                                                <div className="post-ride-btn">
                                                    <button className="btn">Post Ride</button>
                                                </div>
                                            </div>
                                          */}
                      <div className="col-md-12">
                        <div className="upload-img-section">
                          <div className="upload-image">
                            <div className="upload-image-main" ref="images" tabIndex={0}>
                              <h4>Upload Image*</h4>
                              <p>(Max limit 5 MB per image)</p>
                              {images.length < 1 ? (
                                <div className="images">
                                  <img loading="lazy" src={require("../../../assets/Images/postad/upload-image.png")} alt="upload" />
                                  <img loading="lazy" src={require("../../../assets/Images/postad/upload-image.png")} alt="upload" />
                                  <img loading="lazy" src={require("../../../assets/Images/postad/upload-image.png")} alt="upload" />
                                  <img loading="lazy" src={require("../../../assets/Images/postad/upload-image.png")} alt="upload" />
                                  <img loading="lazy" src={require("../../../assets/Images/postad/upload-image.png")} alt="upload" />
                                </div>
                              ) : (
                                <div className="images">
                                  {images.map((image) => {
                                    return <img loading="lazy" key={image.url} src={image.url} className={`rotate${image.rotate}`} alt="upload" />;
                                  })}
                                </div>
                              )}
                              <div className="image-btn">
                                <button type="button" className="btn" onClick={this.toggleModal}>
                                  <img loading="lazy" src={require("../../../assets/Images/postad/add-image.png")} alt="car" />
                                  {images.length > 0 ? <span>Change Photos</span> : <span>Add Photos</span>}
                                </button>
                                {errors.images && <div className="invalid-feedback d-block">{errors.images}</div>}
                              </div>
                              <div className="image-note mt-4">Adding at least 8 pictures improves the chances for a quick sale.</div>
                              <div className="image-note">Adding clear Front, Back and Interior pictures of your car increases the quality of your Ad and gets you noticed more.</div>
                              <div className="image-note">Photos should be in "jpeg, jpg, png, gif" format only.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-common">
                        <label>Public</label>
                        <CustomInput type="switch" id="show-scratches" name="isPublic" checked={isPublic} onChange={this.handleChange} />
                        <div className="clear"></div>
                      </div>
                    </div>
                  </div>
                  <ModalPopup isOpen={showCarInfoModal} onModalClose={this.toggleCarInfoModel} className="postyouradd-carinfo" backdrop={"static"}>
                    {" "}
                    <CarInfoModal onCarInfoClose={this.handleCarInfoModalClose} carInfo={carInfo} category="car" />{" "}
                  </ModalPopup>
                  <ModalPopup isOpen={showMobileCarInfoModal} onModalClose={this.toggleMobileCarInfoModel} className="postyouradd-carinfo" backdrop={"static"}>
                    {" "}
                    <MobileCarInfoModal category="car" onCarInfoClose={this.handleMobileCarInfoModalClose} carInfo={carInfo} />{" "}
                  </ModalPopup>
                  <ModalPopup isOpen={showBikeInfoModal} onModalClose={this.toggleBikeInfoModel} className="postyouradd-carinfo" backdrop={"static"}>
                    {" "}
                    <BikeMakeModel onCarInfoClose={this.handleBikeInfoModalClose} carInfo={carInfo} category="bike" />{" "}
                  </ModalPopup>
                  <ModalPopup isOpen={showMobileBikeInfoModal} onModalClose={this.toggleMobileBikeInfoModel} className="postyouradd-carinfo" backdrop={"static"}>
                    {" "}
                    <MobileCarInfoModal onCarInfoClose={this.handleMobileBikeInfoModalClose} category="bike" carInfo={carInfo} />{" "}
                  </ModalPopup>
                  <ModalPopup isOpen={showImageModal} onModalClose={this.handleModalClose} className="postyouradd" backdrop={"static"}>
                    {" "}
                    <MultiImageModal onUploadFinished={this.imageUploadFinished} images={images} />{" "}
                  </ModalPopup>
                </div>
                <div className="savebtn row">
                  <div className="col-md-12">
                    <div className="container">
                      {update ? (
                        <button type="button" className="btn" onClick={this.update}>
                          Update
                        </button>
                      ) : (
                        <button type="button" className="btn" onClick={this.submit}>
                          SAVE{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export default PostRidePopUp;
