import { setAuthStatus, checkAuthStatus } from "./auth";
import loading from "./loading";
import { setProfile, getProfile } from "./profile";
import { setCartItems, getCartItems } from "./cart";
import { setSocket, getSocket } from "./socket";
import { setMetaData, getMetaData } from "./metadata";
import { setCategory, getCategory } from "./category";

export const actions = {
  setAuthStatus,
  checkAuthStatus,
  loading,
  setProfile,
  getProfile,
  setCartItems,
  getCartItems,
  setSocket,
  getSocket,
  setMetaData,
  getMetaData,
  setCategory,
  getCategory,
};
