import React, { Component } from "react";
import Select from "react-select";
import "./AdvanceSearch.scss";
import { data } from "../../../../assets/data/index";

import StyleConfig from "../../../../config/styleConfig";
import InfoService from "../../../../services/info-service";

class AdvanceSearch extends Component {
  constructor(props) {
    super();
    this.styleConfig = new StyleConfig();
    this.infoService = new InfoService();

    this.state = {
      cities: [],
      years: [],
      mileages: [],
      bodyTypes: [],
      engineCapacities: [],
      registerationCity: "",
      color: "",
      bodyType: "",
      category: "car",
      transmission: "",
      engineType: "",
      assembly: "",
      engineCapacity: "",
      pictureAvail: "",
      sellerType: "",
      adType: "",
      minYear: 0,
      maxYear: 0,
      minMil: 0,
      maxMil: 0,
      minEC: 0,
      maxEC: 0,
    };
    this.engineTypes =
      props.category === "car"
        ? [
            { label: "CNG", value: "CNG" },
            { label: "Diesel", value: "Diesel" },
            { label: "Hybrid", value: "Hybrid" },
            { label: "LPG", value: "LPG" },
            { label: "Petrol", value: "Petrol" },
            { label: "Electric", value: "Electric" },
            { label: "Electric Hybrid", value: "Electric Hybrid" },
            { label: "PHEV", value: "PHEV" },
          ]
        : [
            { label: "2 stroke", value: "2 stroke" },
            { label: "4 stroke", value: "4 stroke" },
          ];
  }

  transmission = [
    { label: "Manual", value: "Manual" },
    { label: "Automatic", value: "Automatic" },
  ];

  assembly = [
    { label: "Local", value: "Local" },
    { label: "Imported", value: "Imported" },
  ];

  pictureAvailable = [
    { label: "With Pictures", value: "picture" },
    { label: "With or Without Pictures", value: "all" },
  ];

  sellerTypes = [
    { label: "Dealers", value: "dealer" },
    { label: "Individuals", value: "individual" },
  ];

  adTypes = [
    { label: "All", value: "all" },
    { label: "Featured Only", value: "featured" },
  ];

  componentDidMount() {
    if (this.props.query) {
      const { query } = this.props;
      this.setState({
        ...query,
      });
    }
    this.setState(
      {
        mileages: data.prices.getPriceRanges(1000, 400000, 25000),
        engineCapacities:
          this.props.category === "car"
            ? data.prices.getPriceRanges(600, 8000, 200)
            : data.prices.getPriceRanges(70, 1050, 50),
        years: data.years.getYears(),
        category: this.props.category,
      },
      () => {
        this.getbodyTypes();
        this.getCities();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.setState(
        {
          category: this.props.category,
        },
        () => {}
      );
    }
  }

  getbodyTypes = () => {
    this.infoService
      .getBodyTypes()
      .then((res) => {
        const { data } = res;
        this.setState(
          {
            bodyTypes: data.map((x) => {
              return { label: x.name, value: x.id };
            }),
          },
          () => console.log(this.state.bodyTypes)
        );
      })
      .catch((err) => {
        this.infoService.handleError(err);
      });
  };

  getCities() {
    let cities = [];
    data.pakCities.forEach((city) => {
      cities.push({ label: city.name, value: city.name });
    });
    this.setState({ cities });
  }

  handleChange = (e, actionMeta) => {
    if (e.target) {
      const { value, name, type, checked } = e.target;
      this.setState(
        {
          ...this.state,
          [name]: type === "checkbox" ? checked : value,
        },
        () => {
          name !== "searchStr" && this.addToQuery();
        }
      );
    } else if (actionMeta && actionMeta.name) {
      const value = e.value;
      const { name } = actionMeta;
      this.setState(
        {
          ...this.state,
          [name]: value,
        },
        () => {
          this.addToQuery();
        }
      );
    }
  };

  getRanges = (min, max, items) => {
    return items.filter((x) => x.value >= min);
  };

  getSelectLabel(list = [], value) {
    if (list && list.length) {
      let val = list.filter((x) => x.value === value)[0];
      if (val) {
        return val.label;
      }
    }
  }

  addToQuery = () => {
    const {
      color,
      bodyType,
      transmission,
      engineType,
      assembly,
      engineCapacity,
      pictureAvail,
      sellerType,
      adType,
      minYear,
      maxYear,
      minMil,
      maxMil,
      minEC,
      maxEC,
      registerationCity,
      bodyTypes,
    } = this.state;
    const selectedBodyTypes = bodyTypes
      .filter((x) => x.value === bodyType)
      .map((x) => {
        return { name: x.label, id: x.value };
      });
    this.props.onAdvanceSearch({
      selectedColors: color ? [color] : "",
      selectedBodyTypes: bodyType ? selectedBodyTypes : "",
      transmission,
      engineType,
      assembly,
      engineCapacity,
      pictureAvail,
      sellerType,
      adType,
      minYear,
      maxYear,
      minMil,
      maxMil,
      minEC,
      maxEC,
      selectedRegCities: registerationCity ? [registerationCity] : "",
    });
  };

  render() {
    const {
      cities,
      registerationCity,
      mileages,
      minMil,
      maxMil,
      transmission,
      engineType,
      engineCapacities,
      minEC,
      maxEC,
      color,
      bodyTypes,
      bodyType,
      pictureAvail,
      sellerType,
      adType,
      years,
      minYear,
      maxYear,
      assembly,
      category,
    } = this.state;

    return (
      <div className="advance-search container">
        <div className="row">
          {category !== "part" && (
            <div className="col-md-6">
              <label>Registration City</label>
              <Select
                placeholder="All Registration Cities"
                name="registerationCity"
                options={cities}
                value={
                  registerationCity
                    ? { label: registerationCity, value: registerationCity }
                    : ""
                }
                onChange={this.handleChange}
              />
            </div>
          )}
          {category !== "part" && (
            <div className="col-md-6 min-max">
              <label>Mileage</label>
              <Select
                placeholder="Min"
                options={mileages}
                name="minMil"
                value={minMil ? { label: minMil, value: minMil } : ""}
                onChange={this.handleChange}
              />
              <Select
                placeholder="Max"
                options={this.getRanges(minMil, maxMil, mileages)}
                name="maxMil"
                value={maxMil ? { label: maxMil, value: maxMil } : ""}
                onChange={this.handleChange}
              />
            </div>
          )}
          {category === "car" && (
            <div className="col-md-4">
              <label>Transmission</label>
              <Select
                placeholder="All Transmission"
                name="transmission"
                options={this.transmission}
                value={
                  transmission
                    ? { label: transmission, value: transmission }
                    : ""
                }
                onChange={this.handleChange}
              />
            </div>
          )}
          {category !== "part" && (
            <div className="col-md-4">
              <label>Engine Type</label>
              <Select
                placeholder="All Engine Types"
                name="engineType"
                options={this.engineTypes}
                value={
                  engineType ? { label: engineType, value: engineType } : ""
                }
                onChange={this.handleChange}
              />
            </div>
          )}
          {category !== "part" && (
            <div className="col-md-4 min-max">
              <label>Engine Capacity</label>
              <Select
                placeholder="Min"
                options={engineCapacities}
                name="minEC"
                value={minEC ? { label: minEC, value: minEC } : ""}
                onChange={this.handleChange}
              />
              <Select
                placeholder="Max"
                options={this.getRanges(minEC, maxEC, engineCapacities)}
                name="maxEC"
                value={maxEC ? { label: maxEC, value: maxEC } : ""}
                onChange={this.handleChange}
              />
            </div>
          )}
          {category === "car" && (
            <div className="col-md-4">
              <label>Assembly</label>
              <Select
                placeholder="All Assemblies"
                name="assembly"
                options={this.assembly}
                value={assembly ? { label: assembly, value: assembly } : ""}
                onChange={this.handleChange}
              />
            </div>
          )}
          {category !== "part" && (
            <div className="col-md-4">
              <label>Color</label>
              <Select
                placeholder="All Colors"
                name="color"
                options={data.colors}
                value={
                  color
                    ? {
                        label: this.getSelectLabel(data.colors, color),
                        value: color,
                      }
                    : ""
                }
                onChange={this.handleChange}
              />
            </div>
          )}
          {category !== "part" && (
            <div className="col-md-4 min-max">
              <label>Year</label>
              <Select
                placeholder="Min"
                options={years}
                name="minYear"
                value={minYear ? { label: minYear, value: minYear } : ""}
                onChange={this.handleChange}
              />
              <Select
                placeholder="Max"
                options={this.getRanges(minYear, maxYear, years)}
                name="maxYear"
                value={maxYear ? { label: maxYear, value: maxYear } : ""}
                onChange={this.handleChange}
              />
            </div>
          )}
          {category === "car" && (
            <div className="col-md-4">
              <label>Body Type</label>
              <Select
                placeholder="All Body Types"
                name="bodyType"
                options={bodyTypes}
                value={
                  bodyType
                    ? {
                        label: this.getSelectLabel(bodyTypes, bodyType),
                        value: bodyType,
                      }
                    : ""
                }
                onChange={this.handleChange}
              />
            </div>
          )}
          <div className="col-md-4">
            <label>Picture Availability</label>
            <Select
              placeholder="Picture Availability"
              options={this.pictureAvailable}
              name="pictureAvail"
              value={
                pictureAvail
                  ? {
                      label: this.getSelectLabel(
                        this.pictureAvailable,
                        pictureAvail
                      ),
                      value: pictureAvail,
                    }
                  : ""
              }
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-4">
            <label>Seller Type</label>
            <Select
              placeholder="All Seller Types"
              options={this.sellerTypes}
              name="sellerType"
              value={
                sellerType
                  ? {
                      label: this.getSelectLabel(this.sellerTypes, sellerType),
                      value: sellerType,
                    }
                  : ""
              }
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-4">
            <label>Ad Type</label>
            <Select
              placeholder="All Ad Types"
              style={this.styleConfig.reactSelectStyleCommon}
              options={this.adTypes}
              name="adType"
              value={
                adType
                  ? {
                      label: this.getSelectLabel(this.adTypes, adType),
                      value: adType,
                    }
                  : ""
              }
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AdvanceSearch;
