import Axios from "axios";
import { useParams, useHistory, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./Find.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function FindAuctionSheet() {
  const { chassis } = useParams();
  const history = useHistory();

  const [key, setKey] = useState();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.get(`https://server.myauctionsheet.com/auction/find?chassis=${chassis}`, { header: { "Content-Type": "application/x-www-form-urlencoded" } })
      .then((res) => {
        if (res.data.status === 0) {
          toast.error(res.data.message);
          history.push("/");
        } else if (res.data.status === 1) {
          history.push({
            pathname: "/auction-sheet/cart",
            state: {
              chassis: chassis,
              key: false,
            },
          });
        } else {
          setKey(res.data.key);
          setDetails(res.data.details);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Try Again, Error while searching");
        // history.push('/')
      });
  }, [chassis]);

  return (
    <div id="find-sheet" className="constiner-fluid pb-5">
      <BreadcrumbsItem to={`/auction-sheet-verification`}>Find Auction Sheet</BreadcrumbsItem>
      <BreadcrumbsItem to={`/`}>Home</BreadcrumbsItem>
      <div className="text-center pt-5">
        <h3>
          {loading ? `We're searching for ` : `We found `}
          <span style={{ color: "#EE7E39" }} className="font-weight-bold">
            {chassis}
          </span>
        </h3>
      </div>
      <div className="d-flex justify-content-center mt-4">
        {loading ? (
          <img loading="lazy" className="loading-animation" src={require(`../../../../assets/Images/loading-animation.png`)} />
        ) : (
          <img loading="lazy" src={require(`../../../../assets/Images/search-chassis.png`)} style={{ width: "auto", height: "150px", objectFit: "contain", margin: "20px" }} />
        )}
      </div>
      <div className="container">
        <div className="text-center py-3 border-bottom border-dark">
          {loading ? (
            <div className="text-center">
              <h4>Looking up millions of records</h4>
              <h4>Please wait while we try to identify your vehicle report</h4>
            </div>
          ) : (
            <>
              <div className="my-3 d-flex justify-content-center">
                <div className="content-auction-details">
                  <h4 className="mb-0">{details}</h4>
                  <div className="img-content-detail">
                    <img loading="lazy" src={require("../../../../assets/Images/auctionsheet/Rectangle_893@2x.png")} />
                  </div>
                </div>
              </div>
              <Link to={{ pathname: "/auction-sheet/cart", state: { chassis: chassis, key: key } }} className="adp-btn adp-btn-secondary-sheet w-100">
                Proceed to Cart
              </Link>
            </>
          )}
        </div>
        <div className="text-center my-4">
          <h4>
            <strong style={{ color: "#093C6A" }} className="font-weight-bold">
              DID YOU KNOW:{" "}
            </strong>{" "}
            Sometimes vehicle record not found due to some unknow factors, then we try with our report+ manual service & it will take 24 hours to gather the data.
          </h4>
        </div>
      </div>
    </div>
  );
}

export default FindAuctionSheet;
