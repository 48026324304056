
export const setSocket = (socket) => {
    return {
        type: 'SET_SOCKET',
        payload: socket
    }
}

export const getSocket = () => {
    return {
        type: 'GET_SOCKET',
    }
}