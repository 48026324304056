import React, { Component } from "react";
import "./MultiImageModal.scss";
import UserService from "../../../services/user-service";
import CommonService from "../../../services/common-service";
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ModalPopup from "../../Shared/ModalPopup/ModalPopup";
import ImageEditorModal from "../../Shared/ImageEditorModal/ImageEditorModal";
// import Sortable, {MultiDrag, Swap} from 'sortablejs';
import { ReactSortable, Sortable, MultiDrag, Swap } from "react-sortablejs";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class MultiImageModal extends Component {
  constructor(props) {
    super(props);
    this.userService = new UserService();
    this.commonService = new CommonService();
    this.multipleImagesRef = React.createRef();
    this.state = {
      toggleImageEditor: false,
      imagesBeforeUpload: [],
      images: [],
      drag: true,
    };
  }

  componentDidMount() {
    this.setState({
      images: this.props.images ? this.props.images : [],
    });
    this.multipleImagesRef.current.firstChild.className = "images-row";
  }

  setImages = (e) => {
    // var reader = new FileReader();
    //debugger;
    let imgs = [];
    const { images } = this.state;
    if (images.length <= 30) {
      Array.from(e.target.files).forEach((file) => {
        let size = (file.size / 1000000).toFixed(1);
        if (size <= 5) {
          imgs.push(file);
        } else {
          this.commonService.showToast("One or more images having size more than 5 mb! Please contact support@autodeals.pk for more details", "warn");
        }
      });
      // imgs = e.target.files;
      // debugger
      // reader.onloadend = () => {
      //     // this.setState({
      //     //     images: [...imgs]
      //     // });
      if (imgs && imgs.length) {
        if (this.checkImagesLimit(images.length, imgs.length)) {
          setTimeout(() => {
            this.uploadPhotos([...imgs]);
          }, 500);
        } else {
          this.commonService.showToast("Some of your photos are skipped due to limit is 30! Please contact support@autodeals.pk for more details", "warn");
          imgs = imgs.filter((x, i) => i < 31 - images.length);
          setTimeout(() => {
            this.uploadPhotos([...imgs]);
          }, 500);
        }
      }
      // }
      // reader.readAsDataURL(imgs[(imgs.length - 1)]);
    }
  };

  checkImagesLimit = (uploadedLength, toBeLength) => {
    if (uploadedLength + toBeLength > 31) {
      return false;
    } else {
      return true;
    }
  };

  uploadPhotos = (imgs = []) => {
    let formData = new FormData();
    imgs.map((image) => formData.append("photo", image));

    this.userService
      .uploadImage(formData)
      .then((res) => {
        const { data } = res;
        this.mapImages(data);
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.userService.handleError(err);
      });
  };

  mapImages = (data) => {
    let imgs = [];
    data.map((img, i) =>
      imgs.push({
        url: img.location,
        order: 0, //i + 1,
        rotate: 0,
      })
    );
    this.setState({
      images: [...this.state.images, ...imgs],
    });
  };

  setImgOrder = (index, orderType, length) => {
    const { images } = this.state;
    if ((index === 0 && orderType === "prev") || ((!images[index + 1] || !images[index + 1].url) && orderType === "next")) {
      return;
    }
    let imgs = images;

    if (orderType === "prev") {
      const tempImg = Object.assign({}, imgs[index - 1]);
      imgs[index - 1].url = imgs[index].url;
      imgs[index].url = tempImg.url;
    } else if (orderType === "next") {
      const tempImg = Object.assign({}, imgs[index + 1]);
      imgs[index + 1].url = imgs[index].url;
      imgs[index].url = tempImg.url;
    } else if (orderType === "first") {
      const tempImg = Object.assign({}, imgs[index]);
      let prevFirstId;
      if (imgs[0].id) {
        prevFirstId = imgs[0].id;
      }
      imgs.splice(index, 1);
      imgs.splice(0, 0, tempImg);
      if (imgs[0].id) {
        imgs[1].id = imgs[0].id;
        imgs[0].id = prevFirstId;
      }
    }
    this.setState({
      images: [...imgs],
    });
  };

  deleteImage = (index) => {
    const { images } = this.state;
    images.splice(index, 1);
    this.setState({ images });
  };

  rotate = (index) => {
    const { images } = this.state;
    if (images[index].rotate < 270) {
      images[index].rotate = images[index].rotate + 90;
    } else {
      images[index].rotate = 0;
    }
    this.setState({
      images,
    });
  };

  onFinished = () => {
    const { images } = this.state;

    this.props.onUploadFinished(images.filter((x) => (x.url ? true : false)));
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const images = reorder(this.state.images, result.source.index, result.destination.index);

    this.setState({
      images,
    });
  };

  editImage = (i) => {
    this.setState({
      toggleImageEditor: !this.state.toggleImageEditor,
    });
  };

  addImagePlaceholder = (imgs = []) => {
    imgs = imgs.filter((x) => x.placeholder === undefined);
    let imgsList = imgs.slice();
    if (imgs && imgs.length <= 3) {
      for (let i = 1; i <= 4 - imgs.length; i++) {
        imgsList.push({ placeholder: i, count: imgs.length + i });
      }
    } else if (imgs && imgs.length >= 4 && imgs.length < 30) {
      // imgsList = imgsList.filter(x => x.placeholder === undefined);
      imgsList.push({ placeholder: 1, count: imgs.length + 1 });
    } else {
      imgsList = imgs.filter((x) => x.placeholder === undefined);
    }
    return imgsList;
  };

  getImagesPlaceholder = (count) => {
    return (
      <div className="image-placeholder">
        <div className="image-placeholder-inner">
          <input type="file" className="upload-photos" multiple onChange={this.setImages} accept=".png,.jpg,.jpeg" />
          <span className="image-placeholder-plus">
            <i className="fas fa-plus"></i>
          </span>
          <div className="image-placeholder-bottom">
            <span className="txt">Add photo</span>
            <span className="remainig">{count} of 30</span>
          </div>
        </div>
      </div>
    );
  };

  imageStar = (i, images) => {
    return i === 0 ? (
      <div className="image-main-icon">
        <span>
          Cover Photo<i className="fas fa-star"></i>
        </span>
      </div>
    ) : (
      <div className="image-main-icon pointer" onClick={() => this.setImgOrder(i, "first", images.length)}>
        <i className="far fa-star"></i>
      </div>
    );
  };

  getImagesList = (images = []) => {
    return images.map((image, i) => {
      if (image.placeholder) {
        return <div key={image.placeholder}>{this.getImagesPlaceholder(image.count)}</div>;
      } else {
        return (
          <div key={image.url ? image.url : i}>
            <div className="multi-image-content">
              <div className="image-main">
                <img loading="lazy" src={image.url || require("../../../assets/Images/postad/modalcar.png")} className={`image-main-img rotate${image.rotate}`} alt="modal-car" />
                {this.imageStar(i, images)}
              </div>
              <div className="multiple-icons">
                <div className="previous-icon">
                  <i className={`fas fa-chevron-left ${i === 0 ? "arrow-disabled" : "arrow-enabled"}`} onClick={() => this.setImgOrder(i, "prev", images.length)}></i>
                  {/* <img loading="lazy" src={require('../../../assets/Images/postad/arrow-left.png')} alt="previous" onClick={() => this.setImgOrder(i, 'prev', images.length)} /> */}
                </div>
                <div className="icon-text">Move</div>
                <div className="forward-icon">
                  <i className={`fas fa-chevron-right ${!images[i + 1] || !images[i + 1].url ? "arrow-disabled" : "arrow-enabled"}`} onClick={() => this.setImgOrder(i, "next", images.length)}></i>
                  {/* <img loading="lazy" src={require('../../../assets/Images/postad/arrow-right.png')} alt="forward" onClick={() => this.setImgOrder(i, 'next', images.length)} /> */}
                </div>
                <div className="right-border"></div>
                <div className="rotate-icon">
                  <img loading="lazy" src={require("../../../assets/Images/postad/rotate.png")} alt="rotate" onClick={() => this.rotate(i)} />
                </div>
                <div className="right-border"></div>
                <div className="delete-icon">
                  <img loading="lazy" src={require("../../../assets/Images/postad/trash.png")} alt="delete" onClick={() => this.deleteImage(i)} />
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  onSwapList = (e) => {
    let { images } = this.state;
    // if (e && (e.oldIndex || e.oldIndex === 0) && (e.newIndex || e.newIndex === 0)) {
    //     // const tempImg = Object.assign({}, images[e.oldIndex]);
    //     // debugger
    //     if (e.oldIndex > e.newIndex) {
    //         const tempId = images[e.newIndex].id;
    //         images[e.newIndex].id = images[e.newIndex + 1].id;
    //         images[e.newIndex + 1].id = tempId;
    //     } else if (e.oldIndex < e.newIndex) {
    //         const tempId = images[e.newIndex].id;
    //         images[e.newIndex].id = images[e.newIndex - 1].id;
    //         images[e.newIndex - 1].id = tempId;
    //     }
    // }
    let imgIds = images.filter((x) => x.id !== undefined).map((x) => x.id);
    imgIds = imgIds.sort((a, b) => {
      return a > b ? 1 : b > a ? -1 : 0;
    });
    images = images.map((img, i) => {
      return { ...img, id: imgIds[i] };
    });
    this.setState({
      images,
    });
  };

  setNewSortedList = (newState) => {
    this.setState({
      images: newState,
    });
  };

  render() {
    const images = this.addImagePlaceholder(this.state.images);
    // Sortable.mount(new MultiDrag(), new Swap());
    return (
      <div className="multiimagemodal-main" id="multi-media">
        <div className="multi-image-header">
          <h2>
            <i className="far fa-images"></i>UPLOAD PHOTOS
          </h2>
        </div>
        <div className="header-text">Tip: Select several photos in the upload box to add them in one go.</div>

        <div className="multiple-images" ref={this.multipleImagesRef}>
          <ReactSortable
            direction={"horizontal"}
            group="groupName"
            animation={800}
            delayOnTouchStart={true}
            delayOnTouchOnly={true}
            // handle=".image-main-img"

            onEnd={(e) => {
              setTimeout(() => {
                this.onSwapList(e);
              }, 500);
            }}
            delay={50}
            list={images}
            setList={(newState) => this.setNewSortedList(newState)}
          >
            {this.getImagesList(images)}
          </ReactSortable>

          <div className="multiimage-buttons mx-md-0 mx-n5">
            {images.filter((x) => (x.placeholder ? true : false)).length === 4 ? (
              <button type="button" className={`btn more-photo ${window.innerWidth < 480 ? "w-100" : ""}`} style={{ borderRadius: "12px" }}>
                <img loading="lazy" src={require("../../../assets/Images/postad/add-image.png")} alt="more-photos" />
                <input type="file" className="upload-photos" multiple onChange={this.setImages} accept=".png,.jpg,.jpeg" /> ADD PHOTOS
              </button>
            ) : (
              <button type="button" disabled={images.length >= 30} className={`btn more-photo ${window.innerWidth < 480 ? "w-100" : ""}`} style={{ borderRadius: "12px" }}>
                <img loading="lazy" src={require("../../../assets/Images/postad/add-image.png")} alt="more-photos" />
                {images.length < 30 && <input type="file" className="upload-photos" multiple onChange={this.setImages} accept=".png,.jpg,.jpeg" />} ADD MORE PHOTOS
              </button>
            )}
            <button type="button" className={`btn finished ${window.innerWidth < 480 ? "w-100" : ""}`} onClick={() => this.onFinished()} style={{ borderRadius: "12px" }}>
              <i className="far fa-check-circle"></i>FINISHED
            </button>
          </div>
          <ModalPopup isOpen={this.state.toggleImageEditor} onModalClose={this.editImage} className="image-editor-modal" backdrop={"static"}>
            {" "}
            <ImageEditorModal />{" "}
          </ModalPopup>
        </div>
      </div>
    );
  }
}

export default MultiImageModal;
