import React, { Component } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import "./RightMenu.scss";

class RightMenu extends Component {
  constructor() {
    super();
    this.state = {
      options: [],
      unreadChatsCount: 0,
    };
  }

  componentDidMount() {
    this.setState({
      options: this.props.options,
      unreadChatsCount: this.props.unreadChatsCount,
    });
  }

  componentDidUpdate(nexProps, prevProps) {
    if (this.props.options !== nexProps.options) {
      this.setState({
        options: this.props.options,
      });
    }
    if (prevProps.unreadChatsCount !== this.props.unreadChatsCount) {
      this.setState({
        unreadChatsCount: this.props.unreadChatsCount,
      });
    }
  }

  handleSelection = (item, index) => {
    const { options } = this.state;
    let prevActiveIndex = options.findIndex((x) => x.active === true);

    if (prevActiveIndex !== index) {
      if (prevActiveIndex >= 0) {
        options[prevActiveIndex].active = false;
      }
      options[index].active = true;
      item.active = true;
      this.setState({
        options,
      });
      this.props.onSelect(item, options, index);
    }
  };

  render() {
    const { showButton } = this.props;
    const { options, unreadChatsCount } = this.state;
    return (
      <>
        <div id="right-menu">
          {options &&
            options.length > 0 &&
            options.map((item, i) => {
              return (
                <div className={`left-top-status ${item.active ? "active" : ""}`} key={i}>
                  <button type="button" className="btn active text-center" onClick={() => this.handleSelection(item, i)}>
                    <span className="status-left">{item.title}</span>
                    {(item.count || item.count === 0) && <span className={`status-right ${item.active ? "active" : ""}`}>({item.count})</span>}
                  </button>
                </div>
              );
            })}
        </div>

        {showButton && (
          <div className="ads-category-tabs mt-n2 pb-3">
            <Nav tabs className="nav-tabs">
              <NavItem className="tab-item">
                <NavLink href="/dashboard/favourites">
                  <i className="far fa-heart mr-1"></i> My Favorites
                </NavLink>
              </NavItem>
              <NavItem className="tab-item">
                <NavLink href="/dashboard/messages">
                  My Messages <small>({unreadChatsCount ? unreadChatsCount : "0"})</small>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        )}
      </>
    );
  }
}

export default RightMenu;
