import React, { Component } from 'react';
import './Rides.scss';
import LeftMenuTop from '../LeftMenuTop/LeftMenuTop';
import LeftMenuBottom from '../LeftMenuBottom/LeftMenuBottom';
import { GiSteeringWheel } from 'react-icons/gi';
import ModalPopup from '../../Shared/ModalPopup/ModalPopup';
import PostRidePopUp from '../../Rides/PostRidePopUp/PostRidePopUp';
import LocalStorageService from '../../../services/localStore-service';
import AdsService from '../../../services/ads-service';
import RidesService from '../../../services/ride-service';

import CommonService from '../../../services/common-service';
import ListingItemVertical from '../../Rides/ListingItemVertical/ListingItemVertical'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import Paginate from '../../Shared/Paginate/Paginate';

class Rides extends Component {


    constructor() {

        super();
        this.localService = new LocalStorageService();
        this.adsService = new AdsService();
        this.rideService = new RidesService();
        this.commonService = new CommonService();

        this.state = {
            ridesItems: [],
            showPostRideModal: false,
            hasData: false,
            pageNo: 0,
            pageSize: 12,
            category: 'car',
            totalPages: 1,
            totalAds: 0,
            options: [
                {
                    title: 'Car',
                    number: '(1)',
                    class: 'active',
                    active: true,
                },
                {
                    title: 'Bike',
                    number: '(1)',
                    class: ''

                }
            ]
        }
    }

    componentDidMount() {
        this.getUserRides();
    }

    getUserRides = () => {
        const { pageNo, pageSize, category } = this.state;
        const formData = { pageNo, pageSize, category, riderId: this.localService.getUserId() };

        this.rideService.getUserRides(formData).then(res => {
            const { totalPages, records, count } = res.data;
            if (records && records.length) {
                this.setState({
                    ridesItems: records,
                    totalPages: totalPages,
                    totalAds: count,
                    hasData: true
                })
            } else {
                this.setState({
                    ridesItems: [],
                    hasData: false
                })
            }
        }).catch(err => {
            this.commonService.showToast('Something went wrong!', 'error')
            this.rideService.handleError(err);
        })

    }  //end function here


    togglePostRideModal = () => {
        this.setState({
            showPostRideModal: !this.state.showPostRideModal
        })
    }

    favouriteHandler = () => {
        this.getUserRides();
    }

    setCategory = (item, options, index) => {

        this.setState({
            category: item.title === "Car" ? 'car' : 'bike'
        }, () => {
            this.getUserRides();
        })
    }

    postRideModalCloseHandler = () => {
        this.getUserRides();
        this.togglePostRideModal();
    }

    handlePageClick = ({ selected }) => {
        this.setState({
            pageNo: selected ? selected : 0,
        }, () => {
            this.getUserRides();
        })
    }

    render() {
        const { showPostRideModal, ridesItems, hasData, totalPages } = this.state;

        return (
            <div className="my-rides-main">
                <BreadcrumbsItem to='/dashboard/my-rides'>Dashboard <i className="fas fa-chevron-right"></i> My Rides</BreadcrumbsItem>
                <div className="row">
                    <div className="col-md-4">
                        <LeftMenuTop onSelect={this.setCategory} options={this.state.options} />
                        <div className="auction-desktop-screen">
                            <LeftMenuBottom />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="rides-main-right">
                            <div className="row">

                                {ridesItems && ridesItems.map((item, i) => {
                                    return (
                                        <div className="col-md-6" key={i}>
                                            <ListingItemVertical key={item.id} data={item} onFavourite={this.favouriteHandler} />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="listing-loadmore">
                                {hasData === true ?
                                    <Paginate totalPages={totalPages} onPageChange={this.handlePageClick} />
                                    :
                                    hasData === false ? <h5 className="text-center pt-4">No Record Found!</h5> : <h5 className="text-center pt-4">Loading...</h5>
                                }
                            </div>
                            <div className="rides-buttons text-right">
                                {/* <button type="button" className="btn reset"><i className="fas fa-retweet"></i>Reset</button> */}
                                <button type="button" className="btn new-ride" onClick={this.togglePostRideModal}><GiSteeringWheel />Add New Ride</button>
                            </div>
                            <ModalPopup isOpen={showPostRideModal} onModalClose={this.togglePostRideModal} className="post-form-ride">
                                <PostRidePopUp onPostCloseModel={this.postRideModalCloseHandler} />
                            </ModalPopup>
                        </div>
                    </div>
                    <div className="col-md-4 auction-mobile-screen">
                        <LeftMenuBottom />
                    </div>
                </div>
            </div>
        );
    }
}

export default Rides;