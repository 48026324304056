import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import Newsletter from "../Newsletter/Newsletter";
import Copyrights from "./Copyrights/Copyrights";
import InfoService from "../../../services/info-service";
import CommonService from "../../../services/common-service";
import Configuration from "../../../config/configuration";

class Footer extends Component {
  constructor(props) {
    super();
    this.infoService = new InfoService();
    this.commonService = new CommonService();
    this.config = new Configuration();

    this.state = {
      category: "car",
      cities: [],
      makes: [],
      bodyTypes: [],
      colors: [],
    };
  }
  componentDidMount() {
    // this.getAdsCities();
    // this.getAllMakes();
    // this.getAdsBodyTypes();
    // this.getAdsColors();
  }
  redirectToBlog = () => {
    let autodealsBlogs = this.config.autodealsBlogs;
    window.open(autodealsBlogs, "_self");
  };

  exploreAutoDeals = [
    {
      link: "/used-cars",
      name: "Used Cars",
    },
    {
      link: "/used-bikes",
      name: "Used Bikes",
    },
    {
      link: "/new-cars",
      name: "New Cars",
    },
    {
      link: "/new-bikes",
      name: "New Bikes",
    },
    {
      link: "/accessories-spare-parts",
      name: "Auto Parts",
    },
    {
      link: "/post-ad/sell-car/post-your-ad",
      name: "Sell Your Car",
    },
    {
      link: "/post-ad/sell-bike",
      name: "Sell Your Bike",
    },
    {
      link: "/blog",
      name: "Blog",
    },
    {
      link: "/sitemap",
      name: "Sitemap",
    },
  ];

  cities = [
    { name: "Lahore" },
    { name: "Islamabad" },
    { name: "karachi" },
    { name: "Rawalpindi" },
    { name: "Peshawar" },
    { name: "Multan" },
    { name: "Faisalabad" },
    { name: "Gujranwala" },
    { name: "Sialkot" },
  ];

  makes = [
    { id: 7, name: "Toyota" },
    { id: 8, name: "Suzuki" },
    { id: 1, name: "Honda" },
    { id: 46, name: "Kia" },
    { id: 6, name: "Daihatsu" },
    { id: 9, name: "Nissan" },
    { id: 54, name: "Mercedes" },
    { id: 38, name: "Hyundai" },
    { id: 4, name: "BMW" },
  ];

  bodyTypes = [
    { id: 5, name: "Hatchback" },
    { id: 7, name: "Sedan" },
    { id: 6, name: "SUV" },
    { id: 17, name: "Mini Van" },
    { id: 14, name: "Van" },
    { id: 16, name: "CrossOver" },
    { id: 20, name: "Double Cabin" },
  ];

  autoDeals = [
    {
      link: "/about-us",
      name: "About AutoDeals",
    },
    {
      link: "/our-products",
      name: "Our Products",
    },
    {
      link: "/advertise",
      name: "Advertise With Us",
    },
    {
      link: "/how-to-pay",
      name: "How To Pay",
    },
    {
      link: "/shipment-and-returns-policy",
      name: "Shipment & Returns Policy",
    },
    {
      link: "/return-exchange-policy",
      name: "Return & Exchange",
    },
    {
      link: "/refund-policy",
      name: "Refund Policy",
    },
    {
      link: "/dmca-policy",
      name: "DMCA Policy",
    },
    {
      link: "/faq",
      name: "FAQs",
    },

    // {
    //   link: '/google.com',
    //   name: 'Refunds & Returns'
    // },
    // {
    //   link: '/google.com',
    //   name: 'Careers'
    // },
    {
      link: "/contact-us",
      name: "Contact Us",
    },
  ];

  carsByCategory = [
    {
      link: "/google.com",
      name: "Automatic Cars",
    },
    {
      link: "/google.com",
      name: "Low Priced Cars",
    },
    {
      link: "/google.com",
      name: "Imported Cars",
    },
    {
      link: "/google.com",
      name: "1000cc Cars",
    },
    {
      link: "/google.com",
      name: "660cc Cars",
    },
    {
      link: "/google.com",
      name: "Jeep",
    },
    {
      link: "/google.com",
      name: "Japanese Cars",
    },
    {
      link: "/google.com",
      name: "4x4 Cars ",
    },
  ];

  carsByProvince = [
    {
      link: "google.com",
      name: "Cars in Punjab",
    },
    {
      link: "google.com",
      name: "Cars in Sindh",
    },
    {
      link: "google.com",
      name: "Cars in KPK",
    },
    {
      link: "google.com",
      name: "Cars in Balochistan",
    },
    {
      link: "google.com",
      name: "Cars in Azad Kashmir",
    },
    {
      link: "google.com",
      name: "Cars in FATA",
    },
  ];

  colors = [{ name: "White" }, { name: "Silver" }, { name: "Black" }, { name: "Grey" }, { name: "Blue" }, { name: "Red" }, { name: "Brown" }];

  getAdsCities = () => {
    const { category } = this.state;
    this.infoService
      .getAdsCities(category, 1, 9)
      .then((res) => {
        const { data } = res;
        if (data && data.length) {
          this.setState({
            cities: data,
          });
        }
      })
      .catch((err) => {
        this.infoService.handleError(err);
      });
  };

  getAllMakes = () => {
    const { category } = this.state;
    this.infoService
      .getAllMakes(category, 9)
      .then((res) => {
        const { data } = res;
        if (data && data.length) {
          this.setState({
            makes: data,
          });
        }
      })
      .catch((err) => {
        this.infoService.handleError(err);
      });
  };

  getAdsBodyTypes = () => {
    const { category } = this.state;
    this.infoService
      .getAdsBodyTypes(category, 9)
      .then((res) => {
        const { data } = res;
        if (data && data.length) {
          this.setState({
            bodyTypes: data,
          });
        }
      })
      .catch((err) => {
        this.infoService.handleError(err);
      });
  };

  getAdsColors = () => {
    const { category } = this.state;
    this.infoService
      .getAdsColors(category, 1, 9)
      .then((res) => {
        const { data } = res;
        if (data && data.length) {
          this.setState({
            colors: data,
          });
        }
      })
      .catch((err) => {
        this.infoService.handleError(err);
      });
  };

  render() {
    const { cities, makes, bodyTypes, colors } = this.state;
    return (
      <div className="main-footer">
        <div className="footer" style={{ backgroundColor: "#0c3148fa" }}>
          <div className="container">
            <div className="footer-desktop">
              <div className="row col-one">
                <div className="col-xl-7 col-lg-7">
                  <div className="row">
                    <div className="col-md-4 col-6 footer-content">
                      <h2>Cars By Make</h2>
                      <ul className="mb-0">
                        {this.makes &&
                          this.makes.map((make, i) => {
                            return (
                              <li key={i}>
                                <Link to={`/used-cars/search/-${this.commonService.getNameBasedQuery("mk", [make.name + "_" + make.id])}`}>{make.name} Cars For Sale</Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>

                    <div className="col-md-4 col-6 footer-content">
                      <h2>Cars By City</h2>
                      <ul>
                        {this.cities.map((city, i) => {
                          return (
                            <li key={i}>
                              <Link to={`/used-cars/search/-${this.commonService.getNameBasedQuery("ct", [city.name])}`}>
                                Cars In <span>{city.name}</span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className="col-md-4 col-6 footer-content">
                      <h2>Explore AutoDeals</h2>
                      <ul className="mb-0 mb-md-4">
                        {this.exploreAutoDeals.map((make, i) => {
                          return (
                            <li key={i}>
                              {make.name === "Blog" ? (
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.redirectToBlog();
                                  }}
                                >
                                  {make.name}
                                </Link>
                              ) : (
                                <Link to={make.link}>{make.name}</Link>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-md-4 col-6 footer-content">
                      <h2>Cars By Body Type</h2>
                      <ul>
                        {this.bodyTypes.map((body, i) => {
                          return (
                            <li key={i}>
                              <Link to={`/used-cars/search/-${this.commonService.getNameBasedQuery("bodyT", [body.name + "_" + body.id])}`}>{body.name}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-md-4 col-6 footer-content">
                      <h2 className="d-flex flex-column">
                        Partners
                      </h2>
                      <ul>
                        <li>
                          <a href="https://autodealsjapan.com/" target="_blank">
                            Auto Deals Japan
                          </a>
                        </li>
                        <li>
                          <a href="https://myauctionsheet.com" target="_blank">
                            My Auction Sheet
                          </a>
                        </li>
                        <li>
                          <a href="https://listnetworks.com" target="_blank">
                            List Networks
                          </a>
                        </li>
                        <li>
                          <a href="https://citybook.pk" target="_blank">
                            Citybook
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-4 col-6 footer-content">
                      <div className="footer-content">
                        <h2>About Company</h2>
                        <ul>
                          {this.autoDeals.map((make, i) => {
                            return (
                              <li key={i}>
                                <Link to={make.link}>{make.name}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row col-two"></div>
                </div>
                <div className="col-xl-5 col-lg-5  footer-content">
                  <Newsletter />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights" style={{ backgroundColor: "#0c3148" }}>
          <Copyrights />
        </div>
      </div>
    );
  }
}

export default Footer;
