const category = (state = "cars", action) => {
  if (action.type === "SET_CATEGORY") {
    state = action.payload;
    return state;
  } else if (action.type === "GET_CATEGORY") {
    return state;
  }
  return state;
};

export default category;
