import React, { Component } from "react";
import Select from "react-select";
import "./MobileCarInfoModal.scss";
import { data } from "../../../../assets/data/index";
import AdsService from "../../../../services/ads-service";

class MobileCarInfoModal extends Component {
  constructor() {
    super();
    this.adsService = new AdsService();
    this.state = {
      years: [],
      makes: [],
      models: [],
      genVariants: [],
      yearsOptions: [],
      makesOptions: [],
      modelsOptions: [],
      genVariantsOptions: [],
      year: "",
      modelId: "",
      makeId: "",
      variantId: "",
      category: "",
      hideYear: false,
    };
  }

  componentDidMount() {
    this.getYears();
    const { carInfo, category, hideYear } = this.props;
    this.setState(
      {
        category,
        hideYear,
      },
      () => {
        if (carInfo) {
          const { year, make, model, variant } = carInfo;
          if (year) {
            this.setState(
              {
                year: year.value,
                makeId: make ? make.id : "",
                modelId: model ? model.id : "",
                variantId: variant ? variant.id : "",
              },
              () => {
                this.getAllMakes(year.value, make.id);
                model.id && category !== "bike" && this.getVariantsByModelIdAndYear(model.id, "start");
              }
            );
          }
        }

        if (hideYear) {
          this.setState({ year: new Date().getFullYear() }, () => {
            this.getAllMakes(new Date().getFullYear());
          });
        }
      }
    );
  }

  getYears = (selectedItem = null) => {
    this.setState({
      years: data.years.getYears(),
    });
  };

  getAllMakes = (year, selectedItem = null) => {
    !selectedItem && this.resetState("years");
    const { category } = this.state;
    this.adsService
      .getAllMakes(category)
      .then((res) => {
        const { data } = res;
        let makes = [];
        if (data && data.length > 0) {
          data.forEach((make) => {
            makes.push({
              label: make.name,
              value: make.id,
            });
          });
          this.setState(
            {
              makesOptions: makes,
              makes: data,
            },
            () => {
              selectedItem && this.getModelsByMakeIdAndMakeType(selectedItem, "start");
            }
          );
        } else {
          this.setState({
            makes: [],
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  };

  getModelsByMakeIdAndMakeType = (makeId, type) => {
    type !== "start" && this.resetState("makes");
    const { category } = this.state;
    this.adsService
      .getModelsByMakeIdAndMakeType(makeId, category)
      .then((res) => {
        const { data } = res;
        let models = [];
        if (data && data.length > 0) {
          data.forEach((model) => {
            models.push({
              label: model.name,
              value: model.id,
            });
          });

          this.setState({
            modelsOptions: models,
            models: data,
          });
        } else {
          this.setState({
            models: [],
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  };

  //varinat and generations
  getVariantsByModelIdAndYear = (modelId, type) => {
    const { year } = this.state;
    type !== "start" && this.resetState("models");
    this.adsService
      .getVariantsByModelIdAndYear(modelId, year)
      .then((res) => {
        const { data } = res;
        let genVariants = [];
        if (data) {
          this.setState({
            genVariantsOptions: this.mapVariantsAndGenerationsOptions(data),
            genVariants: this.mapVariantsAndGenerations(data),
          });
        } else {
          this.setState({
            genVariants: [],
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  };

  mapVariantsAndGenerationsOptions = (data) => {
    let options = [];
    let pOptions = [];
    if (data && data.generations && data.variants) {
      data.generations.forEach((gen) => {
        data.variants.forEach((va) => {
          if (gen.id === va.generationId) {
            options.push({
              label: va.name,
              value: va.id,
            });
          }
        });
        pOptions.push({
          label: gen.name,
          options: options,
        });
      });
    }
    return pOptions;
  };

  mapVariantsAndGenerations = (data) => {
    let options = [];
    let pOptions = [];
    if (data && data.generations && data.variants) {
      data.generations.forEach((gen) => {
        data.variants.forEach((va) => {
          if (gen.id === va.generationId) {
            options.push(va);
          }
        });
        pOptions.push({
          label: gen.name,
          options: options,
        });
      });
    }
    return pOptions;
  };

  getVariantsFromGen = (genList = [], varId) => {
    let variants = [];
    genList.forEach((gen) => {
      let index = gen.options.findIndex((x) => x.value === varId);
      if (index >= 0) {
        variants = gen.options;
      }
    });
    return variants;
  };

  getVariantsFromGenObj = (genList = [], varId) => {
    let variants = [];
    genList.forEach((gen) => {
      let index = gen.options.findIndex((x) => x.id === varId);
      if (index >= 0) {
        variants = gen.options;
      }
    });
    return variants;
  };

  handleChange = (e, actionMeta) => {
    if (e && actionMeta && actionMeta.name) {
      const value = e.value;
      const { name } = actionMeta;
      this.setState(
        {
          ...this.state,
          [name]: value,
        },
        () => {
          if (name === "year") {
            this.getAllMakes(value, null);
          }
        }
      );
    }
  };

  getSelectLabel(list = [], value) {
    if (list && list.length) {
      let val = list.filter((x) => x.value === value)[0];
      if (val) {
        return val.label;
      }
    }
  }

  onCarInfoClose = () => {
    const { makeId, modelId, variantId, makes, models, genVariants, category, hideYear } = this.state;
    let { year } = this.state;
    let make,
      model,
      variant = {};
    year = { label: year, value: year };

    make = makeId ? makes.filter((x) => x.id === makeId)[0] : {};
    model = modelId ? models.filter((x) => x.id === modelId)[0] : {};
    variant = variantId ? this.getVariantsFromGenObj(genVariants, variantId).filter((x) => x.id === variantId)[0] : {};

    let carInfo;
    if (hideYear) {
      carInfo = { make, model, variant };
    } else {
      carInfo = { year, make, model, variant };
    }
    this.props.onCarInfoClose(carInfo);
  };

  resetState = (type) => {
    if (type === "years") {
      this.setState({
        // makes: [],
        // models: [],
        // genVariants: [],
        makeId: "",
        modelId: "",
        variantId: "",
      });
    } else if (type === "makes") {
      this.setState({
        // models: [],
        // genVariants: [],
        modelId: "",
        variantId: "",
      });
    } else if (type === "models") {
      this.setState({
        // genVariants: [],
        variantId: "",
      });
    }
  };

  render() {
    const { years, makesOptions, modelsOptions, year, hideYear, makeId, modelId, genVariantsOptions, variantId, category } = this.state;
    return (
      <div className="modal-body mobile-car-info-modal-main pt-4">
        <h4>Select Vehicle for Compare</h4>
        {!hideYear && (
          <div className="form-group">
            {/* <label htmlFor="year">Year</label> */}
            <Select options={years} name="year" placeholder="Select Year" value={year ? { label: year, value: year } : ""} onChange={this.handleChange} />
          </div>
        )}
        <div className="form-group">
          {/* <label htmlFor="make">Make</label> */}
          <Select
            options={makesOptions}
            name="makeId"
            placeholder="Select Make"
            value={makeId ? { label: this.getSelectLabel(makesOptions, makeId), value: makeId } : ""}
            onChange={(e, actionMeta) => {
              this.handleChange(e, actionMeta);
              this.getModelsByMakeIdAndMakeType(e.value);
            }}
          />
        </div>
        <div className="form-group">
          {/* <label htmlFor="year">Model</label> */}
          <Select
            options={modelsOptions}
            name="modelId"
            placeholder="Select Model"
            value={modelId ? { label: this.getSelectLabel(modelsOptions, modelId), value: modelId } : ""}
            onChange={(e, actionMeta) => {
              this.handleChange(e, actionMeta);
              category !== "bike" && this.getVariantsByModelIdAndYear(e.value);
            }}
          />
        </div>
        {category !== "bike" && (
          <div className="form-group">
            {/* <label htmlFor="year">Variant</label> */}
            <Select
              options={genVariantsOptions}
              name="variantId"
              placeholder="Select Variant"
              value={variantId ? { label: this.getSelectLabel(this.getVariantsFromGen(genVariantsOptions, variantId), variantId), value: variantId } : ""}
              onChange={(e, actionMeta) => {
                this.handleChange(e, actionMeta);
              }}
            />
          </div>
        )}
        <div className="carinfo-button">
          <button type="button" className="btn w-100" onClick={this.onCarInfoClose}>
            DONE
          </button>
        </div>
      </div>
    );
  }
}
export default MobileCarInfoModal;
