import Configuration from '../config/configuration';
import axios from 'axios'


class OrderService {

    constructor() {
        this.config = new Configuration();
    }

    postOrder(formData) {
        return axios.post(this.config.apiBaseUrl + 'orders', formData);
    }

    getOrderDetailById(orderId) {
        return axios.get(this.config.apiBaseUrl + 'orders/' + orderId);
    }

    updateOrder(formData) {
        return axios.put(this.config.apiBaseUrl + 'orders', formData);
    }
    stripePaymentIntent(formData) {
        return axios.post(this.config.apiBaseUrl + 'payments/stripeintent', formData);
    }

    getUserOrdersDetailById(userId){
        // const {pageNo, pageSize, userId} = data ;
        // return axios.get(this.config.apiBaseUrl + `history/${userId}?page=${pageNo}&pageSize=${pageSize}`);
        return axios.get(this.config.apiBaseUrl + `orders/history/${userId}`);

    }

    handleError(error) {
        console.log(error.message);
    }


}

export default OrderService;