import React, { Component } from "react";
import "./NewAlerts.scss";
import RightMenu from "../RightMenu/RightMenu";
import { GrLocation } from "react-icons/gr";
import { AiOutlineEye } from "react-icons/ai";
import { FiPhone, FiCheck, FiStar } from "react-icons/fi";
import { BiPencil, BiImages } from "react-icons/bi";
import AdsService from "../../../services/ads-service";
import LocalStorageService from "../../../services/localStore-service";
import CommonService from "../../../services/common-service";
import Paginate from "../../Shared/Paginate/Paginate";
import { ReactSVG } from "react-svg";

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.adsService = new AdsService();
    this.localService = new LocalStorageService();
    this.commonService = new CommonService();
    this.state = {
      alerts: [],
      hasData: false,
      userId: 0,
      pageNo: 0,
      pageSize: 12,
      totalPages: 1,
      currrentStatus: "Car",
      category: "car",
      options: [
        {
          title: "Car",
          // count: 0,
          active: true,
          items: [],
        },
        {
          title: "Bike",
          // count: 0,
          active: false,
          items: [],
        },
        {
          title: "Accessories",
          // count: 0,
          active: false,
          items: [],
        },
      ],
    };
  }

  componentDidMount() {
    this.setState(
      {
        userId: this.localService.getUserId(),
      },
      () => {
        this.getAdsAlerts();
      }
    );
  }

  getAdsAlerts() {
    const { userId, category, pageNo, pageSize } = this.state;
    this.adsService
      .getUserSubscribedAdsAlerts(userId, category, pageNo, pageSize)
      .then((res) => {
        const { data } = res;
        if (data && data.records && data.records.length) {
          this.setState({
            alerts: data.records,
            totalPages: data.totalPages,
            hasData: true,
          });
        } else {
          this.setState({
            alerts: [],
            hasData: false,
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  }

  getAlertsByCategory = () => {
    let { category, currrentCategory } = this.state;
    if (currrentCategory === "Car") {
      category = "car";
    } else if (currrentCategory === "Bike") {
      category = "bike";
    } else {
      category = "part";
    }
    this.setState(
      {
        category,
      },
      () => {
        this.getAdsAlerts();
      }
    );
  };

  deleteAnAlert = (id) => {
    this.adsService
      .deleteUserSubscribedAdsAlerts(id)
      .then((res) => {
        const { data } = res;
        if (data && data.message === "History deleted successfully") {
          this.commonService.showToast("Alert Subscription deleted successfully", "success");
          this.getAdsAlerts();
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
        this.commonService.showToast("Something went wrong", "error");
      });
  };

  handlePageClick = ({ selected }) => {
    this.setState(
      {
        pageNo: selected ? selected : 0,
      },
      () => {
        this.getAdsAlerts();
      }
    );
  };

  selectLeftMenuHandler = (option, options, index) => {
    this.setState(
      {
        options,
        currrentCategory: option.title,
      },
      () => {
        this.getAlertsByCategory();
      }
    );
  };

  render() {
    const { options, alerts, hasData, totalPages } = this.state;
    return (
      <div id="alerts">
        <div className="row mt-3">
          {window.innerWidth < 500 ? <div className="col-sm-4">{options && <RightMenu options={options} onSelect={this.selectLeftMenuHandler} />}</div> : ""}
          <div className="col-sm-8">
            {alerts &&
              alerts.length > 0 &&
              alerts.map((alert) => {
                const { ad } = alert.alerts;

                return (
                  <div className="card p-0 mb-3" style={{ borderRadius: "10px" }}>
                    <div className="main-img">
                      <div>
                        <img loading="lazy" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }} src={ad.images && ad.images[0] && ad.images[0].url} />
                      </div>
                      <div className="img-count">
                        <BiImages /> {ad.images && ad.images.length}
                      </div>
                      {ad.isFeatured ? (
                        <span className="product-horizontal-label">
                          <span>
                            <FiStar /> Featured
                          </span>
                          {/* <i class="fa fa-exclamation-circle"></i> */}
                          {/* <img loading="lazy" src={require('../../../assets/Images/featured/isfeature.png')} alt="used" /> */}
                        </span>
                      ) : null}
                    </div>
                    <div className="p-3 ad-car-details mt-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h3 className="text-dark">{ad.title}</h3>
                          <h2>
                            <span className="product-horizontal-price">{this.commonService.getPriceIntoWords(ad.price)}</span>
                          </h2>
                          <p>
                            <GrLocation /> {ad.city}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between flex-md-row flex-column">
                          {ad.category !== "part" && (
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                {ad.category === "car" && (
                                  <p className="mb-0 d-flex pr-4">
                                    <ReactSVG
                                      src={require("../../../assets/Images/productDetail/gearshiftprimary.svg")}
                                      beforeInjection={(svg) => {
                                        svg.classList.add("svg-class-name");
                                        svg.setAttribute("style", "width: 14px; height: 100%");
                                      }}
                                    />{" "}
                                    &nbsp;
                                    {ad.transmission}
                                  </p>
                                )}
                                <p className="mb-0  d-flex pr-4">
                                  <ReactSVG
                                    src={require("../../../assets/Images/productDetail/fuelprimary.svg")}
                                    beforeInjection={(svg) => {
                                      svg.classList.add("svg-class-name");
                                      svg.setAttribute("style", "width: 14px; height: 100%");
                                    }}
                                  />{" "}
                                  &nbsp;
                                  {ad.engineType}
                                </p>
                                <p className="mb-0 d-flex pr-4">
                                  <ReactSVG
                                    src={require("../../../assets/Images/productDetail/engineprimary.svg")}
                                    beforeInjection={(svg) => {
                                      svg.classList.add("svg-class-name");
                                      svg.setAttribute("style", "width: 14px; height: 100%");
                                    }}
                                  />{" "}
                                  &nbsp;
                                  {ad.engineCapacity}CC
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <button className="btn adp-btn">Boost Your Ad</button> */}
                      </div>
                      {hasData && (
                        <div className="alerts-buttons mt-3">
                          <button type="button" style={{ fontSize: "0.9rem" }} className="btn reset w-100 btn-custom" onClick={() => this.deleteAnAlert(alert.id)}>
                            <i className="fas fa-trash"></i> Remove
                          </button>
                          {/* <button type="button" className="btn add-alert"><i className="far fa-bell"></i>Add Alert</button> */}
                        </div>
                      )}

                      {/* <small className="text-muted mt-2">Last Update 9 Month ago</small> */}
                    </div>
                  </div>
                );
              })}

            <div className="listing-loadmore">
              {/* <button type="button" className="btn">LOAD MORE</button> */}
              {hasData === true ? (
                <Paginate totalPages={totalPages} onPageChange={this.handlePageClick} />
              ) : hasData === false ? (
                <h5 className="text-center pt-4">No Record Found!</h5>
              ) : (
                <h5 className="text-center pt-4">Loading...</h5>
              )}
            </div>
          </div>

          <div className="col-sm-4 web-view">{options && <RightMenu options={options} onSelect={this.selectLeftMenuHandler} />}</div>
        </div>
      </div>
    );
  }
}

export default Alerts;
