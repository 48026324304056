import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./Loader.scss";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import TopBarProgress from "react-topbar-progress-indicator";
TopBarProgress.config({
  barColors: {
    0: "#ee7d33",
    "1.0": "#ee7d33",
  },
  shadowBlur: 5,
});

const Loader = (props) => {
  // let [percent, setPercent] = useState(20);

  // setTimeout(() => {
  //     setPercent(props.percentCompleted)
  // }, 50);

  return (
    props.loading && <TopBarProgress />
    // <div className="loader-overlay">
    //     {/* <div className="progress">
    //         <div className="progress-bar" role="progressbar" style={{ "width": `${percent}%` }} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100">
    //         </div>
    //     </div> */}
    //     <ReactSVG src={require('../../../assets/Images/moving-gear.svg')} beforeInjection={svg => {
    //         svg.classList.add('svg-class-name')
    //         // svg.setAttribute('style', 'width: 14px; height: 100%')
    //     }} />
    //     {/* <ScaleLoader color={'var(--color-secondary)'} sizeUnit={"px"} size={300} /> */}
    // </div>: ''
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading ? state.loading.isLoading : false,
    percentCompleted: state.loading ? state.loading.percentCompleted : 0,
  };
};

export default connect(mapStateToProps)(Loader);
