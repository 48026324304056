import React, { Component } from "react";
import "./ReviewModal.scss";
import StarRatings from "react-star-ratings";
import CommonService from "../../../services/common-service";
import UserService from "../../../services/user-service";
import LocalStoreService from "../../../services/localStore-service";
class ReviewModal extends Component {
  constructor() {
    super();
    this.userService = new UserService();
    this.commonService = new CommonService();
    this.localService = new LocalStoreService();
    this.state = {
      title: "",
      review: "",
      dealerId: 0,
      reviewerId: this.localService.getUserId(),
      buyingProcess: 0,
      vehicleSelection: 0,
      levelOfServices: 0,
      refs: {},
      status: 0,
      errors: {
        title: "",
        review: "",
        buyingProcess: "",
        vehicleSelection: "",
        levelOfServices: "",
      },
    };
  }

  changeRating = (newRating) => {
    this.setState({
      rating: newRating,
    });
  };
  componentDidMount() {
    if (this.props.dealerObj) {
      this.setState({
        dealerId: this.props.dealerObj.userId,
      });
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.checkFormErrors(name, value);
    this.setState({
      [name]: value,
    });
  };

  submitReview = () => {
    const { title, review, buyingProcess, vehicleSelection, levelOfServices, dealerId, reviewerId } = this.state;
    const formData = { title, review, buyingProcess, vehicleSelection, levelOfServices, dealerId, reviewerId };
    if (this.isValidForm(formData, this.state.errors)) {
      this.userService
        .addDealerReview(formData)
        .then((res) => {
          const { message } = res.data;
          if (message === "Dealer review added successfully") {
            this.commonService.showToast("Your Review submitted successfully!", "success");
            this.onClosed();
          } else {
            this.commonService.showToast("Review not submitted!", "error");
          }
        })
        .catch((err) => {
          this.commonService.showToast("Something went wrong!", "error");
          this.userService.handleError(err);
        });
    }
  };

  isValidForm(formData, errors) {
    for (let key in formData) {
      this.checkFormErrors(key, formData[key]);
    }
    this.focusOnError(errors);
    return this.commonService.checkFormValidity(this.state.errors);
  }

  focusOnError = (errors) => {
    if (errors) {
      for (let key in errors) {
        if (errors[key]) {
          if (this.state.refs[key]) {
            this.state.refs[key].focus();
            break;
          }
        }
      }
    }
  };
  onClosed = () => {
    this.props.onReviewModalClose();
  };

  checkFormErrors = (name, value) => {
    let errors = this.state.errors;
    switch (name) {
      case "title":
        errors.title = value === "" ? "Title is required!" : "";
        break;
      case "review":
        errors.review = value === "" ? "Review is required!" : "";
        break;
      case "buyingProcess":
        errors.buyingProcess = +value === 0 ? "Required!" : "";
        break;
      case "vehicleSelection":
        errors.vehicleSelection = +value === 0 ? "Required!" : "";
        break;
      case "levelOfServices":
        errors.levelOfServices = +value === 0 ? "Required!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors });
  };

  render() {
    const { buyingProcess, vehicleSelection, levelOfServices, errors } = this.state;
    return (
      <div className="review-modal-main">
        <div className="modal-headings">
          <h2>Write Review</h2>
          <p>All fields marked with * are mandatory</p>
        </div>
        <div className="row review-process">
          <div className="col-md-4">
            <span className="mb-3">Buying Process</span>
            <StarRatings
              name="buyingProcess"
              rating={buyingProcess}
              starRatedColor="var(--color-secondary)"
              starDimension="35px"
              starSpacing="5px"
              changeRating={(e, name) => this.handleChange({ target: { name: name, value: e } })}
              numberOfStars={5}
            />
            {errors.buyingProcess && <div className="invalid-feedback d-block">{errors.buyingProcess}</div>}
          </div>
          <div className="col-md-4">
            <span className="my-3"> Vehicle Selection</span>
            <StarRatings
              name="vehicleSelection"
              rating={vehicleSelection}
              starRatedColor="var(--color-secondary)"
              starDimension="35px"
              starSpacing="5px"
              changeRating={(e, name) => this.handleChange({ target: { name: name, value: e } })}
              numberOfStars={5}
            />
            {errors.vehicleSelection && <div className="invalid-feedback d-block">{errors.vehicleSelection}</div>}
          </div>
          <div className="col-md-4">
            <span className="my-3">Level of Services</span>
            <StarRatings
              name="levelOfServices"
              rating={levelOfServices}
              starRatedColor="var(--color-secondary)"
              starDimension="35px"
              starSpacing="5px"
              changeRating={(e, name) => this.handleChange({ target: { name: name, value: e } })}
              numberOfStars={5}
            />
            {errors.levelOfServices && <div className="invalid-feedback d-block">{errors.levelOfServices}</div>}
          </div>
        </div>
        <div className="review-form">
          <div className="review-input">
            <div className="review-form-left">
              <div className="form-group">
                <label htmlFor="registerationCity">Review Title*</label>
                <input type="text" className="form-control" name="title" placeholder="Title" onChange={this.handleChange} />
                {errors.title && <div className="invalid-feedback d-block">{errors.title}</div>}
              </div>
            </div>
            {/* <div className="review-form-right">
                            <div className="form-group">
                                <label htmlFor="registerationCity">Full Name*</label>
                                <input type="text" className="form-control" name="fullName" placeholder="Name" onChange={this.handleChange} />
                            </div>
                        </div> */}
          </div>
          <div className="review-textarea">
            <label htmlFor="registerationCity">Your Review*</label>
            <textarea className="form-control" name="review" rows="11" placeholder="Review..." onChange={this.handleChange}></textarea>
            {errors.review && <div className="invalid-feedback d-block">{errors.review}</div>}
          </div>

          <div className="review-submit">
            <button type="button" className={`btn submit ${window.innerWidth > 480 ? "" : "w-100"}`} onClick={this.submitReview}>
              SUBMIT
            </button>
            <button type="button" className={`btn cancel ${window.innerWidth > 480 ? "" : "w-100"}`} onClick={() => this.onClosed()}>
              CANCEL
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewModal;
