import React, { Component } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import "./CategoryTabs.scss";

class CategoryTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  componentDidMount() {
    this.setState({
      activeTab: this.props.tab,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tab !== this.props.tab) {
      this.setState({
        activeTab: this.props.tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;

    return (
      <div className="ads-category-tabs">
        <Nav tabs className="nav-tabs">
          <NavItem className="tab-item tab-items-1">
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                this.props.onToggleCatergoy("1");
              }}
            >
              Vehicles
            </NavLink>
          </NavItem>
          <NavItem className="tab-item tab-items-1">
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                this.props.onToggleCatergoy("2");
              }}
            >
              Auto Parts
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default CategoryTabs;
