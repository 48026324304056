const colorOptions = [
    { value: "White", label: "White", color: "#FFFFFF" },
    { value: "Silver", label: "Silver", color: "#C0C0C0" },
    { value: "Black", label: "Black", color: "#000000" },
    { value: "Grey", label: "Grey", color: "#808080" },
    { value: "Blue", label: "Blue", color: "#0000FF" },
    { value: "Green", label: "Green", color: "#008000" },
    { value: "Red", label: "Red", color: "#FF0000" },
    { value: "Gold", label: "Gold", color: "#FFD700" },
    { value: "Maroon", label: "Maroon", color: "#800000" },
    { value: "Beige", label: "Beige", color: "#F5F5DC" },
    { value: "Pink", label: "Pink", color: "#FFC0CB" },
    { value: "Brown", label: "Brown", color: "#A52A2A" },
    { value: "Burgundy", label: "Burgundy", color: "#800020" },
    { value: "Yellow", label: "Yellow", color: "#FFFF00" },
    { value: "Bronze", label: "Bronze", color: "#CD7F32" },
    { value: "Purple", label: "Purple", color: "#800080" },
    { value: "Turquoise", label: "Turquoise", color: "#40E0D0" },
    { value: "Orange", label: "Orange", color: "#FFA500" },
    { value: "Indigo", label: "Indigo", color: "#4B0082" },
    { value: "Magenta", label: "Magenta", color: "#FF00FF" },
    { value: "Navy", label: "Navy", color: "#000080" }
];

export default colorOptions;