import React, { Suspense, Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import "./HomeBanner.scss";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import AdvanceSearch from "./AdvanceSearch/AdvanceSearch";
import { data } from "../../../assets/data/index";
import StyleConfig from "../../../config/styleConfig";
import Select from "react-select";
import Autosuggest from "react-autosuggest";
import AdsService from "../../../services/ads-service";
import CommonService from "../../../services/common-service";
import { setCategory } from "../../../store/actions/category";
const AuctionSheet = React.lazy(() => import("./Tabs/AuctionSheet/AuctionSheet.js"));

class HomeBanner extends Component {
  constructor(props) {
    super();
    this.adsService = new AdsService();
    this.styleConfig = new StyleConfig();
    this.commonService = new CommonService();
    this.state = {
      limits: {},
      cities: [],
      prices: [],
      activeTab: "1",
      showAdvanceSearch: false,
      city: "",
      searchStr: "",
      minPrice: 0,
      maxPrice: 0,
      filterQuery: {},
      query: "",
      value: "",
      suggestions: [],
      categoriesOptions: [],
      subCategoriesOptions: [],
      category: "",
      subCategory: "",
      showTab: "1",
    };
  }

  componentDidMount() {
    this.getCities();
    this.getAdsLimits(this.getCategory(this.state.activeTab));
  }

  getAdsLimits = (category) => {
    this.adsService
      .getAdsLimits(category)
      .then((res) => {
        if (res.data && res.data.limits) {
          const { price } = res.data.limits;
          let priceLimit = category === "car" ? price.max : category === "bike" ? 15000000 : 500000;
          let interval = category === "car" ? 500000 : category === "bike" ? 5000 : 1000;
          let min = category === "car" ? 100000 : category === "bike" ? 5000 : 100;
          this.setState({
            limits: data.limits,
            prices: data.prices.getPriceRangesFilter(min, interval + priceLimit, interval),
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  };

  getPartsCategories = (selectedItem = null) => {
    this.adsService
      .getPartsCategories()
      .then((res) => {
        const { data } = res;
        let categories = [];
        if (data && data.length) {
          data.forEach((category) => {
            categories.push({
              label: category.name,
              value: category.id,
            });
          });
          this.setState({
            categoriesOptions: categories,
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  };

  getPartsSubCategories = (id) => {
    this.adsService
      .getPartsSubCategories(id)
      .then((res) => {
        const { data } = res;
        let categories = [];
        if (data && data.length) {
          data.forEach((category) => {
            categories.push({
              label: category.name,
              value: category.id,
            });
          });
          this.setState({
            subCategoriesOptions: categories,
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  };

  handleChange = (e, actionMeta) => {
    if (e.target) {
      const { value, name, type, checked } = e.target;
      this.setState(
        {
          ...this.state,
          [name]: type === "checkbox" ? checked : value,
        },
        () => {
          this.setFilterQuery();
        }
      );
    } else if (actionMeta && actionMeta.name) {
      const value = e.value;
      const { name } = actionMeta;
      if (name === "category") {
        this.getPartsSubCategories(value);
      }
      this.setState(
        {
          ...this.state,
          [name]: value,
        },
        () => {
          this.setFilterQuery();
        }
      );
    }
  };

  getCities() {
    let cities = [];
    data.pakCities.forEach((city) => {
      cities.push({ label: city.name, value: city.name });
    });
    this.setState({ cities });
  }

  advanceSearchHandler = (query) => {
    // const { color, bodyType, transmission, engineType, assembly, engineCapacity, pictureAvail, sellerType,
    //   adType, minYear, maxYear, minMil, maxMil, minEC, maxEC, registerationCity } = query;
    this.setState(
      {
        filterQuery: { ...query },
      },
      () => this.setFilterQuery()
    );
  };

  setFilterQuery() {
    // const { color, bodyType, transmission, engineType, assembly, pictureAvail, sellerType,
    //   adType, minYear, maxYear, minMil, maxMil, minEC, maxEC, registerationCity } = this.state.filterQuery;
    const { searchStr, minPrice, maxPrice, city, filterQuery, category, subCategory } = this.state;
    const selectedCategories = this.state.categoriesOptions.filter((x) => x.value === category)[0];
    const selectedSubCategories = this.state.subCategoriesOptions.filter((x) => x.value === subCategory)[0];
    let selectedPCats, selectedPSubCats;
    if (category) {
      selectedPCats = this.getCatWithArr(selectedCategories);
    }
    if (subCategory) {
      selectedPSubCats = this.getCatWithArr(selectedSubCategories);
    }

    this.setState({
      query: this.commonService.getQuery({
        ...filterQuery,
        searchStr,
        minPrice,
        maxPrice,
        selectedPCats,
        selectedPSubCats,
        selectedCities: city ? [city] : "",
      }),
    });
  }

  getCatWithArr = (selectedCategories) => {
    let categories;
    categories = [
      {
        name: selectedCategories.label,
        id: selectedCategories.value,
      },
    ];

    return categories;
  };

  getRanges = (min, max, items) => {
    return items.filter((x) => x.value >= min);
  };

  getCategory = (activeTab) => {
    if (activeTab === "1") {
      return "car";
    } else if (activeTab === "2") {
      return "bike";
    } else if (activeTab === "3") {
      return "part";
    }
  };

  goToFilteredUrl = () => {
    const { activeTab, query } = this.state;
    let url = "";
    if (this.getCategory(activeTab) !== "part") {
      url = `/used-${this.getCategory(activeTab)}s${query}`;
    } else {
      url = `/accessories-spare-parts${query}`;
    }
    this.props.history.push(url);
  };

  toggle = (tab, tabId) => {
    if (tab === "3") {
      this.getPartsCategories();
      this.getPartsSubCategories();
    }

    if (this.state.activeTab !== tab) {
      this.getAdsLimits(this.getCategory(tab));
      this.setState({ activeTab: tab });
      this.setState({ showTab: tabId });
    }
    if (this.state.showAdvanceSearch) {
      this.toggleAdvanceSearch();
    }
  };

  toggleAdvanceSearch() {
    this.setState({
      showAdvanceSearch: !this.state.showAdvanceSearch,
    });
  }

  getAdsSearchSuggestions = (searchStr) => {
    const { activeTab } = this.state;
    this.adsService
      .getAdsSearchSuggestions(this.getCategory(activeTab), searchStr)
      .then((res) => {
        const { data } = res;
        if (data && data.length) {
          this.setState({
            suggestions: data,
          });
        } else {
          this.setState({
            suggestions: [],
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
        this.setState({
          suggestions: [],
        });
      });
  };

  onChangeSuggestion = (event, { newValue }) => {
    this.setState(
      {
        searchStr: newValue,
      },
      () => {
        this.setFilterQuery();
      }
    );
  };
  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => {
    return suggestion.title;
  };
  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => {
    return <div className="title">{suggestion.title}</div>;
  };
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.getAdsSearchSuggestions(value);
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSelectLabel(list = [], value) {
    if (list && list.length) {
      let val = list.filter((x) => x.value === value)[0];
      if (val) {
        return val.label;
      }
    }
  }

  reset = () => {
    this.setState({
      searchStr: "",
      category: "",
      subCategory: "",
      city: "",
      minPrice: "",
      maxPrice: "",
    });
  };

  render() {
    const { cities, prices, city, minPrice, maxPrice, query, searchStr, activeTab, showTab, filterQuery, suggestions, categoriesOptions, subCategoriesOptions, category, subCategory } = this.state;
    const inputProps = {
      placeholder: "Type Search Keyword",
      value: searchStr,
      onChange: this.onChangeSuggestion,
    };
    return (
      <>
        {/* commented all by UM */}
        {/* <div className="Homepage">
          <div className="jumbotron ">
            <div className="container">
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-12 pl-4">
                  <Suspense fallback={<div style={{ minHeight: "450px", backgroundColor: "rgba(0,0,0,0.25)", borderRadius: "20px" }}></div>}>
                    <div className="card main-card">
                      <Nav tabs className="d-flex flex-row">
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "1" })}
                            onClick={() => {
                              this.toggle("1", "1");
                              this.props.setCategory("car");
                            }}
                          >
                            <i className="fas fa-car"></i> Cars
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "2" })}
                            onClick={() => {
                              this.toggle("2", "1");
                              this.props.setCategory("bike");
                            }}
                          >
                            <img loading="lazy" width="20" src={require("../../../assets/Images/motorbike.png")} className="bikes" /> Bikes
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "3" })}
                            onClick={() => {
                              this.toggle("3", "1");
                              this.props.setCategory("parts");
                            }}
                          >
                            <img loading="lazy" width="20" src={require("../../../assets/Images/car-parts.png")} className="bikes" /> Parts
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "4" })}
                            onClick={() => {
                              this.toggle("4", "4");
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12zM10 9h8v2h-8zm0 3h4v2h-4zm0-6h8v2h-8z"
                              />
                            </svg>{" "}
                            Auction Sheet
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={showTab}>
                        <TabPane tabId="1">
                          <div className="d-flex justify-content-center mt-3">
                            <h3>
                              Find Your Perfect <span className="text-capitalize">{this.getCategory(activeTab)}s</span>
                            </h3>
                          </div>
                          <div className="input-group search-inputs">
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              inputProps={inputProps}
                            />
                            <div className="all-cities">
                              <Select placeholder="All Cities" name="city" options={cities} value={city ? { label: city, value: city } : ""} onChange={this.handleChange} />
                            </div>
                            {activeTab === "3" ? (
                              <div className="">
                                <Select
                                  options={categoriesOptions}
                                  name="category"
                                  placeholder="Category"
                                  value={
                                    category
                                      ? {
                                          label: this.getSelectLabel(categoriesOptions, category),
                                          value: category,
                                        }
                                      : ""
                                  }
                                  onChange={this.handleChange}
                                />
                                <Select
                                  options={subCategoriesOptions}
                                  name="subCategory"
                                  placeholder="Sub Category"
                                  value={
                                    subCategory
                                      ? {
                                          label: this.getSelectLabel(subCategoriesOptions, subCategory),
                                          value: subCategory,
                                        }
                                      : ""
                                  }
                                  onChange={this.handleChange}
                                />
                              </div>
                            ) : (
                              <div className=" min-max">
                                <Select
                                  className="my-3"
                                  placeholder="Min Price"
                                  options={prices}
                                  name="minPrice"
                                  value={
                                    minPrice
                                      ? {
                                          label: this.getSelectLabel(prices, minPrice),
                                          value: minPrice,
                                        }
                                      : ""
                                  }
                                  onChange={this.handleChange}
                                />
                                <Select
                                  className="my-3"
                                  placeholder="Max Price"
                                  options={this.getRanges(minPrice, maxPrice, prices)}
                                  name="maxPrice"
                                  value={
                                    maxPrice
                                      ? {
                                          label: this.getSelectLabel(prices, maxPrice),
                                          value: maxPrice,
                                        }
                                      : ""
                                  }
                                  onChange={this.handleChange}
                                />
                              </div>
                            )}

                            <div className="input-group-prepend">
                              <button onClick={this.goToFilteredUrl} className="btn custom-btn mb-4" style={{ borderRadius: "10px" }}>
                                Search
                              </button>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="4" className="tabs-space" style={{ height: "380px" }}>
                          <div className="aligns-elf-center">
                            <h3 className="text-center mt-3">Find Auction Sheet</h3>
                            <AuctionSheet />
                          </div>
                        </TabPane>
                        <div className="container mt-n3 mb-3 px-4">
                          <div className="d-flex justify-content-between">
                            <Link to={"/used-cars/search"} className="text-dark text-decoration-none">
                              <span className="border-bottom border-dark">Advanced Search</span>
                            </Link>
                            <p style={{ cursor: "pointer" }} onClick={this.reset} className="border-bottom border-dark mb-0">
                              Reset Filters
                            </p>
                          </div>
                        </div>
                      </TabContent>
                    </div>
                  </Suspense>
                </div>
                <div className="col-xl-7 col-lg-6 col-12 pl-5 heading">
                  {activeTab == 4 ? (
                    <>
                      <h1 className="mb-2">Auction Sheet Verification</h1>
                      <p>Japan’s first auction sheet provider with 100% authenticity.</p>
                    </>
                  ) : (
                    <>
                      <h1 className="mb-2">
                        Find Best Used  <span className="text-capitalize"> {this.getCategory(activeTab)}s </span> in Pakistan
                      </h1>
                      <p>
                        Thousands of new <span> {this.getCategory(activeTab)}s </span>, used <span> {this.getCategory(activeTab)}s </span> for sale in Pakistan and we have right one for you
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="Homepage">
          <div className="jumbotron ">
            <div className="container">
            <div className="homepage-form pb-5">
          <div className="">
            <div className="text-white mb-5">
              <h1 className="mb-2 text-center">Find Best Used Cars in Pakistan</h1>
              <div className="text-center">
                <p>Thousands of new cars , used cars for sale in Pakistan and we have right one for you</p>
              </div>
            </div>
            <div className="horizontal-form-box1 horizontal-form-box mx-auto">
              <Nav tabs className="d-flex flex-row outer-nav">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      this.toggle("1", "1");
                      this.props.setCategory("car");
                    }}
                  >
                    Find Used Car
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      this.toggle("2", "2");
                      this.props.setCategory("bike");
                    }}
                  >
                    Find Used Bikes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      this.toggle("3", "3");
                      this.props.setCategory("parts");
                    }}
                  >
                    Find Used Parts
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      this.toggle("4", "4");
                    }}
                  >
                    Auction Sheet
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={showTab} className="outer-content">
                <TabPane tabId="1">
                  <div className="row mx-0">
                    <div className="col-md-4 px-1 mb-md-0 mb-3">
                      <div className="search-inputs">
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          inputProps={inputProps}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 px-1 mb-md-0 mb-3">
                      <div className="all-cities">
                        <Select placeholder="All Cities" name="city" options={cities} value={city ? { label: city, value: city } : ""} onChange={this.handleChange} />
                      </div>
                    </div>

                    <div className="col-md-4 px-1">
                      <button onClick={this.goToFilteredUrl} className="adp-btn adp-btn-secondary w-100">Search</button>
                    </div>
                  </div>
                  <div className="mt-3 px-4">
                    <div className="d-flex justify-content-between">
                      <Link to={"/used-cars/search"} className="text-dark text-decoration-none">
                        <span className="border-bottom border-dark">Advanced Search</span>
                      </Link>
                      <p style={{ cursor: "pointer" }} onClick={this.reset} className="border-bottom border-dark mb-0">
                        Reset Filters
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="row mx-0">
                    <div className="col-md-4 px-1 mb-md-0 mb-3">
                      <div className="search-inputs">
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          inputProps={inputProps}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 px-1 mb-md-0 mb-3">
                      <div className="all-cities">
                        <Select placeholder="All Cities" name="city" options={cities} value={city ? { label: city, value: city } : ""} onChange={this.handleChange} />
                      </div>
                    </div>

                    <div className="col-md-4 px-1">
                      <button onClick={this.goToFilteredUrl} className="adp-btn adp-btn-secondary w-100">Search</button>
                    </div>
                  </div>
                  <div className="mt-3 px-4">
                    <div className="d-flex justify-content-between">
                      <Link to={"/used-cars/search"} className="text-dark text-decoration-none">
                        <span className="border-bottom border-dark">Advanced Search</span>
                      </Link>
                      <p style={{ cursor: "pointer" }} onClick={this.reset} className="border-bottom border-dark mb-0">
                        Reset Filters
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="row mx-0">
                    <div className="col-md-4 px-1 mb-md-0 mb-3">
                      <div className="search-inputs">
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          inputProps={inputProps}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 px-1 mb-md-0 mb-3">
                      <div className="all-cities">
                        <Select placeholder="All Cities" name="city" options={cities} value={city ? { label: city, value: city } : ""} onChange={this.handleChange} />
                      </div>
                    </div>

                    <div className="col-md-4 px-1">
                      <button onClick={this.goToFilteredUrl} className="adp-btn adp-btn-secondary w-100">Search</button>
                    </div>
                  </div>
                  <div className="mt-3 px-4">
                    <div className="d-flex justify-content-between">
                      <Link to={"/used-cars/search"} className="text-dark text-decoration-none">
                        <span className="border-bottom border-dark">Advanced Search</span>
                      </Link>
                      <p style={{ cursor: "pointer" }} onClick={this.reset} className="border-bottom border-dark mb-0">
                        Reset Filters
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <AuctionSheet />
                </TabPane>
              </TabContent>

            </div>
          </div>
        </div>
            </div>
          </div>
        </div>
        
      </>
    );
  }
}

export default withRouter(HomeBanner);
