import Configuration from "../config/configuration";

class LocalStoreService {
  constructor() {
    this.config = new Configuration();
  }

  saveUserDataToLocal(data, rememberMe) {
    localStorage.setItem("token", data.token);
    localStorage.setItem("refreshToken", data.refreshToken);
    localStorage.setItem("rememberMe", rememberMe);
    delete data.token;
    delete data.refreshToken;
    localStorage.setItem("userData", JSON.stringify(data));
  }

  saveUserProfile(data) {
    let user = this.getUserData() ? JSON.parse(this.getUserData()) : {};
    user.profile = data;
    localStorage.setItem("userData", JSON.stringify(user));
  }

  setTokenOnly(tokenObj) {
    localStorage.setItem("token", tokenObj.token);
  }

  clearStorage() {
    localStorage.clear();
  }

  removeItem(item) {
    localStorage.removeItem(item);
  }

  getUserToken() {
    return localStorage.getItem("token");
  }

  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  getRememberMe() {
    return localStorage.getItem("rememberMe");
  }

  getUserData() {
    return localStorage.getItem("userData");
  }

  getUserProfile() {
    let userData = localStorage.getItem("userData");
    let userProfile = null;
    if (userData) {
      userProfile = JSON.parse(userData).profile;
    } else {
      userProfile = null;
    }
    return userProfile;
  }

  getUserId() {
    let userId = "";
    if (localStorage.getItem("userData")) {
      let data = JSON.parse(localStorage.getItem("userData"));
      userId = data.profile.userId;
    }
    return userId;
  }

  getCartData() {
    let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];
    return cart;
  }

  setLoginRedirection(path) {
    if (path) {
      localStorage.setItem("loginAfterPath", path);
    }
  }
  getLoginRedirection() {
    return localStorage.getItem("loginAfterPath");
  }
}

export default LocalStoreService;
