import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./ListingHorizontal.scss";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import CommonService from "../../../../services/common-service";
import LocalStoreService from "../../../../services/localStore-service";
import AdsService from "../../../../services/ads-service";
import UserService from "../../../../services/user-service";
import { GrLocation } from "react-icons/gr";
import { AiOutlineStar } from "react-icons/ai";

class ListingHorizontal extends Component {
  constructor() {
    super();
    this.commonService = new CommonService();
    this.localService = new LocalStoreService();
    this.adsService = new AdsService();
    this.userService = new UserService();

    this.state = {
      part: {},
      className: "",
      isAuth: false,
      userId: 0,
      callFrom: "",
      addClass: false,
    };
  }

  componentDidMount() {
    this.setState({
      isAuth: this.checkAuth(),
      userId: this.localService.getUserId(),
      part: this.props.data ? this.props.data : {},
      callFrom: this.props.callFrom,
    });
  }

  componentDidUpdate(prevProp) {
    if (prevProp.data !== this.props.data) {
      this.setState({
        part: this.props.data ? this.props.data : {},
      });
    }
  }
  handleDelete = (part) => {
    this.props.onDelete(part);
  };

  handleEdit = (part) => {
    this.props.onEdit(part);
  };

  checkAuth = () => {
    const token = this.localService.getUserToken();
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  handleFavourite = (product, type) => {
    const { userId } = this.state;
    if (userId) {
      if (type === "addFav") {
        this.addFavourite(product);
      } else if (type === "removeFav") {
        this.removeFavourite(product);
        // this.props.onFavourite(product, 'removeFav');
      }
    }
  };

  checkFavourite = (part) => {
    if (part && part.favourites && part.favourites.length) {
      let isFavourite = false;
      part.favourites.forEach((fav) => {
        if (fav.user_favourite_ads) {
          const { userId } = fav.user_favourite_ads;
          const loginUserId = this.state.userId;
          if (userId === loginUserId) {
            isFavourite = true;
          }
        }
      });
      return isFavourite;
    }
  };

  goToFeaturedSubscription = () => {
    const { part } = this.state;
    this.props.history.push({ pathname: `/boost-ad/`, state: { adId: part.id } });
  };

  addFavourite = (part) => {
    const { userId } = this.state;
    let formData = { userId, adId: part.id };
    this.adsService
      .addFavouriteAd(formData)
      .then((res) => {
        const { message } = res.data;
        if (message === "Favourite added successfully") {
          this.commonService.showToast("Favourite added successfully", "success");
          this.props.onFavourite();
        } else {
          this.commonService.showToast("Favourite not added!", "error");
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  removeFavourite = (part) => {
    const { userId } = this.state;
    let formData = { userId, adId: part.id };
    this.adsService
      .removeFavouriteAd(formData)
      .then((res) => {
        const { message } = res.data;
        if (message === "Favourite removed successfully") {
          this.commonService.showToast("Favourite removed successfully", "success");
          debugger;
          this.props.onFavourite();
        } else {
          this.commonService.showToast("Favourite not added!", "error");
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  getDiscount = (part) => {
    return Math.round(((+part.regularPrice - +part.salePrice) / part.regularPrice) * 100) >= 1 ? (
      <div className="discount-horizontal">
        <div className="discount-percentage">
          <p className="mb-0 d-flex justify-content-center">
            <p className="price-off">{Math.round(((+part.regularPrice - +part.salePrice) / part.regularPrice) * 100)}%</p>
            <small className="price-discount">Off</small>
          </p>
        </div>
      </div>
    ) : (
      <span></span>
    );
  };

  activateAd = (part) => {
    this.props.onActivateAd(part);
  };

  render() {
    const { part, isAuth, callFrom, addClass, userId } = this.state;
    const images = part && part.images && part.images.length > 0 ? part.images : "";

    return (
      <div className="listing-horizontal mb-3">
        <div className="card">
          <div className="row">
            <div className="col-sm-3 col-5 pr-0 img-labels">
              <Link to={{ pathname: `/accessories-spare-parts/detail/${part && part.title ? part.title.toLowerCase().trim().replace(/\s/g, "-") : ""}-${part.id}` }}>
                <img loading="lazy" src={images && images.length > 0 ? images[0].url : require("../../../../assets/Images/car-placeholder.png")} alt="car" />
              </Link>
              {part && part.featured && part.featured.length > 0 ? (
                <span className="product-horizontal-label">
                  <p>
                    <AiOutlineStar />
                  </p>
                  {/* <label>Featured</label> */}
                  {/* <i class="fa fa-exclamation-circle"></i> */}
                  {/* <img loading="lazy" src={require('../../../../assets/Images/featured/isfeature.png')} alt="used" /> */}
                </span>
              ) : (
                ""
              )}
              <p className=" mb-0">{part.partDetail && +part.partDetail.salePrice < +part.partDetail.regularPrice && this.getDiscount(part.partDetail)}</p>
            </div>
            <div className="col-sm-5 col-7 p-3">
              <div className="d-flex justify-content-between ">
                <h4 className="text-dark" style={{ textTransform: "capitalize" }}>
                  {part.title ? part.title.substring(0, 20) : "Unknown"}..
                </h4>
                <p className="mb-0 mx-4">
                  {isAuth && (
                    <div className="part-horizontal-item-icons">
                      {this.checkFavourite(part) ? (
                        <span className="favourit" onClick={() => this.handleFavourite(part, "removeFav")}>
                          <i className="fas fa-heart" style={{ color: "#F68300" }}></i>
                        </span>
                      ) : (
                        <span className="favourit" onClick={() => this.handleFavourite(part, "addFav")}>
                          <i className="far fa-heart" style={{ color: "#F68300" }}></i>
                        </span>
                      )}
                      {callFrom === "dashboard-ads" && part.status !== -1 && part.sellerId === userId && (
                        <span
                          tabIndex="-1"
                          onBlur={() => {
                            this.setState({ addClass: !addClass });
                          }}
                          className={`dots ${addClass ? "color" : ""}`}
                          id={"make-popover-" + part.id}
                          onClick={this.toggleAdsDots}
                        >
                          <i className="fas fa-ellipsis-v ml-3"></i>
                        </span>
                      )}
                      {callFrom === "dashboard-ads" && part.status !== -1 && part.sellerId === userId && (
                        <UncontrolledPopover trigger="legacy" placement="bottom" target={"make-popover-" + part.id}>
                          <PopoverBody>
                            <button type="button" className="btn btn-link" onClick={() => this.handleEdit(part)}>
                              <i className="fas fa-pen"></i>
                            </button>
                            <button type="button" className="btn btn-link" onClick={() => this.handleDelete(part)}>
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </PopoverBody>
                        </UncontrolledPopover>
                      )}
                    </div>
                  )}
                </p>
              </div>
              <p className="mb-0 text-muted">
                <GrLocation /> {part.city ? part.city : "Unknown"}
                {(part.sellerId === "37" || part.sellerId === 37) && (
                  <span className={`shipping ${part && part.partDetail && !part.partDetail.shippingFee ? "free-ship" : ""}`}>
                    {part && part.partDetail && part.partDetail.shippingFee ? "Shipping Fee: PKR " + part.partDetail.shippingFee : "Free Shipping"}
                  </span>
                )}
              </p>
              <div className="mt-2 pt-1">
                <div className="d-flex justify-content-between">
                  <del className="mb-0 text-muted deleted-price">
                    {part.partDetail && +part.partDetail.salePrice < +part.partDetail.regularPrice && (
                      <span className="price-regular">{this.commonService.getPriceIntoWords(part.partDetail && part.partDetail.regularPrice ? part.partDetail.regularPrice : 0)}</span>
                    )}
                    <small> PKR</small>
                  </del>
                  <h4 className="mb-0 m-md-0 m-2">
                    {this.commonService.getPriceIntoWords(part.partDetail && part.partDetail.salePrice ? part.partDetail.salePrice : 0)}
                    <small> PKR</small>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-12">
              {part.addedToCart
                ? (part.sellerId === 37 || part.sellerId === "37") &&
                  callFrom !== "dashboard-ads" && (
                    <div className="bottom-buttons">
                      <button className="btn view-cart" onClick={this.props.onViewCart}>
                        View Cart
                      </button>
                    </div>
                  )
                : callFrom !== "dashboard-ads" &&
                  (part.sellerId === 37 || part.sellerId === "37") && (
                    <div className="bottom-buttons">
                      <button className="btn btn-buy-now" onClick={() => this.props.onAddToCart(part, "buyNow")}>
                        Buy Now
                      </button>
                      <button type="button" className="btn add-cart" onClick={() => this.props.onAddToCart(part)}>
                        Add to Cart
                      </button>
                    </div>
                  )}
              {part.status !== -1
                ? part.sellerId === userId &&
                  part.featured &&
                  part.featured.length <= 0 &&
                  callFrom === "dashboard-ads" && (
                    <div className="product-boster">
                      <div className="booster-text">Sell Your Part Faster</div>
                      <div className="booster-button">
                        <button type="button" className="adp-btn adp-btn-secondary" onClick={this.goToFeaturedSubscription}>
                          Boost Your Ad
                        </button>
                      </div>
                    </div>
                  )
                : part.sellerId === userId &&
                  callFrom === "dashboard-ads" && (
                    <div className="product-boster">
                      <div className="activate-button">
                        <button type="button" className="adp-btn adp-btn-secondary" onClick={() => this.activateAd(part)}>
                          <i class="fas fa-redo-alt"></i> Activate
                        </button>
                      </div>
                    </div>
                  )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ListingHorizontal);
