import React, { Component } from "react";
import "./ChatBox.scss";
import { Link } from "react-router-dom";
import CommonService from "../../../../services/common-service";
import moment from "moment";
import { BiChevronLeft } from "react-icons/bi";

class NewChatBox extends Component {
  constructor(props) {
    super(props);
    this.scrollDown = this.scrollDown.bind(this);
    this.commonService = new CommonService();
    this.state = {
      message: "",
      errors: {
        message: "",
      },
    };
  }

  componentDidMount() {
    this.scrollDown();
  }

  componentDidUpdate(prevProps, prevState) {
    this.scrollDown();
  }

  scrollDown() {
    const { container } = this.refs;
    container.scrollTop = container.scrollHeight;
  }

  formatDateTime = (dateTime, format) => {
    return moment(dateTime).format(format);
  };

  getMessageSenderName = (msg, chat) => {
    let name = "";
    if (msg.senderId === chat.sender.id) {
      name = chat.sender.profile.dealerName ? chat.sender.profile.dealerName : chat.sender.profile.firstName + " " + chat.sender.profile.lastName;
    } else if (chat.receiver && msg.senderId === chat.receiver.id) {
      name = chat.receiver.profile.dealerName ? chat.receiver.profile.dealerName : chat.receiver.profile.firstName + " " + chat.receiver.profile.lastName;
    }
    return name;
  };

  calculateDays(dateTime) {
    let string = "";
    let eventdate = moment(dateTime).format("YYYY-MM-DD");
    let todaysdate = moment();
    let diff = todaysdate.diff(eventdate, "days");
    if (diff === 0) {
      string = "Today";
    } else {
      if (diff === 1) {
        string = diff + " Day";
      } else {
        string = diff + " Days";
      }
    }
    return string;
  }

  render() {
    const { messages, chat, user, typingUsers, makeMessageListVisible } = this.props;

    return (
      <div id="new-chatbox">
        {/* Top Information about messages */}

        {/* Top Information about mes sages */}

        <div className="messages-only ">
          {/* header */}
          <div className="msg-header">
            <div className="profile">
              <div className="d-flex align-self-center" onClick={() => makeMessageListVisible()}>
                <BiChevronLeft size={50} />
              </div>
              <img loading="lazy" src={chat.sender.profile.profileImage || require("../../../../assets/Images/userimage.png")} alt="message-pic" />
              <div className="profile-details">
                <h5 className="text-dark">
                  {chat.sender.profile.firstName} {chat.sender.profile.lastName}
                </h5>
                <p>
                  <p className="date mb-0">{this.formatDateTime(chat.createdAt, "MMMM DD,YYYY")}</p>
                  <p className="time mb-0">{this.formatDateTime(chat.createdAt, "hh:mm a")}</p>
                </p>
              </div>
            </div>
            {/* <div className="profile-btn">
              <Link>View Profile</Link>
            </div> */}
          </div>
          {/* header end */}

          <div className="message-chat" ref="container">
            {messages.map((msg) => {
              let className = "msg-box-1";
              let imgClass = "right-img";
              let msgClass = "msg-user-1";
              let img = chat.receiver.profile.profileImage || require("../../../../assets/Images/userimage.png");

              if (msg.senderId === user) {
                className = "msg-box-2";
                imgClass = "left-img";
                msgClass = "msg-user-2";
                img = chat.sender.profile.profileImage || require("../../../../assets/Images/userimage.png");
              }
              return (
                <div className={className} key={msg.id}>
                  <img loading="lazy" className={imgClass} src={img} />
                  <div className={msgClass}>
                    <p>{msg.message}</p>
                    <div className="pt-3">
                      <small style={{ fontSize: "10px" }}>{this.formatDateTime(msg.createdAt, "YYYY-MM-DD")}</small>
                    </div>
                  </div>
                </div>
              );
            })}
            {typingUsers.map((name) => {
              return (
                <div key={name} className="typing-user">
                  {`${name} is typing . . .`}
                </div>
              );
            })}
          </div>
          {/* 
          {[1, 2, 3].map((items) => (
            <div className="msg-box-2">
              <div className="msg-user-1">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                  a galley of type and scrambled it to make a type specimen book.
                </p>
                <div className="pt-3">
                  <small>10:35 </small>
                </div>
              </div>
              <img loading="lazy" src={require("../../../../assets/Images/userimage.png")} />
            </div>
          ))} */}
        </div>
      </div>
    );
  }
}
export default NewChatBox;
