import Configuration from "../config/configuration";
import axios from "axios";
import LocalStorageService from "./localStore-service";

class AdsService {
  constructor() {
    this.config = new Configuration();
    this.localService = new LocalStorageService();
    this.token = this.localService.getUserToken();
    this.headers = { Authorization: `Bearer ${this.token}` };
  }

  uploadImage(formData) {
    return axios.post(this.config.apiBaseUrl + "upload/image", formData);
  }

  getUserAds(pageNo, pageSize, userId, status, category) {
    return axios.get(this.config.apiBaseUrl + `flyers/myflyers?page=${pageNo}&pageSize=${pageSize}&userId=${userId}&category=${category}&status=${status}`);
  }

  getUserAdsPublic(pageNo, pageSize, userId, category) {
    return axios.get(this.config.apiBaseUrl + `flyers/myflyerspub?page=${pageNo}&pageSize=${pageSize}&userId=${userId}&category=${category}`);
  }

  postAd(formData) {
    return axios.post(this.config.apiBaseUrl + "flyers", formData);
  }

  makeAdFeatured(formData) {
    return axios.post(this.config.apiBaseUrl + "flyers/make-ad-featured", formData);
  }

  updateAd(formData, id) {
    return axios.put(this.config.apiBaseUrl + `flyers?id=${id}`, formData);
  }

  updateAdAuction(formData, id) {
    return axios.put(this.config.apiBaseUrl + `flyers/auctions?id=${id}`, formData);
  }

  deleteAd() {}

  getAllMakes(category, year) {
    return axios.get(this.config.apiBaseUrl + `makes?category=${category}${year ? "&year=" + year : ""}`);
  }

  getModelsByMakeIdAndMakeType(makeId, makeType, year) {
    return axios.get(this.config.apiBaseUrl + `models?makeId=${makeId}&makeType=${makeType}${year ? "&year=" + year : ""}`);
  }

  getNewModelsByMakeIdAndMakeType(makeId, makeType, year) {
    return axios.get(this.config.apiBaseUrl + `models?inventory=true&makeId=${makeId}&makeType=${makeType}${year ? "&year=" + year : ""}`);
  }

  getVariantsByModelIdAndYear(modelId, year) {
    return axios.get(this.config.apiBaseUrl + `variants?modelId=${modelId}&year=${year}`);
  }

  getNewVariantsByModelIdAndYear(modelId, year) {
    return axios.get(this.config.apiBaseUrl + `variants?inventory=true&modelId=${modelId}&year=${year}`);
  }

  getFeatures(category) {
    return axios.get(this.config.apiBaseUrl + `features?category=${category}`);
  }

  postSellForMe(formData) {
    return axios.post(this.config.apiBaseUrl + "sellforme", formData);
  }

  addFavouriteAd(formData) {
    return axios.post(this.config.apiBaseUrl + "flyers/updatefavourite", formData);
  }

  getMyFavouritesAds(pageNo, pageSize, userId, category) {
    return axios.get(this.config.apiBaseUrl + `flyers/myfavourite?page=${pageNo}&pageSize=${pageSize}&userId=${userId}&category=${category}`);
  }

  getAdsPaginated(data) {
    const { pageNo, pageSize, status, category, filterQuery, sortBy } = data;
    const { md, mk, vr, ct, rg, color, bodyT, minY, maxY, minM, maxM, minP, maxP, onlyN, engT, minEC, maxEC, minBC, maxBC, transmission, assembly, adT, sellT, picture, searchStr, sId, cert } =
      filterQuery;
    let query = `${mk ? "&mk=" + mk : ""}${md ? "&md=" + md : ""}${vr ? "&vr=" + vr : ""}${ct ? "&ct=" + ct : ""}${rg ? "&rct=" + rg : ""}${minP ? "&minP=" + minP : ""}${maxP ? "&maxP=" + maxP : ""}${
      minY ? "&minY=" + minY : ""
    }${maxY ? "&maxY=" + maxY : ""}${minM ? "&minM=" + minM : ""}${maxM ? "&maxM=" + maxM : ""}${color ? "&color=" + color : ""}${bodyT ? "&bodyT=" + bodyT : ""}${engT ? "&engT=" + engT : ""}${
      minEC ? "&minEC=" + minEC : ""
    }${maxEC ? "&maxEC=" + maxEC : ""}${minBC ? "&minBC=" + minBC : ""}${maxBC ? "&maxBC=" + maxBC : ""}${transmission ? "&transmission=" + transmission : ""}${
      assembly ? "&assembly=" + assembly : ""
    }${searchStr ? "&search=" + searchStr : ""}${onlyN ? "&onlyN=" + onlyN : ""}${adT ? "&adT=" + adT : ""}${sellT ? "&sellT=" + sellT : ""}${picture ? "&picture=" + picture : ""}${
      sId ? "&sellerId=" + sId : ""
    }${cert ? "&cert=" + cert : ""}${sortBy ? "&sortBy=" + sortBy : ""}`;
    return axios.get(this.config.apiBaseUrl + `flyers?page=${pageNo}&status=${status}&pageSize=${pageSize}&category=${category}${query}`);
  }

  getPartsAds(data) {
    const { pageNo, pageSize, status, category, filterQuery, sortBy } = data;
    const { md, mk, pCat, pSubCat, minP, maxP, ct, onlyN, adT, sellT, picture, searchStr, sId } = filterQuery;
    let query = `${mk ? "&mk=" + mk : ""}${md ? "&md=" + md : ""}${pCat ? "&pCat=" + pCat : ""}${pSubCat ? "&pSubCat=" + pSubCat : ""}${ct ? "&ct=" + ct : ""}${minP ? "&minP=" + minP : ""}${
      maxP ? "&maxP=" + maxP : ""
    }${searchStr ? "&search=" + searchStr : ""}${onlyN ? "&onlyN=" + onlyN : ""}${adT ? "&adT=" + adT : ""}${sellT ? "&sellT=" + sellT : ""}${picture ? "&picture=" + picture : ""}${
      sId ? "&sellerId=" + sId : ""
    }${sortBy ? "&sortBy=" + sortBy : ""}`;
    return axios.get(this.config.apiBaseUrl + `flyers?page=${pageNo}&status=${status}&pageSize=${pageSize}&category=${category}${query}`);
  }

  getAdById(id) {
    return axios.get(this.config.apiBaseUrl + `flyers/${id}`, {
      headers: this.headers,
    });
  }

  getAdsPerStatus(status) {
    return axios.get(this.config.apiBaseUrl + `flyers?status=${status}`);
  }

  getPaginatedFeaturedAds(pageNo, pageSize, status) {
    return axios.get(this.config.apiBaseUrl + `flyers/featured?page=${pageNo}&pageSize=${pageSize}&status=${status}&category=car`);
  }

  getSimilarAds(category, modelId, id, status, limit) {
    return axios.get(this.config.apiBaseUrl + `flyers/similar?category=${category}&status=${status}&modelId=${modelId}&id=${id}&limit=${limit}`);
  }

  getUserFeaturedAds(pageNo, pageSize, userId, category) {
    return axios.get(this.config.apiBaseUrl + `flyers/myfeatured?page=${pageNo}&pageSize=${pageSize}&userId=${userId}&category=${category}`);
  }

  removeFavouriteAd(formData) {
    return axios.post(this.config.apiBaseUrl + "flyers/removefavourite", formData);
  }

  createAdHistory(formData) {
    return axios.post(this.config.apiBaseUrl + "flyers/history", formData);
  }

  getPartsCategories() {
    return axios.get(this.config.apiBaseUrl + "partscategories");
  }

  getPartsSubCategories(id) {
    return axios.get(this.config.apiBaseUrl + `partssubcategories/bycategory${id ? `?catId=${id}` : ""}`);
  }

  // getNewCars(pageNo, pageSize) {

  //     return axios.get(this.config.apiBaseUrl + `cars?page=${pageNo}&pageSize=${pageSize}`);
  // }

  // getCarById(id) {
  //     return axios.get(this.config.apiBaseUrl + `cars/${id}`);
  // }

  postReview(formData) {
    return axios.post(this.config.apiBaseUrl + "reviews", formData);
  }

  addFavouriteCar(formData) {
    return axios.post(this.config.apiBaseUrl + "cars/updatefavourite", formData);
  }

  removeFavouriteCar(formData) {
    return axios.post(this.config.apiBaseUrl + "cars/removefavourite", formData);
  }

  postRide(formData) {
    return axios.post(this.config.apiBaseUrl + "rides", formData);
  }
  updateRide(formData) {
    return axios.put(this.config.apiBaseUrl + `rides`, formData);
  }

  addFavouriteRide(formData) {
    return axios.post(this.config.apiBaseUrl + "rides/updatefavourite", formData);
  }

  removeFavouriteRide(formData) {
    return axios.post(this.config.apiBaseUrl + "rides/removefavourite", formData);
  }

  checkChat(senderId, adId) {
    return axios.get(this.config.apiBaseUrl + "chats/check?senderId=" + senderId + "&adId=" + adId);
  }

  createAlert(data) {
    return axios.post(this.config.apiBaseUrl + "alerts", data);
  }
  createEmailAlert(data) {
    return axios.post(this.config.apiBaseUrl + "alerts/email", data);
  }
  getAdsAlerts(userId, category, pageNo, pageSize) {
    return axios.get(this.config.apiBaseUrl + `alerts/history/${userId}?page=${pageNo}&pageSize=${pageSize}&category=${category}`);
  }
  getUserSubscribedAdsAlerts(userId, category, pageNo, pageSize) {
    return axios.get(this.config.apiBaseUrl + `alerts/userSubscribedAlerts/${userId}?page=${pageNo}&pageSize=${pageSize}&category=${category}`);
  }
  deleteUserAdsAlerts(userId) {
    return axios.delete(this.config.apiBaseUrl + `alerts/history/${userId}`);
  }

  deleteUserSubscribedAdsAlerts(userId) {
    return axios.delete(this.config.apiBaseUrl + `alerts/userSubscribedAlerts/${userId}`);
  }

  reportAd(data) {
    return axios.post(this.config.apiBaseUrl + "reportad", data);
  }

  shareViaEmail(data) {
    return axios.post(this.config.apiBaseUrl + "flyers/share/email", data);
  }

  getAdsSearchSuggestions(category, searchStr) {
    const instance = axios.create();
    return instance.get(this.config.apiBaseUrl + `flyers/autocomplete?category=${category}&searchStr=${searchStr}`);
  }

  getAdsLimits(category) {
    //axios instance created to avoid loader
    const instance = axios.create();
    return instance.get(this.config.apiBaseUrl + `flyers/limits?category=${category}`);
  }

  getSeoCrumb(category) {
    //axios instance created to avoid loader
    const instance = axios.create();
    return instance.get(this.config.apiBaseUrl + `flyers/seocrumb?category=${category}`);
  }

  getSeoCrumbInside(category, make, model, variant) {
    //axios instance created to avoid loader
    const instance = axios.create();
    return instance.get(this.config.apiBaseUrl + `flyers/seocrumb-inside?category=${category}&make=${make}&model=${model}&variant=${variant}`);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default AdsService;
