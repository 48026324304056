const socket = (state = null, action) => {
    if (action.type === "SET_SOCKET") {
        state = {...state, socket: action.payload}
        return state;
    } else if(action.type === "GET_SOCKET") {
        return state;
    }
    return state;
};

export default socket;