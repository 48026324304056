import Configuration from '../config/configuration';
import axios from 'axios'


class RidesService {

    constructor() {
        this.config = new Configuration();
    }

    uploadImage(formData) {
        return axios.post(this.config.apiBaseUrl + 'upload/image', formData);
    }

    postRide(formData) {
        return axios.post(this.config.apiBaseUrl + 'rides', formData);
    }

    // getRidesPaginated(data) {
    //     const { pageNo, pageSize, category } = data;
    //     return axios.get(this.config.apiBaseUrl + `rides?page=${pageNo}&pageSize=${pageSize}&category=${category}`);
    // }

    addFavouriteRide(formData) {
        return axios.post(this.config.apiBaseUrl + 'rides/updatefavourite', formData);
    }

    removeFavouriteRide(formData) {
        return axios.post(this.config.apiBaseUrl + 'rides/removefavourite', formData);
    }

    getRidesPaginated(data) {
        const { pageNo, pageSize, category, filterQuery, sortBy } = data;
        const { mk, md } = filterQuery;
        const query = `${mk ? '&mk=' + mk : ''}${md ? '&md=' + md : ''}${sortBy ? '&sortBy=' + sortBy : ''}`
        return axios.get(this.config.apiBaseUrl + `rides/public?page=${pageNo}&pageSize=${pageSize}&category=${category}${query}`);
    }

    getUserRides(data) {
        const { pageNo, pageSize, category, riderId } = data;
        return axios.get(this.config.apiBaseUrl + `rides?page=${pageNo}&pageSize=${pageSize}&category=${category}&riderId=${riderId}`);
    }

    getRideById(id) {
        return axios.get(this.config.apiBaseUrl + `rides/${id}`);
    }

    postRideReview(formData) {
        return axios.post(this.config.apiBaseUrl + 'ridereviews', formData);
    }

    getServiceTypes() {
        return axios.get(this.config.apiBaseUrl + `servicetypes`);
    }

    postService(formData) {
        return axios.post(this.config.apiBaseUrl + 'servicehistory', formData);
    }

    deleteService(id) {
        return axios.delete(this.config.apiBaseUrl + `servicehistory?id=${id}`,);
    }

    getServiceLogsByRideId(rideId) {
        return axios.get(this.config.apiBaseUrl + `servicehistory?rideId=${rideId}`);
    }

    handleError(error) {
        console.log(error.message);
    }
}

export default RidesService;