import CommonService from '../../services/common-service';
const commonService = new CommonService();
const prices = {
    getPriceRanges: (min, max, interval) => {
        let prices = [];
        for (let i = min; i <= max; i += interval) {
            prices.push({ label: i, value: i });
        }
        return prices;
    },

    getPriceRangesFilter: (min, max, interval) => {
        let prices = [];
        for (let i = 0; i < max; i += interval) {
            if (i >=10000000) {
                interval = 10000000
            }
            prices.push({ label: commonService.getPriceIntoWordsFilter(i <=0 ? min : i), value: i <=0 ? min : i  });
        }
        return prices;
    }
    

}

export default prices; 