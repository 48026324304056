import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import StarRatings from "react-star-ratings";
import "./ListingItemVertical.scss";
import LocalStorageService from "../../../services/localStore-service";
import AdsService from "../../../services/ads-service";
import CommonService from "../../../services/common-service";

class ListingItemVertical extends Component {
  constructor() {
    super();
    this.commonService = new CommonService();
    this.localService = new LocalStorageService();
    this.adsService = new AdsService();
    this.state = {
      ride: {},
      isAuth: false,
      userId: 0,
    };
  }

  componentDidMount() {
    this.setState({
      // isAuth: this.checkAuth(),
      userId: this.localService.getUserId(),
      ride: this.props.data ? this.props.data : {},
    });
  }

  componentDidUpdate(prevProp) {
    if (this.props.data) {
      if (prevProp.data !== this.props.data) {
        this.setState({
          ride: this.props.data,
        });
      }
    }
  }

  checkAuth = () => {
    const token = this.localService.getUserToken();
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  handleFavourite = (ridesItem, type) => {
    const { userId } = this.state;
    if (userId) {
      if (type === "addFav") {
        this.addFavourite(ridesItem);
      } else if (type === "removeFav") {
        this.removeFavourite(ridesItem);
      }
    }
  };

  addFavourite = (ridesItem) => {
    const { userId } = this.state;
    let formData = { userId, rideId: ridesItem.id };

    this.adsService
      .addFavouriteRide(formData)
      .then((res) => {
        const { message } = res.data;
        if (message === "Favourite added successfully") {
          this.commonService.showToast("Favourite added successfully", "success");
          this.props.onFavourite();
        } else {
          this.commonService.showToast("Favourite not added!", "error");
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  removeFavourite = (ridesItem) => {
    const { userId } = this.state;
    let formData = { userId, rideId: ridesItem.id };
    this.adsService
      .removeFavouriteRide(formData)
      .then((res) => {
        const { message } = res.data;
        if (message === "Favourite removed successfully") {
          this.commonService.showToast("Favourite removed successfully", "success");
          this.props.onFavourite();
        } else {
          this.commonService.showToast("Favourite not added!", "error");
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  checkFavourite = (ridesItem) => {
    if (ridesItem && ridesItem.favourites && ridesItem.favourites.length) {
      let isFavourite = false;
      ridesItem.favourites.forEach((fav) => {
        if (fav.id) {
          let riderId = fav.id;
          const loginUserId = this.state.userId;
          if (riderId === loginUserId) {
            isFavourite = true;
          }
        }
      });
      return isFavourite;
    }
  };

  goToDetail = (id) => {
    this.props.history.push(`/rides/detail/${id}`);
  };

  render() {
    const { isAuth, ride } = this.state;
    const { images } = ride ? ride : {};
    return (
      <div className="rides-verticle-item-main">
        <div className="product-img" onClick={() => this.goToDetail(ride.id)}>
          <img loading="lazy" className="card-img-top" src={images && images.length ? images[0].url : require("../../../assets/Images/car-placeholder.png")} alt="featured car" />
        </div>
        {/* <span className="product-label">
                    <span className="copy" >
                        <i className="far fa-copy"></i>
                    </span>
                    <span className="share" >
                        <i className="fas fa-share-alt"></i>
                    </span>
                </span> */}
        <div className="card-body product-item-body">
          <h6>
            <span className="card-title" onClick={() => this.goToDetail(ride.id)}>
              {ride && ride.title ? ride.title : ""}
            </span>

            {isAuth && (
              <span className="favourit">
                {this.checkFavourite(ride) ? (
                  <span className="favourit" onClick={() => this.handleFavourite(ride, "removeFav")}>
                    <i className="fas fa-heart"></i>
                  </span>
                ) : (
                  <span className="favourit" onClick={() => this.handleFavourite(ride, "addFav")}>
                    <i className="far fa-heart"></i>
                  </span>
                )}
              </span>
            )}
          </h6>

          <div className="footer-rating">
            <StarRatings name="levelOfServices" rating={ride.avgRating ? ride.avgRating : 0} starRatedColor="var(--color-secondary)" starDimension="15px" starSpacing="2px" numberOfStars={5} />
            <span>{ride && ride.reviews ? ride.reviews.length : 0} Reviews</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ListingItemVertical);
