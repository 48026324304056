export const setCategory = (category) => {
  return {
    type: "SET_CATEGORY",
    payload: category,
  };
};

export const getCategory = () => {
  return {
    type: "GET_CATEGORY",
  };
};
