import React, { Component } from "react";
import "./Newsletter.scss";
import { Link } from "react-router-dom";
import CommonService from "../../../services/common-service";
import UserService from "../../../services/user-service";
import { HiOutlinePhone, HiOutlineLocationMarker } from "react-icons/hi";
import { BiMobileAlt, BiEnvelope } from "react-icons/bi";

class Newsletter extends Component {
  constructor() {
    super();
    this.commonService = new CommonService();
    this.userService = new UserService();
    this.state = {
      email: "",
      msg: "",
      errors: {
        email: "",
      },
    };
  }

  checkFormErrors = (name, value) => {
    let errors = this.state.errors;
    switch (name) {
      case "email":
        errors.email = value === "" ? "Email is required!" : this.commonService.isEmailValid(value) ? "" : "Email is not valid!";
        break;
      default:
        break;
    }
    this.setState({ errors });
  };
  handleChange = (e) => {
    const { value, name, type, checked } = e.target;
    this.checkFormErrors(name, value);
    this.setState({
      ...this.state,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  subscribeNewsLetter = () => {
    const { email, errors } = this.state;
    const formData = { email };
    if (email && !errors.email) {
      this.userService
        .subscribeNewsLetter(formData)
        .then((res) => {
          const { data, status } = res;
          let msg = "";
          if (data && data.message === "Newsletter subscribed successfully.") {
            msg = "Thank you for Subscribing to our Newsletter.";
          } else if ((data && data.status === 400) || (status && status === 400)) {
            const emailErr = "Email has already been subscribed";
            this.setState({
              errors: { ...this.state.errors, email: emailErr },
            });
          }
          this.setState({
            msg,
          });
        })
        .catch((err) => {
          this.userService.handleError(err);
        });
    }
  };

  render() {
    const { email, errors, msg } = this.state;
    return (
      <div>
        <div className="newsletter pb-0" style={{ backgroundColor: "transparent" }}>
          <div className="">
            {!msg ? (
              <div className="newsletter-heading mb-0">
                <h3 className="mb-3 mt-n2">Newsletter</h3>
                <div className="d-flex newsletter-input-new ">
                  <input type="text" className="form-control" id="usr" placeholder="name@email.com" value={email} name="email" onChange={this.handleChange}></input>
                  <button type="button" className="adp-btn adp-btn-secondary-1" onClick={this.subscribeNewsLetter}>
                    Subscribe
                  </button>
                </div>
                {errors.email && <div className="invalid-feedback d-block newsletter-validation">{errors.email}</div>}
              </div>
            ) : (
              <div className="newsletter-msg">
                <h5>{msg}</h5>
              </div>
            )}
            {/* <div className="newsletter-download">
              <h3>Mobile Apps Coming Soon</h3>
              <div className="newsletter-button">
                <div className="row">
                  <div className="col-md-5">
                    <Link to={"/"}>
                      <img
                        src={require("../../../assets/Images/apple.png")}
                        alt="apple"
                      />
                    </Link>
                  </div>
                  <div className="col-md-5 ml-2">
                    <Link to={"/"}>
                      <img
                        src={require("../../../assets/Images/playstore.png")}
                        alt="android"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="app-download-mobile">
            <div className="newsletter-download">
              <h3>Download App</h3>
              <div className="newsletter-button">
                <div className="row">
                  <div className="col-md-5">
                    <Link to={'/'}>
                      <img loading="lazy" src={require("../../../assets/Images/apple.png")} alt="apple" />
                    </Link>
                  </div>
                  <div className="col-md-5 ml-2">

                    <Link to={'/'}>
                      <img loading="lazy" src={require("../../../assets/Images/playstore.png")} alt="android" />
                    </Link>
                  </div>
                </div>

              </div>
            </div>
          </div> */}
        </div>

        <div className="follow-us">
          <h5 className="">Our Social Media</h5>
          <ul class="list-unstyled list-inline networks primary-lang">
            <li>
              <button
                className="btn btn-link"
                onClick={() => {
                  window.open("https://twitter.com/autodealspk", "_blank");
                }}
              >
                <i className="fab fa-twitter text-primary"></i>
              </button>
            </li>
            <li>
              <button
                className="btn btn-link"
                onClick={() => {
                  window.open("https://www.facebook.com/autodeals.int", "_blank");
                }}
              >
                <img loading="lazy" height="18px" width="18px" src={require("../../../assets/Images/Facebook-logo-2021@2x.png")} />
              </button>
            </li>
            <li>
              <button
                className="btn btn-link"
                onClick={() => {
                  window.open("https://www.pinterest.com/autodealspk", "_blank");
                }}
              >
                <i className="fab fa-pinterest" style={{ color: "#E60023" }}></i>
              </button>
            </li>
            <li>
              <button
                className="btn btn-link"
                onClick={() => {
                  window.open("https://www.instagram.com/autodealspk", "_blank");
                }}
              >
                <i className="fab fa-instagram" style={{ color: "#E60023" }}></i>
                {/* <img loading="lazy" height="37px" width="37px" src={require("../../../assets/Images/instagram.png")} /> */}
              </button>
            </li>
            <li>
              <button
                className="btn btn-link"
                onClick={() => {
                  window.open("https://www.linkedin.com/company/autodeals.pk", "_blank");
                }}
              >
                <i class="fab fa-linkedin-in text-primary"></i>
              </button>
            </li>
            <li>
              <button
                className="btn btn-link"
                onClick={() => {
                  window.open("https://www.youtube.com/channel/UCaV87DqgVrrvWB4vImw-kfw", "_blank");
                }}
              >
                <i class="fab fa-youtube text-danger"></i>
              </button>
            </li>
          </ul>
          <div></div>
        </div>
        <br />
        <br />
        <br />
        <div className="d-flex flex-wrap flex-sm-nowrap my-sm-0 my-4">
          <img loading="lazy" className="" style={{ width: "auto", height: "43.5px", objectFit: "contain", cursor: "pointer" }} src={require("./google-play-badge.png")} />
          <img
            className="pl-1"
            style={{ width: "auto", height: "43.5px", objectFit: "contain", cursor: "pointer" }}
            src={require("../../../assets/Images/Download_on_the_App_Store_Badge.svg (1).webp")}
          />
          <img loading="lazy" className="pl-sm-1 mt-1 mt-sm-0" style={{ width: "auto", height: "43.5px", objectFit: "contain", cursor: "pointer" }} src={require("./huawei.png")} />
        </div>
        <div className="d-flex">
          <h2 className="mb-0">Get In Touch :</h2>
          <div className="address ml-2 align-self-center mb-0">
            <div className="icon mt-1 mr-0">
              <p className="icon-color mb-0 align-self-center">
                <BiEnvelope />
              </p>
            </div>
            <div className="main-address">
              <div className="main-address-content align-self-center">
                <button
                  className="btn btn-link p-0"
                  onClick={(e) => {
                    window.location = "mailto:support@autodeals.pk";
                    e.preventDefault();
                  }}
                >
                  support@autodeals.pk
                </button>
              </div>
            </div>
          </div>
          </div>
          <div className="d-flex">
          <div className="address">
            <div className="icon">
              <p className="icon-color mb-0">
                <HiOutlinePhone />
              </p>
            </div>
            <div className="main-address">
              {/* <div className="main-address-heading"><span> Phone:</span></div> */}
              <div className="main-address-content">
                <button
                  className="btn btn-link pt-md-1"
                  onClick={(e) => {
                    window.location = "tel:+923041114474";
                    e.preventDefault();
                  }}
                >
                  +923041114474
                </button>
              </div>
            </div>
          </div>
          </div>
          <div className="d-flex">
          <div className="address">
            <div className="icon">
              <p className="icon-color mb-0">
                <HiOutlineLocationMarker />
              </p>
            </div>
            <div className="main-address">
              <div className="main-address-content pt-md-1">
                <span className="text-white">59-B-3, MM Alam Rd, Block C2, Gulberg III, Lahore, Punjab 54000</span>
              </div>
            </div>
          </div>
          </div>
      </div>
    );
  }
}

export default Newsletter;
