import Configuration from '../config/configuration';
import axios from 'axios'
import LocalStorageService from './localStore-service';

class CartService {

    constructor() {
        this.config = new Configuration();
        this.localService = new LocalStorageService();
        this.token = this.localService.getUserToken();
        this.headers = { Authorization: `Bearer ${this.token}` }
        this.state = {
            quantity: 1
        }
    }

    addToCart = (part) => {
        let cart = localStorage.getItem('cart')
            ? JSON.parse(localStorage.getItem('cart')) : [];
        if (part) {
            const index = cart.findIndex(x => x.item.id === part.id);
            if (index >= 0) {
                cart[index].quantity += 1;
            } else {
                cart.push({ item: part, quantity: 1 })
            }
        }
        localStorage.setItem('cart', JSON.stringify(cart));
        return cart;
    }

    removeFromCart = (part, type) => {
        let cart = localStorage.getItem('cart')
            ? JSON.parse(localStorage.getItem('cart')) : [];
        if (part) {
            const index = cart.findIndex(x => x.item.id === part.id);
            if (index >= 0) {
                if (type === 'counter') {
                    if (cart[index].quantity >= 2) {
                        cart[index].quantity -= 1;
                    }
                } else if (type === 'delete') {
                    cart.splice(index, 1)
                }

            }
        }
        localStorage.setItem('cart', JSON.stringify(cart));
        return cart;
    }

    checkOrderMessage(orderId) {
        return axios.get(this.config.apiBaseUrl + `orders/validate/${orderId}`, { headers: this.headers });
    }

    removeItem(item) {
        localStorage.removeItem(item);
    }

    handleError(error) {
        console.log(error.message);
    }
}
export default CartService;