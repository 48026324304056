import React from "react";
import FindAuctionSheet from "../components/AuctionSheet/CartSystem/FindSheet/Find";
import WriteReview from "../components/Dealer/ReviewModal/ReviewModal";
import HomeNew from "../components/Home/HomeNew";

// Used Cars Home Page
const Home = window.innerWidth > 500 ? React.lazy(() => import("../components/Home/Home.js")) : React.lazy(() => import("../components/MobileHome/MobileHome"));
const UsedSearch = React.lazy(() => import("../components/UsedCarHome/UsedCar"));
const ProductListing = React.lazy(() => import("../components/ProductListing/ProductListing"));
const ProductDetail = React.lazy(() => import("../components/ProductDetail/ProductDetail"));
const FinanceThisCarUsed = React.lazy(() => import("../components/ProductDetail/FinancethisCar/FinancethisCar.js"));
const PageNotFound = React.lazy(() => import("../components/PageNotFound/PageNotFound"));
const PriceCalculator = React.lazy(() => import("../components/PriceCalculator/PriceCalculator"));
const Authentication = React.lazy(() => import("../components/Authentication/Authentication"));
const ForgetPassword = React.lazy(() => import("../components/Authentication/ForgetPassword/ForgetPassword.js"));
const UserProfile = React.lazy(() => import("../components/UserProfile/UserProfile"));
const ViewUserProfile = React.lazy(() => import("../components/Dashboard/ViewUserProfile/ViewUserProfile"));
const Dashboard = React.lazy(() => import("../components/Dashboard/Dashboard"));
const PostAd = React.lazy(() => import("../components/PostAd/PostAd"));
// const SellForMe = React.lazy(() => import('../components/PostAd/SellCar/SellForMe/SellForMe'));
const PostYourAd = React.lazy(() => import("../components/PostAd/SellCar/PostYourAd/PostYourAd"));
const FeaturedAdPlans = React.lazy(() => import("../components/UserFeaturedSubscription/FeaturedAdPlans/FeaturedAdPlans"));
const BoostAd = React.lazy(() => import("../components/BootsAdd/BootsAdd"));
const CarFinancePlan = React.lazy(() => import("../components/CarFinancePlan/CarFinancePlan"));

const UserFeaturedSubscription = React.lazy(() => import("../components/UserFeaturedSubscription/UserFeaturedSubscription"));
const SellParts = React.lazy(() => import("../components/PostAd/SellPart/SellPart"));
const Verify = React.lazy(() => import("../components/Pages/Verify/Verify"));
const DealersListing = React.lazy(() => import("../components/Dealer/Listing/Listing"));
// ------------------------------------------------------------------------------------------
const DealerOverview = React.lazy(() => import("../components/UsedDealer/Dealers"));
// ------------------------------------------------------------------------------------------

const DealerDetail = React.lazy(() => import("../components/Dealer/DealerDetail/DealerDetail"));
// used-parts
// const UsedParts = React.lazy(() => ("../components/UsedCarHome/UsedCar.js"));

const PartsListing = React.lazy(() => import("../components/Parts/Listing/Listing"));

// -------------- Shop Overview -----------------------

const ShopOverview = React.lazy(() => import("../components/Parts/ShopOverview/ShopOverview"));

const PartDetail = React.lazy(() => import("../components/Parts/Detail/Detail.js"));
const FeatureYourAds = React.lazy(() => import("../components/UserFeaturedSubscription/FeatureYourAds/FeatureYourAds"));
const CarFinancing = React.lazy(() => import("../components/CarFinancing/CarFinancing"));
const Videos = React.lazy(() => import("../components/Videos/Videos"));
const VideoDetail = React.lazy(() => import("../components/Videos/VideoDetail/VideoDetail"));
const SearchedVideos = React.lazy(() => import("../components/Videos/SearchedVideos/SearchedVideos"));
const CarComparison = React.lazy(() => import("../components/CarComparison/CarComparison"));
const BikeComparison = React.lazy(() => import("../components/BikeComparison/BikeComparison"));
const CheckOut = React.lazy(() => import("../components/CheckOut/CheckOut"));
const Payment = React.lazy(() => import("../components/CheckOut/Payment/Payment"));
const Makes = React.lazy(() => import("../components/AllMake/AllMake"));
const Make = React.lazy(() => import("../components/Make/Make"));
const AboutUs = React.lazy(() => import("../components/AboutUs/AboutUs"));
const PetrolPrices = React.lazy(() => import("../components/PetrolPrices"));
// const Blogs = React.lazy(() => import('../components/Blogs/Blogs'))
const BlogDetails = React.lazy(() => import("../components/Blogs/BlogDetails/BlogDetails"));
const CarLoanCalculator = React.lazy(() => import("../components/CarLoanCalculator/CarLoanCalculator"));
const CarLoanCalculatorPayment = React.lazy(() => import("../components/CarLoanCalculator/BanksPayment/BanksPayment"));
const CarLoanCalculatorQuery = React.lazy(() => import("../components/CarLoanCalculator/QueryForm/QueryForm"));
const CertifiedCars = React.lazy(() => import("../components/CertifiedCars/CertifiedCars"));
const CarInspection = React.lazy(() => import("../components/CarInspection/CarInspection"));
const CarInspectionReport = React.lazy(() => import("../components/CarInspection/CarInspectionReport/CarInspectionReport"));
const CarImport = React.lazy(() => import("../components/CarImport/CarImport"));
const CarInsurance = React.lazy(() => import("../components/CarInsurance/CarInsurance"));
const CarInsuranceBanks = React.lazy(() => import("../components/CarInsurance/Banks/Banks"));
const CarInsuranceResult = React.lazy(() => import("../components/CarInsurance/CarInformation/CarInformation"));
const CarTracker = React.lazy(() => import("../components/CarTracker/CarTracker"));
const AuctionSheet = React.lazy(() => import("../components/AuctionSheet/AuctionSheet"));
const AuctionReport = React.lazy(() => import("../components/AuctionSheet/AuctionReport/AuctionReport"));
const New = React.lazy(() => import("../components/NewCars/New/New"));
const NewCars = React.lazy(() => import("../components/NewCars/NewCars"));
const NewCarsListing = React.lazy(() => import("../components/NewCars/Listing/Listing"));
const NewCarsDetail = React.lazy(() => import("../components/NewCars/Detail/Detail"));
const NewCarsReview = React.lazy(() => import("../components/NewCars/Review/Review"));
const RecentCarReviews = React.lazy(() => import("../components/RecentCarReviews/RecentCarReviews"));
const ModelReview = React.lazy(() => import("../components/ModelReview/ModelReview"));
const PriceList = React.lazy(() => import("../components/NewCars/PriceList/PriceList"));
const PriceListDetail = React.lazy(() => import("../components/NewCars/PriceList/PriceListDetail/PriceListDetail"));
const Rides = React.lazy(() => import("../components/Rides/Rides"));
const Detail = React.lazy(() => import("../components/Rides/Detail/Detail"));
const ManageRide = React.lazy(() => import("../components/Rides/Detail/ManageRide/ManageRide"));
const SellBikes = React.lazy(() => import("../components/PostAd/SellBike/SellBike"));
const CarComparisonDetail = React.lazy(() => import("../components/CarComparison/CarComparisonDetail/CarComparisonDetail"));
const BikeComparisonDetail = React.lazy(() => import("../components/BikeComparison/BikeComparisonDetail/BikeComparisonDetail"));
const PaymentCallback = React.lazy(() => import("../components/Pages/PaymentCallback/PaymentCallback"));
const ResetPassword = React.lazy(() => import("../components/Pages/ResetPassword/ResetPassword"));
const CustomDutyCalculator = React.lazy(() => import("../components/CarImport/CustomDutyCalculator/CustomDutyCalculator"));
const SafetyGuide = React.lazy(() => import("../components/SafetyGuide/SafetyGuide"));
const SiteMap = React.lazy(() => import("../components/SiteMap/SiteMap"));
const ByYearSiteMap = React.lazy(() => import("../components/SiteMap/ByYear/ByYear"));
const ByCitySiteMap = React.lazy(() => import("../components/SiteMap/ByCity/ByCity"));
const PartsSiteMap = React.lazy(() => import("../components/SiteMap/PartsSiteMap/PartsSiteMap"));
const EasyPayConfirm = React.lazy(() => import("../components/CheckOut/Payment/EasyPayConfirm/EasyPayConfirm"));
const PaymentResponses = React.lazy(() => import("../components/Shared/PaymentResponses/PaymentResponses"));
const ContactUs = React.lazy(() => import("../components/ContactUs/ContactUs"));
const Advertise = React.lazy(() => import("../components/Advertise/Advertise"));
const HowToPay = React.lazy(() => import("../components/HowToPay/HowToPay"));
const ReturnExchange = React.lazy(() => import("../components/ReturnExchange/ReturnExchange"));
const RefundPolicy = React.lazy(() => import("../components/RefundPolicy/RefundPolicy"));
const Faq = React.lazy(() => import("../components/Faq/Faq"));
const Map = React.lazy(() => import("../components/Map/Map"));
const ProductsByAutoDeals = React.lazy(() => import("../components/Shared/ProductsByAutoDeals/ProductsByAutoDeals"));
const TermsOfServices = React.lazy(() => import("../components/Pages/TermsOfServices/TermsOfServices"));
const ShipmentAndReturnsPolicy = React.lazy(() => import("../components/Pages/ShipmentAndReturnsPolicy/ShipmentAndReturnsPolicy"));
const DmcaPolicy = React.lazy(() => import("../components/Pages/DmcaPolicy/DmcaPolicy"));
const PrivacyPolicy = React.lazy(() => import("../components/Pages/PrivacyPolicy/PrivacyPolicy"));
// Varient
const Varient = React.lazy(() => import("../components/NewCars/Varient/Varient"));
const CarLeasing = React.lazy(() => import("../components/NewCars/Detail/FinanceCar"));
// Auction Sheet Cart System
const AuctionSheetCart = React.lazy(() => import("../components/AuctionSheet/CartSystem/Cart/Cart"));
const AuctionSheetCheckout = React.lazy(() => import("../components/AuctionSheet/CartSystem/Checkout/Checkout"));
const AuctionSheetFind = React.lazy(() => import("../components/AuctionSheet/CartSystem/FindSheet/Find"));

// New Dealer Overview
const NewDealerOverview = React.lazy(() => import("../components/NewDealerOverview/NewDealers.js"));

// NEW used-car PAGE
// const NewWriteReview = React.lazy(() =>
//   import("../components/NewCars/WriteReview/WriteReview")
// );

// Write a review
const NewWriteReview = React.lazy(() => import("../components/NewCars/WriteReview/WriteReview"));
const WriteReviewNew = React.lazy(() => import("../components/NewCars/WriteReview/WriteReview"));

// review-details
const NewReviewDetail = React.lazy(() => import("../components/NewCars/ReviewDetail/ReviewDetail"));
const ThankYou = React.lazy(() => import("../components/Pages/PaymentCallback/ThankYou"));
const asThankYou = React.lazy(() => import("../components/Pages/PaymentCallback/asThankYou"));
// featured ad payments
const FeaturedAdPlansPayement = React.lazy(() => import("../components/CheckOut/Payment/Payment.js"));
// order summary
// const ShippingInfoNew = React.lazy(() => import("../components/CheckOut/ShippingInfo/NewShippingInfo"));

const appRoutes = [
  {
    path: "/",
    component: Home,
    className: "home-page",
    access: "public",
    exact: true,
  },
  {
    path: "/home-new",
    component: HomeNew,
    className: "home-page",
    access: "public",
    exact: true,
  },
  {
    path: "/post-ad/sell-bike",
    component: SellBikes,
    className: "post-sell-bike-page",
    access: "private",
    exact: false,
  },
  {
    path: "/post-ad/sell-bike/edit",
    component: SellBikes,
    className: "post-sell-bike-page",
    access: "private",
    exact: false,
  },
  {
    path: "/product-listing/:category",
    component: ProductListing,
    className: "product-listing-page",
    access: "public",
    exact: true,
  },
  {
    path: "/product-listing/detail/:id",
    component: ProductDetail,
    className: "product-detail-page",
    access: "public",
    exact: false,
  },

  // Used Cars Home Page
  {
    path: "/used-cars",
    component: UsedSearch,
    className: "used-cars-home-page",
    access: "public",
    exact: true,
  },
  {
    path: "/used-cars/search",
    component: ProductListing,
    className: "used-cars-listing-page",
    access: "public",
    exact: true,
  },
  {
    path: "/used-cars/search/-/",
    component: ProductListing,
    className: "product-listing-page",
    access: "public",
    exact: false,
  },
  {
    path: "/used-cars/:detail",
    component: ProductDetail,
    className: "product-detail-page",
    access: "public",
    exact: true,
  },
  // finacne this car used
  {
    path: "/used-car-leasing/:detail",
    component: FinanceThisCarUsed,
    className: "finance-this-car-used",
    access: "public",
    exact: true,
  },
  // used-bikes home-page
  {
    path: "/used-bikes",
    component: UsedSearch,
    className: "used-bikes-home-page",
    access: "public",
    exact: true,
  },
  {
    path: "/used-bikes/search",
    component: ProductListing,
    className: "used-bike-listing-page",
    access: "public",
    exact: true,
  },
  {
    path: "/used-bikes/search/-/",
    component: ProductListing,
    className: "product-listing-page",
    access: "public",
    exact: false,
  },
  {
    path: "/used-bikes/:detail",
    component: ProductDetail,
    className: "product-detail-page",
    access: "public",
    exact: true,
  },
  {
    path: "/price-calculator",
    component: PriceCalculator,
    className: "price-calculator-page",
    access: "public",
    exact: false,
  },
  {
    path: "/feature-your-ads",
    component: FeatureYourAds,
    className: "feature-your-ads",
    access: "public",
    exact: false,
  },
  {
    path: "/auth/:type",
    component: Authentication,
    className: "authentication-page",
    access: "onlyPublic",
    exact: false,
  },
  // Forgot Password
  {
    path: "/forget-password",
    component: ForgetPassword,
    className: "forget-password",
    access: "onlyPublic",
    exact: false,
  },
  // Forgot Password
  {
    path: "/user-profile/:profileType",
    component: UserProfile,
    className: "user-profile-page",
    access: "private",
    exact: false,
  },
  {
    path: "/dashboard/user-profile/detail/:id",
    component: ViewUserProfile,
    className: "view-user-profile-page",
    access: "private",
    exact: false,
  },
  {
    path: "/dashboard/:type",
    component: Dashboard,
    className: "dashboard-page",
    access: "private",
    exact: false,
  },
  {
    path: "/post-ad/:type",
    component: PostAd,
    className: "post-ad-page",
    access: "private",
    exact: true,
  },

  // {
  //     path: '/post-ad/sell-car/sell-for-me',
  //     component: SellForMe,
  //     className: 'post-ad-page',
  //     access: 'public',
  //     exact: false
  // },
  {
    path: "/post-ad/sell-car/post-your-ad",
    component: PostYourAd,
    className: "post-car-ad-page",
    access: "private",
    exact: true,
  },
  {
    path: "/post-ad/sell-car/post-your-ad/edit",
    component: PostYourAd,
    className: "post-car-ad-page",
    access: "private",
    exact: false,
  },
  {
    path: "/post-ad/sell-part/edit",
    component: SellParts,
    className: "post-sell-ad-part-page",
    access: "private",
    exact: false,
  },
  {
    path: "/featured-ad-plans",
    component: FeaturedAdPlans,
    className: "featured-ad-plans",
    access: "private",
    exact: true,
  },
  // ---------------------------- Boost ad -----------------------
  {
    path: "/boost-ad",
    component: BoostAd,
    className: "boost-ad",
    access: "private",
    exact: true,
  },
  // ---------------------------- Car Financing Plan -----------------------
  {
    path: "/car-financing/car-loan-calculator/detail",
    component: CarFinancePlan,
    className: "car-financing-plan",
    access: "public",
    exact: true,
  },
  // ---------------------------- payments -----------------------
  {
    path: "/featured-ad-plans/payment/:id",
    component: FeaturedAdPlansPayement,
    className: "featured-ad-plans-payment",
    access: "private",
    exact: true,
  },
  {
    path: "/featured-ad-plans/:adId",
    component: FeaturedAdPlans,
    className: "featured-ad-plans",
    access: "private",
    exact: true,
  },
  {
    path: "/user-featured-subscription",
    component: UserFeaturedSubscription,
    className: "user-featured-subscription",
    access: "private",
    exact: true,
  },
  {
    path: "/user-featured-subscription/:adId",
    component: UserFeaturedSubscription,
    className: "user-featured-subscription",
    access: "private",
    exact: true,
  },
  {
    path: "/user-featured-subscription/:adId/:planId",
    component: UserFeaturedSubscription,
    className: "user-featured-subscription",
    access: "private",
    exact: true,
  },
  {
    path: "/verify/:token",
    component: Verify,
    className: "verify",
    access: "public",
    exact: false,
  },
  {
    path: "/new/:type/:make",
    component: Make,
    className: "new-make",
    access: "public",
    exact: true,
  },
  {
    path: "/new/",
    component: New,
    className: "new",
    access: "public",
    exact: false,
  },
  {
    path: "/new/:type",
    component: NewCars,
    className: "new-cars",
    access: "public",
    exact: true,
  },
  {
    path: "/new-cars",
    component: NewCarsListing,
    className: "new-cars-listing-page",
    access: "public",
    exact: true,
  },
  {
    path: "/new-cars/search/-/",
    component: NewCarsListing,
    className: "new-cars",
    access: "public",
    exact: false,
  },
  {
    path: "/new-cars/pricelist",
    component: PriceList,
    className: "new-cars-price-list",
    access: "public",
    exact: true,
  },
  {
    path: "/new-cars/pricelist/:detail",
    component: PriceListDetail,
    className: "new-cars-price-list-detail",
    access: "public",
    exact: true,
  },
  {
    path: "/new-cars/:detail",
    component: NewCarsDetail,
    className: "new-cars-detail",
    access: "public",
    exact: true,
  },
  {
    path: "/new-cars/:make/:model",
    component: Varient,
    className: "new-cars",
    access: "public",
    exact: true,
  },
  // Finance
  {
    path: "/car-leasing/:detail",
    component: CarLeasing,
    className: "new-cars-leasing",
    access: "public",
    exact: true,
  },
  {
    path: "/new-cars/:title/review/:id",
    component: NewCarsReview,
    className: "new-cars-review",
    access: "public",
    exact: true,
  },
  {
    path: "/recent-car-reviews",
    component: RecentCarReviews,
    className: "recent-car-review",
    access: "public",
    exact: true,
  },
  {
    path: "/recent-bike-reviews",
    component: RecentCarReviews,
    className: "recent-bike-review",
    access: "public",
    exact: true,
  },
  {
    path: "/car-reviews/:title",
    component: ModelReview,
    className: "model-review",
    access: "public",
    exact: true,
  },
  {
    path: "/bike-reviews/:title",
    component: ModelReview,
    className: "model-review",
    access: "public",
    exact: true,
  },
  {
    path: "/new-cars-dealers",
    component: NewDealerOverview,
    className: "new-cars-dealers-listing",
    access: "public",
    exact: true,
  },
  {
    path: "/new-cars-dealers/search/-/",
    component: DealersListing,
    className: "new-cars-dealers-listing",
    access: "public",
    exact: false,
  },
  {
    path: "/new-cars-dealers/:detail",
    component: DealerDetail,
    className: "new-cars-dealers-detail",
    access: "public",
    exact: true,
  },
  {
    path: "/used-cars-dealers",
    component: DealerOverview,
    className: "UsedCarsDealers",
    access: "public",
    exact: true,
  },
  {
    path: "/used-cars-dealers/search/",
    component: DealersListing,
    className: "used-cars-dealers-listing",
    access: "public",
    exact: true,
  },
  {
    path: "/used-cars-dealers/search/-/",
    component: DealersListing,
    className: "used-cars-dealers-listing",
    access: "public",
    exact: false,
  },
  {
    path: "/used-cars-dealers/:detail",
    component: DealerDetail,
    className: "used-cars-dealers-detail",
    access: "public",
    exact: true,
  },

  // used-partss home-page
  {
    path: "/accessories-spare-parts",
    component: UsedSearch,
    className: "used-parts-home-page",
    access: "public",
    exact: true,
  },
  // {
  //   path: "/accessories-spare-parts",
  //   component: PartsListing,
  //   className: "parts-listing-page",
  //   access: "public",
  //   exact: true,
  // },

  {
    path: "/accessories-spare-parts/:orderId",
    component: PartsListing,
    className: "parts-listing",
    access: "public",
    exact: true,
  },

  // ---------------- Shop Overview ----------------

  {
    path: "/accessories-spare-parts-shop",
    component: ShopOverview,
    className: "shop-overview",
    access: "public",
    exact: true,
  },

  // ---------------- Shop Overview ----------------

  {
    path: "/accessories-spare-parts/search/-/",
    component: PartsListing,
    className: "parts-listing",
    access: "public",
    exact: false,
  },
  {
    path: "/accessories-spare-parts/detail/:detail",
    component: PartDetail,
    className: "part-detail",
    access: "public",
    exact: true,
  },
  {
    path: "/car-financing",
    component: CarFinancing,
    className: "car-financing",
    access: "public",
    exact: true,
  },
  {
    path: "/car-comparison",
    component: CarComparison,
    className: "car-comparison",
    access: "public",
    exact: true,
  },
  {
    path: "/bike-comparison",
    component: BikeComparison,
    className: "bike-comparison",
    access: "public",
    exact: true,
  },

  {
    path: "/bike-comparison/:bikes",
    component: BikeComparisonDetail,
    className: "bike-comparison-detail",
    access: "public",
    exact: false,
  },

  {
    path: "/car-comparison/:cars",
    component: CarComparisonDetail,
    className: "car-comparison-detail",
    access: "public",
    exact: false,
  },
  {
    path: "/videos",
    component: Videos,
    className: "videos",
    access: "public",
    exact: true,
  },

  {
    path: "/videos/search/-/:detail",
    component: SearchedVideos,
    className: "searched-videos",
    access: "public",
    exact: true,
  },
  {
    path: "/videos/view-all/:type",
    component: SearchedVideos,
    className: "searched-videos",
    access: "public",
    exact: false,
  },

  {
    path: "/videos/:type/:detail",
    component: VideoDetail,
    className: "video-detail",
    access: "public",
    exact: true,
  },
  {
    path: "/makes",
    component: Makes,
    className: "all-makes",
    access: "public",
    exact: true,
  },
  {
    path: "/makes/:type/:category",
    component: Makes,
    className: "all-makes",
    access: "public",
    exact: false,
  },
  {
    path: "/about-us",
    component: AboutUs,
    className: "about-us",
    access: "public",
    exact: false,
  },
  // {
  //     path: '/blogs',
  //     component: Blogs,
  //     className: 'blogs',
  //     access: 'public',
  //     exact: false
  // },
  {
    path: "/blog-details",
    component: BlogDetails,
    className: "blog-details",
    access: "public",
    exact: false,
  },
  {
    path: "/car-financing/car-loan-calculator",
    component: CarLoanCalculator,
    className: "car-loan-calculator",
    access: "public",
    exact: true,
  },
  {
    path: "/car-financing/car-loan-calculator/details",
    component: CarLoanCalculatorPayment,
    className: "car-loan-calculator-payment",
    access: "public",
    exact: false,
  },
  {
    path: "/car-financing/car-loan-calculator/query",
    component: CarLoanCalculatorQuery,
    className: "car-loan-calculator-query",
    access: "public",
    exact: false,
  },

  // {
  //     path: '/certified-cars',
  //     component: CertifiedCars,
  //     className: 'certified-cars',
  //     access: 'public',
  //     exact: false
  // },
  // {
  //     path: '/car-inspection',
  //     component: CarInspection,
  //     className: 'car-inspection',
  //     access: 'public',
  //     exact: true
  // },
  {
    path: "/car-inspection/report",
    component: CarInspectionReport,
    className: "car-inspection-report",
    access: "public",
    exact: false,
  },
  {
    path: "/car-import",
    component: CarImport,
    className: "car-import",
    access: "public",
    exact: true,
  },
  {
    path: "/car-insurance",
    component: CarInsurance,
    className: "car-insurance",
    access: "public",
    exact: true,
  },
  {
    path: "/car-insurance-banks",
    component: CarInsuranceBanks,
    className: "car-insurance-banks",
    access: "public",
    exact: true,
  },
  {
    path: "/car-insurance-banks/apply-now",
    component: CarInsuranceResult,
    className: "car-insurance-result",
    access: "public",
    exact: true,
  },
  {
    path: "/car-tracker",
    component: CarTracker,
    className: "car-tracker",
    access: "public",
    exact: true,
  },
  {
    path: "/car-import/custom-duty-calculator",
    component: CustomDutyCalculator,
    className: "custom-duty-calculator",
    access: "public",
    exact: false,
  },
  {
    path: "/auction-sheet-verification",
    component: AuctionSheet,
    className: "auction-sheet",
    access: "public",
    exact: true,
  },
  {
    path: "/auction-sheet/thank-you/:id",
    component: asThankYou,
    className: "auction-sheet",
    access: "public",
    exact: false,
  },
  {
    path: "/verified-auction-sheet/:id",
    component: AuctionReport,
    className: "auction-sheet-report",
    access: "public",
    exact: true,
  },
  {
    path: "/auctionsheet-verification/:adId",
    component: AuctionSheet,
    className: "auction-sheet",
    access: "public",
    exact: true,
  },
  {
    path: "/auctionsheet-verification/:email/:phoneNumber/:chassisNo",
    component: AuctionSheet,
    className: "auction-sheet",
    access: "public",
    exact: false,
  },
  // Auction sheet cart system
  {
    path: "/auction-sheet/verify-sheet/:chassis",
    component: AuctionSheetFind,
    className: "cart-check",
    access: "public",
    exact: true,
  },
  {
    path: "/auction-sheet/checkout",
    component: AuctionSheetCheckout,
    className: "cart-checkout",
    access: "public",
    exact: true,
  },
  {
    path: "/auction-sheet/cart",
    component: AuctionSheetCart,
    className: "find-auction-sheet",
    access: "public",
    exact: true,
  },
  // -----------------------------------
  {
    path: "/rides",
    component: Rides,
    className: "rides",
    access: "public",
    exact: true,
  },
  {
    path: "/rides/detail/:id",
    component: Detail,
    className: "ride-detail",
    access: "public",
    exact: true,
  },
  {
    path: "/rides/detail/manage/:id",
    component: ManageRide,
    className: "ride-detail-manage",
    access: "private",
    exact: false,
  },
  {
    path: "/checkout/:type",
    component: CheckOut,
    className: "checkout",
    access: "private",
    exact: false,
  },
  {
    path: "/payment",
    component: Payment,
    className: "payment-methods",
    access: "public",
    exact: true,
  },
  {
    path: "/payment/easypay-confirm/:paymentMethod/:orderId",
    component: EasyPayConfirm,
    className: "payment-easypay-confirm",
    access: "public",
    exact: true,
  },
  {
    path: "/payment/response/:paymentMethod/:orderId",
    component: PaymentResponses,
    className: "payment-responses",
    access: "public",
    exact: true,
  },
  {
    path: "/new-bikes",
    component: NewCarsListing,
    className: "new-bikes-listing-page",
    access: "public",
    exact: true,
  },
  {
    path: "/new-bikes/search/-/",
    component: NewCarsListing,
    className: "new-bikes-listing-page",
    access: "public",
    exact: false,
  },
  {
    path: "/new-bikes/pricelist",
    component: PriceList,
    className: "new-bikes-price-list",
    access: "public",
    exact: true,
  },
  {
    path: "/new-bikes/pricelist/:detail",
    component: PriceListDetail,
    className: "new-bikes-price-list-detail",
    access: "public",
    exact: true,
  },
  {
    path: "/new-bikes/:detail",
    component: NewCarsDetail,
    className: "new-bike-detail",
    access: "public",
    exact: true,
  },
  {
    path: "/new-bikes/:title/review/:id",
    component: NewCarsReview,
    className: "new-bike-review",
    access: "public",
    exact: true,
  },
  {
    path: "/payment-response/:paymentMethod/:status/:orderId/:adId/:planId",
    component: PaymentCallback,
    className: "payment-callback",
    access: "public",
    exact: false,
  },
  {
    path: "/payment-response/:paymentMethod/:status/:orderId",
    component: PaymentCallback,
    className: "payment-callback",
    access: "public",
    exact: false,
  },
  {
    path: "/thank-you",
    component: ThankYou,
    className: "thank-you",
    access: "public",
    exact: false,
  },
  {
    path: "/reset-password/:token",
    component: ResetPassword,
    className: "reset-password-page",
    access: "public",
    exact: false,
  },
  {
    path: "/safety-guide",
    component: SafetyGuide,
    className: "saftey-guide",
    access: "public",
    exact: false,
  },
  {
    path: "/sitemap",
    component: SiteMap,
    className: "site-map",
    access: "public",
    exact: true,
  },
  {
    path: "/sitemap/bikes-make",
    component: SiteMap,
    className: "site-map",
    access: "public",
    exact: true,
  },
  {
    path: "/sitemap/cars-city",
    component: ByCitySiteMap,
    className: "bycity-site-map",
    access: "public",
    exact: true,
  },
  {
    path: "/sitemap/bikes-city",
    component: ByCitySiteMap,
    className: "bycity-site-map",
    access: "public",
    exact: true,
  },
  {
    path: "/sitemap/accessories-spare-parts",
    component: PartsSiteMap,
    className: "parts-site-map",
    access: "public",
    exact: true,
  },
  {
    path: "/sitemap/accessories-spare-parts-category",
    component: PartsSiteMap,
    className: "parts-site-map",
    access: "public",
    exact: true,
  },
  {
    path: "/sitemap/accessories-spare-parts-city",
    component: ByCitySiteMap,
    className: "bycity-site-map",
    access: "public",
    exact: true,
  },
  {
    path: "/sitemap/:detail",
    component: ByYearSiteMap,
    className: "byyear-site-map",
    access: "public",
    exact: true,
  },
  {
    path: "/contact-us",
    component: ContactUs,
    className: "contact-us",
    access: "public",
    exact: true,
  },
  {
    path: "/advertise",
    component: Advertise,
    className: "advertise",
    access: "public",
    exact: true,
  },
  {
    path: "/how-to-pay",
    component: HowToPay,
    className: "how-to-pay",
    access: "public",
    exact: true,
  },
  {
    path: "/return-exchange-policy",
    component: ReturnExchange,
    className: "return-exchange-policy",
    access: "public",
    exact: true,
  },
  {
    path: "/refund-policy",
    component: RefundPolicy,
    className: "refund-policy",
    access: "public",
    exact: true,
  },
  {
    path: "/faq",
    component: Faq,
    className: "faq",
    access: "public",
    exact: true,
  },
  {
    path: "/map",
    component: Map,
    className: "map",
    access: "public",
    exact: true,
  },
  {
    path: "/our-products",
    component: ProductsByAutoDeals,
    className: "our-products-page",
    access: "public",
    exact: true,
  },
  {
    path: "/terms-of-services",
    component: TermsOfServices,
    className: "term-of-terms",
    access: "public",
    exact: true,
  },
  {
    path: "/shipment-and-returns-policy",
    component: ShipmentAndReturnsPolicy,
    className: "shipment-and-returns-policy",
    access: "public",
    exact: true,
  },
  {
    path: "/dmca-policy",
    component: DmcaPolicy ,
    className: "dmca-policy",
    access: "public",
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    className: "privacy-policy",
    access: "public",
    exact: true,
  },
  // ------------------------------ New Write a review
  {
    path: "/write-review/:title/:id",
    component: NewWriteReview,
    className: "write-review",
    access: "public",
    exact: true,
  },
  {
    path: "/write-review",
    component: WriteReviewNew,
    className: "write-review-new",
    access: "public",
    exact: true,
  },
  // ------------------------------
  {
    path: "/petrol-prices-in-pakistan",
    component: PetrolPrices,
    className: "petrol-prices",
    access: "public",
    exact: true,
  },
  // ------------------------------ New Review Detail ------------------------
  {
    path: "/new-cars/:title/review-detail/:id",
    component: NewReviewDetail,
    className: "review-detail",
    access: "public",
    exact: true,
  },
  // --------------------------------------------------------------------------
];

export default appRoutes;
