import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import React from "react";
import LocalStoreService from "./localStore-service";
import UserService from "./user-service";
import axios from "axios";
import Configuration from "../config/configuration";
import ToastModal, {
  options,
} from "../components/Shared/ToastModal/ToastModal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

class CommonService {
  constructor() {
    this.localStorage = new LocalStoreService();
    this.userService = new UserService();
    this.config = new Configuration();
  }
  // eslint-disable-next-line no-useless-escape
  validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  isEmailValid(email) {
    return this.validEmailRegex.test(email);
  }

  showToast(message, type) {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        // toast.error(message);
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ToastModal
                onClose={onClose}
                message={message}
                heading={"Alert"}
              />
            );
          },
          overlayClassName: "error-alert-overlay",
        });
        // alert(message);
        break;
      case "warn":
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ToastModal
                onClose={onClose}
                message={message}
                heading={"Alert"}
              />
            );
          },
          overlayClassName: "error-alert-overlay",
        });
        break;
      default:
        toast.info(message);
        break;
    }
  }

  showAlert(message, color) {
    return <Alert color={color}>{message}</Alert>;
  }

  checkFormValidity(errors) {
    for (let key in errors) {
      if (errors[key]) {
        return false;
      }
    }
    return true;
  }

  getUserDataLocal() {
    return JSON.parse(localStorage.getItem("userData"));
  }

  getPriceIntoWords(number, decimals, recursiveCall) {
    const decimalPoints = decimals || 2;
    const noOfLakhs = number / 100000;
    const noOfThousands = number / 1000;
    const noOfHundreds = number / 100;
    let displayStr = "";
    let isPlural;
    let splitStr = "";
    // Rounds off digits to decimalPoints decimal places
    if (number) {
      if (noOfLakhs >= 1 && noOfLakhs < 100) {
        const lakhs = this.addZero(this.roundOf(noOfLakhs, decimalPoints));
        isPlural = lakhs > 1 && !recursiveCall;
        displayStr = lakhs;
        splitStr = displayStr.split(".")[1];
        //splitStr.length > 2 ? this.addZero(this.formatCurrency(Math.round(+number))) :
        displayStr = `${lakhs} Lac${isPlural ? "s" : ""}`;
      } else if (noOfLakhs >= 100) {
        const crores = this.addZero(
          this.roundOf(noOfLakhs / 100, decimalPoints)
        );
        const crorePrefix =
          crores >= 100000
            ? this.getPriceIntoWords(crores, decimals, true)
            : crores;
        isPlural = crores > 1 && !recursiveCall;
        displayStr = crorePrefix;
        splitStr = displayStr.split(".")[1];
        //splitStr.length > 2 ? this.addZero(this.formatCurrency(Math.round(+number))) :
        displayStr = `${crorePrefix} Crore${isPlural ? "s" : ""}`;
      } else {
        displayStr = this.addZero(this.formatCurrency(Math.round(+number)));
      }
    }
    displayStr = displayStr.replace("PKR", "").trim();
    return displayStr;
  }

  getPriceIntoWordsFilter(number, decimals, recursiveCall) {
    const decimalPoints = decimals || 4;
    const noOfLakhs = number / 100000;
    const noOfThousand = number / 1000;

    let displayStr = "";
    let isPlural;
    let splitStr = "";
    // Rounds off digits to decimalPoints decimal places
    if (number) {
      if (noOfThousand >= 1 && noOfThousand <= 99) {
        const thousands = this.roundOf(noOfThousand, decimalPoints);
        isPlural = thousands > 1 && !recursiveCall;
        // displayStr = thousands.toString();
        // splitStr = displayStr.split('.')[1];
        //splitStr.length > 2 ? this.addZero(this.formatCurrency(Math.round(+number))) :
        displayStr = `${thousands} thousand${isPlural ? "s" : ""}`;
      } else if (noOfLakhs >= 1 && noOfLakhs <= 99) {
        const lakhs = this.addZero(this.roundOf(noOfLakhs, decimalPoints));
        isPlural = lakhs > 1 && !recursiveCall;
        displayStr = lakhs;
        splitStr = displayStr.split(".")[1];
        //splitStr.length > 2 ? this.addZero(this.formatCurrency(Math.round(+number))) :
        displayStr = `${lakhs} lac${isPlural ? "s" : ""}`;
      } else if (noOfLakhs >= 100) {
        const crores = this.addZero(
          this.roundOf(noOfLakhs / 100, decimalPoints)
        );
        const crorePrefix =
          crores >= 100000
            ? this.getPriceIntoWords(crores, decimals, true)
            : crores;
        isPlural = crores > 1 && !recursiveCall;
        displayStr = crorePrefix;
        splitStr = displayStr.split(".")[1];
        //splitStr.length > 2 ? this.addZero(this.formatCurrency(Math.round(+number))) :
        displayStr = `${crorePrefix} crore${isPlural ? "s" : ""}`;
      } else {
        displayStr = this.addZero(this.formatCurrency(Math.round(+number)));
      }
    }
    displayStr = displayStr.replace("PKR", "").trim();
    return displayStr;
  }

  roundOf = (integer, decimalPoints) => {
    return +integer.toLocaleString(undefined, {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints,
    });
  };

  addZero = (price) => {
    let str = price.toString();
    if (!str.split(".")[1]) {
      str = str + ".00";
    } else if (str.split(".")[1] && str.split(".")[1].length < 2) {
      str = str + "0";
    }
    return str;
  };

  formatCurrency(price) {
    let formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "PKR",
    });

    return formatter.format(+price);
  }

  getAdsStatus(status) {
    if (status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Active";
    } else {
      return "Removed";
    }
  }

  getAdsStatusFromTxt(status) {
    if (status === "Pending") {
      return 0;
    } else if (status === "Active") {
      return 1;
    } else {
      return -1;
    }
  }

  getChatsStatus(status, senderId, currentUserId) {
    if (status === 0) {
      return senderId === currentUserId ? "Sent" : "Inbox";
    } else if (status === 1) {
      return "Inbox";
    } else if (status === -1) {
      return "Archived";
    }
  }

  bannerRedirection = (link) => {
    window.open(link, "_blank");
  };

  updateProfile = (data) => {
    const { userId, id } = this.localStorage.getUserProfile();
    const profileData = { userId, id, ...data };
    this.userService
      .updateUserProfile(profileData)
      .then((res) => {
        const { data } = res;
        if (data && data.data) {
          this.localStorage.saveUserProfile(data.data);
        }
      })
      .catch((err) => {
        this.userService.handleError(err);
      });
  };

  getAdsBanners(pageName) {
    const instance = axios.create();
    return instance.get(
      this.config.apiBaseUrl + `advertisementimages/page?page=${pageName}`
    );
  }

  //#region for filters
  getQuery = (queryP) => {
    const {
      selectedCities,
      selectedRegCities,
      selectedMakes,
      selectedModels,
      selectedVariants,
      selectedPCats,
      selectedPSubCats,
      selectedColors,
      selectedBodyTypes,
      searchStr,
      onlyNew,
      maxPrice,
      minPrice,
      minYear,
      maxYear,
      minMil,
      maxMil,
      engineType,
      minEC,
      maxEC,
      minBC,
      maxBC,
      assembly,
      transmission,
      adType,
      sellerType,
      pictureAvail,
      sId,
      certified,
    } = queryP;

    let query = `/search/-${this.getIdBasedQuery(
      "mk",
      selectedMakes
    )}${this.getIdBasedQuery("md", selectedModels)}${this.getIdBasedQuery(
      "vr",
      selectedVariants
    )}${this.getIdBasedQuery("bodyT", selectedBodyTypes)}
        ${this.getIdBasedQuery("pCat", selectedPCats)}${this.getIdBasedQuery(
      "pSubCat",
      selectedPSubCats
    )}
        ${this.getNameBasedQuery("ct", selectedCities)}${this.getNameBasedQuery(
      "rg",
      selectedRegCities
    )}${this.getNameBasedQuery("color", selectedColors)}
        ${minPrice ? "/minP_" + minPrice : ""}${
      maxPrice ? "/maxP_" + maxPrice : ""
    }${engineType ? "/engT_" + engineType : ""}${
      minEC ? "/minEC_" + minEC : ""
    }${maxEC ? "/maxEC_" + maxEC : ""}${
      minBC ? "/minBC_" + minBC : ""
    }${maxBC ? "/maxBC_" + maxBC : ""}${minYear ? "/minY_" + minYear : ""}
        ${maxYear ? "/maxY_" + maxYear : ""}${minMil ? "/minM_" + minMil : ""}${
      maxMil ? "/maxM_" + maxMil : ""
    }${transmission ? "/transmission_" + transmission : ""}${
      assembly ? "/assembly_" + assembly : ""
    }${searchStr ? "/searchStr_" + searchStr.replace(/\s/g, "-") : ""}
        ${onlyNew ? "/onlyN_" + onlyNew : ""}${adType ? "/adT_" + adType : ""}${
      sellerType ? "/sellT_" + sellerType : ""
    }${pictureAvail ? "/picture_" + pictureAvail : ""}${
      certified ? "/cert_" + certified : ""
    }`; //${certified ? '/cert_' + certified : ''} ${sId ? 'sId_' + sId : ''}
    query = query.replace(/\s/g, "");
    query = query === "/search/-" ? "/search/-/" : query;
    return query;
  };

  getIdBasedQuery = (shortName, list = []) => {
    let str = "";
    if (list && list.length) {
      list.forEach((x) => {
        str =
          str +
          `/${shortName}_${x.name
            .toLowerCase()
            .replace(/\s/g, "-")
            .replace(/\//g, "-")}_${x.id}`;
      });
    }
    return str;
  };

  getNameBasedQuery = (shortName, list = []) => {
    let str = "";
    if (list && list.length) {
      list.forEach((x) => {
        str = str + `/${shortName}_${x.toLowerCase().replace(/\s/g, "-")}`;
      });
    }
    return str;
  };

  getSearchValues = (shortName, location) => {
    const { pathname } = location;
    let val = "";
    if (pathname.includes("search/-/")) {
      let segments = pathname.split("/");
      segments.forEach((x) => {
        const temp = x.split(shortName + "_");
        if (temp && temp.length > 1) {
          val =
            val +
            (val ? "," : "") +
            temp[1]
              .split("_")
              [temp[1].split("_").length - 1].replace(/-/g, " ");
        }
      });
    }
    return val;
  };

  getSearchValuesObj = (filterQuery, location) => {
    filterQuery = {
      mk: "",
      md: "",
      vr: "",
      ct: "",
      rg: "",
      color: "",
      bodyT: "",
      minY: "",
      maxY: "",
      minM: "",
      maxM: "",
      minP: "",
      maxP: "",
      onlyN: "",
      pCat: "",
      pSubCat: "",
      engT: "",
      minEC: "",
      maxEC: "",
      minBC: "",
      maxBC: "",
      transmission: "",
      assembly: "",
      adT: "",
      sellT: "",
      picture: "",
      searchStr: "",
      sId: "",
      cert: "",
      ...filterQuery,
    };
    for (let key in filterQuery) {
      filterQuery[key] = this.getSearchValues(`${key}`, location);
    }
    console.log(filterQuery);
    return filterQuery;
  };
  //#endregion for filters

  getOrderStatus = (status) => {
    if (status === 0) {
      return "Payment Pending";
    } else if (status === 1) {
      return "Delivered";
    } else if (status === -1) {
      return "Canceled";
    } else if (status === 2) {
      return "In Processing";
    }
  };

  getPageMetaData = (page) => {
    return axios.get(this.config.apiBaseUrl + `metadata/bypage/${page}`);
  };

  checkDescription = (value) => {
    let regExp = /\d+/g;
    let format = /[!@#$^&*()_+\=\[\]{};':"\\|<>\?]+/;
    let description;
    if (value.length < 1000) {
      // if (format.test(value)) {
      //     return true;
      // }
      // while ((description = regExp.exec(value)) != null) {
      //     if (description[0].length > 10) {
      //         return true
      //     }
      // }
      return false;
    }
    return true;
  };

  handleError(error) {
    console.log(error.message);
  }
}

export default CommonService;
