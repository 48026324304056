import React, { Component } from "react";
import { GrLocation } from "react-icons/gr";
import { AiOutlineEye } from "react-icons/ai";
import { FiPhone, FiCheck, FiStar } from "react-icons/fi";
import { BiPencil, BiImages } from "react-icons/bi";
import "./NewPartsListItemHorizontal.scss";
import { Link, withRouter } from "react-router-dom";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import CommonService from "../../../services/common-service";
import LocalStoreService from "../../../services/localStore-service";
import AdsService from "../../../services/ads-service";
import UserService from "../../../services/user-service";
import { ReactSVG } from "react-svg";

class NewProductListItemHorizontal extends Component {
  constructor() {
    super();
    this.commonService = new CommonService();
    this.localService = new LocalStoreService();
    this.adsService = new AdsService();
    this.userService = new UserService();
    this.state = {
      product: {},
      className: "",
      isAuth: false,
      userId: 0,
      callFrom: "",
      addclass: false,
    };
  }

  componentDidMount() {
    this.setState({
      isAuth: this.checkAuth(),
      userId: this.localService.getUserId(),
      className: this.props.data,
      product: this.props.data ? this.props.data : {},
      callFrom: this.props.callFrom,
    });
  }

  componentDidUpdate(prevProp) {
    if (this.props.data) {
      if (prevProp.data !== this.props.data) {
        this.setState({
          className: this.props.data,
          product: this.props.data,
        });
      }
    }
  }

  checkAuth = () => {
    const token = this.localService.getUserToken();
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  handleDelete = (product) => {
    this.props.onDelete(product);
  };

  handleEdit = (product) => {
    this.props.onEdit(product);
  };

  handleFavourite = (product, type) => {
    const { userId } = this.state;
    if (userId) {
      if (type === "addFav") {
        this.addFavourite(product);
      } else if (type === "removeFav") {
        this.removeFavourite(product);
      }
    }
  };

  checkFavourite = (product) => {
    if (product && product.favourites && product.favourites.length) {
      let isFavourite = false;
      product.favourites.forEach((fav) => {
        if (fav.user_favourite_ads) {
          const { userId } = fav.user_favourite_ads;
          const loginUserId = this.state.userId;
          if (userId === loginUserId) {
            isFavourite = true;
          }
        }
      });
      return isFavourite;
    }
  };

  goToFeaturedSubscription = () => {
    const { product } = this.state;
    this.props.history.push({ pathname: `/boost-ad/`, state: { adId: product.id } });
  };

  addFavourite = (product) => {
    const { userId } = this.state;
    let formData = { userId, adId: product.id };
    this.adsService
      .addFavouriteAd(formData)
      .then((res) => {
        const { message } = res.data;
        if (message === "Favourite added successfully") {
          this.commonService.showToast("Favourite added successfully", "success");
          // this.getUserAds(userId, currrentStatus);
          this.props.onFavourite();
        } else {
          this.commonService.showToast("Favourite not added!", "error");
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  removeFavourite = (product) => {
    const { userId } = this.state;
    let formData = { userId, adId: product.id };
    this.adsService
      .removeFavouriteAd(formData)
      .then((res) => {
        const { message } = res.data;
        if (message === "Favourite removed successfully") {
          this.commonService.showToast("Favourite removed successfully", "success");
          this.props.onFavourite();
        } else {
          this.commonService.showToast("Favourite not added!", "error");
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  activateAd = (product) => {
    this.props.onActivateAd(product);
  };

  getTitle = (product) => {
    if (product) {
      if (product.title) {
        return product.title;
      } else if (product.productInfo) {
        let info = JSON.parse(product.productInfo);
        // let prodInfo = ''
        // if (info && info.make && info.model && info.variant && info.year) {
        const prodInfo = `${info.make ? info.make.name : ""} ${info.model ? info.model.name : ""} ${info.variant ? info.variant.name : ""}`;
        return (
          <h6>
            <span>{info.year ? info.year.value : ""}</span> {prodInfo}
          </h6>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  toggleAdsDots = () => {
    this.setState({ addClass: !this.state.addClass });
  };

  render() {
    const { isAuth, product, className, callFrom, addClass, userId } = this.state;
    return (
      <div id="ad-card" className="card mb-3">
        <div className="main-img">
          <Link
            to={{
              pathname: `/used-${product.category + "s"}/${
                product && product.title
                  ? product.title.toLowerCase().trim().replace(/\s/g, "-").replace(/\//g, "_") // for replacing forward slashes
                  : ""
              }-${product.id}`,
            }}
          >
            <img loading="lazy" className="brand-image" src={product.images && product.images.length ? product.images[0].url : require("../../../assets/Images/car-placeholder.png")} alt="car" />
          </Link>

          <div className="certified-icon">{product && product.isCertified ? <ReactSVG src={require("../../../assets/Images/certified.svg")} /> : ""}</div>
          {product.status !== -1
            ? product.sellerId === userId &&
              product.featured &&
              product.featured.length <= 0 &&
              callFrom === "dashboard-ads" && (
                <>
                  {callFrom === "dashboard-ads" && product.status !== -1 && product.sellerId === userId && (
                    <button type="button" className="btn active-btn bg-danger" onClick={() => this.handleDelete(product)}>
                      <i className="far fa-trash-alt"></i> Delete
                    </button>
                  )}
                </>
              )
            : product.sellerId === userId &&
              callFrom === "dashboard-ads" && (
                <div className="product-boster">
                  <div className="activate-button">
                    <button type="button" className="btn active-btn" onClick={() => this.activateAd(product)}>
                      <i class="fas fa-redo-alt"></i> Re-Activate
                    </button>
                  </div>
                </div>
              )}

          {isAuth && (
            <div className="">
              {userId !== product.sellerId ? (
                this.checkFavourite(product) ? (
                  <span className="favourit edit-btn" onClick={() => this.handleFavourite(product, "removeFav")}>
                    <i className="fas fa-heart"></i>
                  </span>
                ) : (
                  <span className="favourit edit-btn" onClick={() => this.handleFavourite(product, "addFav")}>
                    <i className="far fa-heart"></i>
                  </span>
                )
              ) : (
                <>
                  {callFrom === "dashboard-ads" && product.status !== -1 && product.sellerId === userId && (
                    <button type="button" className="btn edit-btn" onClick={() => this.handleEdit(product)}>
                      <i className="fas fa-pen"></i> Edit
                    </button>
                  )}
                </>
              )}
            </div>
          )}
          <Link
            className="img-count"
            to={{
              pathname: `/used-${product.category + "s"}/${
                product && product.title
                  ? product.title.toLowerCase().trim().replace(/\s/g, "-").replace(/\//g, "_") // for replacing forward slashes
                  : ""
              }-${product.id}`,
              state: { item: product },
            }}
          >
            <BiImages /> {product.images ? product.images.length : 0}
          </Link>
          {product.featured && product.featured.length > 0 && (
            <span className="product-horizontal-label">
              <span>
                <FiStar /> Featured
              </span>
              {/* <i class="fa fa-exclamation-circle"></i> */}
              {/* <img loading="lazy" src={require('../../../assets/Images/featured/isfeature.png')} alt="used" /> */}
            </span>
          )}
        </div>
        <div className="ad-car-details mt-2">
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="text-dark">{product.title}</h2>
              <h2>
                <span className="product-horizontal-price">{product.price ? `PKR ${this.commonService.getPriceIntoWords(product.price)}` : "Price On Call"}</span>
              </h2>
              <p>
                <GrLocation /> {product.city}
              </p>
            </div>
            <div className="ad-views">
              <p className="mb-0">
                <AiOutlineEye /> {product.history && product.history.views ? product.history.views : 0} Ad Views
              </p>
              {/* <p className="mb-0">
                <AiOutlineEye /> 2030 Total Search Views
              </p> */}
              <p className="mb-0">
                <FiPhone /> {product.history && product.history.calls ? product.history.calls : 0} Calls
              </p>
            </div>
          </div>
          <div className="border-top pt-2 d-flex justify-content-between flex-md-row flex-column">
            {product.category !== "part" && (
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  {product.category === "car" && (
                    <p className="mb-0 d-flex pr-4">
                      <ReactSVG
                        src={require("../../../assets/Images/productDetail/gearshiftprimary.svg")}
                        beforeInjection={(svg) => {
                          svg.classList.add("svg-class-name");
                          svg.setAttribute("style", "width: 14px; height: 100%");
                        }}
                      />{" "}
                      &nbsp;
                      {product.transmission}
                    </p>
                  )}
                  <p className="mb-0  d-flex pr-4">
                    <ReactSVG
                      src={require("../../../assets/Images/productDetail/fuelprimary.svg")}
                      beforeInjection={(svg) => {
                        svg.classList.add("svg-class-name");
                        svg.setAttribute("style", "width: 14px; height: 100%");
                      }}
                    />{" "}
                    &nbsp;
                    {product.engineType}
                  </p>
                  <p className="mb-0 d-flex pr-4">
                    <ReactSVG
                      src={require("../../../assets/Images/productDetail/engineprimary.svg")}
                      beforeInjection={(svg) => {
                        svg.classList.add("svg-class-name");
                        svg.setAttribute("style", "width: 14px; height: 100%");
                      }}
                    />{" "}
                    &nbsp;
                    {product.engineCapacity}CC
                  </p>
                </div>
              </div>
            )}
            {/* <button className="btn adp-btn">Boost Your Ad</button> */}
            {product.status !== -1
              ? product.sellerId === userId &&
                product.featured &&
                product.featured.length <= 0 &&
                callFrom === "dashboard-ads" && (
                  <div className="product-boster">
                    {/* {product.category === "car" ? (
                  <div className="booster-text">Sell Your Car Faster</div>
                ) : (
                  <div className="booster-text">Sell Your Bike Faster</div>
                )} */}
                    <div className="booster-button mt-md-0 mt-3">
                      <button type="button" className="btn adp-btns w-100" onClick={this.goToFeaturedSubscription}>
                        Boost Your Ad
                      </button>
                    </div>
                  </div>
                )
              : product.sellerId === userId && callFrom === "dashboard-ads" && ""}
            {product.isFeatured == 1 && callFrom === "dashboard-ads" && (
              <div className="product-boster">
                <div className="booster-button mt-md-0 mt-3">
                  <button type="button" className="btn adp-btns w-100">
                    Boosted!
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="horizontal-milage">
            <div className="auciton-verify-horizontal">
              {product.category === "car" && product.assembly === "Imported" && product.aucId && product.auction && product.auction.status === 1 && (
                <ReactSVG src={require("../../../assets/Images/auction-sheet-logo.svg")} />
              )}
            </div>
            <div className="certified-horizontal"></div>
          </div>

          {/* <small className="text-muted mt-2">Last Update 9 Month ago</small> */}
        </div>
      </div>
    );
  }
}

export default withRouter(NewProductListItemHorizontal);
