import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./theming/variables.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import httpIntercept from "./intercepters/http-intercepter";
import configureReduxStore from "./store/reduxStore";
import { ThroughProvider } from "react-through";
import "./assets/styles/custom.scss";

const store = configureReduxStore(window.REDUX_INITIAL_DATA);
httpIntercept(store);

ReactDOM.render(
  <Provider store={store}>
    <ThroughProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThroughProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
