import React, { Component } from "react";
import NewPartsListItemHorizontal from "../../Shared/NewPartsListItemHorizontal/NewPartsListItemHorizontal";
import PartsListingHorizontal from "../../Parts/Listing/ListingHorizontal/ListingHorizontal";
import CommonService from "../../../services/common-service";
import AdsService from "../../../services/ads-service";
import LocalStoreService from "../../../services/localStore-service";
import CategoryTabs from "../CategoryTabs/CategoryTabs";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Paginate from "../../Shared/Paginate/Paginate";
import "./NewFavorites.scss";

class NewFavorites extends Component {
  constructor() {
    super();
    this.commonService = new CommonService();
    this.adsService = new AdsService();
    this.localService = new LocalStoreService();

    this.state = {
      isFavourite: true,
      favouriteAds: [],
      userId: 0,
      activeTab: "1",
      pageNo: 0,
      pageSize: 12,
      totalPages: 1,
      hasData: "",
      totalPages: "",
    };
  }
  items = [1, 2, 3, 4, 5];

  componentDidMount() {
    const userId = this.localService.getUserId();
    this.setState(
      {
        userId: userId,
      },
      () => {
        this.toggleCatergoy("1");
      }
    );
  }

  handlePageClick = ({ selected }) => {
    this.setState(
      {
        pageNo: selected ? selected : 0,
      },
      () => {
        const { activeTab } = this.state;
        if (activeTab === "1") {
          this.getUserAds("car");
        } else {
          this.getUserAds("part");
        }
      }
    );
  };

  getFavouritesAds = (userId, category) => {
    const { pageNo, pageSize } = this.state;
    this.adsService
      .getMyFavouritesAds(pageNo, pageSize, userId, category)
      .then((res) => {
        const { data } = res;
        if (data && data.records && data.records.length) {
          this.setState({
            totalPages: data.totalPages,
            totalRecords: data.count,
            hasData: true,
            favouriteAds: data.records,
          });
        } else {
          this.setState({
            totalPages: data.totalPages,
            totalRecords: data.count,
            favouriteAds: data.records,
            hasData: false,
          });
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  favouriteHandler = (item, type) => {
    const { userId, activeTab } = this.state;
    if (activeTab === "1") {
      this.getFavouritesAds(userId, "vehicles");
    } else {
      this.getFavouritesAds(userId, "part");
    }
  };

  toggleCatergoy = (tab) => {
    const { userId } = this.state;
    this.setState({
      activeTab: tab,
    });
    if (tab === "1") {
      this.getFavouritesAds(userId, "vehicles");
    } else {
      this.getFavouritesAds(userId, "part");
    }
  };

  render() {
    const { favouriteAds, userId, activeTab, hasData, totalPages } = this.state;

    return (
      <div id="new-favorites-ad" className="example">
        <BreadcrumbsItem to="/dashboard/favourites"></BreadcrumbsItem>
        <div className="row">
          <div className="col-md-8 ad-lefts">
            <CategoryTabs tab={activeTab} onToggleCatergoy={this.toggleCatergoy} />
            {favouriteAds && favouriteAds.length > 0
              ? favouriteAds.map((item) => {
                  item.userId = userId;
                  return (
                    // <ProductListItemHorizontal key={item.id} data={item} callFrom={'dashboard-fav-ads'} onFavourite={this.favouriteHnadler} />
                    item.category === "part" ? (
                      <PartsListingHorizontal
                        callFrom={"dashboard-ads"}
                        key={item.id}
                        data={item}
                        onDelete={this.deleteHandler}
                        onEdit={this.editHandler}
                        onFavourite={this.favouriteHandler}
                      ></PartsListingHorizontal>
                    ) : (
                      <NewPartsListItemHorizontal
                        key={item.id}
                        data={item}
                        callFrom={"dashboard-ads"}
                        onDelete={this.deleteHandler}
                        onEdit={this.editHandler}
                        onFavourite={this.favouriteHandler}
                      ></NewPartsListItemHorizontal>
                    )
                  );
                })
              : // : <h5 className="text-center pt-4">No Record Found!</h5>
                ""}
            <div className="listing-loadmore">
              {hasData === true ? (
                <Paginate totalPages={totalPages} onPageChange={this.handlePageClick} />
              ) : hasData === false ? (
                <h5 className="text-center pt-4">No Record Found!</h5>
              ) : (
                <h5 className="text-center pt-4">Loading...</h5>
              )}
            </div>
          </div>
          <div className="col-sm-4 ad-rights">
            <div className="mt-5">
              <img loading="lazy" src={require("../../../assets/Images/dummy.png")} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NewFavorites;
