import React, { Component } from "react";
import "./NewAds.scss";
import NewPartsListItemHorizontal from "../../Shared/NewPartsListItemHorizontal/NewPartsListItemHorizontal";
import PartsListingHorizontal from "../../Parts/Listing/ListingHorizontal/ListingHorizontal";
import RightMenu from "../RightMenu/RightMenu";
import AdsService from "../../../services/ads-service";
import CommonService from "../../../services/common-service";
import LocalStoreService from "../../../services/localStore-service";
import CategoryTabs from "../CategoryTabs/CategoryTabs";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Paginate from "../../Shared/Paginate/Paginate";

class NewAds extends Component {
  constructor() {
    super();
    this.commonService = new CommonService();
    this.adsService = new AdsService();
    this.localService = new LocalStoreService();

    this.state = {
      activeTab: "1",
      options: [
        {
          title: "Active",
          count: 0,
          active: true,
          items: [],
        },
        {
          title: "Pending",
          count: 0,
          active: false,
          items: [],
        },
        {
          title: "Removed",
          count: 0,
          active: false,
          items: [],
        },
      ],
      ads: [],
      selectedAds: [],
      statusCount: [],
      userId: 0,
      currrentStatus: "Active",
      pageNo: 0,
      pageSize: 12,
      totalPages: 1,
      hasData: "",
      totalPages: "",
    };
  }

  componentDidMount() {
    this.setState(
      {
        userId: this.localService.getUserId(),
      },
      () => {
        this.toggleCatergoy("1");
      }
    );
  }

  handlePageClick = ({ selected }) => {
    this.setState(
      {
        pageNo: selected ? selected : 0,
      },
      () => {
        const { activeTab } = this.state;
        if (activeTab === "1") {
          this.getUserAds("vehicles");
        } else {
          this.getUserAds("part");
        }
      }
    );
  };

  getUserAds(category) {
    const { pageNo, pageSize, userId, currrentStatus } = this.state;
    this.adsService
      .getUserAds(pageNo, pageSize, userId, this.commonService.getAdsStatusFromTxt(currrentStatus), category)
      .then((res) => {
        const { data } = res;
        if (data && data.records && data.records.length) {
          this.setState(
            {
              totalPages: data.totalPages,
              totalRecords: data.count,
              ads: data.records,
              statusCount: data.statusCount,
              hasData: true,
            },
            () => {
              this.setAdsBasedOnStatus(currrentStatus);
            }
          );
        } else {
          this.setState(
            {
              totalPages: data.totalPages,
              totalRecords: data.count,
              ads: data.records,
              statusCount: data.statusCount,
              hasData: false,
            },
            () => {
              this.setAdsBasedOnStatus(currrentStatus);
            }
          );
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  }

  setAdsBasedOnStatus = (status) => {
    let adItems = [];
    const { options, ads, statusCount } = this.state;
    options.forEach((option) => {
      const statusNum = this.commonService.getAdsStatusFromTxt(option.title);
      adItems.push({
        active: option.active,
        title: option.title,
        count: statusCount && statusCount.filter((x) => x.status === statusNum)[0] ? statusCount.filter((x) => x.status === statusNum)[0].count : 0,
        items: ads,
      });
    });
    this.setState({
      options: adItems,
      selectedAds: adItems && adItems.length > 0 ? (adItems.filter((x) => x.title === status)[0] ? adItems.filter((x) => x.title === status)[0].items : []) : [],
    });
  };

  deleteHandler = (item) => {
    this.deleteAd(item, item.id);
  };

  editHandler = (item) => {
    this.editAd(item);
  };

  activateHandler = (item) => {
    this.activateAd(item, item.id);
  };

  favouriteHandler = (item, type) => {
    const { activeTab } = this.state;
    this.toggleCatergoy(activeTab);
  };

  editAd = (item) => {
    if (item.category === "car") {
      this.props.history.push({ pathname: "/post-ad/sell-car/post-your-ad/edit", state: { item, update: true } });
    } else if (item.category === "bike") {
      this.props.history.push({ pathname: "/post-ad/sell-bike/edit", state: { item, update: true } });
    } else {
      this.props.history.push({ pathname: "/post-ad/sell-part/edit", state: { item, update: true } });
    }
  };

  deleteAd = (formData, id) => {
    formData.status = -1;
    this.adsService
      .updateAd(formData, id)
      .then((res) => {
        const { message } = res.data;
        if (message === "Ad updated successfully") {
          this.commonService.showToast("Ad removed successfully!", "success");
          this.toggleCatergoy(this.state.activeTab);
        } else {
          this.commonService.showToast("Ad not removed!", "error");
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  activateAd = (formData, id) => {
    formData.status = 0;
    this.adsService
      .updateAd(formData, id)
      .then((res) => {
        const { message } = res.data;
        if (message === "Ad updated successfully") {
          this.commonService.showToast("Ad activated successfully!", "success");
          this.toggleCatergoy(this.state.activeTab);
        } else {
          this.commonService.showToast("Ad not activated!", "error");
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  selectLeftMenuHandler = (option, options, index) => {
    this.setState(
      {
        options,
        selectedAds: option.items,
        currrentStatus: option.title,
      },
      () => {
        const { activeTab } = this.state;
        if (activeTab === "1") {
          this.getUserAds("vehicles");
        } else {
          this.getUserAds("part");
        }
      }
    );
  };

  toggleCatergoy = (tab) => {
    this.setState({
      activeTab: tab,
    });
    if (tab === "1") {
      this.getUserAds("vehicles");
    } else {
      this.getUserAds("part");
    }
  };

  render() {
    const { selectedAds, userId, options, activeTab, hasData, totalPages } = this.state;

    return (
      <div id="new-ads" className="example">
        <BreadcrumbsItem to="/dashboard/ads">
          Dashboard <i className="fas fa-chevron-right"></i> Ads
        </BreadcrumbsItem>

        <div className="row">
          <div className="col-sm-8 ad-lefts">
            <CategoryTabs tab={activeTab} onToggleCatergoy={this.toggleCatergoy} />
            {window.innerWidth < 480 ? <div className="">{options && <RightMenu showButton={true} options={options} onSelect={this.selectLeftMenuHandler} />}</div> : ""}
            {selectedAds && selectedAds.length > 0
              ? selectedAds.map((item) => {
                  item.userId = userId;
                  return item.category === "part" ? (
                    // parts listing
                    <PartsListingHorizontal
                      callFrom={"dashboard-ads"}
                      key={item.id}
                      data={item}
                      onDelete={this.deleteHandler}
                      onActivateAd={this.activateHandler}
                      onEdit={this.editHandler}
                      onFavourite={this.favouriteHandler}
                    />
                  ) : (
                    // cars listing
                    <NewPartsListItemHorizontal
                      key={item.id}
                      data={item}
                      callFrom={"dashboard-ads"}
                      onDelete={this.deleteHandler}
                      onActivateAd={this.activateHandler}
                      onEdit={this.editHandler}
                      onFavourite={this.favouriteHandler}
                    />
                  );
                })
              : // : <h5 className="text-center pt-4">No ad found!</h5>
                ""}
            <div className="listing-loadmore">
              {hasData === true ? (
                <Paginate totalPages={totalPages} onPageChange={this.handlePageClick} />
              ) : hasData === false ? (
                <h5 className="text-center pt-4">No Record Found!</h5>
              ) : (
                <h5 className="text-center pt-4">Loading...</h5>
              )}
            </div>
            {/* </TabPane>
            </TabContent> */}
          </div>
          <div className="col-sm-4 ad-rights">
            <div className="web-view">{options && <RightMenu options={options} onSelect={this.selectLeftMenuHandler} />}</div>
            <div className="mt-5">
              <img loading="lazy" src={require("../../../assets/Images/dummy.png")} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewAds;
