import React, { Component } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import ChatBox from "./ChatBox/ChatBox";
import MessageInput from "./MessageInput/MessageInput";
import RightMenu from "../RightMenu/RightMenu";
import "./NewMessages.scss";
import { BiSearchAlt } from "react-icons/bi";
import Configuration from "../../../config/configuration";
import io from "socket.io-client";
import LocalStoreService from "../../../services/localStore-service";
import moment from "moment";
import { connect } from "react-redux";
import UserService from "../../../services/user-service";
import CommonService from "../../../services/common-service";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { actions } from "../../../store/actions/index";
import { BiChevronLeft } from "react-icons/bi";

class NewMessages extends Component {
  constructor() {
    super();
    this.config = new Configuration();
    this.localStorageService = new LocalStoreService();
    this.userService = new UserService();
    this.commonService = new CommonService();
    this.state = {
      socket: {},
      textSelection: false,
      showMessageList: true,
      showMessageContent: false,
      chats: [],
      checkedChat: {},
      activeChat: null,
      receiverId: 0,
      messages: [],
      typingUsers: [],
      userProfile: "",
      accept: true,
      showMessageInput: false,
      showMessageInputBlock: false,
      currrentStatus: "Inbox",
      selectedChats: [],
      show: true,
      options: [
        {
          title: "Inbox",
          count: 0,
          active: true,
          items: [],
        },
        {
          title: "Sent",
          count: 0,
          active: false,
          items: [],
        },
        {
          title: "Archived",
          count: 0,
          active: false,
          items: [],
        },
      ],
    };
  }

  componentDidMount() {
    this.setState(
      {
        receiverId: this.localStorageService.getUserId(),
        userProfile: this.localStorageService.getUserProfile(),
        socket: this.props.socket,
      },
      () => {
        this.getAllChat();
        this.registerGetMessageSocketListener();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.socket !== this.props.socket) {
      this.setState(
        {
          socket: this.props.socket,
        },
        () => this.getAllChats()
      );
    }
  }

  getAllChat = () => {
    const { socket, receiverId } = this.state;
    if (receiverId) {
      socket.emit("user-all-messages", receiverId, this.setResponse);
    }
  };

  deleteChat = () => {
    const { checkedChat, userProfile } = this.state;
    if (checkedChat && checkedChat.id) {
      this.userService
        .deleteChat(userProfile.userId, checkedChat.id)
        .then((res) => {
          const { data } = res;
          if (data && data.message === "Chat deleted successfully") {
            this.commonService.showToast(data.message, "success");
            this.getAllChat();
          }
        })
        .catch((err) => {
          this.commonService.showToast("Something went wrong!");
          this.userService.handleError(err);
        });
    } else {
      this.commonService.showToast("Please select a chat to delete", "error");
    }
  };

  setResponse = (res) => {
    if (res) {
      if (this.props.location.state && this.props.location.state.chatId) {
        res.forEach((element) => {
          if (element.id === this.props.location.state.chatId) {
            this.toggleMessageContent(element);
          }
        });
        this.setState(
          {
            chats: res,
          },
          () => this.setChatsBasedOnStatus(this.state.currrentStatus)
        );
      } else {
        this.setState(
          {
            chats: res,
          },
          () => this.setChatsBasedOnStatus(this.state.currrentStatus)
        );
      }
    }
  };

  setChatsBasedOnStatus = (status) => {
    let chatItems = [];
    const { options, chats } = this.state;
    options.forEach((option) => {
      chatItems.push({
        active: option.active,
        title: option.title,
        count: this.getChatsBasedOnStatus(chats, option.title).length,
        items: this.getChatsBasedOnStatus(chats, option.title),
      });
    });
    this.setState({
      options: chatItems,
      selectedChats: chatItems && chatItems.length > 0 ? (chatItems.filter((x) => x.title === status)[0] ? chatItems.filter((x) => x.title === status)[0].items : []) : [],
    });
  };

  getChatsBasedOnStatus = (data, status) => {
    let chats = [];
    if (data && data.length) {
      data.forEach((chat) => {
        const chatStatus = this.commonService.getChatsStatus(chat.status, chat.sender.id, this.localStorageService.getUserId());
        if (chatStatus === status) {
          chats.push(chat);
        }
      });
    }
    return chats;
  };

  registerGetMessageSocketListener = () => {
    const { socket, receiverId } = this.state;
    if (receiverId) {
      socket.on(`initiate-chat-response-${receiverId}`, (chat, id) => {
        if (receiverId === id) {
          const { chats } = this.state;
          chats.push(chat);
          this.setState({ chats });
        }
      });
    }
  };

  toggleMessageContent = (chat) => {
    const { receiverId } = this.state;
    this.updatedUnreadMessages(chat.id, receiverId);
    let showMessageInput = false;
    let showMessageInputBlock = true;
    if (chat.status === 0) {
      if (receiverId === chat.sender.id) {
        showMessageInput = true;
      } else {
        showMessageInput = false;
      }
    } else if (chat.status === 1) {
      showMessageInput = true;
    } else {
      showMessageInputBlock = false;
    }
    this.setState(
      {
        // this line
        showMessageList: true,
        showMessageContent: true,
        messages: chat.messages,
        activeChat: chat,
        showMessageInput: showMessageInput,
        showMessageInputBlock: showMessageInputBlock,
      },
      () => {
        this.addChat();
      }
    );
  };

  updatedUnreadMessages = (chatId, receiverId) => {
    const data = { status: 1 };
    const { socket } = this.state;
    this.userService
      .updatedUnreadMessages(data, chatId, receiverId)
      .then((res) => {
        socket._update = `To update counter at header ${Date.now()}`;
        this.props.setSocket(socket);
      })
      .catch((err) => {
        this.userService.handleError(err);
      });
  };

  addChat = () => {
    const { socket, activeChat } = this.state;
    let messageEvent = `MESSAGE_RECIEVED-${activeChat.id}`;
    const typingEvent = `TYPING-${activeChat.id}`;

    socket.on(typingEvent, this.updateTypingInChat(activeChat.id));
    socket.on(messageEvent, this.addMessageToChat(activeChat.id));
  };

  addMessageToChat = (chatId) => {
    return (message) => {
      const { chats } = this.state;
      let newChats = chats.map((chat) => {
        if (chat.id === chatId) chat.messages.push(message);
        return chat;
      });
      this.setState({ chats: newChats });
    };
  };

  sendMessage = (chatId, message) => {
    const { socket } = this.state;
    socket.emit("MESSAGE_SENT", { chatId, message, messageType: "", status: 0 });
  };

  sendTyping = (chatId, isTyping) => {
    const { socket } = this.state;
    socket.emit("TYPING", { chatId, isTyping });
  };

  setActiveChat = (activeChat) => {
    this.setState({ activeChat });
  };

  updateTypingInChat = (chatId) => {
    return ({ isTyping, user }) => {
      if (user !== this.state.userProfile.firstName) {
        const { typingUsers, activeChat } = this.state;
        let newTypingUsers = [];
        if (activeChat.id === chatId) {
          if (isTyping && !typingUsers.includes(user)) {
            newTypingUsers.push(user);
          } else if (!isTyping && typingUsers.includes(user)) {
            newTypingUsers = typingUsers.filter((u) => u !== user);
          }
        }
        this.setState({ typingUsers: newTypingUsers });
      }
    };
  };

  calculateDays(dateTime) {
    let string = "";
    let eventdate = moment(dateTime).format("YYYY-MM-DD");
    let todaysdate = moment();
    let diff = todaysdate.diff(eventdate, "days");
    if (diff === 0) {
      string = "Today";
    } else {
      if (diff === 1) {
        string = diff + " Day";
      } else {
        string = diff + " Days";
      }
    }
    return string;
  }

  messageInput = (status) => {
    const { activeChat } = this.state;
    this.userService
      .updateChatStatus(activeChat.id, status)
      .then((res) => {
        const { data } = res;
        let showMessageInput = false;
        let showMessageInputBlock = true;
        if (status === 1) {
          showMessageInput = true;
          this.changeChatStatus(activeChat.id, status);
        } else {
          this.getAllChat();
          showMessageInputBlock = false;
        }
        this.setState({
          showMessageInput: showMessageInput,
          showMessageInputBlock: showMessageInputBlock,
        });
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        // this.userService.handleError(err);
      });
  };

  changeChatStatus(chatId, status) {
    const { chats } = this.state;
    let newChats = [];
    chats.forEach((element) => {
      if (element.id === chatId) {
        element.status = status;
      }
      newChats.push(element);
    });

    this.setState({ chats: newChats });
  }

  selectLeftMenuHandler = (option, options, index) => {
    this.setState(
      {
        options,
        selectedChats: option.items,
        currrentStatus: option.title,
        showMessageList: true,
        showMessageContent: false,
      },
      () => {
        // this.setAdsBasedOnStatus(option.title)
      }
    );
  };

  backToMsgList = () => {
    this.setState({
      showMessageList: true,
      showMessageContent: false,
    });
  };

  selectChat = (chat) => {
    this.setState({
      checkedChat: chat,
    });
  };

  viewUserprofile = (id) => {
    this.props.history.push(`/dashboard/user-profile/detail/${id}`);
  };
  formatDateTime = (dateTime, format) => {
    return moment(dateTime).format(format);
  };

  makeMessageListVisible = () => {
    this.setState({
      showMessageList: true,
      showMessageContent: false,
    });
  };

  render() {
    const { textSelection, showMessageList, showMessageContent, currrentStatus, checkedChat, options, userProfile, activeChat, showMessageInput, showMessageInputBlock, selectedChats, show } =
      this.state;

    return (
      <div id="new-messages">
        <BreadcrumbsItem to="/dashboard/messages">
          Dashboard <i className="fas fa-chevron-right"></i> Messages
        </BreadcrumbsItem>
        <div className="row">
          {showMessageList && <div className="col-sm-4 d-block d-sm-none col-right mt-2">{options && <RightMenu options={options} onSelect={this.selectLeftMenuHandler} />}</div>}
          <div className="col-sm-8 col-left">
            {selectedChats && selectedChats.length ? (
              <div className="row">
                <div className="col-sm-5 col-inner-left web-view">
                  <div className="msg-header">
                    <div className="d-flex justify-content-between">
                      <Form className="w-100">
                        <p>
                          <BiSearchAlt />{" "}
                        </p>
                        <FormGroup>
                          <Input id="exampleEmail" name="email" placeholder="Search here.." type="email" className="w-100" />
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                  {showMessageList ? (
                    <div
                      // className={`msg-body ${textSelection ? 'bg-danger':''}`}
                      className="msg-body"
                      style={{ cursor: "pointer" }}
                    >
                      {selectedChats.map((chat) => {
                        if (chat.id) {
                          return (
                            <div
                              // style={{display: showMessageList? "none":"block"}}
                              key={chat.id}
                              className={`d-flex justify-content-between border-bottom ${showMessageList ? "active-mobile" : ""}`}
                              onClick={() => {
                                this.toggleMessageContent(chat);
                              }}
                            >
                              <div
                                className="msg-outside m-3 row"
                                // className="msg-outside m-3 row"
                              >
                                {/* {currrentStatus !== "Archived" && (
                                  <div className="mt-4 mr-2">
                                    <input type="checkbox" className="check-input" id="messagecheck" checked={checkedChat === chat} onChange={() => this.selectChat(chat)} />
                                  </div>
                                )} */}
                                <div
                                  className="col-sm-4 px-0"
                                  // onClick={() => {
                                  //   this.viewUserprofile(chat.sender.id);
                                  // }}
                                >
                                  <img loading="lazy" src={chat.sender.profile.profileImage || require("../../../assets/Images/userimage.png")} alt="message-pic" />
                                </div>
                                <div className="msg-details col-sm-5 px-0">
                                  <h6>{chat.sender.profile.dealerName ? chat.sender.profile.dealerName : chat.sender.profile.firstName + " " + chat.sender.profile.lastName} </h6>
                                  <p className="mb-2">{chat.title}</p>
                                  <small>{this.formatDateTime(chat.createdAt, "DD-MM-YYYY")}</small>
                                </div>
                              </div>
                              <div className="msg-time align-self-center mr-3">
                                <p className="badge">{this.calculateDays(chat.createdAt)}</p>
                                {/* {currrentStatus !== "Archived" && (
                                    <button type="button" className="btn message-del mt-n3" onClick={this.deleteChat} style={{ fontSize: "12px" }}>
                                      <span>
                                        <i className="fas fa-trash-alt text-danger "></i>
                                      </span>
                                    </button>
                                  )} */}
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* Mobile view */}
                {window.innerWidth < 500 ? (
                  <div className="col-sm-5 col-inner-left">
                    {showMessageList && (
                      <div className="msg-header">
                        <div className="d-flex justify-content-between">
                          <h1 onClick={() => this.setState({ showMessageList: true })}>
                            <BiChevronLeft />{" "}
                          </h1>
                          <Form className="w-100">
                            <p>
                              <BiSearchAlt />{" "}
                            </p>
                            <FormGroup>
                              <Input id="exampleEmail" className="w-100" name="email" placeholder="Search here.." type="email" />
                            </FormGroup>
                          </Form>
                          {/* <div className="align-self-center">Recent</div> */}
                        </div>
                      </div>
                    )}
                    {showMessageList ? (
                      <div
                        onClick={() => this.setState({ showMessageList: false })}
                        // className={`msg-body ${textSelection ? 'bg-danger':''}`}
                        className="msg-body"
                        style={{ cursor: "pointer" }}
                      >
                        {selectedChats.map((chat) => {
                          if (chat.id) {
                            return (
                              <div
                                // style={{display: showMessageList? "none":"block"}}
                                key={chat.id}
                                className={`d-flex justify-content-between border-bottom ${showMessageList ? "active-mobile" : ""}`}
                                onClick={() => {
                                  this.toggleMessageContent(chat);
                                }}
                              >
                                <div
                                  className="msg-outside m-3 row"
                                  // className="msg-outside m-3 row"
                                >
                                  {/* {currrentStatus !== "Archived" && (
                                  <div className="mt-4 mr-2">
                                    <input type="checkbox" className="check-input" id="messagecheck" checked={checkedChat === chat} onChange={() => this.selectChat(chat)} />
                                  </div>
                                )} */}
                                  <div
                                    className="col-sm-4 px-0"
                                    // onClick={() => {
                                    //   this.viewUserprofile(chat.sender.id);
                                    // }}
                                  >
                                    <div className="d-flex">
                                      <img loading="lazy" src={chat.sender.profile.profileImage || require("../../../assets/Images/userimage.png")} alt="message-pic" />
                                      <div className="d-flex align-items-center msg-details col-sm-5 px-0">
                                        <div>
                                          <h6>{chat.sender.profile.dealerName ? chat.sender.profile.dealerName : chat.sender.profile.firstName + " " + chat.sender.profile.lastName} </h6>
                                          <p className="mb-0">{chat.title}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="msg-time align-self-center mr-3">
                                  <p className="badge">{this.calculateDays(chat.createdAt)}</p>
                                  {/* {currrentStatus !== "Archived" && (
                                    <button type="button" className="btn message-del mt-n3" onClick={this.deleteChat} style={{ fontSize: "12px" }}>
                                      <span>
                                        <i className="fas fa-trash-alt text-danger "></i>
                                      </span>
                                    </button>
                                  )} */}
                                  <small>{this.formatDateTime(chat.createdAt, "DD-MM-YYYY")}</small>
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {showMessageContent ? (
                  <div className="col-sm-7 col-inner-right">
                    <ChatBox
                      chat={activeChat}
                      makeMessageListVisible={this.makeMessageListVisible}
                      messages={activeChat.messages}
                      typingUsers={this.state.typingUsers}
                      user={this.state.receiverId}
                      userProfile={userProfile}
                    />
                    {showMessageInputBlock ? (
                      <div>
                        {showMessageInput ? (
                          <MessageInput
                            sendMessage={(message) => {
                              this.sendMessage(activeChat.id, message);
                            }}
                            sendTyping={(isTyping) => {
                              this.sendTyping(activeChat.id, isTyping);
                            }}
                          />
                        ) : (
                          <div className="actions-buttons d-flex justify-content-center py-3 border">
                            <button type="button" class="btn custom-btn mx-2" onClick={() => this.messageInput(1)}>
                              Accept
                            </button>
                            <button type="button" class="btn btn-primarys mx-2" onClick={() => this.messageInput(-1)}>
                              Reject
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="col-sm-7 col-inner-right">
                    <div className="bg-light" style={{ width: "100%", height: "100vh" }}>
                      <div className="profile d-flex bg-white py-2">
                        <div className="d-flex align-self-center">
                          <BiChevronLeft size={50} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center d-flex">
                            <img loading="lazy" style={{ width: "auto", height: "80px" }} src={require("../../../assets/Images/userimage.png")} alt="message-pic" />
                            <h5 className="align-self-center text-dark">User Name</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              // Mobile-view
              <div className="messages-main-right">
                <h5 className="text-center pt-4">No message found!</h5>
              </div>
            )}
          </div>
          <div className="col-sm-4 d-none d-sm-block col-right mt-4">{options && <RightMenu options={options} onSelect={this.selectLeftMenuHandler} />}</div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setSocket: (socket) => dispatch(actions.setSocket(socket)),
  };
};

const mapStateToProps = (state) => {
  return {
    socket: state.socket ? state.socket.socket : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMessages);
