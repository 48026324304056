import React, { Component } from "react";
import { Link } from "react-router-dom";
import LocalStoreService from "../../../services/localStore-service";
import "./LeftMenuBottom.scss";
import ModalPopup from "../../Shared/ModalPopup/ModalPopup";
import AuctionSheets from "../AuctionSheets/AuctionSheets";

class LeftMenuBottom extends Component {
  constructor() {
    super();
    this.localService = new LocalStoreService();
    this.state = {
      profile: {},
      showAuctionSheet: false,
    };
  }

  componentDidMount() {
    this.setState({
      profile: this.localService && this.localService.getUserProfile() ? this.localService.getUserProfile() : {},
    });
  }

  toggleAuctionSheet = () => {
    this.setState({
      showAuctionSheet: !this.state.showAuctionSheet,
    });
  };

  handleModalClose = () => {
    this.toggleAuctionSheet();
  };

  render() {
    const { profile } = this.state;

    const menuOptions = [
      {
        text: "Auction Sheet",
        img: require("../../../assets/Images/dashboard/icons/1.png"),
        url: "/auctionsheet-verification",
      },
      // {
      //     text: 'Car Registration',
      //     img: require("../../../assets/Images/dashboard/icons/2.png"),
      //     url: '/dashboard/ads'
      // },
      // {
      //     text: 'Car Tracker',
      //     img: require("../../../assets/Images/dashboard/icons/3.png"),
      //     url: '/dashboard/ads'
      // },
      // {
      //     text: 'Car Insurance',
      //     img: require("../../../assets/Images/dashboard/icons/4.png"),
      //     url: '/dashboard/ads'
      // },
      {
        text: "Car Finance",
        img: require("../../../assets/Images/dashboard/icons/5.png"),
        url: "/car-financing",
      },
      // {
      //     text: 'Custom Clearance',
      //     img: require("../../../assets/Images/dashboard/icons/6.png"),
      //     url: '/car-import'
      // },
      {
        text: "Import Your Car",
        img: require("../../../assets/Images/dashboard/icons/7.png"),
        url: "/car-import",
      },
      {
        text: "Parts Shop",
        img: require("../../../assets/Images/dashboard/icons/8.png"),
        url: `/accessories-spare-parts/search/-/sId_${
          profile && profile.showroomName ? profile.showroomName.toLowerCase().replace(/\s\//g, "-") : (profile.firstName + "-" + profile.lastName).toLowerCase().replace(/\s\//g, "-")
        }_${profile.userId}`,
      },
      // {
      //     text: 'Affiliation',
      //     img: require("../../../assets/Images/dashboard/icons/9.png"),
      //     url: '/dashboard/ads'
      // },
      // {
      //     text: 'Advertise With Us',
      //     img: require("../../../assets/Images/dashboard/icons/10.png"),
      //     url: '/dashboard/ads'
      // }
    ];

    return (
      <div className="left-menu-bottom-main">
        {menuOptions.map((option, i) => {
          return (
            <div className="left-bottom-status" key={i}>
              {option.text !== "Auction Sheet" ? (
                <Link to={option.url} key={option.text}>
                  <span>{option.text}</span>
                  <span className="float-right">
                    <img loading="lazy" src={option.img} alt="" />
                  </span>
                </Link>
              ) : (
                <button type="button" className="btn auctionsheet-btn" onClick={this.toggleAuctionSheet}>
                  <span>Auction Sheet</span>
                  <span className="float-right">
                    <img loading="lazy" src={option.img} alt="" />
                  </span>
                </button>
              )}
            </div>
          );
        })}
        <ModalPopup isOpen={this.state.showAuctionSheet} onModalClose={this.handleModalClose} className="auctionsheet-modalpopup" backdrop={"static"}>
          {" "}
          <AuctionSheets onClose={this.toggleAuctionSheet} />{" "}
        </ModalPopup>
      </div>
    );
  }
}

export default LeftMenuBottom;
