import { createStore, applyMiddleware, compose, combineReducers } from "redux";
// Logger with default options
import logger from "redux-logger";
import { reducers } from "./reducers/index";
import metaData from "./reducers/metadata";

const masterReducer = combineReducers({
  loading: reducers.loading,
  auth: reducers.auth,
  profile: reducers.profile,
  cart: reducers.cart,
  socket: reducers.socket,
  metaData: reducers.metaData,
  category: reducers.category,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE || compose;

const configureReduxStore = (initialState) => {
  const store = createStore(masterReducer, initialState, composeEnhancers(applyMiddleware()));
  return store;
};

export default configureReduxStore;
