import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Moment from "moment";
import "./NewPayment.scss";
import RightMenu from "../RightMenu/RightMenu";
import LocalStoreService from "../../../services/localStore-service";
import UserService from "../../../services/user-service";
import CommonService from "../../../services/common-service";
import ModalPopup from "../../Shared/ModalPopup/ModalPopup";
import { InputGroup, InputGroupAddon, Button } from "reactstrap";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import OrderService from "../../../services/order-service";
import { active } from "sortablejs";

class Payment extends Component {
  constructor() {
    super();

    this.localService = new LocalStoreService();
    this.UserService = new UserService();
    this.commonService = new CommonService();
    this.orderService = new OrderService();

    this.state = {
      options: [
        {
          title: "Subscribed Feature Plans",
          count: "",
          active: true,
          items: [],
        },
        {
          title: "Payment History",
          count: "",
          active: false,
          items: [],
        },
      ],
      count: 0,
      subscribedFeaturePlans: {},
      userId: 0,
      userOrders: [],
      showAdDetailModal: false,
      currentOrder: {},
    }; // end options
  }

  componentDidMount() {
    this.setState(
      {
        userId: this.localService.getUserId(),
      },
      () => {
        this.getSubscribedFeaturedPlans();
        this.getOrdersOfUsers();
      }
    );
  }

  getSubscribedFeaturedPlans = () => {
    const { userId, options } = this.state;

    this.UserService.getUserSubscribedPlans(userId)
      .then((res) => {
        const { data } = res;
        options[0].items = data;
        this.setState({
          subscribedFeaturePlans: data,
          options,
        });
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.UserService.handleError(err);
      });
  };

  getOrdersOfUsers = () => {
    const { userId, options } = this.state;
    this.orderService
      .getUserOrdersDetailById(userId)
      .then((res) => {
        const { data } = res;
        options[1].items = data;
        this.setState({
          userOrders: data,
          options,
        });
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.orderService.handleError(err);
      });
  };

  selectLeftMenuHandler = (option, options, index) => {
    this.setState({
      options,
      subscribedFeaturePlans: option.items,
    });
  };

  counter = (operator) => {
    let { count } = this.state;
    if (operator === "+") {
      this.setState({
        count: ++count,
      });
    } else {
      if (this.state.count > 0) {
        this.setState({
          count: --count,
        });
      }
    }
  };

  showSubscribedFeaturedPlans = () => {
    const { subscribedFeaturePlans } = this.state;
    return (
      subscribedFeaturePlans &&
      subscribedFeaturePlans.length > 0 && (
        <div className="payment-main-right px-4">
          <div className="d-flex justify-content-between mt-3">
            <h4 className="text-primary mb-0">Available Credits </h4>
            <button className="btn p-0 text-primary" onClick={() => this.props.history.push("/featured-ad-plans")}>
              <h4 className="mb-0">View Credit Plans</h4>
            </button>
          </div>
          <hr />
          <div className="payment-content-top mt-n2">
            <div className="row mb-3">
              <div className="col-sm-6 my-3">
                <div className="d-flex justify-content-between border-bottom py-3">
                  <p className="mb-0">Cars Featured Ads</p>
                  <p className="mb-0">
                    <span className="status-right">PKR 250</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between border-bottom py-3">
                  <p className="mb-0">Cars Boost Ads</p>
                  <p className="mb-0">PKR 250</p>
                </div>
                <div className="d-flex justify-content-between border-bottom py-3">
                  <p className="mb-0">Bulk Ads</p>
                  <p className="mb-0">PKR 25,00</p>
                </div>
                <div className="d-flex justify-content-between border-bottom py-3">
                  <p className="mb-0">Normal Bike Ad Credit</p>
                  <p className="mb-0">PKR 250</p>
                </div>
              </div>
              <div className="col-sm-6 my-3">
                <div className="d-flex justify-content-between border-bottom py-3">
                  <p className="mb-0">Bikes Featured Ads</p>
                  <p className="mb-0">PKR 250</p>
                </div>
                <div className="d-flex justify-content-between border-bottom py-3">
                  <p className="mb-0">Bulk Ads for 45 days</p>
                  <p className="mb-0">PKR 12,500</p>
                </div>
                <div className="d-flex justify-content-between border-bottom py-3">
                  <p className="mb-0">Bulk Ad for 30 days</p>
                  <p className="mb-0">PKR 12,500</p>
                </div>
                <div className="d-flex justify-content-between border-bottom py-3">
                  <p className="mb-0">Normal Accessories Ad Credit</p>
                  <p className="mb-0">PKR 250</p>
                </div>
              </div>
            </div>
            <h4 className="text-primary my-3">History</h4>

            <table className="table table-bordered web-view">
              <thead>
                <tr>
                  <th width="32%" className="payment-functions">
                    <button type="button" className="btn message-refresh" onClick={this.getSubscribedFeaturedPlans}>
                      <span>
                        <i className="fas fa-redo"></i>
                      </span>
                    </button>
                    <span>Plan Title</span>
                  </th>
                  <th width="15%" className="text-center">
                    Total
                  </th>
                  <th width="15%" className="text-center">
                    Used
                  </th>
                  <th width="63%" className="text-center">
                    Remaining
                  </th>
                </tr>
              </thead>
              <tbody>
                {subscribedFeaturePlans.map((plan, i) => {
                  return (
                    <tr key={i}>
                      <td className="payment-functions-content">
                        <div className="payment-functions-inner">
                          <div className="car-name">{plan.title}</div>
                        </div>
                      </td>
                      <td className="text-center">{plan.quantity}</td>
                      <td className="text-center">{plan.quantity - (plan.remainingQty || plan.remainingQty === 0 ? plan.remainingQty : plan.quantity)}</td>
                      <td className="text-center">You have only {plan.remainingQty || plan.remainingQty === 0 ? plan.remainingQty : plan.quantity} Ad Remaining</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {subscribedFeaturePlans.map((plan, i) => {
              return (
                <>
                  {window.innerWidth < 500 ? (
                    <div className="row plan-history-mobile" key={i}>
                      <div className="col-6">
                        <p>Plan Title</p>
                        <p>Total</p>
                        <p>Used</p>
                        <p>Remaining</p>
                      </div>
                      <div className="col-6">
                        <p className="text-muted">{plan.title}</p>
                        <p className="text-muted">{plan.quantity}</p>
                        <p className="text-muted">{plan.quantity - (plan.remainingQty || plan.remainingQty === 0 ? plan.remainingQty : plan.quantity)}</p>
                        <p className="text-muted">You have only {plan.remainingQty || plan.remainingQty === 0 ? plan.remainingQty : plan.quantity} Ad Remaining</p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
          <div className="btn-bottom mb-3">
            <button className="btn btn-show-new-plan" onClick={() => this.props.history.push("/featured-ad-plans")}>
              Show New Plans
            </button>
          </div>
        </div>
      )
    );
  };

  viewOrderDetail = (row) => {
    if (row) {
      this.setState({
        showAdDetailModal: !this.state.showAdDetailModal,
        currentOrder: row,
      });
    }
  };

  showPaymentHistory = () => {
    const { count, userOrders } = this.state;
    return (
      <div className="payment-main-right">
        <div className="payment-content-top px-3">
          <button className="btn text-primary ml-n2" onClick={() => this.props.history.push("/featured-ad-plans")}>
            <h4 className="mb-0">View Credit Plans</h4>
          </button>
          <hr />
          <h4 className="text-primary mt-4">Available Credits</h4>
          <div className="row mb-3">
            <div className="col-sm-6 my-3">
              <div className="d-flex justify-content-between border-bottom py-3">
                <p className="mb-0">Cars Featured Ads</p>
                <p className="mb-0">
                  <span className="status-right">PKR 250</span>
                </p>
              </div>
              <div className="d-flex justify-content-between border-bottom py-3">
                <p className="mb-0">Cars Boost Ads</p>
                <p className="mb-0">PKR 250</p>
              </div>
              <div className="d-flex justify-content-between border-bottom py-3">
                <p className="mb-0">Bulk Ads</p>
                <p className="mb-0">PKR 25,00</p>
              </div>
              <div className="d-flex justify-content-between border-bottom py-3">
                <p className="mb-0">Normal Bike Ad Credit</p>
                <p className="mb-0">PKR 250</p>
              </div>
            </div>
            <div className="col-sm-6 my-3">
              <div className="d-flex justify-content-between border-bottom py-3">
                <p className="mb-0">Bikes Featured Ads</p>
                <p className="mb-0">PKR 250</p>
              </div>
              <div className="d-flex justify-content-between border-bottom py-3">
                <p className="mb-0">Bulk Ads for 45 days</p>
                <p className="mb-0">PKR 12,500</p>
              </div>
              <div className="d-flex justify-content-between border-bottom py-3">
                <p className="mb-0">Bulk Ad for 30 days</p>
                <p className="mb-0">PKR 12,500</p>
              </div>
              <div className="d-flex justify-content-between border-bottom py-3">
                <p className="mb-0">Normal Accessories Ad Credit</p>
                <p className="mb-0">PKR 250</p>
              </div>
            </div>
          </div>
          <h5 className="text-primary font-weight-normal mb-3">Credit History</h5>
          <table className="table table-bordered table-responsive web-view">
            <thead>
              <tr>
                <th width="10%" className="payment-functions">
                  <button type="button" className="btn message-refresh" onClick={this.getOrdersOfUsers}>
                    <span>
                      <i className="fas fa-redo"></i>
                    </span>
                  </button>
                  Ref#
                </th>
                <th width="20%" className="text-center">
                  Order Type
                </th>
                <th width="15%" className="text-center">
                  Date
                </th>
                {/* <th width="20%" className="text-center">Payment Method</th> */}
                <th width="15%" className="text-center">
                  Total Amount
                </th>
                <th width="20%" className="text-center">
                  Status
                </th>
                <th width="30%" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {userOrders.map((item) => {
                return (
                  <tr key={item.id}>
                    <td className="text-center">{item.id}</td>
                    <td className="text-center">{item.orders}</td>
                    <td className="text-center">{Moment(item.updatedAt).format("MMM DD, YYYY ")}</td>
                    {/* <td className="text-center">{item.paymentMethod}</td> */}
                    <td className="text-center">PKR {this.commonService.getPriceIntoWords(item.totalAmount)}</td>
                    <td className="text-center">{this.commonService.getOrderStatus(item.status)}</td>
                    <td className="text-center">
                      <button type="button" className="btn btn-view-detail" onClick={() => this.viewOrderDetail(item)}>
                        View Detail
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {userOrders.map((item) => {
            return (
              <>
                {window.innerWidth < 500 ? (
                  <div className="row plan-history-mobile" key={item.id}>
                    <div className="col-6">
                      <p>Ref#</p>
                      <p>Order Type</p>
                      <p>Date</p>
                      <p>Total Amount</p>
                      <p>Status</p>
                      <p>Action</p>
                    </div>
                    <div className="col-6">
                      <p className="text-muted">{item.id}</p>
                      <p className="text-muted">{item.orders}</p>
                      <p className="text-muted">{Moment(item.updatedAt).format("MMM DD, YYYY ")}</p>
                      <p className="text-muted">PKR {this.commonService.getPriceIntoWords(item.totalAmount)}</p>
                      <p className="text-muted">{this.commonService.getOrderStatus(item.status)}</p>
                      <p className="text-muted">
                        <button type="button" className="btn btn-view-detail" onClick={() => this.viewOrderDetail(item)}>
                          View Detail
                        </button>
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  getPurchasedItemsList = (items = []) => {
    return (
      <div>
        {items.map((item, i) => {
          return (
            <p key={item.id}>
              {i + 1}. {item.title} ({item.order_part_quantities && item.order_part_quantities.quantity})
            </p>
          );
        })}
      </div>
    );
  };

  toggleAdDetail = () => {
    this.setState({
      showAdDetailModal: !this.state.showAdDetailModal,
    });
  };

  render() {
    const { options, subscribedFeaturePlans, userOrders, showAdDetailModal, currentOrder } = this.state;
    const { createdAt, id, name, email, phoneNumber, paymentMethod, totalAmount, status, orders } = currentOrder;
    return (
      <div id="payment-main">
        <BreadcrumbsItem to="/dashboard/payment">
          Dashboard <i className="fas fa-chevron-right"></i> Payment History
        </BreadcrumbsItem>
        <div className="row mt-3">
          {window.innerWidth < 500 ? (
            <div className="col-sm-4">
              <RightMenu options={options} onSelect={this.selectLeftMenuHandler} />
            </div>
          ) : (
            ""
          )}
          <div className="col-sm-8">
            {subscribedFeaturePlans && subscribedFeaturePlans.length && options && options[0].items.length > 0 && options[0].active === true ? (
              this.showSubscribedFeaturedPlans()
            ) : userOrders && userOrders.length && options && options[1].items.length > 0 && options[1].active === true ? (
              this.showPaymentHistory()
            ) : (
              <div className="payment-main-right">
                <h5 className="text-center pt-4">No Record Found!</h5>
                {subscribedFeaturePlans && !subscribedFeaturePlans.length && options && !options[0].items.length && options[0].active === true ? (
                  <div className="btn-bottom">
                    <button className="btn btn-show-new-plan" onClick={() => this.props.history.push("/featured-ad-plans")}>
                      Show New Plans
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div className="col-sm-4 web-view">
            <RightMenu options={options} onSelect={this.selectLeftMenuHandler} />
          </div>
        </div>
        <ModalPopup
          isOpen={showAdDetailModal}
          onModalClose={() => {
            this.toggleAdDetail();
          }}
          className="payment-view-modal"
        >
          <ModalHeader>
            Order Summary <button type="button" className="close" aria-label="Close"></button>
          </ModalHeader>

          <ModalBody>
            <div className="order-detail-main" id="DivIdToPrint">
              <div className="order-content">
                <div className="title">Order Ref#</div>
                <div className="content">{id}</div>
              </div>
              <div className="order-content">
                <div className="title">Customer Name:</div>
                <div className="content">{name}</div>
              </div>
              <div className="order-content">
                <div className="title">Customer Email:</div>
                <div className="content">{email}</div>
              </div>
              <div className="order-content">
                <div className="title">Phone No:</div>
                <div className="content">{phoneNumber}</div>
              </div>
              <div className="order-content">
                <div className="title">Purchased Item Type:</div>
                <div className="content">{orders ? orders : "N/A"}</div>
              </div>
              <div className="order-content">
                <div className="title" style={{ float: "left" }}>
                  Purchased Item:
                </div>
                <div className="content">{currentOrder && currentOrder.partAds && currentOrder.partAds.length ? this.getPurchasedItemsList(currentOrder.partAds) : "N/A"}</div>
              </div>
              <div className="order-content">
                <div className="title">Payment Method:</div>
                <div className="content">{paymentMethod}</div>
              </div>
              <div className="order-content">
                <div className="title">Total Amount:</div>
                <div className="content">PKR {totalAmount}</div>
              </div>
              <div className="order-content">
                <div className="title">Status:</div>
                <div className="content">{this.commonService.getOrderStatus(status)}</div>
              </div>
              <div className="order-content">
                <div className="title">Date:</div>
                <div className="content">{Moment(createdAt).format("MMM DD, YYYY ")}</div>
              </div>
            </div>
            <form>
              <button type="button" className="btn btn-danger mr-2" onClick={this.toggleAdDetail}>
                Close
              </button>
              {/* <button type="button" className="btn btn-success" onClick={this.printDiv}>Print</button> */}
            </form>
          </ModalBody>
        </ModalPopup>
      </div>
    );
  }
}

export default Payment;
