import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Breadcrumbs } from 'react-breadcrumbs-dynamic'
import { NavLink } from 'react-router-dom';
import "./TopBreadcrumb.scss";


class TopBreadcrumb extends Component {


    render() {
        return (
            <div className="breadcrumb-main" >
                <div className="container">

                    {/* <Breadcrumb>
                        <BreadcrumbItem><Link to={'/'}>Home</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={'/product-listing'}>Used Cars</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Used Car for sale in Pakistan</BreadcrumbItem>
                    </Breadcrumb> */}
                    <Breadcrumbs separator={<b> | </b>} item={NavLink} finalItem={'span'} />

                </div>
            </div>
        );
    }
}

export default withRouter(TopBreadcrumb);
