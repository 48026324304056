
const cart = (state = null, action) => {
    if (action.type === "SET_CART_ITEMS") {
        state = {...state, cartItems: action.payload}
        return state;
    } else if(action.type === "GET_CART_ITEMS") {
        return state;
    }
    return state;
};

export default cart;