import React, { Component } from 'react';
import './ImageEditorModal.scss'
import CommonService from '../../../services/common-service';
import UserService from '../../../services/user-service';


class ImageEditorModal extends Component {
    constructor(props) {
        super(props);
        this.userService = new UserService();
        this.commonService = new CommonService();

        this.state = {
            image: ''
        }
    }

    componentDidMount() {
    }

    render() {

        return (
            <div className="multiimagemodal-main">
                
            </div >
        );
    }
}

export default ImageEditorModal;