import React, { Component } from "react";
import "./NewFeatured.scss";
import NewPartsListItemHorizontal from "../../Shared/NewPartsListItemHorizontal/NewPartsListItemHorizontal";
import PartsListingHorizontal from "../../Parts/Listing/ListingHorizontal/ListingHorizontal";
import RightMenu from "../RightMenu/RightMenu";
import AdsService from "../../../services/ads-service";
import CommonService from "../../../services/common-service";
import LocalStoreService from "../../../services/localStore-service";
import CategoryTabs from "../CategoryTabs/CategoryTabs";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Paginate from "../../Shared/Paginate/Paginate";

class NewFeatured extends Component {
  constructor() {
    super();
    this.commonService = new CommonService();
    this.adsService = new AdsService();
    this.localService = new LocalStoreService();

    this.state = {
      options: [
        {
          title: "Active",
          count: 0,
          active: true,
          items: [],
        },
        {
          title: "Pending",
          count: 0,
          active: false,
          items: [],
        },
        {
          title: "Removed",
          count: 0,
          active: false,
          items: [],
        },
      ],
      ads: [],
      selectedAds: [],
      userId: 0,
      currrentStatus: "Active",
      activeTab: "1",
      pageNo: 0,
      pageSize: 12,
      totalPages: 1,
      hasData: "",
      totalPages: "",
    };
  }

  componentDidMount() {
    this.setState(
      {
        userId: this.localService.getUserId(),
      },
      () => {
        this.toggleCatergoy("1");
      }
    );
  }

  handlePageClick = ({ selected }) => {
    this.setState(
      {
        pageNo: selected ? selected : 0,
      },
      () => {
        const { activeTab } = this.state;
        if (activeTab === "1") {
          this.getUserAds("car");
        } else {
          this.getUserAds("part");
        }
      }
    );
  };

  getUserAds(category) {
    const { pageNo, pageSize, userId, currrentStatus } = this.state;
    this.adsService
      .getUserFeaturedAds(pageNo, pageSize, userId, category)
      .then((res) => {
        const { data } = res;
        if (data && data.records && data.records.length) {
          this.setState(
            {
              totalPages: data.totalPages,
              totalRecords: data.count,
              ads: data.records,
              hasData: true,
            },
            () => {
              this.setAdsBasedOnStatus(currrentStatus);
            }
          );
        } else {
          this.setState(
            {
              totalPages: data.totalPages,
              totalRecords: data.count,
              ads: data.records,
              hasData: false,
            },
            () => {
              this.setAdsBasedOnStatus(currrentStatus);
            }
          );
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  }

  setAdsBasedOnStatus = (status) => {
    let adItems = [];
    const { options, ads } = this.state;
    options.forEach((option) => {
      adItems.push({
        active: option.active,
        title: option.title,
        count: this.getAdsBasedOnStatus(ads, option.title).length,
        items: this.getAdsBasedOnStatus(ads, option.title),
      });
    });
    this.setState({
      options: adItems,
      selectedAds: adItems && adItems.length > 0 ? (adItems.filter((x) => x.title === status)[0] ? adItems.filter((x) => x.title === status)[0].items : []) : [],
    });
  };

  getAdsBasedOnStatus = (data, status) => {
    let ads = [];
    if (data && data.length) {
      data.forEach((ad) => {
        const adStatus = this.commonService.getAdsStatus(ad.status);
        if (adStatus === status) {
          ads.push(ad);
        }
      });
    }
    return ads;
  };

  deleteHandler = (item) => {
    this.deleteAd(item, item.id);
  };

  editHandler = (item) => {
    this.editAd(item);
  };

  favouriteHnadler = (item, type) => {
    const { activeTab } = this.state;
    this.toggleCatergoy(activeTab);
  };

  editAd = (item) => {
    if (item.category === "car") {
      this.props.history.push({ pathname: "/post-ad/sell-car/post-your-ad/edit", state: { item, update: true } });
    } else {
      this.props.history.push({ pathname: "/post-ad/sell-part/edit", state: { item, update: true } });
    }
  };

  deleteAd = (formData, id) => {
    formData.status = -1;
    this.adsService
      .updateAd(formData, id)
      .then((res) => {
        const { message } = res.data;
        if (message === "Ad updated successfully") {
          this.commonService.showToast("Ad removed successfully!", "success");
          this.toggleCatergoy(this.state.activeTab);
        } else {
          this.commonService.showToast("Ad not removed!", "error");
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong!", "error");
        this.adsService.handleError(err);
      });
  };

  selectLeftMenuHandler = (option, options, index) => {
    this.setState(
      {
        options,
        selectedAds: option.items,
        currrentStatus: option.title,
      },
      () => {
        // this.setAdsBasedOnStatus(option.title)
      }
    );
  };

  toggleCatergoy = (tab) => {
    this.setState({
      activeTab: tab,
    });
    if (tab === "1") {
      this.getUserAds("vehicles");
    } else {
      this.getUserAds("part");
    }
  };

  editDeleteBtns = (id) => {
    return (
      <div>
        <button type="button" className="btn btn-link" onClick={() => this.handleEdit(id)}>
          <i className="fas fa-pen"></i>
        </button>
        <button type="button" className="btn btn-link" onClick={() => this.handleDelete(id)}>
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
    );
  };

  render() {
    const { selectedAds, userId, options, activeTab, hasData, totalPages } = this.state;

    return (
      <div id="new-featured-ads" className="example">
        <BreadcrumbsItem to="/dashboard/featuredads">
          Dashboard <i className="fas fa-chevron-right"></i> Featured Ads
        </BreadcrumbsItem>

        <div className="row">
          <div className="col-sm-8 ad-lefts">
            <CategoryTabs tab={activeTab} onToggleCatergoy={this.toggleCatergoy} />
            {selectedAds && selectedAds.length > 0
              ? selectedAds.map((item) => {
                  item.userId = userId;
                  return (
                    // <ProductListItemHorizontal key={item.id} data={item} callFrom={'dashboard-ads'} onDelete={this.deleteHandler} onEdit={this.editHandler} onFavourite={this.favouriteHnadler} >
                    //     {this.editDeleteBtns(item.id)}
                    // </ProductListItemHorizontal>
                    item.category === "part" ? (
                      <PartsListingHorizontal
                        callFrom={"dashboard-ads"}
                        key={item.id}
                        data={item}
                        onDelete={this.deleteHandler}
                        onEdit={this.editHandler}
                        onFavourite={this.favouriteHandler}
                      ></PartsListingHorizontal>
                    ) : (
                      <NewPartsListItemHorizontal
                        key={item.id}
                        data={item}
                        callFrom={"dashboard-ads"}
                        onDelete={this.deleteHandler}
                        onEdit={this.editHandler}
                        onFavourite={this.favouriteHandler}
                      ></NewPartsListItemHorizontal>
                    )
                  );
                })
              : // : <h5 className="text-center pt-4">No ad found!</h5>
                ""}
            <div className="listing-loadmore">
              {hasData === true ? (
                <Paginate totalPages={totalPages} onPageChange={this.handlePageClick} />
              ) : hasData === false ? (
                <h5 className="text-center pt-4">No Record Found!</h5>
              ) : (
                <h5 className="text-center pt-4">Loading...</h5>
              )}
            </div>
          </div>
          <div className="col-sm-4 ad-rights">
            <div>{options && <RightMenu options={options} onSelect={this.selectLeftMenuHandler} />}</div>
            <div className="mt-5">
              <img loading="lazy" src={require("../../../assets/Images/dummy.png")} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewFeatured;
