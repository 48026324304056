class MaskConfig {
    //Masks
    priceMask = '9999999999';
    phoneNumberMask = "+\\929999999999";
    mileAgeMask = '99999999';

    beforeMaskedValueChange = (newState, oldState, userInput) => {
      var { value } = newState;
      var selection = newState.selection;
      var cursorPosition = selection ? selection.start : null;

      // keep minus if entered by user
      if (value.charAt(3) === '0') {

        value = value.slice(0, -1);
      }

      return {
        value,
        selection
      };
    }

}
export default MaskConfig;
