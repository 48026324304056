const years = {
    getYears : () => {
      let currentYear = new Date().getFullYear();
      let startYear = 1940;
      let years = [];
      for (let i = currentYear; i >= startYear; i--) {
          years.push({ label: i, value: i });
      }
      return years;
  }
}

export default years;
