import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import "./ModalPopup.scss";

const ModalPopup = (props) => {
  let [showModal, setShowModal] = useState(props.isOpen);

  useEffect(() => {
    setShowModal(props.isOpen);
  }, [props.isOpen]);

  const toggleModal = () => {
    setShowModal(!showModal);
    props.onModalClose();
  };

  return (
    // <div className="modal-popup-main">

    <Modal className={props.className} isOpen={showModal} toggle={() => toggleModal()} backdrop={props.backdrop}>
      {/* <ModalHeader toggle={() => toggleModal()}>Testing</ModalHeader> */}
      {/* <ModalBody> */}
      {props.children}
      {/* </ModalBody> */}
    </Modal>

    // </div >
  );
};

export default ModalPopup;
