import axios from "axios";
import { actions } from "../store/actions/index";
import CommonService from "../services/common-service";
import Configuration from "../config/configuration";
import LocalStorageService from "../services/localStore-service";

const commonSevice = new CommonService();
const config = new Configuration();
const localService = new LocalStorageService();

const httpIntercept = (store) => {
  axios.interceptors.request.use(
    (config) => {
      // config.onUploadProgress = progressEvent => {
      //         let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      //         store.dispatch(actions.loading({ isLoading: true, percentCompleted }));
      //     }
      //     config.onDownloadProgress = progressEvent => {
      //         let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      //         store.dispatch(actions.loading({ isLoading: true, percentCompleted }));
      //     }
      const token = localService.getUserToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.headers["Content-Type"] = "application/json";
      store.dispatch(
        actions.loading({ isLoading: true, percentCompleted: 100 })
      );
      return config;
    },
    (error) => {
      store.dispatch(
        actions.loading({ isLoading: false, percentCompleted: 20 })
      );
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      store.dispatch(
        actions.loading({ isLoading: false, percentCompleted: 20 })
      );
      return response;
    },
    (error) => {
      console.log(
        `errr response ${error.response && error.response} status ${
          error.response && error.response.status
        } type ${typeof localService.getRememberMe()} asd ${localService.getRememberMe()} `
      );
      store.dispatch(
        actions.loading({ isLoading: false, percentCompleted: 20 })
      );
      if (
        error.response &&
        error.response.status === 401 &&
        (localService.getRememberMe() === "false" ||
          localService.getRememberMe() === "undefined")
      ) {
        commonSevice.showToast("Session expired", "error");
        localService.clearStorage();
        store.dispatch(actions.setAuthStatus(false));
        return Promise.reject(error);
      }
      if (
        error.response &&
        error.response.status === 401 &&
        localService.getRememberMe() === "true"
      ) {
        const refreshToken = localService.getRefreshToken();
        return axios
          .post(config.apiBaseUrl + "users/refreshtoken", {
            refreshToken: refreshToken,
          })
          .then((res) => {
            if (res.status === 200) {
              localService.setTokenOnly(res.data);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + localService.getUserToken();
              return axios(error.config);
            }
          });
      }
      return Promise.reject(error);
    }
  );
};

export default httpIntercept;
