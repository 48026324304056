import React, { Component } from 'react';
import './LeftMenuTop.scss';
class LeftMenuTop extends Component {

    constructor() {
        super();
        this.state = {
            options: []
        }
    }

    componentDidMount() {
        this.setState({
            options: this.props.options
        })
    }

    componentDidUpdate(nexProps) {
        if (this.props.options !== nexProps.options) {
            this.setState({
                options: this.props.options
            })
        }
    }

    handleSelection = (item, index) => {
        const { options } = this.state;
        let prevActiveIndex = options.findIndex(x => x.active === true)

        if (prevActiveIndex !== index) {
            if (prevActiveIndex >= 0) {
                options[prevActiveIndex].active = false;
            }
            options[index].active = true;
            item.active = true;
            this.setState({
                options
            })
            this.props.onSelect(item, options, index);
        }
      
    }

    render() {
        const { options } = this.state;

        return (
            <div className="left-menu-top-main">
                {options && options.length>0 && options.map((item, i) => {
                    return (
                        <div className={`left-top-status ${item.active ? 'active' : ''}`} key={i}>
                            <button type="button" className="btn active" onClick={() => this.handleSelection(item, i)}>
                                <span className="status-left">{item.title}</span>
                                {(item.count || item.count===0) && <span className="status-right">({item.count})</span>}
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default LeftMenuTop;