import Configuration from "../config/configuration";
import axios from "axios";

class InfoService {
  constructor() {
    this.config = new Configuration();
  }

  getMakes() {
    return axios.get(this.config.apiBaseUrl + "makes");
  }
  getAllMakes(category, limit) {
    return axios.get(this.config.apiBaseUrl + `makes?category=${category}&makeSize=${limit}&adsStatus=1`);
  }
  getNewCarMakes(category) {
    return axios.get(this.config.apiBaseUrl + `makes?category=${category}&inventory=true`);
  }
  getAllNewMakes(category) {
    return axios.get(this.config.apiBaseUrl + `makes?category=${category}&inventory=true`);
  }
  getCarMakesByYear(year) {
    return axios.get(this.config.apiBaseUrl + `cars/makes/${year}`);
  }
  // getCarPricesByMakeId(year, makeId) {
  //     return axios.get(this.config.apiBaseUrl + `cars/prices/${year}/${makeId}`);
  // }
  getCarPricesByMakeId(year, makeId) {
    return axios.get(this.config.apiBaseUrl + `cars/prices/-/${makeId}`);
  }
  getBikeMakesByYear(year) {
    return axios.get(this.config.apiBaseUrl + `bikes/makes/${year}`);
  }
  // getBikePricesByMakeId(year, makeId) {
  //     return axios.get(this.config.apiBaseUrl + `bikes/prices/${year}/${makeId}`);
  // }
  getBikePricesByMakeId(year, makeId) {
    return axios.get(this.config.apiBaseUrl + `bikes/prices/-/${makeId}`);
  }
  getModelsByMakeIdAndMakeType(makeId, makeType) {
    return axios.get(this.config.apiBaseUrl + `models?makeId=${makeId}&makeType=${makeType}`);
  }
  getBodyTypes() {
    return axios.get(this.config.apiBaseUrl + "bodytypes");
  }
  getCarsComparison(variants) {
    return axios.get(this.config.apiBaseUrl + `cars/comparison?variants=${variants}`);
  }

  getBikesComparison(models) {
    return axios.get(this.config.apiBaseUrl + `bikes/comparison?models=${models}`);
  }

  getVariantsByModelId(modelId) {
    return axios.get(this.config.apiBaseUrl + `variants?modelId=${modelId}`);
  }
  getVariantsByModelIdAndYear(modelId, year) {
    return axios.get(this.config.apiBaseUrl + `variants?modelId=${modelId}&year=${year}`);
  }

  getModelsByMakeIdAndYear(makeId, year) {
    return axios.get(this.config.apiBaseUrl + `models?makeId=${makeId}&year=${year}`);
  }

  calculateCarFinance(formData) {
    return axios.post(this.config.apiBaseUrl + "cars/finance-calculator", formData);
  }

  calculateCarPrice(formData) {
    return axios.post(this.config.apiBaseUrl + "price-calculator/calculate", formData);
  }

  calculateCustomDuty(formData) {
    return axios.post(this.config.apiBaseUrl + "duty-calculator/calculate", formData);
  }

  getExchangeRate(from, to) {
    const instance = axios.create();
    const config = {
      headers:{
        apikey: "swVRxjSYqpxI9izw6YgOnnNltJSIuZwR",
      }
    };
    return instance.get(`https://api.apilayer.com/fixer/convert?to=${to}&from=${from}&amount=1`, config);
    // return instance.get(`https://free.currconv.com/api/v7/convert?q=${from}_${to}&compact=ultra&apiKey=${this.config.currConvApiKey}`);
  }

  getDealersCities() {
    return axios.get(this.config.apiBaseUrl + "users/dealers/cities");
  }

  getAdsCities(category, status, limit) {
    return axios.get(this.config.apiBaseUrl + `flyers/cities?category=${category}&status=${status}&pageSize=${limit}`);
  }

  getAdsRegCities(category, status) {
    return axios.get(this.config.apiBaseUrl + `flyers/regcities?category=${category}&status=${status}`);
  }

  getAdsColors(category, status, limit) {
    return axios.get(this.config.apiBaseUrl + `flyers/colors?category=${category}&status=${status}&pageSize=${limit}`);
  }

  getAdsSuggestion(category) {
    return axios.get(this.config.apiBaseUrl + `adssuggestions?category=${category}`);
  }

  getAdsBodyTypes(category, limit) {
    return axios.get(this.config.apiBaseUrl + `flyers/bodytypes?category=${category}&pageSize=${limit}`);
  }

  getFeatures(category) {
    return axios.get(this.config.apiBaseUrl + `features?category=${category}`);
  }

  getFeaturedPlans() {
    return axios.get(this.config.apiBaseUrl + "featuredplans");
  }

  getPopularCarComparisons(page, pageSize, make) {
    console.log(make, "api")
    return axios.get(this.config.apiBaseUrl + `hotcomparison/cars?page=${page}&pageSize=${pageSize}${make ? `&make=${make}` : ""}`);
  }

  getPopularBikeComparisons(page, pageSize, make) {
    return axios.get(this.config.apiBaseUrl + `hotcomparison/bikes?page=${page}&pageSize=${pageSize}${make ? `&make=${make}` : ""}`);
  }

  findAuctionSheet(chassisNo) {
    const instance = axios.create();
    // return axios.get(`http://auc.autodealsjapan.com/report?q=${chassisNo}`);
    return axios.get(this.config.apiBaseUrl + "auctions/auc-japan/" + chassisNo);
  }

  findAuctionSheetNew(chassisNo) {
    return axios.get("https://server.myauctionsheet.com/auction/find?chassis=" + chassisNo);
  }

  addAuctionSheetVerification(formData) {
    return axios.post(this.config.apiBaseUrl + "auctions", formData);
  }

  getAucitonSheetById(id) {
    return axios.get(`https://server.myauctionsheet.com/auction?id=${id}`);
  }

  getClientsReviews = () => {
    return axios.get(this.config.apiBaseUrl + `clientsreviews`);
  };

  handleError(error) {
    console.log(error.message);
  }
}

export default InfoService;
