import React, { Component } from "react";
import "./Main.scss";
import DefaultLayout from "../Layout/DefaultLayout/DefaultLayout";

class Main extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    return <DefaultLayout />;
  }
}

export default Main;
