import Configuration from "../config/configuration";
import axios from "axios";

class UserService {
  constructor() {
    this.config = new Configuration();
  }

  updateUserProfile(data) {
    return axios.put(this.config.apiBaseUrl + "users/profile", data);
  }

  getUserProfileById(profileId) {
    return axios.get(this.config.apiBaseUrl + "users/profile/" + profileId);
  }

  updateProfileToLocal(profileData) {
    let data = JSON.parse(localStorage.getItem("userData"));
    data.profile = profileData;
    localStorage.setItem("userData", JSON.stringify(data));
  }

  changePassword(data) {
    return axios.put(this.config.apiBaseUrl + "users/changePassword", data);
  }

  uploadImage(formData) {
    return axios.post(this.config.apiBaseUrl + "upload/image", formData);
  }

  handleError(error) {
    console.log(error.message);
  }

  getAds(userId) {
    return axios.get(this.config.apiBaseUrl + `flyers/myflyers/${userId}`);
  }

  postAd(formData) {
    return axios.post(this.config.apiBaseUrl + "flyers", formData);
  }

  getFeaturedPlans() {
    return axios.get(this.config.apiBaseUrl + "featuredplans");
  }

  getUserSubscribedPlans(userId, status) {
    return axios.get(this.config.apiBaseUrl + `usersubscriptions?userId=${userId}${status ? "&status=" + status : ""}`);
  }
  getAuctionSheets(userId) {
    return axios.get(this.config.apiBaseUrl + `auctions/byuser/${userId}`);
  }

  addUserFeaturedPlanSubscription(formData) {
    return axios.post(this.config.apiBaseUrl + "usersubscriptions", formData);
  }

  getFeaturedPlanById(planId) {
    return axios.get(this.config.apiBaseUrl + `featuredplans/${planId}`);
  }

  createFeaturedAd(formData) {
    return axios.post(this.config.apiBaseUrl + "featuredflyers", formData);
  }

  postSellForMe(formData) {
    return axios.post(this.config.apiBaseUrl + "sellforme", formData);
  }

  googleGeoCode(address) {
    const instance = axios.create();
    return instance.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${this.config.googleApiKey}`);
  }

  verifyEmail(id) {
    return axios.get(this.config.apiBaseUrl + "verification/email/" + id);
  }

  getDealers(page, pageSize, filterQuery, sortBy, carsType) {
    const { ct, searchStr, cert, mk, popular } = filterQuery;
    const query = `${popular ? "&popular=" + popular : ""}${ct ? "&ct=" + ct : ""}${mk ? "&mk=" + mk : ""}${searchStr ? "&search=" + searchStr : ""}${
      cert ? "&cert=" + cert : ""
    }&status=Active&sortBy=${sortBy}`;
    return axios.get(this.config.apiBaseUrl + `users/dealers?page=${page}&pageSize=${pageSize}&carsType=${carsType}${query}`);
  }

  addDealerReview(formData) {
    return axios.post(this.config.apiBaseUrl + "dealerreviews", formData);
  }

  getDealerReviews(dealerId, page, pageSize) {
    return axios.get(this.config.apiBaseUrl + `dealerreviews/bydealerid?dealerId=${dealerId}&page=${page}&pageSize=${pageSize}&status=1`);
  }
  sendForgetPasswordEmail(formData) {
    return axios.put(this.config.apiBaseUrl + "users/forgetpassword", formData);
  }

  verifyResetPassToken(token) {
    return axios.get(this.config.apiBaseUrl + `verification/resetpassword/${token}`);
  }
  resetPassword(formData, token) {
    return axios.put(this.config.apiBaseUrl + "users/resetpassword/" + token, formData);
  }
  resendVerificationEmail(formData) {
    return axios.post(this.config.apiBaseUrl + "users/resendverificationemail", formData);
  }
  // getUserProfileById(userId){
  //     return axios.get(this.config.apiBaseUrl + `users/profile?userId=${userId}`);
  // }

  updateChatStatus(chatId, status) {
    return axios.put(this.config.apiBaseUrl + "chats?chatId=" + chatId + "&status=" + status);
  }

  deleteChat(userId, id) {
    return axios.delete(this.config.apiBaseUrl + "chats?userId=" + userId + "&id=" + id);
  }
  updatedUnreadMessages = (data, chatId, userId) => {
    return axios.put(this.config.apiBaseUrl + `chatmessages/unreadmsg?chatId=${chatId}&userId=${userId}`, data);
  };

  resendVerificationCode(formData) {
    return axios.post(this.config.apiBaseUrl + "phoneverification", formData);
  }
  phoneVerificationCode(verifyCode) {
    return axios.get(this.config.apiBaseUrl + "phoneverification/phone/" + verifyCode);
  }

  sendPhoneCodeSignup(formData) {
    return axios.post(this.config.apiBaseUrl + "phoneverification/signup/code", formData);
  }
  sendPhoneCodeSignin(formData) {
    return axios.post(this.config.apiBaseUrl + "phoneverification/signin/code", formData);
  }
  verifyPhoneCodeSignup(formData) {
    return axios.post(this.config.apiBaseUrl + "phoneverification/signup", formData);
  }

  verifyPhoneCodeSignin(formData) {
    return axios.post(this.config.apiBaseUrl + "phoneverification/signin", formData);
  }

  subscribeNewsLetter(formData) {
    return axios.post(this.config.apiBaseUrl + "alerts/addsubscribertochimp", formData);
  }

  sendAuctionSheet(aucId, email) {
    return axios.post(this.config.apiBaseUrl + `auctions/sendauctionsheet?aucId=${aucId}&email=${email}`);
  }
}

export default UserService;
