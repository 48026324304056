import Configuration from '../config/configuration';
import axios from 'axios'


class AuthService {
    constructor() {
        this.config = new Configuration();
    }

    signupUser(data) {
        return axios.post(this.config.apiBaseUrl + 'users/signup', data);
    }

    signinUser(data) {
        const { username, password } = data;
        return axios.get(this.config.apiBaseUrl + 'users/signin', {
            params: {
                username,
                password
            }
        });
    }

    googleAuth(data) {
        return axios.post(this.config.apiBaseUrl + 'sociallogin/google', data);
    }
    
    facebookAuth(data) {
        return axios.post(this.config.apiBaseUrl + 'sociallogin/facebook', data);
    }

    signOut() {
        localStorage.clear();
    }

    handleError(error) {
        console.log(error.message);
    }

}

export default AuthService;