import React, { Component, Suspense } from "react";
import "./Home.scss";
import { Link } from "react-router-dom";
import { BsChevronRight, BsNewspaper } from "react-icons/bs";
import { AiOutlineCar, AiOutlineStar } from "react-icons/ai";
import { FaMotorcycle } from "react-icons/fa";
import { SiCoronaengine } from "react-icons/si";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { MdCompareArrows } from "react-icons/md";
import HomeBanner from "./HomeBanner/HomeBanner.js";
const Browse = React.lazy(() => import("./Browse/NewBrowse"));
const NewListing = React.lazy(() => import("./NewListing/NewListing"));
const Featured = React.lazy(() => import("./FeaturedCars/FeaturedCars"));
const MobileFeatured = React.lazy(() => import("./MobileFeaturedCars/MobileFeaturedCars.js"));
const Products = React.lazy(() => import("./Products/Products"));
const Compare = React.lazy(() => import("./Compare/Compare"));
const AuctionSheet = React.lazy(() => import("./AuctionSheet/AuctionSheet"));
const CustomDuty = React.lazy(() => import("./CustomDuty/CustomDuty"));
const NewsDiscussion = React.lazy(() => import("./News&Discussion/News&Discussion"));
const Accessories = React.lazy(() => import("./Accessories/Accessories"));
const SEO = React.lazy(() => import("./SEO/SEO"));
const RecentCars = React.lazy(() => import("./RecentCars/RecentCars.js"));
const Videos = React.lazy(() => import("../NewCars/New/VideoHomePage/VideoHomePage"));
const ListingParts = React.lazy(() => import("../UsedCarHome/Parts/Listing/Listing.js"));
const UsedCarsOverview = React.lazy(() => import("../UsedCarHome/UsedCar"));
const PetrolPriceComponent = React.lazy(() => import("../Shared/PetrolPrices"));

class HomeNew extends Component {
  constructor() {
    super();
    this.state = {
      category: "car",
      condition: "used",
      size: window.innerWidth,
    };
  }

  componentDidMount() {
    this.setState({
      category: this.props.category ? this.props.category : "car",
    });

    window.addEventListener("resize", () => {
      this.setState({
        size: window.innerWidth,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      this.setState({
        category: this.props.category ? this.props.category : "car",
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      console.log("removed listener");
    });
  }

  setCategory = (value) => {
    this.setState({
      category: value,
    });
  };

  loading = () => {
    return (
      <div className="container">
        <div style={{ margin: "30px 0px", minHeight: "320px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "20px" }}></div>
        <div style={{ margin: "30px 0px", minHeight: "320px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "20px" }}></div>
        <div style={{ margin: "30px 0px", minHeight: "320px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: "20px" }}></div>
      </div>
    );
  };

  render() {
    const { category, condition, size } = this.state;

    return (
      <>

        <HomeBanner setCategory={this.setCategory} />
        <Suspense fallback={this.loading()}>
          {category != "parts" ? (
            <>
              <Browse category={category} />
              {category === "car" || category === "bike" ? <NewListing category={category} /> : <ListingParts featured={true} heading="New & Used Featured Parts" />}

              <Suspense>
                {category != "parts" && size > 480 ? (

                  <Featured category={category} />

                ) : (
                  <>
                    {category === "car" ? (

                      <MobileFeatured category={category} />

                    ) : category === "bike" ? (

                      <MobileFeatured category={category} />

                    ) : (
                      ""
                    )}
                  </>
                )}
              </Suspense>

              {window.innerWidth > 480 ? (
                <section className="pt-5 bg-lightgrey">
                  <div className="container">
                    <img
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                      src={require("../../assets/img/AUTO DEALS BANNER PC.png")}
                    />
                  </div>
                </section>
              ) : (
                <section className="pt-5 bg-lightgrey">
                  <div className=" container mb-3">
                    <img
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                      src={require("../../assets/img/AUTO DEALS BANNER MOBILE 2.png")}
                    />
                  </div>
                </section>
              )}

              <Suspense fallback={<div className="my-3">Petrol Prices Loading ...</div>}>
                <PetrolPriceComponent sectionClasses="bg-lightgrey" />
              </Suspense>

              {category === "car" || category === "bike" ? <RecentCars category={category} /> : <ListingParts featured={false} heading="New & Used Recent Parts" />}
              <Products category={category} />
              <Suspense fallback={this.loading()}>{category != "parts" && <Compare {...this.props} category={category} />}</Suspense>
              <CustomDuty category={category} />


              <Suspense fallback={this.loading()}>
                <Videos {...this.props} />
                <NewsDiscussion />
                <Accessories category={category} />
              </Suspense>

              {category === "car" ? (
                <>
                  <AuctionSheet category={category} />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <UsedCarsOverview hideBanner={true} {...this.props} category={category} />
          )}

          {size > 480 ? (
            <SEO category={category} />
          ) : (
            <section className="seocrumb-section section-padding bg-lightgrey">
              <div className="seo-tags-mobile mt-3">
                <div className="px-3">
                  <h3 className="text-primary">Browse Useful Links</h3>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <AiOutlineCar />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <Link to={"/new-cars/pricelist"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">New Car Prices in Pakistan</h5>
                        <p>Find out latest car prices of Toyota, Honda, Suzuki and other car brands</p>
                      </Link>
                    </div>
                    <div className="col-1">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <FaMotorcycle />
                      </div>
                    </div>
                    <div className="details pl-4 col-10 col-10">
                      <Link to={"/new-bikes/pricelist"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">New Bikes Prices in Pakistan</h5>
                        <p>Find latest bike prices of Honda, Yamha, United, Suzuki and Road Prince</p>
                      </Link>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <AiOutlineCar />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <Link to={"/used-cars"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">Used Cars in Pakistan</h5>
                        <p>Browse used car for sale in Pakistan</p>
                      </Link>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <FaMotorcycle />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <Link to={"/used-bikes"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">Used Bikes in Pakistan</h5>
                        <p>Find used bikes & motorcycle in Pakistan</p>
                      </Link>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <SiCoronaengine />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <Link to={"/accessories-spare-parts"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">Parts & Accessories Shop Online</h5>
                        <p>Auto-parts & spare-parts online in Pakistan</p>
                      </Link>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <BsNewspaper />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <a to="/blog" className="text-decoration-none text-dark">
                        <h5 className="mb-0">Latest automotive updates</h5>
                        <p>Car news automotives trends and new models & upcoming models</p>
                      </a>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <HiOutlineOfficeBuilding />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <Link to={"/new-cars-dealers/search/-/"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">New Car Dealers & Showroom</h5>
                        <p>Find out new cars showroom & display center in Pakistan</p>
                      </Link>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <MdCompareArrows />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <Link to={"/car-comparison"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">Car Comparison</h5>
                        <p>Check prices and spcification two or more cars</p>
                      </Link>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <AiOutlineStar />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <Link to={"/recent-car-reviews"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">Car reviews</h5>
                        <p>Car Reviews and ratings from users</p>
                      </Link>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <AiOutlineCar />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <Link to={"/post-ad/sell-car/post-your-ad"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">Sell Car online</h5>
                        <p>Sell used and new cars online in Pakistan at Auto Deals</p>
                      </Link>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                  <div class="d-flex mt-4 border-bottom ">
                    <div className="col-1">
                      <div className="icons" style={{ fontSize: "24px" }}>
                        <FaMotorcycle />
                      </div>
                    </div>
                    <div className="details pl-4 col-10">
                      <Link to={"/post-ad/sell-bike/post-your-ad"} className="text-decoration-none text-dark">
                        <h5 className="mb-0">Sell Bike online</h5>
                        <p>Sell used and new bikes online in Pakistan at Auto Deals</p>
                      </Link>
                    </div>
                    <div className="col-1 ">
                      <BsChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </Suspense>
      </>
    );
  }
}

export default HomeNew;
