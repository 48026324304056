import auth from "./auth";
import loading from "./loading";
import profile from "./profile";
import cart from "./cart";
import socket from "./socket";
import metaData from "./metadata";
import category from "./category";

export const reducers = {
  auth,
  loading,
  profile,
  cart,
  socket,
  metaData,
  category,
};
