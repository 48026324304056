import React, { Component } from "react";
import CommonService from "../../services/common-service";
import UserService from "../../services/user-service";
import LocalStoreService from "../../services/localStore-service";
import OrderService from "../../services/order-service";
import "./UserFeaturedSubscription.scss";

class UserFeaturedSubscription extends Component {
  constructor(props) {
    super(props);
    this.commonService = new CommonService();
    this.userService = new UserService();
    this.orderService = new OrderService();
    this.localService = new LocalStoreService();
    this.state = {
      selectedPlan: {},
      featuredPlan: {},
      userId: this.localService.getUserId(),
      adId: 0,
      planId: 0,
      isSelected: 0,
    };
  }

  componentDidMount() {
    //     debugger;
    if (this.props.match && this.props.match.params) {
      this.setState(
        {
          adId: this.props.match.params.adId,
          planId: this.props.match.params.planId,
        },
        () => {
          if (this.state.planId) {
            this.getFeaturedPlanById();
          } else {
            this.checkUserFeaturedPlan();
          }
        }
      );
    }
  }

  getFeaturedPlanById = () => {
    const { planId } = this.state;
    this.userService
      .getFeaturedPlanById(planId)
      .then((res) => {
        const { data } = res;
        if (data) {
          this.setState(
            {
              featuredPlan: data,
            },
            () => {
              this.addUserFeaturedPlanSubscription();
            }
          );
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong", "error");
        this.userService.handleError(err);
      });
  };

  addUserFeaturedPlanSubscription = () => {
    const { planId, userId, featuredPlan, adId } = this.state;
    let formData = [];
    delete featuredPlan.id;
    formData.push({ userId, planId: planId, ...featuredPlan });
    this.userService
      .addUserFeaturedPlanSubscription(formData)
      .then((res) => {
        const { data } = res;
        if (data && data.message === "Subscriptions added successfully") {
          this.commonService.showToast("Subscriptions added successfully", "success");
          this.props.history.push(`/user-featured-subscription/${adId}`);
          this.checkUserFeaturedPlan();
        }
      })
      .catch((err) => {
        this.commonService.showToast("Something went wrong", "error");
        this.userService.handleError(err);
      });
  };

  checkUserFeaturedPlan = () => {
    const { userId } = this.state;

    this.userService
      .getUserSubscribedPlans(userId, 1)
      .then((res) => {
        const { data } = res;
        if (data && data.length) {
          this.setState({
            subscribedPlans: data,
          });
        } else {
          this.commonService.showToast("No active subscription, please purchase a new plan, Please contact support@autodeals.pk for more details", "warn");
          setTimeout(() => {
            this.props.history.push({ pathname: `/featured-ad-plans/${this.state.adId}` });
          }, 2000);
        }
      })
      .catch((err) => {
        this.userService.handleError(err);
      });
  };

  createFeaturedAd = () => {
    const { selectedPlan, adId } = this.state;
    if (selectedPlan && selectedPlan.subscriptionId && adId) {
      const formData = { adId, subscriptionId: selectedPlan.subscriptionId };
      this.userService
        .createFeaturedAd(formData)
        .then((res) => {
          const { data } = res;
          if (data && data.message && data.message === "Ad already featured") {
            this.commonService.showToast(data.message, "success");
            this.props.history.push("/dashboard/ads");
          } else if (data && data.message && (data.message === "Subscription expired" || data.message === "You have reached your plan limit")) {
            this.commonService.showToast(data.message + ", please purchase a new plan, Please contact support@autodeals.pk for more details", "warn");
            this.props.history.push({ pathname: "/featured-ad-plans", state: { adId: this.state.adId } });
          } else if (data && data.message && data.message === "Ad featured successfully") {
            this.commonService.showToast("Ad featured successfully", "success");
            this.props.history.push("/dashboard/ads");
          }
        })
        .catch((err) => {
          this.commonService.showToast("Something went wrong", "error");
          this.userService.handleError(err);
        });
    }
  };

  selectPlan = (plan) => {
    const { isSelected } = this.state;
    if (isSelected !== plan.id) {
      this.setState({
        isSelected: plan.id,
        selectedPlan: plan,
      });
    } else {
      this.setState({
        isSelected: 0,
        selectedPlan: {},
      });
    }
  };

  getDiscount = (plan) => {
    return Math.round(((+plan.actualAmount - +plan.discountedAmount) / plan.actualAmount) * 100) >= 1 ? (
      <div className="discount-percentage">
        <span>
          {Math.round(((+plan.actualAmount - +plan.discountedAmount) / plan.actualAmount) * 100)}%
          <br />
          Off
        </span>
      </div>
    ) : (
      <span></span>
    );
  };

  render() {
    const { subscribedPlans, isSelected, selectedPlan } = this.state;

    return (
      <div className="featured-ad-plans-subscribed-main">
        <div className="container featured-container">
          <div className="single-plans mt-5">
            <h2>Purchased Plans</h2>
            <div className="row">
              {subscribedPlans &&
                subscribedPlans.length &&
                subscribedPlans.map((plan, i) => {
                  return (
                    <div key={plan.id} className="featured-plans-wrapper column col-12">
                      <div className="featured-panel-container">
                        <div className="featured-plans-panel">
                          <div className="panel-heading d-flex justify-content-between">
                            <div className="ml-5 align-self-center">
                              <h3 className="title-row" data-fontsize="28" data-lineheight="44">
                                {" "}
                                {plan.title}{" "}
                              </h3>
                              <p>
                                {" "}
                                Feature {plan.quantity} ad for {plan.duration} days{" "}
                              </p>
                            </div>
                            <div className="panel-body pricing-row">
                              <div className="price">
                                {/* <span className="currency">$</span> */}
                                <span className="integer-part">PKR {this.commonService.getPriceIntoWords(plan.discountedAmount)}</span>
                                {plan.actualAmount > plan.discountedAmount && <span className="integer-part-prev">PKR {this.commonService.getPriceIntoWords(plan.actualAmount)}</span>}
                                {/* <span className="time price-without-decimal">PKR</span> */}
                              </div>
                              <div>
                                <span className="note">Note: </span>You have only <span>{plan.remainingQty}</span>
                                {plan.remainingQty > 1 ? " ads" : " ad"} remaining
                              </div>
                              {plan.actualAmount > plan.discountedAmount && this.getDiscount(plan)}
                            </div>
                          </div>
                          {/* <div className="panel-body pricing-row">
                            <div className="price ">
                              <span className="integer-part">PKR {this.commonService.getPriceIntoWords(plan.discountedAmount)}</span>
                              {plan.actualAmount > plan.discountedAmount && <span className="integer-part-prev">PKR {this.commonService.getPriceIntoWords(plan.actualAmount)}</span>}
                            </div>
                          </div> */}
                          <div className="panel-footer ">
                            <div className="featured-plans-button-wrapper">
                              <button className="plans-button" onClick={() => this.selectPlan(plan)}>
                                {isSelected === plan.id && <i className="fa fa-check"></i>} {isSelected === plan.id ? "Selected" : "Select"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="subscribe-div">
            <button className="btn btn-primary btn-subscribe" disabled={!selectedPlan.id} onClick={this.createFeaturedAd}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UserFeaturedSubscription;
