export const setMetaData = (metaData) => {
    return {
        type: 'SET_META_DATA',
        payload: metaData
    }
}

export const getMetaData = () => {
    return {
        type: 'GET_META_DATA',
    }
}