import React, { Component } from "react";
import "./App.scss";
import Main from "../src/components/Main/Main";
import Moment from "moment";

class App extends Component {
  render() {
    Moment.locale("en");
    return <Main />;
  }
}

export default App;
