class StyleConfig {
    reactSelectStyle = {
        padding: '11px 8px',
        borderRadius: 0,
        boxShadow: 'none',
        background: 'var(--color-light-gray)',
        border: '1px solid var(--border-color-primary)'
    }
    reactSelectStyleCommon = {
        control: styles => ({ ...styles }),
        indicatorSeparator: styles => ({ ...styles }),
        option: styles => ({ ...styles }),
        input: styles => ({ ...styles }),
        placeholder: styles => ({ ...styles }),
        singleValue: (styles) => ({ ...styles, color: 'var(--text-color-primary)' }),
    }

}

export default StyleConfig;