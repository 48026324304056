const adSuggestions = [
    { value: "Everything is in genuine condition", label: "Bumper-to-Bumper Original" },
    { value: "Authorized dealership maintained", label: "Authorized Workshop Maintained" },
    { value: "All service history log maintained", label: "Complete Service History" },
    { value: "Fresh Import", label: "Fresh Import" },
    { value: "Price is flexible", label: "Price Negotiable" },
    { value: "Alloy Rims", label: "Alloy Rims" },
    { value: "Original Book is available", label: "Original Book" },
    { value: "Original book is missing but duplicate book is available", label: "Duplicate Book" },
    { value: "All original documents are complete", label: "Complete Original File" },
    { value: "Original file missing, duplicate file is available", label: "Duplicate File" },
    { value: "Original number plates missing, only duplicate ones are available", label: "Duplicate Number Plate" },
    { value: "Never been into any accident", label: "Non Accidental Car" },
    { value: "New tires installed recently", label: "New Tires" },
    { value: "Auction Sheet Available", label: "Auction Sheet Available" },
    { value: "All token taxes are paid to date", label: "Token or Tax Up to Date" },
    { value: "Lifetime token tax paid", label: "Lifetime Token Paid" },
    { value: "Need to sell the car urgently", label: "Urgent Sale" },
    { value: "Petrol driven, CNG never installed", label: "Driven on Petrol" },
    { value: "Company fitted CNG", label: "Factory Fitted CNG" },
    { value: "Army Officer driven", label: "Army Officer Car" },
    { value: "Few paint touchups on the body", label: "Minor Touch Ups" },
    { value: "Engine in pristine condition", label: "Sealed Engine" },
    { value: "Engine is changed", label: "Engine Swapped" },
    { value: "Call/SMS only during office hours please", label: "Engine Repaired" },
    { value: "Engine is repaired", label: "Contact During Office Hours" },
    { value: "Exchange is possible with other car", label: "Exchange Possible" }
];

export default adSuggestions;