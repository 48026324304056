import React, { Component } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import "./MessageInput.scss";
import { BiSend } from "react-icons/bi";
import CommonService from "../../../../services/common-service";

class MessageInput extends Component {
  constructor(props) {
    super(props);
    this.commonService = new CommonService();

    this.state = {
      message: "",
      isTyping: false,
      messageType: "",
      maxLengthDescription: 250,
      errors: {
        message: "",
      },
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.sendMessage();
    this.setState({ message: "" });
  };

  sendMessage = () => {
    if (this.state.message) {
      this.props.sendMessage(this.state.message, this.state.messageType);
    } else {
      this.commonService.showToast("Please Enter Message", "error");
    }
  };

  componentWillUnmount() {
    this.stopCheckingTyping();
  }

  sendTyping = () => {
    this.lastUpdateTime = Date.now();
    if (!this.state.isTyping) {
      this.setState({ isTyping: true });
      this.props.sendTyping(true);
      this.startCheckingTyping();
    }
  };

  /*
   *	startCheckingTyping
   *	Start an interval that checks if the user is typing.
   */
  startCheckingTyping = () => {
    this.typingInterval = setInterval(() => {
      if (Date.now() - this.lastUpdateTime > 300) {
        this.setState({ isTyping: false });
        this.stopCheckingTyping();
      }
    }, 300);
  };

  /*
   *	stopCheckingTyping
   *	Start the interval from checking if the user is typing.
   */
  stopCheckingTyping = () => {
    if (this.typingInterval) {
      clearInterval(this.typingInterval);
      this.props.sendTyping(false);
    }
  };
  handleChange = (e, actionMeta) => {
    if (e && e.target) {
      const { value, name, type, checked } = e.target;

      this.checkFormErrors(name, value);
      this.setState({
        ...this.state,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  checkDescriptionLength = (description) => {
    if (description && description.length <= 250) {
      this.setState({
        maxLengthDescription: 250 - description.length,
      });
    } else if (description.length === 0) {
      this.setState({
        maxLengthDescription: 250,
      });
    }
  };

  checkFormErrors = (name, value) => {
    let errors = this.state.errors;
    switch (name) {
      case "message":
        errors.message = value === "" ? "Message is required!" : value.length > 250 ? "Invalid message!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors });
  };

  render() {
    const { message, maxLengthDescription, errors } = this.state;

    return (
      <div id="message-input">
        <div className="text-area">
          <form onSubmit={this.handleSubmit} className="d-flex justify-content-between px-0">
            <div>
              <textarea
                className="form-control"
                name="message"
                rows="2"
                cols="40"
                placeholder="Your Message"
                id="message"
                ref={"messageinput"}
                type="text"
                className="form-control"
                value={message}
                autoComplete={"off"}
                onKeyUp={(e) => {
                  e.keyCode !== 13 && this.sendTyping();
                }}
                onChange={({ target }) => {
                  this.setState({ message: target.value });
                }}
                onChange={(e) => {
                  this.handleChange(e);
                  this.checkDescriptionLength(e.target.value);
                }}
                value={message}
              />

              {errors.message ? (
                <div className="invalid-feedback d-block">{errors.message}</div>
              ) : (
                <p className="ml-2">
                  <span>{maxLengthDescription}</span> Characters Left
                </p>
              )}
            </div>
            <button className="btn msg-btn ml-2" type="submit" disabled={message.length > 250 ? true : false} style={{ whiteSpace: "nowrap" }}>
              Send <BiSend />
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default MessageInput;
