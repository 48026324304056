import React, { Component } from "react";
import "./CarInfoModal.scss";
import CommonService from "../../../../services/common-service";
import UserService from "../../../../services/user-service";
import { MdChevronRight } from "react-icons/md";
import AdsService from "../../../../services/ads-service";
import { data } from "../../../../assets/data/index";

class CarInfoModal extends Component {
  constructor() {
    super();
    this.commonService = new CommonService();
    this.userService = new UserService();
    this.adsService = new AdsService();
    this.state = {
      years: [],
      makes: [],
      models: [],
      genVariants: [],
      year: "",
      make: "",
      model: "",
      variant: "",
      vari: "",
      isYear: true,
      isMake: false,
      isModel: false,
      isVariant: false,
      category: "",
      hideYear: false,
    };
  }

  componentDidMount() {
    this.getYears();
    this.mapCarInfoToState(this.props.carInfo);
    const { category, hideYear } = this.props;
    this.setState(
      {
        category,
        hideYear,
      },
      () => {
        if (hideYear) {
          this.setState({ year: { value: new Date().getFullYear() } }, () => {
            this.getAllMakes(new Date().getFullYear());
          });
        }
      }
    );
  }

  mapCarInfoToState = (carInfo) => {
    if (carInfo) {
      this.setState(
        {
          ...carInfo,
        },
        () => {
          const { year, make, model, variant } = this.state;

          this.getYears(year);
          this.getAllMakes(year.value, make);
          this.getModelsByMakeIdAndMakeType(make.id, model);
          this.getVariantsByModelIdAndYear(model.id, variant);
        }
      );
    }
  };

  getYears = (selectedItem = null) => {
    this.setState(
      {
        years: this.mapAtiveStateToData(data.years.getYears()),
      },
      () => {
        selectedItem && this.addActive(this.state.years, selectedItem, "year");
      }
    );
  };

  getAllMakes = (year, selectedItem = null) => {
    this.resetState("years");
    const { category } = this.state;
    this.adsService
      .getAllMakes(category, year)
      .then((res) => {
        const { data } = res;
        if (data && data.length) {
          this.setState(
            {
              makes: this.mapAtiveStateToData(data),
            },
            () => {
              selectedItem && this.addActive(this.state.makes, selectedItem, "make");
            }
          );
        } else {
          this.setState({
            makes: [],
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  };

  getModelsByMakeIdAndMakeType = (makeId, selectedItem = null) => {
    this.resetState("makes");
    const { category, year } = this.state;
    this.adsService
      .getModelsByMakeIdAndMakeType(makeId, category, year.value)
      .then((res) => {
        const { data } = res;
        if (data && data.length) {
          this.setState(
            {
              models: this.mapAtiveStateToData(data),
            },
            () => {
              selectedItem && this.addActive(this.state.models, selectedItem, "model");
            }
          );
        } else {
          this.setState({
            models: [],
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  };

  //varinat and generations
  getVariantsByModelIdAndYear = (modelId, selectedItem = null) => {
    const { year } = this.state;
    this.resetState("models");
    this.adsService
      .getVariantsByModelIdAndYear(modelId, year.value)
      .then((res) => {
        const { data } = res;
        if (data) {
          this.setState(
            {
              genVariants: this.mapVariantsAndGenerations(data, selectedItem),
            },
            () => {
              selectedItem && this.addActive([], selectedItem, "variant");
            }
          );
        } else {
          this.setState({
            genVariants: [],
          });
        }
      })
      .catch((err) => {
        this.adsService.handleError(err);
      });
  };

  mapVariantsAndGenerations = (data, selectedItem = null) => {
    let variantsList = [];
    if (data.generations && data.generations.length && data.variants && data.variants.length) {
      const { generations, variants } = data;
      generations.forEach((gen) => {
        let genObj = { genId: gen.id, startYear: gen.startYear, endYear: gen.endYear };
        let genVariants = variants.filter((x) => x.generationId === gen.id);
        genObj.variants = this.mapAtiveStateToData(genVariants);
        variantsList.push(genObj);
      });
    }
    return variantsList;
  };

  mapAtiveStateToData = (list) => {
    return list.map((x) => {
      return { ...x, active: false };
    });
  };

  addActive = (list = [], item, name) => {
    item.active = true;
    this.setState({
      [name]: item,
    });
    if (name === "variant") {
      this.addVariantActive(item);
    } else {
      list = this.removeActive(list);
      if (name === "year") {
        let index = list.findIndex((x) => x.value === item.value);
        if (index >= 0) {
          list[index].active = true;
          this.setState({
            [name + "s"]: list,
          });
        }
      } else {
        let index = list.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          list[index].active = true;
          this.setState({
            [name + "s"]: list,
          });
        }
      }
    }
  };

  removeActive = (list = []) => {
    return list.map((x) => {
      return { ...x, active: false };
    });
  };

  addVariantActive = (item) => {
    const { genVariants } = this.state;
    let items = [];
    genVariants.forEach((gen) => {
      gen.variants.forEach((vari) => {
        if (vari.id === item.id) {
          vari.active = true;
        } else {
          vari.active = false;
        }
      });
      items.push(gen);
    });
    this.setState({
      genVariants: items,
    });
  };

  removeVariantActive = () => {
    const { genVariants } = this.state;
    let items = [];
    genVariants.forEach((item) => {
      item.variants.forEach((vari) => {
        vari.active = false;
      });
      items.push(item);
    });
    this.setState({
      genVariants: items,
    });
  };

  changeYearColor = () => {
    this.setState({
      isYear: false,
      isMake: true,
      isModel: false,
      isVariant: false,
    });
  };
  changeMakeColor = () => {
    this.setState({
      isMake: false,
      isYear: false,
      isModel: true,
      isVariant: false,
    });
  };
  changeModelColor = () => {
    this.setState({
      isModel: false,
      isMake: false,
      isVariant: true,
    });
  };
  changeVariantColor = () => {
    this.setState({
      isVariant: true,
      isModel: false,
      isMake: false,
    });
  };

  getActiveTopMenu = () => {};

  resetState = (type) => {
    if (type === "years") {
      this.setState({
        makes: [],
        models: [],
        genVariants: [],
        make: "",
        model: "",
        variant: "",
      });
    } else if (type === "makes") {
      this.setState({
        models: [],
        genVariants: [],
        model: "",
        variant: "",
      });
    } else if (type === "models") {
      this.setState({
        genVariants: [],
        variant: "",
      });
    }
  };

  onCarInfoClose = () => {
    const { year, make, model, variant, hideYear, category } = this.state;
    let carInfo;
    if (hideYear) {
      carInfo = { make, model, variant };
    } else {
      carInfo = { year, make, model, variant };
    }
    this.props.onCarInfoClose(carInfo);
  };

  yearsListing = () => {
    const { years, year } = this.state;
    let yearObj = year;
    return years.map((year) => {
      return (
        <div
          key={year.value}
          className={`info-section ${year.active ? "active-option" : yearObj ? "deactive" : ""}`}
          onClick={() => {
            this.getAllMakes(year.value);
            this.changeYearColor();
            this.addActive(years, year, "year");
          }}
        >
          <div className="text">{year.label}</div>
          <div className="arrow">
            <MdChevronRight />
          </div>
        </div>
      );
    });
  };

  makesListing = () => {
    const { makes, make } = this.state;
    let makeObj = make;
    return makes.map((make) => {
      return (
        <div
          key={make.id}
          className={`info-section ${make.active ? "active-option" : makeObj ? "deactive" : ""}`}
          onClick={() => {
            this.getModelsByMakeIdAndMakeType(make.id);
            this.changeMakeColor();
            this.addActive(makes, make, "make");
          }}
        >
          <div className="text">
            <span className="make-logo" style={{ background: `url(${make.logoUrl})no-repeat 0 -480px` }}>
              {" "}
            </span>
            {make.name}
          </div>
          <div className="arrow">
            <MdChevronRight />
          </div>
        </div>
      );
    });
  };

  modelsListing = () => {
    const { models, model } = this.state;
    let modelObj = model;
    return models.map((model) => {
      return (
        <div
          key={model.id}
          className={`info-section ${model.active ? "active-option" : modelObj ? "deactive" : ""}`}
          onClick={() => {
            this.getVariantsByModelIdAndYear(model.id);
            this.changeModelColor();
            this.addActive(models, model, "model");
          }}
        >
          <div className="text">{model.name}</div>
          <div className="arrow">
            <MdChevronRight />
          </div>
        </div>
      );
    });
  };

  variantsListing = (variants, vari) => {
    let modelvari = vari;
    return variants.map((vari) => {
      const { engineCapacity, engineType, transmission } = vari.car ? vari.car : {};
      return (
        <div
          key={vari.id}
          className={`info-section ${vari.active ? "active-option" : modelvari ? "deactive" : ""}`}
          onClick={() => {
            this.addActive(variants, vari, "variant");
            this.changeVariantColor();
          }}
        >
          <div className="variant-text">
            <div className="variant-model">{vari.name}</div>
            {vari.car && (
              <div className="variant-type">
                {engineCapacity}CC, {transmission}, {engineType}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  generationListing = () => {
    const { genVariants } = this.state;

    return genVariants.map((gen) => {
      return (
        <div key={gen.genId}>
          <div className="">
            <div className="text heading">
              {gen.startYear} - {gen.endYear}
            </div>
          </div>
          {gen.variants && this.variantsListing(gen.variants)}
        </div>
      );
    });
  };

  render() {
    const { isMake, isModel, isVariant, isYear, hideYear } = this.state;

    return (
      <div className="modal-body car-info-modal-main">
        <div className="row info-main">
          {!hideYear && (
            <div className="col-md-3 info-first active-menu">
              <div className="headings">
                <div className={`main-heading ${isYear ? "active-text" : ""}`}>YEAR</div>
              </div>
              <div className="carinfo-content">{this.yearsListing()}</div>
            </div>
          )}
          <div className="col-md-3 info-second">
            <div className="headings">
              <div className={`main-heading ${isMake ? "active-text" : ""}`}>MAKE</div>
            </div>
            <div className="carinfo-content">{this.makesListing()}</div>
          </div>
          <div className="col-md-3 info-third">
            <div className="headings">
              <div className={`main-heading ${isModel ? "active-text" : ""}`}>MODEL</div>
            </div>
            <div className="carinfo-content">{this.modelsListing()}</div>
          </div>
          <div className="col-md-3 info-fourth">
            <div className="headings">
              <div className={`main-heading ${isVariant ? "active-text" : ""}`}>VARIANT {this.props.required ? <span className="optional"></span> : <span className="optional">(Optional)</span>}</div>
            </div>
            <div className="carinfo-content">{this.generationListing()}</div>
          </div>
        </div>
        <div className="carinfo-button">
          <button type="button" className="btn" onClick={this.onCarInfoClose}>
            DONE
          </button>
        </div>
      </div>
    );
  }
}

export default CarInfoModal;
