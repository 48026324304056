import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import "./ToastModal.scss";

const ToastModal = (props) => {
  return (
    <div className="toast-modal">
      <i className="fa fa-exclamation-triangle"></i>
      <h1 className="heading">{props.heading}</h1>
      <p className="message">{props.message}</p>

      {/* <button onClick={onClose}>No</button> */}
      <button
        className="button"
        onClick={() => {
          //   this.handleClickDelete();
          props.onClose();
        }}
      >
        Dismiss
      </button>
    </div>
  );
};

export default ToastModal;
