import React, { Component } from "react";
import "./TopNavigationPrivate.scss";
import Configuration from "../../../../config/configuration";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import AuthService from "../../../../services/auth-service";
import { FiVolume2, FiPackage, FiStar, FiHeart } from "react-icons/fi";
import { AiOutlineWarning, AiOutlineMessage } from "react-icons/ai";
import { RiShutDownLine } from "react-icons/ri";

class TopNavigationPrivate extends Component {
  constructor() {
    super();
    this.authService = new AuthService();
    this.config = new Configuration();
    this.state = {
      unreadChatsCount: 0,
    };
  }
  state = {
    isOpen: false,
  };

  closeMenu = () => {
    this.setState({
      isOpen: false,
    });
  };

  openMenu = () => {
    this.setState({
      isOpen: true,
    });
  };

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  signOut = () => {
    this.authService.signOut();
    this.props.onSignOut();
  };

  componentDidMount() {
    this.setState({
      unreadChatsCount: this.props.unreadChatsCount,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unreadChatsCount !== this.props.unreadChatsCount) {
      this.setState({
        unreadChatsCount: this.props.unreadChatsCount,
      });
    }
  }
  redirectToBlog = () => {
    let autodealsBlogs = this.config.autodealsBlogs;
    window.open(autodealsBlogs, "_self");
  };
  render() {
    const { unreadChatsCount, isOpen } = this.state;

    const { firstName, dealerName, profileImage, username, type } = this.props.userProfile ? this.props.userProfile : {};
    return (
      <div className="top-navigation-private-main" isOpen={isOpen}>
        <div className="top-navigation-private desktop-screen">
          <div className="container">
            <Navbar color="" light expand="md" className="upper-nav-private">
              <NavbarBrand
                onClick={(e) => {
                  window.location = "mailto:support@autodeals.pk";
                  e.preventDefault();
                }}
              >
                <i className="fas fa-envelope"></i>
                support@autodeals.pk
              </NavbarBrand>
              <NavbarToggler onClick={() => this.toggle()} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto opactity-70" navbar>
                  <NavItem>
                    <NavLink tag={Link} onClick={this.redirectToBlog}>
                      Blog
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                                        <NavLink tag={Link} to={"/map"}>MAP</NavLink>
                                    </NavItem> */}
                  <NavItem>
                    <NavLink tag={Link} to={"/faq"}>
                      Faq
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to={"/about-us"}>
                      About Us
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                                        <NavLink tag={Link} to={"/"}>FORUM</NavLink>
                                    </NavItem> */}
                </Nav>

                <span>|</span>
                <div className="contact-num-private">
                  <i className="fas fa-phone-alt"></i>
                  <button
                    className="btn btn-link"
                    onClick={(e) => {
                      window.location = "tel:+92 3041114474";
                      e.preventDefault();
                    }}
                  >
                    +92 3041114474
                  </button>
                </div>
                <span>|</span>
                <div className="private-login-person">
                  <UncontrolledDropdown>
                    <DropdownToggle nav caret>
                      <div className="welcome-person">
                        <p>
                          {" "}
                          Welcome<span className="person-name"> {firstName ? firstName : dealerName ? dealerName.split(" ")[0] : `User-${username}`}!</span>
                        </p>
                      </div>
                      <div className="private-profile-image">
                        <img loading="lazy" src={profileImage || require("../../../../assets/Images/userimage.png")} alt="user-profleimage" />
                      </div>
                      <i className="fas fa-chevron-down private-down"></i>
                    </DropdownToggle>
                    <DropdownMenu right style={{ boxShadow: "0px 0px 20px rgba(0,0,0,0.3)", borderRadius: "10px", marginTop: "30px", border: "0", width: "220px" }}>
                      <DropdownItem>
                        <div className="private-dropdown-content">
                          <div className="dropdown-profile">
                            {<Link to={type === "individual" ? "/user-profile/individual" : "/user-profile/dealer"}>Profile</Link>}
                            {/* <Link to={type === "individual" ? "/user-profile/individual" : "/user-profile/dealer"}>Edit Profile</Link> */}
                            {/* <Link to={"/"}>Change Password</Link> */}
                          </div>
                          <div className="dropdown-ads">
                            <Link to={"/dashboard/ads"}>Dashboard</Link>
                            <Link to={"/dashboard/featuredads"}>Featured Ads</Link>
                            <Link to={"/dashboard/ads"}>Ads</Link>
                            <Link to={"/dashboard/favourites"}>Favorities</Link>
                            {/* <Link to={"/dashboard/my-rides"}>My Rides</Link> */}
                            <Link to={"/dashboard/alerts"}>Alerts</Link>
                          </div>
                          <div className="dropdown-message">
                            <Link to={"/dashboard/messages"}>Messages {unreadChatsCount ? unreadChatsCount : ""}</Link>
                            <Link to={"/dashboard/payment"}>Payment</Link>
                          </div>
                          <div className="dropdown-signout">
                            <Link to={"/auth/signin"} onClick={this.signOut}>
                              Sign Out
                            </Link>
                          </div>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <span>|</span>
                  <Link to={"/dashboard/messages"} className="private-message">
                    <i className="fas fa-envelope private-message-icon"></i>
                    {unreadChatsCount !== 0 && <span className="badge badge-light counter">{unreadChatsCount}</span>}
                  </Link>
                  {this.props.children}
                </div>
              </Collapse>
            </Navbar>
          </div>
        </div>

        <div className="top-navigation-private mobile-screen">
          <div className="">
            <Navbar color="" light expand="md" className="upper-nav-private">
              {/* <NavbarToggler onClick={() => this.toggle()} /> */}
              {/* <Collapse isOpen={this.state.isOpen} navbar>  */}
              <div className="private-login-person" isOpen={this.state.isOpen}>
                <div class="d-flex p-3">
                  <div className="private-profile-image">
                    <img loading="lazy" src={profileImage || require("../../../../assets/Images/userimage.png")} alt="user-profleimage" />
                  </div>
                  <div className="welcome-person">
                    <p>
                      <span className="text-dark"> {firstName ? firstName : dealerName ? dealerName.split(" ")[0] : username}!</span>
                    </p>
                    {
                      <Link class="border-bottom text-dark" to={type === "individual" ? "/user-profile/individual" : "/user-profile/dealer"}>
                        Profile
                      </Link>
                    }
                  </div>
                </div>
                <div className="private-dropdown-content">
                  <div className="dropdown-profile">
                    {/* <Link to={type === "individual" ? "/user-profile/individual" : "/user-profile/dealer"}>Edit Profile</Link> */}
                    {/* <Link to={"/"}>Change Password</Link>
                    onClick={this.props.toggler} */}
                  </div>
                  <Link class=" p-3" to={"/dashboard/ads"}>
                    <div className="d-flex">
                      <div class="mr-3 align-self-center">
                        <FiVolume2 />
                      </div>
                      <div>
                        <h4 class="mb-0 text-left">My Ads</h4>
                        <p className="mb-0">View Your Posted Ads</p>
                      </div>
                    </div>
                  </Link>
                  <Link class="border-top p-3" to={"/dashboard/featuredads"}>
                    <div class="d-flex">
                      <div className="mr-3 align-self-center">
                        <FiStar />
                      </div>
                      <div>
                        <h4 className="mb-0 text-left">Featured Ads</h4>
                        <p className="mb-0">View Your Featured Ads</p>
                      </div>
                    </div>
                  </Link>
                  <Link class="border-top p-3" to={"/dashboard/favourites"}>
                    <div className="d-flex">
                      <div className="mr-3 align-self-center">
                        <FiHeart />
                      </div>
                      <div>
                        <h4 className="mb-0 text-left">My Favourities</h4>
                        <p className="mb-0">View Your Favorities Vehicle</p>
                      </div>
                    </div>
                  </Link>
                  <Link class="border-top p-3" to={"/dashboard/alerts"}>
                    <div className="d-flex">
                      <div className="align-self-center mr-3">
                        <AiOutlineWarning />
                      </div>
                      <div>
                        <h4 className="mb-0 text-left">My Alerts</h4>
                        <p className="mb-0">View Your Vehicle Alerts</p>
                      </div>
                    </div>
                  </Link>
                  <Link class="border-top p-3" to={"/dashboard/messages"}>
                    <div class="d-flex">
                      <div className="mr-3 alignself-center">
                        <AiOutlineMessage />
                      </div>
                      <div>
                        <h4 className="mb-0 text-left">Messages {unreadChatsCount ? unreadChatsCount : ""}</h4>
                        <h4 className="mb-0">View Your Message</h4>
                      </div>
                    </div>
                  </Link>
                  <Link class="border-top p-3" to={"/dashboard/payment"}>
                    <div className="d-flex">
                      <div className="mr-3 align-self-center">
                        <FiPackage />
                      </div>
                      <div>
                        <h4 className="mb-0 text-left">Payment</h4>
                        <p className="mb-0">View Your Payment</p>
                      </div>
                    </div>
                  </Link>
                  <Link
                    className="border-top mb-3"
                    to={"/auth/signin"}
                    onClick={this.signOut}
                    style={{ backgroundColor: "var(--orange)", color: "white", margin: "0 10px", borderRadius: "10px", textAlign: "center", padding: "8px " }}
                  >
                    <RiShutDownLine /> &nbsp;Sign Out
                  </Link>
                </div>
                {/* <span>|</span>
                                    <Link to={"/dashboard/messages"} className="private-message"><i className="fas fa-envelope private-message-icon"></i>
                                        {unreadChatsCount !== 0 && <span className="badge badge-light counter">{unreadChatsCount}</span>}
                                    </Link> */}
                {/* {this.props.children} */}
              </div>
              {/* </Collapse> */}
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}

export default TopNavigationPrivate;
