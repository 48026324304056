class Configuration {
  // apiBaseUrl = 'https://autodeals-be.herokuapp.com/';
  // apiBaseUrl = "https://autodeals-be2.herokuapp.com/"; //be2 is for second option if be free hours expired
  // apiBaseUrl = "http://localhost:4000/";
  apiBaseUrl = "https://api.autodeals.pk/";
  // hostUrl = "http://localhost:3000";
  hostUrl = "https://beta.autodeals.pk";
  auctionSheetUrl = "https://myauctionsheet.com/";
  googleClientId = "243198468885-ifumanj6calpbtebt8ttlt7a1vt4rfr1.apps.googleusercontent.com";
  fbAppId = "786077899175283";
  googleApiKey = "AIzaSyBhi69sc4hEM4RNCGSeftG__3kK4aRSxlE"; //AIzaSyDY_l918L_nK2gnSvXAaRXDl9ME-__npBE
  proxyUrl = "https://secret-ocean-49799.herokuapp.com/";
  currConvApiKey = "2c92533b148817d93ce0";
  //stripePublishableKey = "pk_test_51MH9ToJKL930r4Ibb9mtnT7ZpEbS4wcxt4nuW5Sc3WmZiB9zaQ5xa3fmXhPBE6kEt3Ug7ywi5nKHHeqV3voF4qcF00YSkCCaco";
  stripePublishableKey = "pk_live_51MH9ToJKL930r4Ib6A0aFg5JaBx9MwVppL3sqmazgTwG2yawtcyjtziW48jXmCfhkH6IBMa7foUwgXXhpFD68BEr00bXGVzbJP";
  // stripePublishableKeyMAS = "pk_live_51MW18fIgEfzMzDhwHjmRbY0PYbpJfulMIdM4IZRathHGTFIB7YQLQeRC1YAOJg5wbfgAhSmfP8X4fIrF8h77QzS900tQCNdNFy";
  stripePublishableKeyMAS = "pk_live_51MW18fIgEfzMzDhwHjmRbY0PYbpJfulMIdM4IZRathHGTFIB7YQLQeRC1YAOJg5wbfgAhSmfP8X4fIrF8h77QzS900tQCNdNFy";
  //stripePublishableKeyMAS = "pk_test_51MW18fIgEfzMzDhwrN4fR6KPMgdtOtHbGmrkuBKR0E3gvEhFJIoiM3k5CbXZSZ7biAMIq5OxTLH9melEYkfe6X5O00SLYGCwm8";
  paypalSandboxKey = "AdnI1Q9UkQuiChIuNwUmwMXI3oHnS1Eyr-hm3drDbiUdR-YpfFZxVAPf8al5YFNZbw5DJYwgIC6vJWPn";
  paypalProdKey = "AR0lKd1Te5YQpDymrMOF8-f9HfXpOx501uUJOba0BJ5s8UwK1FdZqF10EwcjNtdPY1FKvINMNoCjVJ6X";
  // "AdnI1Q9UkQuiChIuNwUmwMXI3oHnS1Eyr-hm3drDbiUdR-YpfFZxVAPf8al5YFNZbw5DJYwgIC6vJWPn"; //sandbox
  autodealsBlogs = "https://autodeals.pk/blog";
}

export default Configuration;
