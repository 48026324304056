import React, { Component } from "react";
import "./TopNavigation.scss";
import Configuration from "../../../../config/configuration";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { ImMobile } from "react-icons/im";
import { GoTriangleDown } from "react-icons/go";

class TopNavigation extends Component {
  constructor() {
    super();
    this.config = new Configuration();
  }

  state = {
    isOpen: false,
  };
  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  redirectToBlog = () => {
    let autodealsBlogs = this.config.autodealsBlogs;
    window.open(autodealsBlogs, "_self");
  };
  render() {
    return (
      <div className="top-navigation" style={{ borderBottom: "none" }}>
        <div className="container Desktop-Menu">
          <Navbar color="" light expand="md" className="upper-nav">
            <NavbarBrand
              onClick={(e) => {
                window.location = "mailto:support@autodeals.pk";
                e.preventDefault();
              }}
            >
              <ImMobile size={16} /> &nbsp; <span className="text-orange" style={{opacity: 1}}>Mobile App Coming Soon</span>
              {/* <i className="fas fa-envelope"></i>
              support@autodeals.pk */}
            </NavbarBrand>
            <NavbarToggler onClick={() => this.toggle()} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto opactity-70" navbar>
                {/* <NavItem>
                  <NavLink tag={Link} onClick={this.redirectToBlog}>Blog</NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink tag={Link} to={"/map"}>MAP</NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink tag={Link} to={"/faq"}>Faq</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to={"/about-us"}>About Us</NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink tag={Link} to={"/"}>FORUM</NavLink>
                </NavItem> */}
              </Nav>

              {/* <span>|</span>
              <div className="contact-num">
                <i className="fas fa-phone-alt"></i>
                <button className="btn btn-link" onClick={(e) => { window.location = 'tel:+923226696699'; e.preventDefault(); }}>+92 322 6696699</button>
              </div>
              <span>|</span> */}
              <div className="signup-btn">
                <Link to={"/auth/signup"}>
                  {/* <i className="fas fa-unlock-alt"></i> */}
                  <span>Sign Up</span>
                </Link>
              </div>
              <span>/</span>
              <div className="signin-btn">
                <Link to={"/auth/signin"}>
                  {/* <i className="fas fa-user"></i> */}
                  <span>Sign In</span>
                </Link>
              </div>
              <div className="ml-4">
                <img loading="lazy" style={{ width: "40px", marginRight: "6px" }} src={require("../../../../assets/Images/userimage.png")} />
                <span style={{ opacity: "1" }}>Account</span>
                <GoTriangleDown size={12} />
              </div>
              {/* {this.props.children} */}
            </Collapse>
          </Navbar>
        </div>

        <div className="Mobile-Menu">
          <Navbar color="" light expand="md" className="upper-nav px-0">
            {/* <NavbarBrand onClick={(e) => { window.location = 'mailto:support@autodeals.pk'; e.preventDefault(); }}>
            <i className="fas fa-envelope"></i>
              support@autodeals.pk
            </NavbarBrand> */}
            <div className="d-flex px-3">
              <Link to={"/auth/signup"} className="sign-up-btn" onClick={this.closeMenu}>
                Sign Up
              </Link>
              <Link to={"/auth/signin"} className="sign-in-btn">
                Sign In
              </Link>
            </div>
          </Navbar>
        </div>
      </div>
    );
  }
}

export default TopNavigation;
