import Ads from "../components/Dashboard/Ads/Ads";
import Favorites from "../components/Dashboard/Favorites/Favorites";
import Rides from "../components/Dashboard/Rides/Rides";
import Alerts from "../components/Dashboard/Alerts/Alerts";
import Messages from "../components/Dashboard/Messages/Messages";
import Payment from "../components/Dashboard/Payment/Payment";
import FeaturedAds from "../components/Dashboard/FeaturedAds/FeaturedAds";
import MyPlans from "../components/UserFeaturedSubscription/UserFeaturedSubscription";

const dashboardRoutes = [
  {
    path: "/dashboard/ads",
    component: Ads,
    exact: true,
  },
  {
    path: "/dashboard/favourites",
    component: Favorites,
    exact: true,
  },
  {
    path: "/dashboard/my-rides",
    component: Rides,
    exact: true,
  },
  {
    path: "/dashboard/alerts",
    component: Alerts,
    exact: true,
  },
  {
    path: "/dashboard/messages",
    component: Messages,
    exact: true,
  },
  {
    path: "/dashboard/payment",
    component: Payment,
    exact: true,
  },
  {
    path: "/dashboard/featuredads",
    component: FeaturedAds,
    exact: true,
  },
  {
    path: "/dashboard/my-plans",
    component: MyPlans,
    exact: true,
  },
];

export default dashboardRoutes;
