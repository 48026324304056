import adSuggestions from './adSuggestions';
import colors from './colors';
import pakCities from './pakCities';
import countries from './countries';
import years from './years';
import prices from './prices';
import months from './months';

export const data = {
    adSuggestions,
    colors,
    pakCities,
    countries,
    years,
    months,
    prices
}