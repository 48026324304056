import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import "./Paginate.scss";

class Paginate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: props.totalPages,
    };
  }

  componentDidMount() {
    this.setState({
      totalPages: this.props.totalPages,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.totalPages !== this.props.totalPages) {
      this.setState({
        totalPages: this.props.totalPages,
      });
    }
  }

  handlePageClick = (pageNo) => {
    this.props.onPageChange(pageNo);
  };

  render() {
    const { totalPages } = this.state;

    return (
      <div className="custom-paginate-main d-flex justify-content-center">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    );
  }
}

export default Paginate;
