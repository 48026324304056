
const metaData = (state = null, action) => {
    if (action.type === "SET_META_DATA") {
        state = {...state, metaData: action.payload}
        return state;
    } else if(action.type === "GET_META_DATA") {
        return state;
    }
    return state;
};

export default metaData;