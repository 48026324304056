import React, { Component } from 'react';
import LocalStoreService from '../../../services/localStore-service';
import UserService from '../../../services/user-service';
import './AuctionSheets.scss';
import Moment from 'moment';
import { Link } from 'react-router-dom';

class AuctionSheets extends Component {
    constructor() {
        super();

        this.userService = new UserService();
        this.localService = new LocalStoreService();
        this.state = {
            userId: 0,
            auctionsheets: [],
        }
    }

    componentDidMount() {
        this.getAuctionSheets();
    }

    getAuctionSheets = () => {
        const userId = this.localService.getUserId()
        this.userService.getAuctionSheets(userId).then(res => {
            const { data } = res;
            if (data && data.auctions) {
                this.setState({
                    auctionsheets: data.auctions
                })
            }
        }).catch(err => {
            this.userService.handleError(err)
        })
    }

    render() {
        const { auctionsheets } = this.state;
        return (
            <div className="users-auction-sheets-main">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th width="10%" className="payment-functions">
                                Ref#
                            </th>
                            <th width="30%" className="text-center">Chassis No</th>
                            <th width="30%" className="text-center">Date</th>
                            <th width="30%" className="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            auctionsheets && auctionsheets.length && auctionsheets.map(item => {
                                return (
                                    <tr >
                                        <td className="payment-functions-content">
                                            {/* <div className="payment-functions-inner">
                                                <div className="car-name">Auction</div>
                                            </div> */}
                                            {item.id}
                                        </td>
                                        <td className="text-center"> {item.chassisNo}</td>
                                        <td className="text-center">{Moment(item.createdAt).format('MMM DD, YYYY ')}</td>
                                        <td className="text-center">
                                            <Link to={`/verified-auction-sheet/${item.id}`}>View</Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>

                </table>
                <button type="button" className="btn auction-cancel" onClick={() => this.props.onClose()}>Close</button>

            </div>
        )
    }
}

export default AuctionSheets;